import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('Components/HomeComponent'),
    meta: { requiresAuth: true },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('Components/ProductsComponent'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('Components/Login.vue'),
    meta: { guestArea: true },
  },
  {
    path: '/login-beta',
    name: 'login-beta',
    component: () => import('Components/Signin.vue'),
    meta: { guestArea: true },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('Components/Registration-two.vue'),
    meta: { guestArea: true },
  },
  {
    path: '/create-account',
    name: 'create-account',
    component: () => import('Components/CreateAccount.vue'),
    meta: { guestArea: true },
  },
  {
    path: '/verify',
    name: 'email-verify',
    component: () => import('Components/EmailVerified.vue'),
    meta: { guestArea: true },
  },
  {
    path: '/verify-otp',
    name: 'verify-otp',
    component: () => import('Components/VerifyOtp.vue'),
    meta: { guestArea: true },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('Components/WelcomePage.vue'),
    meta: { guestArea: true },
  },
  {
    path: '/otp',
    name: 'otp',
    component: () => import('Components/MobileOtp.vue'),
    meta: { guestArea: true },
  },
  {
    path: '/forget-password',
    name: 'ResetPassword',
    component: () => import('Components/ForgetPassword.vue'),
  },
  {
    path: '/resetpassword',
    name: 'UpdatePassword',
    component: () => import('Components/UpdatingPassword.vue'),
  },
]

const router = new VueRouter({
  // mode: 'hash',
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // console.log("routes",to)
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next()
    } else {
      next({
        name: 'login',
      })
    }
  }
  else if (to.matched.some((record) => record.meta.guestArea)) {
    if (isLoggedIn()) {
      next({
        name: 'home',
      })
    } else {
      console.log("next")
      next()
    }
  }
  else {
    next() // make sure to always call next()!
  }
})

export default router

function isLoggedIn() {
  if(localStorage.getItem('state')?.length){
    if (localStorage.getItem('stripeSession')=='active'||localStorage.getItem('stripeSession')=='trialing') {
      // console.log('active')
      return 1
    }
    else{
      console.log("not active")
      return true
    }
  }

  else{
    console.log("no user")
    return 0
  }

  

  
  // return localStorage.getItem('state')?.length

}
