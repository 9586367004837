<template>
  <div id="health-container">
    <div id="progress-bar-container" >
      <div class="progress-bar-child progress"></div>
      <div
        class="progress-bar-child mask"
        :style="healthBarEmptyArea"
      ></div>
    </div>
    <sui-button disabled icon="info" secondary size="mini" attached="right" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    percentage: {
      type: Number,
      default: 0
    }
  },
  computed:{
    ...mapGetters(['themeDarkMode']),
    healthBarEmptyArea(){
      return this.themeDarkMode ? {left: `${this.percentage}%`,background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:{left: `${this.percentage}%`}
    }
  },
  name: 'HealthBar'
}
</script>

<style scoped>
#health-container {
  margin-top: 1rem;
  display: flex;
  height: 35px;
}

#progress-bar-container {
  width: 90%;
  overflow: hidden;
  border: 1px solid #2e2f2f;
  position: relative;
}

.progress-bar-child {
  width: 100%;
  height: 100%;
}

.progress {
  color: white;
  text-align: center;
  line-height: 75px;
  font-size: 35px;
  font-family: 'Segoe UI';
  animation-direction: reverse;
  background: rgb(207, 12, 12);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(217, 35, 15, 1) 0,
    rgba(70, 148, 8, 1) 85%
  );
}

.mask {
  position: absolute;
  background-color: #fff;
  width: 100%;
  top: 0;
}

/* .timelapse {
  animation-name: timelapse;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.86, 0.05, 0.4, 0.96);
} */

/* @keyframes timelapse {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
} */
</style>