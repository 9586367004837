// src/utils/svgUtils.js

export function getSvgByName(name, isTrue) {
  const colorHex = isTrue ? '#110024' : '#001A2C'
  const svgs = {
    ruler: `
      <svg xmlns="http://www.w3.org/2000/svg" class="${
        isTrue ? 'appBlue' : 'text-[#131722]'
      } dark:text-gray-100" width="28" height="28" viewBox="0 0 33 32.996">
        <g id="ruler" transform="translate(0 -0.002)">
          <path id="Path_3" data-name="Path 3" d="M10.577,33a.5.5,0,0,1-.354-.146L.146,22.773a.5.5,0,0,1,0-.707L22.07.143a.514.514,0,0,1,.707,0L32.853,10.221a.5.5,0,0,1,0,.707L10.93,32.852A.5.5,0,0,1,10.577,33ZM1.207,22.42l9.37,9.371L31.793,10.574,22.424,1.2Z" fill="currentColor"/>
          <g id="Group_4" data-name="Group 4">
            <path id="Path_4" data-name="Path 4" d="M21.144,7.207a.5.5,0,0,1-.354-.146L18.323,4.594a.5.5,0,1,1,.707-.707L21.5,6.354a.5.5,0,0,1-.353.853Z" fill="currentColor"/>
            <path id="Path_5" data-name="Path 5" d="M19.119,12.4a.5.5,0,0,1-.354-.146L14.716,8.2a.5.5,0,0,1,.707-.707l4.049,4.049a.5.5,0,0,1,0,.707A.494.494,0,0,1,19.119,12.4Z" fill="currentColor"/>
            <path id="Path_6" data-name="Path 6" d="M13.928,14.424a.5.5,0,0,1-.354-.146l-2.467-2.467a.5.5,0,0,1,.707-.707l2.467,2.467a.5.5,0,0,1-.353.853Z" fill="currentColor"/>
            <path id="Path_7" data-name="Path 7" d="M11.9,19.615a.5.5,0,0,1-.354-.146L7.5,15.42a.5.5,0,1,1,.707-.707l4.049,4.049a.5.5,0,0,1-.353.853Z" fill="currentColor"/>
            <path id="Path_8" data-name="Path 8" d="M6.711,21.64a.5.5,0,0,1-.354-.146L3.89,19.027A.5.5,0,0,1,4.6,18.32l2.467,2.467a.5.5,0,0,1-.353.853Z" fill="currentColor"/>
          </g>
        </g>
      </svg>

    `,
    crosshair: `<svg xmlns="http://www.w3.org/2000/svg" class="${
      isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
    } dark:text-gray-100" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor"><path d="M18 15h8v-1h-8z"></path><path d="M14 18v8h1v-8zM14 3v8h1v-8zM3 15h8v-1h-8z"></path></g></svg>`,
    default: `<svg xmlns="http://www.w3.org/2000/svg" class="${
      isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
    } dark:text-gray-100" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M11.682 16.09l3.504 6.068 1.732-1-3.497-6.057 3.595-2.1L8 7.74v10.512l3.682-2.163zm-.362 1.372L7 20V6l12 7-4.216 2.462 3.5 6.062-3.464 2-3.5-6.062z"></path></svg>`,
    dot: `<svg xmlns="http://www.w3.org/2000/svg" class="${
      isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
    } dark:text-gray-100" viewBox="0 0 28 28" width="28" height="28"><circle fill="currentColor" cx="14" cy="14" r="3"></circle></svg>`,

    rightArrow: `<svg xmlns="http://www.w3.org/2000/svg" class="${
      isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
    } dark:text-gray-100" viewBox="0 0 10 16" width="6" height="10"><path d="M.6 1.4l1.4-1.4 8 8-8 8-1.4-1.4 6.389-6.532-6.389-6.668z"></path></svg>`,
    trendLine: `
    <svg xmlns="http://www.w3.org/2000/svg" class="${
      isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
    } dark:text-gray-300" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor" fill-rule="nonzero"><path d="M7.354 21.354l14-14-.707-.707-14 14z"></path><path d="M22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"></path></g></svg>
    `,
    arc: `
      <svg xmlns="http://www.w3.org/2000/svg" class="${
        isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
      } dark:text-gray-300" viewBox="0 0 28 28" width="25" height="25">
    <g fill="currentColor" stroke="currentColor" stroke-width="0.3"  fill-rule="nonzero">
        <path d="M8.013 23.846c4.923 2.247 9.792 2.626 13.448-.517l-.652-.758c-3.277 2.817-7.775 2.467-12.381.365l-.415.91z">
        </path>
        <path d="M24.035 20.056c2.262-4.176 1.814-8.73-.13-12.98l-.909.416c1.826 3.993 2.244 8.24.16 12.088l.879.476z">
        </path>
        <path d="M8.221 21.401l13.249-14.077-.728-.685-13.249 14.077z"></path>
        <path d="M6.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM22.5 23c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z">
        </path>
    </g>
</svg>
    `,

    horizontalLine: `
          <svg xmlns="http://www.w3.org/2000/svg" class="${
            isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
          } dark:text-gray-300" width="28" height="28" viewBox="0 0 20.057 5.044">
        <g id="horizontal-line" transform="translate(0 -7.8)">
          <rect id="Rectangle_1" data-name="Rectangle 1" width="2.985" height="3.044" transform="translate(1 8.8)" fill="none" stroke="currentColor" stroke-width="1"/>
          <rect id="Rectangle_2" data-name="Rectangle 2" width="2.984" height="3.044" transform="translate(16.073 8.8)" fill="none" stroke="currentColor" stroke-width="1"/>
          <line id="Line_17" data-name="Line 17" x2="12.728" transform="translate(3.985 10.35)" stroke="currentColor" stroke-width="1"/>
        </g>
      </svg>

    `,
    T: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" class="${
      isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
    } dark:text-gray-300" height="28"><path fill="currentColor" d="M8 6.5c0-.28.22-.5.5-.5H14v16h-2v1h5v-1h-2V6h5.5c.28 0 .5.22.5.5V9h1V6.5c0-.83-.67-1.5-1.5-1.5h-12C7.67 5 7 5.67 7 6.5V9h1V6.5Z"></path></svg>
    `,
    emoji: `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="28" width="28" stroke="currentColor" class="${
      isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
    }">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
    </svg>

    `,
    rectangle: `
    <svg xmlns="http://www.w3.org/2000/svg" class="${
      isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
    } dark:!text-gray-300" width="24" height="24" viewBox="0 0 20 20">
        <rect id="rectangle" width="18" height="18" transform="translate(1 1)" fill="rgba(0,0,0,0)" stroke="currentColor" stroke-width="1"/>
      </svg>
    `,
    parallelLine: `
    <svg id="parallel-line" xmlns="http://www.w3.org/2000/svg" class="${
      isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
    } dark:text-gray-300" width="20" height="20" viewBox="0 0 20 20">
      <line id="Line_18" data-name="Line 18" x2="20" transform="translate(0 1)" stroke="currentColor" stroke-width="1.5"/>
      <line id="Line_19" data-name="Line 19" x2="20" transform="translate(0 19)" stroke="currentColor" stroke-width="1.5"/>
    </svg>
    `,
    fibRetracement: `
      <svg id="fib-retracement" xmlns="http://www.w3.org/2000/svg" class="${
        isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
      } dark:text-gray-300" width="20" height="20" viewBox="0 0 20.015 20.015">
        <line id="Line_13" data-name="Line 13" x2="20" transform="translate(0.015 1)" stroke="currentColor" stroke-width="1.5"/>
        <line id="Line_14" data-name="Line 14" x2="20" transform="translate(0.015 19.015)" stroke="currentColor" stroke-width="1.5"/>
        <line id="Line_15" data-name="Line 15" x2="20" transform="translate(0 10)" stroke="currentColor" stroke-width="1.5"/>
        <line id="Line_16" data-name="Line 16" y1="17.678" x2="17.677" transform="translate(1.146 1.249)" stroke="currentColor" stroke-width="1.5"/>
      </svg>
    `,
    tradePlanner: `
      <svg id="trade-planner" xmlns="http://www.w3.org/2000/svg" class="${
        isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
      } dark:text-gray-300" width="20" height="20" viewBox="0 0 20.015 20.015">
        <line id="Line_20" data-name="Line 20" x2="20" transform="translate(0.015 1)" stroke="currentColor" stroke-width="1.5"/>
        <line id="Line_21" data-name="Line 21" x2="20" transform="translate(0.015 19.015)" stroke="currentColor" stroke-width="1.5"/>
        <line id="Line_22" data-name="Line 22" x2="20" transform="translate(0 11)" stroke="currentColor" stroke-width="1.5"/>
      </svg>

    `,
    tradeStop: `
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="${
      isTrue ? 'text-BtnActiveTxtLite' : 'text-[#131722]'
    } dark:text-gray-300" viewBox="0 0 19.877 19.98">
      <g id="trailingstop" transform="translate(-0.111 -0.018)">
        <line id="line5-0" x2="5.884" transform="translate(7.066 10.418)" stroke="currentColor" stroke-width="1.5"/>
        <line id="line5-0-1" x2="5.884" transform="translate(14.105 1.018)" stroke="currentColor" stroke-width="1.5"/>
        <line id="line5-0-7" x2="5.884" transform="translate(0.111 18.997)" stroke="currentColor" stroke-width="1.5"/>
      </g>
    </svg>


    `,
    delete: `
    <span class="icon-KTgbfaP5 dark:text-gray-300" role="img" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M18 7h5v1h-2.01l-1.33 14.64a1.5 1.5 0 0 1-1.5 1.36H9.84a1.5 1.5 0 0 1-1.49-1.36L7.01 8H5V7h5V6c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v1Zm-6-2a1 1 0 0 0-1 1v1h6V6a1 1 0 0 0-1-1h-4ZM8.02 8l1.32 14.54a.5.5 0 0 0 .5.46h8.33a.5.5 0 0 0 .5-.46L19.99 8H8.02Z"></path></svg></span>
    `,

    icon1: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor"><path d="M18 15h8v-1h-8z"></path><path d="M14 18v8h1v-8zM14 3v8h1v-8zM3 15h8v-1h-8z"></path></g></svg>
      `,
    icon2: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor" fill-rule="nonzero"><path d="M7.354 21.354l14-14-.707-.707-14 14z"></path><path d="M22.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"></path></g></svg>
      `,
    icon3: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor" fill-rule="nonzero"><path d="M3 5h22v-1h-22z"></path><path d="M3 17h22v-1h-22z"></path><path d="M3 11h19.5v-1h-19.5z"></path><path d="M5.5 23h19.5v-1h-19.5z"></path><path d="M3.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM24.5 12c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"></path></g></svg>
      `,
    icon4: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor" fill-rule="nonzero"><path d="M20.449 8.505l2.103 9.112.974-.225-2.103-9.112zM13.943 14.011l7.631 4.856.537-.844-7.631-4.856zM14.379 11.716l4.812-3.609-.6-.8-4.812 3.609zM10.96 13.828l-4.721 6.744.819.573 4.721-6.744zM6.331 20.67l2.31-13.088-.985-.174-2.31 13.088zM9.041 7.454l1.995 3.492.868-.496-1.995-3.492z"></path><path d="M8.5 7c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM5.5 24c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM12.5 14c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM20.5 8c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM23.5 21c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"></path></g></svg>
      `,
    icon5: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none"><path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M4.5 5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2 6.5A2.5 2.5 0 0 1 6.95 6H24v1H6.95A2.5 2.5 0 0 1 2 6.5zM4.5 15a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2 16.5a2.5 2.5 0 0 1 4.95-.5h13.1a2.5 2.5 0 1 1 0 1H6.95A2.5 2.5 0 0 1 2 16.5zM22.5 15a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-18 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2 22.5a2.5 2.5 0 0 1 4.95-.5H24v1H6.95A2.5 2.5 0 0 1 2 22.5z"></path><path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M22.4 8.94l-1.39.63-.41-.91 1.39-.63.41.91zm-4 1.8l-1.39.63-.41-.91 1.39-.63.41.91zm-4 1.8l-1.4.63-.4-.91 1.39-.63.41.91zm-4 1.8l-1.4.63-.4-.91 1.39-.63.41.91z"></path></svg>
      `,
    icon6: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor" fill-rule="nonzero"><path d="M1.789 23l.859-.854.221-.228c.18-.19.38-.409.597-.655.619-.704 1.238-1.478 1.815-2.298.982-1.396 1.738-2.776 2.177-4.081 1.234-3.667 5.957-4.716 8.923-1.263 3.251 3.785-.037 9.38-5.379 9.38h-9.211zm9.211-1c4.544 0 7.272-4.642 4.621-7.728-2.45-2.853-6.225-2.015-7.216.931-.474 1.408-1.273 2.869-2.307 4.337-.599.852-1.241 1.653-1.882 2.383l-.068.078h6.853z"></path><path d="M18.182 6.002l-1.419 1.286c-1.031.935-1.075 2.501-.096 3.48l1.877 1.877c.976.976 2.553.954 3.513-.045l5.65-5.874-.721-.693-5.65 5.874c-.574.596-1.507.609-2.086.031l-1.877-1.877c-.574-.574-.548-1.48.061-2.032l1.419-1.286-.672-.741z"></path></g></svg>
      `,
    icon7: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M8 6.5c0-.28.22-.5.5-.5H14v16h-2v1h5v-1h-2V6h5.5c.28 0 .5.22.5.5V9h1V6.5c0-.83-.67-1.5-1.5-1.5h-12C7.67 5 7 5.67 7 6.5V9h1V6.5Z"></path></svg>
      `,
    icon8: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M4.05 14a9.95 9.95 0 1 1 19.9 0 9.95 9.95 0 0 1-19.9 0ZM14 3a11 11 0 1 0 0 22 11 11 0 0 0 0-22Zm-3 13.03a.5.5 0 0 1 .64.3 2.5 2.5 0 0 0 4.72 0 .5.5 0 0 1 .94.34 3.5 3.5 0 0 1-6.6 0 .5.5 0 0 1 .3-.64Zm.5-4.53a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"></path></svg>
      `,
    icon9: `
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"><path fill="currentColor" d="M2 9.75a1.5 1.5 0 0 0-1.5 1.5v5.5a1.5 1.5 0 0 0 1.5 1.5h24a1.5 1.5 0 0 0 1.5-1.5v-5.5a1.5 1.5 0 0 0-1.5-1.5zm0 1h3v2.5h1v-2.5h3.25v3.9h1v-3.9h3.25v2.5h1v-2.5h3.25v3.9h1v-3.9H22v2.5h1v-2.5h3a.5.5 0 0 1 .5.5v5.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-5.5a.5.5 0 0 1 .5-.5z" transform="rotate(-45 14 14)"></path></svg>
    `,
    icon10: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path d="M17.646 18.354l4 4 .708-.708-4-4z"></path><path d="M12.5 21a8.5 8.5 0 1 1 0-17 8.5 8.5 0 0 1 0 17zm0-1a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15z"></path><path d="M9 13h7v-1H9z"></path><path d="M13 16V9h-1v7z"></path></svg>
    `,
    icon11: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor" fill-rule="evenodd"><path fill-rule="nonzero" d="M14 10a2 2 0 0 0-2 2v11H6V12c0-4.416 3.584-8 8-8s8 3.584 8 8v11h-6V12a2 2 0 0 0-2-2zm-3 2a3 3 0 0 1 6 0v10h4V12c0-3.864-3.136-7-7-7s-7 3.136-7 7v10h4V12z"></path><path d="M6.5 18h5v1h-5zm10 0h5v1h-5z"></path></g></svg>
    `,
    icon12: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M17.27 4.56a2.5 2.5 0 0 0-3.54 0l-.58.59-9 9-1 1-.15.14V20h4.7l.15-.15 1-1 9-9 .59-.58a2.5 2.5 0 0 0 0-3.54l-1.17-1.17Zm-2.83.7a1.5 1.5 0 0 1 2.12 0l1.17 1.18a1.5 1.5 0 0 1 0 2.12l-.23.23-3.3-3.29.24-.23Zm-.94.95 3.3 3.29-8.3 8.3-3.3-3.3 8.3-8.3Zm-9 9 3.3 3.29-.5.5H4v-3.3l.5-.5Zm16.5.29a1.5 1.5 0 0 0-3 0V18h4.5c.83 0 1.5.67 1.5 1.5v4c0 .83-.67 1.5-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5v-4c0-.83.67-1.5 1.5-1.5h.5v-2.5a2.5 2.5 0 0 1 5 0v.5h-1v-.5ZM16.5 19a.5.5 0 0 0-.5.5v4c0 .28.22.5.5.5h6a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-6Zm2.5 4v-2h1v2h-1Z"></path></svg>
    `,
    icon13: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="M14 6a3 3 0 0 0-3 3v3h8.5a2.5 2.5 0 0 1 2.5 2.5v7a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 6 21.5v-7A2.5 2.5 0 0 1 8.5 12H10V9a4 4 0 0 1 8 0h-1a3 3 0 0 0-3-3zm-1 11a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0v-2zm-6-2.5c0-.83.67-1.5 1.5-1.5h11c.83 0 1.5.67 1.5 1.5v7c0 .83-.67 1.5-1.5 1.5h-11A1.5 1.5 0 0 1 7 21.5v-7z"></path></svg>

    `,
    icon14: `
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="M5 10.76l-.41-.72-.03-.04.03-.04a15 15 0 012.09-2.9c1.47-1.6 3.6-3.12 6.32-3.12 2.73 0 4.85 1.53 6.33 3.12a15.01 15.01 0 012.08 2.9l.03.04-.03.04a15 15 0 01-2.09 2.9c-1.47 1.6-3.6 3.12-6.32 3.12-2.73 0-4.85-1.53-6.33-3.12a15 15 0 01-1.66-2.18zm17.45-.98L22 10l.45.22-.01.02a5.04 5.04 0 01-.15.28 16.01 16.01 0 01-2.23 3.1c-1.56 1.69-3.94 3.44-7.06 3.44-3.12 0-5.5-1.75-7.06-3.44a16 16 0 01-2.38-3.38v-.02h-.01L4 10l-.45-.22.01-.02a5.4 5.4 0 01.15-.28 16 16 0 012.23-3.1C7.5 4.69 9.88 2.94 13 2.94c3.12 0 5.5 1.75 7.06 3.44a16.01 16.01 0 012.38 3.38v.02h.01zM22 10l.45-.22.1.22-.1.22L22 10zM3.55 9.78L4 10l-.45.22-.1-.22.1-.22zm6.8.22A2.6 2.6 0 0113 7.44 2.6 2.6 0 0115.65 10 2.6 2.6 0 0113 12.56 2.6 2.6 0 0110.35 10zM13 6.44A3.6 3.6 0 009.35 10 3.6 3.6 0 0013 13.56c2 0 3.65-1.58 3.65-3.56A3.6 3.6 0 0013 6.44zm7.85 12l.8-.8.7.71-.79.8a.5.5 0 000 .7l.59.59c.2.2.5.2.7 0l1.8-1.8.7.71-1.79 1.8a1.5 1.5 0 01-2.12 0l-.59-.59a1.5 1.5 0 010-2.12zM16.5 21.5l-.35-.35a.5.5 0 00-.07.07l-1 1.5-1 1.5a.5.5 0 00.42.78h4a2.5 2.5 0 001.73-.77A2.5 2.5 0 0021 22.5a2.5 2.5 0 00-.77-1.73A2.5 2.5 0 0018.5 20a3.1 3.1 0 00-1.65.58 5.28 5.28 0 00-.69.55v.01h-.01l.35.36zm.39.32l-.97 1.46-.49.72h3.07c.34 0 .72-.17 1.02-.48.3-.3.48-.68.48-1.02 0-.34-.17-.72-.48-1.02-.3-.3-.68-.48-1.02-.48-.35 0-.75.18-1.1.42a4.27 4.27 0 00-.51.4z"></path></svg>

    `,
    icon15: `
    <span class="icon-KTgbfaP5" role="img" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M18 7h5v1h-2.01l-1.33 14.64a1.5 1.5 0 0 1-1.5 1.36H9.84a1.5 1.5 0 0 1-1.49-1.36L7.01 8H5V7h5V6c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v1Zm-6-2a1 1 0 0 0-1 1v1h6V6a1 1 0 0 0-1-1h-4ZM8.02 8l1.32 14.54a.5.5 0 0 0 .5.46h8.33a.5.5 0 0 0 .5-.46L19.99 8H8.02Z"></path></svg></span>
    `,
    // svgs for scanners start
    'Saucar-Button': `<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 0.894531C3.26667 11.6181 10.04 26.6312 19 0.894531" stroke=${colorHex} />
    </svg>
    `,
    'Double-Bottom': `<svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.60637 6.25L5.5 21.1215L1.39363 6.25L9.60637 6.25Z" stroke="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    <path d="M18.6064 6.25L14.5 21.1215L10.3936 6.25L18.6064 6.25Z" stroke="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    </svg>
    `,
    'Bearish-Key-Reversal': `<svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.67804 23C5.1109 23 5.46177 22.6373 5.46179 22.1899V8.98559C5.46177 8.93882 5.45796 8.89299 5.45061 8.84843C6.28783 8.69327 7.08928 8.28133 7.73636 7.61253C9.4212 5.87103 9.42122 3.04758 7.73636 1.30609C6.05154 -0.435344 3.31985 -0.435383 1.635 1.30609C-0.0498463 3.04756 -0.0498077 5.87108 1.635 7.61253C2.27825 8.27739 3.07403 8.68841 3.90593 8.84559C3.89826 8.89112 3.89433 8.93785 3.89428 8.98559L3.89433 15.1147L0.784414 15.1147C0.351216 15.1147 0 15.4777 0 15.9255C5.54664e-05 16.3733 0.351216 16.7363 0.784414 16.7363L3.89428 16.7363L3.89433 18.5546L2.48529 18.5546C2.05207 18.5546 1.70086 18.9176 1.70087 19.3654C1.70088 19.8132 2.05208 20.1762 2.48529 20.1762L3.89428 20.1762L3.89431 22.1899C3.89428 22.6373 4.24518 23 4.67804 23ZM2.75594 2.46469C3.82171 1.36311 5.54969 1.36315 6.61541 2.46471C7.68122 3.56633 7.6812 5.35233 6.61543 6.45392C5.54966 7.55551 3.82173 7.55551 2.75594 6.4539C1.6902 5.35233 1.69016 3.56628 2.75594 2.46469Z" fill="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    <g clip-path="url(#clip0_857_29902)">
    <path d="M13.4979 22.9824L17.9961 15.8867L8.99977 15.8867L13.4979 22.9824ZM12.7188 0.00690033L12.7188 16.5963L14.277 16.5963L14.277 0.00690046L12.7188 0.00690033Z" fill="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    </g>
    <defs>
    <clipPath id="clip0_857_29902">
    <rect width="9" height="23" fill="white" transform="translate(9)"/>
    </clipPath>
    </defs>
    </svg>
    
    `,
    'Bullish-Pennant': `<svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 22.0433V2.45508L19 14.6315L1.52941 11.9845" stroke="${
      isTrue ? '#110024' : '#001A2C'
    }" stroke-width="2"/>
    </svg>
    `,
    'Bullish-Flag': `<svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 24.5V13.25M1 13.25V2L19 8.5V18L1 13.25Z" stroke="${
      isTrue ? '#110024' : '#001A2C'
    }" stroke-width="2" stroke-width="2"/>
    </svg>
    `,
    'Ascending-Triangle': `<svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.70577 14.5L9.5 1L17.2942 14.5H1.70577Z" stroke="${
      isTrue ? '#110024' : '#001A2C'
    }" stroke-opacity="0.717647"/>
    </svg>
    
    `,
    'Bullish-Key-Reversal': `<svg width="18" height="23" class="!text=[#6C707A]" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="#6C707A" stroke="#6C707A" clip-rule="evenodd" d="M4.67804 23C5.1109 23 5.46177 22.6373 5.46179 22.1899V8.98559C5.46177 8.93882 5.45796 8.89299 5.45061 8.84843C6.28783 8.69327 7.08928 8.28133 7.73636 7.61253C9.4212 5.87103 9.42122 3.04758 7.73636 1.30609C6.05154 -0.435344 3.31985 -0.435383 1.635 1.30609C-0.0498463 3.04756 -0.0498077 5.87108 1.635 7.61253C2.27825 8.27739 3.07403 8.68841 3.90593 8.84559C3.89826 8.89112 3.89433 8.93785 3.89428 8.98559L3.89433 15.1147L0.784414 15.1147C0.351216 15.1147 0 15.4777 0 15.9255C5.54664e-05 16.3733 0.351216 16.7363 0.784414 16.7363L3.89428 16.7363L3.89433 18.5546L2.48529 18.5546C2.05207 18.5546 1.70086 18.9176 1.70087 19.3654C1.70088 19.8132 2.05208 20.1762 2.48529 20.1762L3.89428 20.1762L3.89431 22.1899C3.89428 22.6373 4.24518 23 4.67804 23ZM2.75594 2.46469C3.82171 1.36311 5.54969 1.36315 6.61541 2.46471C7.68122 3.56633 7.6812 5.35233 6.61543 6.45392C5.54966 7.55551 3.82173 7.55551 2.75594 6.4539C1.6902 5.35233 1.69016 3.56628 2.75594 2.46469Z" fill="#6C707A"/>
    <g clip-path="url(#clip0_4978_2038)">
    <path d="M13.5001 0.0078125L9.00195 7.10355H17.9983L13.5001 0.0078125ZM14.2792 22.9833V6.39397H12.721V22.9833H14.2792Z" fill="#6C707A" stroke="#6C707A"/>
    </g>
    <defs>
    <clipPath id="clip0_4978_2038">
    <rect width="9" height="23" fill="#6C707A" stroke="#6C707A" transform="translate(9)"/>
    </clipPath>
    </defs>
    </svg>
    `,
    'Double-Top': `<svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8936 16.75L15 1.87854L19.1064 16.75L10.8936 16.75Z"  stroke="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    <path d="M1.89364 16.75L6 1.87854L10.1064 16.75L1.89364 16.75Z"  stroke="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    </svg>
    `,
    'Tri-Point-Resistance': `<svg width="19" height="18" viewBox="0 0 19 18"  fill="${
      isTrue ? '#110024' : '#001A2C'
    }" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.39167 2.27734L0.500001 6.83889L6.28335 6.83889L3.39167 2.27734ZM3.89253 17.0473L3.89252 6.38273L2.89082 6.38273L2.89082 17.0473L3.89253 17.0473Z" fill="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    <path d="M9.17683 2.27734L6.28516 6.83889L12.0685 6.83889L9.17683 2.27734ZM9.67768 17.0473L9.67768 6.38273L8.67597 6.38273L8.67598 17.0473L9.67768 17.0473Z" fill="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    <path d="M15.6065 2.27734L12.7148 6.83889L18.4982 6.83889L15.6065 2.27734ZM16.1074 17.0473L16.1074 6.38273L15.1057 6.38273L15.1057 17.0473L16.1074 17.0473Z" fill="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    <line x1="0.5" y1="1" x2="18.5" y2="1"  stroke="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    </svg>
    `,
    'Bearish-Pennant': `<svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 0.125004L1.5 20.375L19.5 7.78716L2.02941 10.5237"  stroke="${
      isTrue ? '#110024' : '#001A2C'
    }" stroke-width="2"/>
    </svg>
    `,
    'Bullish-Key-Reversal': `<svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.67804 23C5.1109 23 5.46177 22.6373 5.46179 22.1899V8.98559C5.46177 8.93882 5.45796 8.89299 5.45061 8.84843C6.28783 8.69327 7.08928 8.28133 7.73636 7.61253C9.4212 5.87103 9.42122 3.04758 7.73636 1.30609C6.05154 -0.435344 3.31985 -0.435383 1.635 1.30609C-0.0498463 3.04756 -0.0498077 5.87108 1.635 7.61253C2.27825 8.27739 3.07403 8.68841 3.90593 8.84559C3.89826 8.89112 3.89433 8.93785 3.89428 8.98559L3.89433 15.1147L0.784414 15.1147C0.351216 15.1147 0 15.4777 0 15.9255C5.54664e-05 16.3733 0.351216 16.7363 0.784414 16.7363L3.89428 16.7363L3.89433 18.5546L2.48529 18.5546C2.05207 18.5546 1.70086 18.9176 1.70087 19.3654C1.70088 19.8132 2.05208 20.1762 2.48529 20.1762L3.89428 20.1762L3.89431 22.1899C3.89428 22.6373 4.24518 23 4.67804 23ZM2.75594 2.46469C3.82171 1.36311 5.54969 1.36315 6.61541 2.46471C7.68122 3.56633 7.6812 5.35233 6.61543 6.45392C5.54966 7.55551 3.82173 7.55551 2.75594 6.4539C1.6902 5.35233 1.69016 3.56628 2.75594 2.46469Z" fill="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    <g clip-path="url(#clip0_4978_2038)">
    <path d="M13.5001 0.0078125L9.00195 7.10355H17.9983L13.5001 0.0078125ZM14.2792 22.9833V6.39397H12.721V22.9833H14.2792Z" fill="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    </g>
    <defs>
    <clipPath id="clip0_4978_2038">
    <rect width="9" height="23" fill="white" transform="translate(9)"/>
    </clipPath>
    </defs>
    </svg>
    `,
    'Tri-Point-Support': `<svg width="18" height="18" viewBox="0 0 18 18" fill="${
      isTrue ? '#110024' : '#001A2C'
    }" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.1083 15.7227L18 11.1611L12.2166 11.1611L15.1083 15.7227ZM14.6075 0.95268L14.6075 11.6173L15.6092 11.6173L15.6092 0.952679L14.6075 0.95268Z" fill="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    <path d="M9.32317 15.7227L12.2148 11.1611L6.43149 11.1611L9.32317 15.7227ZM8.82232 0.95268L8.82232 11.6173L9.82402 11.6173L9.82402 0.952679L8.82232 0.95268Z" fill="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    <path d="M2.89349 15.7227L5.78516 11.1611L0.00180172 11.1611L2.89349 15.7227ZM2.39263 0.95268L2.39263 11.6173L3.39434 11.6173L3.39433 0.952679L2.39263 0.95268Z" fill="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    <line x1="18" y1="17" x2="4.37114e-08" y2="17" stroke="${
      isTrue ? '#110024' : '#001A2C'
    }"/>
    </svg>
    `,
    'Bullish Flag': `<svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 24.5V13.25M1 13.25V2L19 8.5V18L1 13.25Z" stroke="${
      isTrue ? '#110024' : '#001A2C'
    } stroke-width="2"/>
    </svg>
    `,
    'Descending-Triangle': `<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.7942 0.5L9 14L1.20577 0.500002L16.7942 0.5Z" stroke="${
      isTrue ? '#110024' : '#001A2C'
    }" />
    </svg>
    `,
    checkbox: `                                    
    <svg width="18" height="19" class="text-[#B7B7B7] dark:text-[#7F8490]" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18.5C4.75736 18.5 2.63604 18.5 1.31802 17.1819C-1.07288e-07 15.864 0 13.7426 0 9.5C0 5.25736 -1.07288e-07 3.13604 1.31802 1.81802C2.63604 0.5 4.75736 0.5 9 0.5C13.2426 0.5 15.364 0.5 16.6819 1.81802C18 3.13604 18 5.25736 18 9.5C18 13.7426 18 15.864 16.6819 17.1819C15.364 18.5 13.2426 18.5 9 18.5ZM12.6273 6.7727C12.8909 7.0363 12.8909 7.4637 12.6273 7.72727L8.12727 12.2273C7.86366 12.4909 7.43634 12.4909 7.1727 12.2273L5.3727 10.4273C5.1091 10.1637 5.1091 9.73634 5.3727 9.47273C5.6363 9.20912 6.0637 9.20912 6.3273 9.47273L7.65 10.7954L11.6727 6.7727C11.9363 6.5091 12.3637 6.5091 12.6273 6.7727Z" fill="currentColor"/>
    </svg>
    `,
    selected: `<svg width="18" height="18" class="dark:!text-[#6C707A]" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C4.75736 18 2.63604 18 1.31802 16.6819C-1.07288e-07 15.364 0 13.2426 0 9C0 4.75736 -1.07288e-07 2.63604 1.31802 1.31802C2.63604 -1.07288e-07 4.75736 0 9 0C13.2426 0 15.364 -1.07288e-07 16.6819 1.31802C18 2.63604 18 4.75736 18 9C18 13.2426 18 15.364 16.6819 16.6819C15.364 18 13.2426 18 9 18ZM12.6273 6.2727C12.8909 6.5363 12.8909 6.9637 12.6273 7.22727L8.12727 11.7273C7.86366 11.9909 7.43634 11.9909 7.1727 11.7273L5.3727 9.92727C5.1091 9.66366 5.1091 9.23634 5.3727 8.97273C5.6363 8.70912 6.0637 8.70912 6.3273 8.97273L7.65 10.2954L11.6727 6.2727C11.9363 6.0091 12.3637 6.0091 12.6273 6.2727Z" fill="#110024"/>
    </svg>
    `,
    'Bearish-Flag': `<svg width="20" height="24" class="dark:!text-[#6C707A]" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 0.5L1 11.5M1 11.5L1 22.5L19 16.1444L19 6.85556L1 11.5Z"  stroke="${
      isTrue ? '#110024' : '#001A2C'
    }" stroke-width="2"/>
    </svg>`,

    // #2d2d2d
    // Add more SVGs here
  }

  return (
    svgs[name] ||
    `<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke-width="1.5"
  stroke="currentColor"
  class="size-6"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
  />
</svg>`
  )
}
