<template>
  <div
    style="height: full; width: fit-content; background-color: transparent"
    v-if="checkFav"
  >
    <sui-button-group>
      <sui-button
        @click="ResetSettings()"
        secondary
        icon="undo alternate"
      ></sui-button>
    </sui-button-group>

    <select v-model="selectedScan" @change="handleSelectedOption">
      <option disabled value="">Select Scan</option>
      <option value="LatestScan">Latest Scan</option>
      <option :value="i" v-for="(Scan_Name, i) of localScanList" :key="i">
        {{ Scan_Name.scanName }}
      </option>
    </select>

    <sui-button-group>
      <!-- <sui-button secondary icon="angle down"></sui-button> -->
      <sui-button
        :disabled="!valid || userSavedScans.length >= 5"
        secondary
        icon="save outline"
        @click="ShowPopup()"
      ></sui-button>
      <sui-button
        :disabled="selectedScan.length === 0 || selectedScan === 'LatestScan'"
        secondary
        icon="delete"
        @click="Delete()"
      ></sui-button>
    </sui-button-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
    hideFav: {
      type: Boolean,
      default: false,
    },
    scanType: {
      type: String,
      default: '',
    },
  },
  name: 'ScanBottomForm',
  // components:{},
  data() {
    return {
      selectedScan: 'LatestScan',
      localScanList: [],
      propVariable: this.ScanTypeIs,
    }
  },
  computed: {
    ...mapGetters(['userSavedScans', 'userChoice','userData']),
    checkFav() {
      if (this.hideFav) {
        return false
      }
      return this.scanType.length
    },
  },
  methods: {
    handleSelectedOption() {
      console.log('handleSelectedOption', this.selectedScan)
    },
    ShowPopup() {
      this.$store.commit('SCAN_POPUP', true)
    },
    Delete() {
      this.$store.commit(
        'FAV_SELECTED_SCAN_ID',
        this.localScanList[this.selectedScan].id
      )
      this.$emit('deletedScan', this.localScanList[this.selectedScan].scanName)
      this.$store.commit('SCAN_DELETE_POPUP', true)
    },
    ResetSettings() {
      this.selectedScan = ''
      this.$store.commit('SET_USER_CHOICE', false)
      this.$emit('ResetScanDefaultSettings')
    },
    getLatestScan(val) {
      let item = this.userData?.ScanRequests ?? null
      // let item = localStorage.getItem('ScanRequests')
      // console.log("ScanRequests in Scan Form",this.scanType)
      if (item) {
        let latestData = JSON.parse(item)
        if (this.scanType === 'samsPattern') {
          // console.log("ScanRequests in Scan Form",latestData)
        }
        if (val !== '' && val === 'LatestScan' && latestData[this.scanType]) {
          this.$store.commit('SET_USER_CHOICE', false)
          let latestDatum = latestData[this.scanType]
          // if(this.scanType === "indicators")
          // console.log("Scan Settings ",JSON.stringify(latestDatum.events))
          this.$emit('changeScanSettings', latestDatum)
        }
      }
    },
  },
  watch: {
    userSavedScans(val) {
      this.localScanList = val.filter((v) => v.scanType === this.scanType)
    },
    userChoice() {
      if (this.userChoice) {
        this.selectedScan = ''
      }
    },
    userData(n){
      if(n){
        this.getLatestScan(this.selectedScan)
      }
    },
    selectedScan(val) {
      this.getLatestScan(val)
      if (
        val !== '' &&
        this.localScanList[val] &&
        this.localScanList[val].data
      ) {
        this.$emit('changeScanSettings', this.localScanList[val].data)
      }
    },
  },
  mounted() {
    // this.getLatestScan(this.selectedScan)
  },
}
</script>

<style scoped>
select {
  width: 120px;
  height: 32px;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0px 0.2px 1.2px 1.5px #dddddd;
}
</style>
