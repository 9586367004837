<template>
  <div v-if="scanPopup" class="FavouritePopupParent" style="">
    <!-- <div> -->

    <div
      class="Favourite-Popup"
      :style="themeDarkMode ? { backgroundColor: '#151515' } : {}"
    >
      <!-- <div > -->
      <div class="popup-firstChild">
        <!-- <div > -->
        <p :style="themeDarkMode ? { color: '#A2A2A2' } : {}">
          Please enter a name for favourite scan:
        </p>
        <input
          :style="
            themeDarkMode
              ? { backgroundColor: '#505050', color: '#A2A2A2' }
              : {}
          "
          type="text"
          @keypress.enter="SaveSettings()"
          v-model="FavouriteName"
          id=""
        />
      </div>
      <hr />
      <div class="popup-secondChild">
        <!-- <div  > -->
        <sui-button-group>
          <sui-button
            :disabled="!FavouriteName.length"
            :style="[
              themeSecondaryColor,
              themeDarkMode ? { color: '#A2A2A2' } : { color: 'white' },
            ]"
            @click="SaveSettings()"
            >Save</sui-button
          >
        </sui-button-group>
        <sui-button-group>
          <sui-button secondary @click="HidePopup()">Cancel</sui-button>
        </sui-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeleteFavouriteList from './DeleteFavouriteList.vue'
export default {
  name: 'FavouriteScanPopup',
  components: { DeleteFavouriteList },
  computed: {
    ...mapGetters([
      'scanPopup',
      'scans',
      'userSavedScans',
      'themeSecondaryColor',
      'themePrimaryColor',
      'themeFontColor',
      'themeDarkMode',
    ]),
  },
  // props:[],
  data() {
    return {
      FavouriteName: '',
      FavouritePopupParent: false,
    }
  },
  methods: {
    HidePopup() {
      this.$store.commit('SCAN_POPUP', false)
      this.FavouritePopupParent = false
    },
    SaveSettings() {
      // this.$store.commit('SHOW_FAV_PARENT_LAYER',true)
      if (this.FavouriteName.length) {
        this.FavouritePopupParent = true
        this.$emit('saveScanSettings', this.FavouriteName)
        this.FavouriteName = ''
      }
    },
  },
}
</script>

<style scoped>
.Favourite-Popup {
  height: 200px;
  width: 350px;
  box-shadow: 0px 0.2px 20px 2px #afa7a7;
  z-index: 106;
  background-color: #ffffff;
  position: relative;
  top: 10px;
}

.popup-firstChild {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
  width: 100%;

  padding-bottom: 10px;
  box-sizing: border-box;
}

.popup-secondChild {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 40%;
  width: 100%;
  padding: 8px 13px;
  gap: 4px;
}

input {
  width: 80%;
  height: 30%;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0px 0.2px 1.2px 1.5px #dddddd;
}
.FavouritePopupParent {
  display: flex;
  justify-content: center;
  background: rgba(10, 10, 04, 0.5);
  width: 100%;
  height: 100%;
  z-index: 102;
  position: absolute;
}
</style>
