<template>
  <div style="padding: 16px">
    <sui-form>
      <h3>Select one or more of the entry signals</h3>
      <sui-form-field>
        <sui-checkbox label="Darvas" v-model="formData.darvas" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox
          label="Ascending Triangle"
          v-model="formData.ascendingTriangle"
        />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox
          label="Break Resistance"
          v-model="formData.breakResistance"
        />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Newsletter" v-model="formData.newsletter" />
      </sui-form-field>
    </sui-form>
  </div>
</template>

<script>
export default {
  computed: {
    /** @returns {Object} */
    formData: {
      get() {
        return this.$store.state.tadePlanner.entrySignalData;
      },
      set(value) {
        this.$store.commit("UPDATE_TRADE_PLANNER_STATE", {
          key: "entrySignalData",
          data: value,
        });
      },
    },
  },
  name: "EntrySignal",
};
</script>

<style></style>
