<template>
  <div style="padding: 16px">
    <sui-form>
      <h3>Select one or more of the confirmation signals</h3>
      <sui-form-field>
        <sui-checkbox label="OBV" v-model="formData.obv" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Market Depth" v-model="formData.marketDepth" />
      </sui-form-field>
    </sui-form>
  </div>
</template>

<script>
export default {
  computed: {
    /** @returns {Object} */
    formData: {
      get() {
        return this.$store.state.tadePlanner.confirmationSignalData;
      },
      set(value) {
        this.$store.commit("UPDATE_TRADE_PLANNER_STATE", {
          key: "confirmationSignalData",
          data: value,
        });
      },
    },
  },
  name: "ConfirmationSignal",
};
</script>

<style></style>
