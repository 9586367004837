<template>
  <div class="flex flex-col h-full w-full">
    <!-- Search Bar and Column Toggle -->
    <div class="flex justify-between items-center mb-4 py-2">
      <!-- Search Bar -->
      <div class="flex items-center gap-3 border border-gray-300 rounded w-2/3 h-12 px-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
        >
          <path
            stroke="#a3a6af"
            d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"
          ></path>
        </svg>
        <input
          type="text"
          v-model="searchTerm"
          placeholder="Search by symbol or company name..."
          class="px-4 py-2 w-full focus:ring-0 focus:outline-none uppercase"
        />
      </div>

      <!-- Add to Watchlist Button -->
      <button
        class="px-4 py-2 bg-black text-white rounded hover:bg-black/80 h-12"
      >
        Add to Watchlist
      </button>

      <!-- Toggle Columns Dropdown -->
      <div class="relative" v-click-outside="onClickOutside">
        <button
          @click="toggleDropdown"
          class="px-4 py-2 bg-[#E1EBF4]/50 text-black rounded hover:bg-[#E1EBF4] h-12"
        >
          Toggle Columns
        </button>
        <div
          v-if="dropdownVisible"
          class="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg z-20"
        >
          <div
            v-for="(column, index) in columnOrder.filter(col => col !== 'icon')"
            :key="index"
            class="flex items-center px-4 py-2"
          >
            <input
              type="checkbox"
              :checked="visibleColumns.includes(column)"
              :disabled="column === 'symbol'"
            @change="toggleColumn(column)"
              class="mr-2 custom-checkbox"
            />
            <label>{{ column }}</label>
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="border w-full">
      <table class="table-fixed w-full border-collapse">
        <!-- Table Header -->
        <thead class="bg-gray-100 sticky top-0 z-10">
        <tr>
          <th class="px-4 py-2 border-b border-b-gray-300 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-7 fill-gray-300"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                clip-rule="evenodd"
              />
            </svg>
          </th>
          <th
            v-for="(header, i) in visibleColumns"
            :key="i"
            class="px-4 py-2 border-b border-b-gray-300 text-left text-[12px] font-medium"
          >
            {{ header.toUpperCase() }}
          </th>
        </tr>
        </thead>
      </table>

      <!-- Scrollable Table Body -->
      <div v-if="tableData.length === 0" class="flex h-[65vh] w-full items-center justify-center">
        <div class="loader"></div>
      </div>
      <div v-else class="h-[55vh] overflow-y-auto scrollbar">
        <table class="table-fixed w-full border-collapse">
          <tbody>
          <!-- No Data Message -->
          <tr v-if="filteredData.length < 1">
            <td
              colspan="8"
              class="text-center px-4 py-2 text-gray-400 flex justify-center items-center h-[40vh]"
            >
              No match found with&nbsp; <span class="font-[700]" >{{searchTerm.toUpperCase()}}</span>
            </td>
          </tr>
          <!-- Table Rows -->
          <tr
            v-else
            v-for="(row, rowIndex) in filteredData"
            :key="rowIndex"
            class="hover:bg-gray-50 cursor-pointer"
            @click="getClick(row)"
          >
            <!-- SVG Column -->
            <td class="px-4 py-2 border-b border-b-gray-300 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-7 fill-gray-300"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                  clip-rule="evenodd"
                />
              </svg>
            </td>
            <!-- Dynamic Columns -->
            <td
              v-for="(header, colIndex) in visibleColumns"
              :key="colIndex"
              class="px-4 py-2 border-b border-b-gray-300"
              :class="getClass(header, row[header])"
            >
              <!-- Conditional Formatting -->
              <span
                v-if="['change', 'change percent', 'close'].includes(header)"
              >
                  {{ getFixed(header, row[header]) }}
                </span>
              <span class="line-clamp-1" v-else v-html="getFixed(header, row[header])"></span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>


<script>
export default {
  name: 'SearchableTableWithToggle',
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchTerm: '',
      dropdownVisible: false,
      columnOrder: [
        "icon",
        "symbol",
        "company name",
        "close",
        "change",
        "change percent",
        "value",
        "market cap",
      ],
      visibleColumns: [
        'symbol',
        'company name',
        'close',
        'change',
        'change percent',
        'value',
        'market cap'
      ],
      selectedRows: [],
    }
  },
  computed: {
    filteredData() {
      if (!this.searchTerm) {
        return this.tableData
      }

      return this.tableData.filter((row) =>
        row.symbol.toLowerCase().startsWith(this.searchTerm.toLowerCase()) ||
        row['company name']
          .toLowerCase()
          .startsWith(this.searchTerm.toLowerCase())
      )
    }
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible
    },
    toggleColumn(column) {
      if (this.visibleColumns.includes(column)) {
        this.visibleColumns = this.visibleColumns.filter((col) => col !== column)
      } else {
        this.visibleColumns.push(column)
      }
    },
    onClickOutside(event) {
      this.dropdownVisible = false
    },
    getClass(key, val) {
      if (key == 'change' || key == 'change percent') {
        return val < 0 ? 'text-red-500' : 'text-green-600'
      } else {
        return 'text-black'
      }
    },
    getFixed(key, val) {
      if (key == 'change' || key == 'change percent' || key == 'close') {
        return parseFloat(val).toFixed(2)
      } else if (key == 'symbol') {
        return `<span class="bg-[#E1EBF4] px-2 py-2 rounded-full w-[4vw] flex items-center justify-center text-[14px]">${val}</span>`
      } else return val
    },
    getClick(val){
      console.log('val',val)
      const index = this.filteredData.findIndex(selectedRow => selectedRow.symbol === val.symbol);
      if (index > -1) {
        this.selectedRows.splice(index, 1);
      } else {
        this.selectedRows.push(row);
      }
      console.log('Selected Rows:', this.selectedRows);
    }
  }
}
</script>

<style scoped>
.loader {
  border: 4px solid #E1EBF4; /* Light background color */
  border-top: 4px solid #acbed3; /* Primary loader color */
  border-radius: 50%; /* Make it circular */
  width: 40px; /* Loader width */
  height: 40px; /* Loader height */
  animation: spin 1s linear infinite; /* Spinning animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Hide the default checkbox */
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #a3a6af; /* Border color */
  border-radius: 3px; /* Rounded corners */
  background-color: #fff; /* Default background */
  display: inline-block;
  position: relative;
  cursor: pointer;
}

/* Checkbox checked state */
.custom-checkbox:checked {
  background-color: #000000; /* Custom checked color */
  border-color: #000000; /* Border color when checked */
}

/* Add a checkmark */
.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.table-fixed {
  table-layout: fixed;
}

th,
td {
  padding: 8px 12px;
  text-align: left;
}

thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa; /* Match the table header background */
  z-index: 10;
}

tbody {
  overflow-y: auto;
}
</style>
