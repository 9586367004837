var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"chartContainer",staticClass:"f-wh inner-chart relative",staticStyle:{"overflow":"hidden"},attrs:{"id":"chartContainer"}},[_c('div'),_c('span',{staticClass:"absolute top-[35%] left-[35%] watermark xxs:!text-[30px] sm:!text-[48px] md:!text-[54px]"},[_vm._v(" protradingscans.com ")]),_c('ChartUpd',{ref:"chartWrapper",attrs:{"data":_vm.getOhlcv,"titleTxt":_vm.getSymbolName,"colorBack":_vm.colors.back,"colorGrid":_vm.colors.grid,"colorText":_vm.themeFontColor.color,"colorTitle":_vm.themeFontColor.color,"colorWickUp":_vm.settings.colorWickUp,"colorWickDw":_vm.settings.colorWickDw,"colorVolDw":_vm.settings.colorVolDw,"roundOffVolume":_vm.roundOffVolume,"colorCandleDw":_vm.generalSettingSet('chart', 'downColor', 'colorCandleDw'),"colorCandleUp":_vm.generalSettingSet('chart', 'upColor', 'colorCandleUp'),"colorCross":_vm.generalCrossHairSettingSet(
          'crossHair',
          'lineColor',
          'colorCrossHairLine'
        ),"colorTextHL":_vm.generalCrossHairSettingSet(
          'crossHair',
          'textColor',
          'colorCrossHairText'
        ),"colorPanel":_vm.generalCrossHairSettingSet('crossHair', 'boxColor', 'colorCrossHairBox'),"colorVolUp":"#FF0039","enableCrosshair":true,"indexBased":true,"ignoreNegativeIndex":true,"extensions":_vm.extensions,"overlays":_vm.overlays,"height":_vm.chartHeight,"width":_vm.chartWidth,"xsett":_vm.xsett},on:{"legend-button-click":_vm.on_button_click,"ChartLoad":_vm.ChartLoad,"chartUpdate":_vm.chartUpdate,"updatChartRange":_vm.updatChartRange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }