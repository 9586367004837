<template>
  <trading-vue
      ref="tradingChart"

      v-on:legend-button-click="on_button_click"
      v-on:range-changed="handleChartRange"
      v-on:data-len-changed="dataLengthChanges"
      @grid-mousedown="onmousedown"
      :data="chart"

      :titleTxt="titleTxt"
      :colorBack="colorBack"
      :colorGrid="colorGrid"
      :colorText="colorText"
      :decimalPlace="3"
      :roundOffVolume="roundOffVolume"
      :colorTitle="colorTitle"
      :colorWickUp="colorWickUp"
      :colorWickDw="colorWickDw"
      :colorVolDw="colorVolDw"
      :colorCandleDw="colorCandleDw"
      :colorCandleUp="colorCandleUp"
      :colorCross="colorCross"
      :colorTextHL="colorTextHL"
      :colorPanel="colorPanel"
       :colorVolUp="colorVolUp"
      :enableCrosshair="enableCrosshair"
      :indexBased="indexBased"
      :ignoreNegativeIndex="ignoreNegativeIndex"
      :enableZoom="true"
      :extensions="extensions"
      :legend-buttons="['display', 'settings', 'remove']"
      :overlays="overlays"
      :ignore_OHLC="['CompareChartMain']"
      :showSettingsButton="['PnF','Candles','LineChart','HeikenAshi','Kagi','Bars','BarTrend','UpAndDown','CompareChart','CompareChartMain']"

      :height="height"
      :width="width"

      :x-settings="xsett"
  ></trading-vue>
</template>
<script>
import TradingVue, {DataCube} from 'trading-vue-js'
import {chartEvents} from "../chartEvents";
import moment from "moment/moment";
import {GetIterationData} from "../settings/TimeRangeConfig";
import ChartAdding from "./ChartAdding";
import {mapActions, mapGetters} from "vuex";
import {DaysDiffByTimeStamp, FormatUnixDate} from "../utils/candle";
import {debounce} from "../utils/helper";
import {generateUUID} from "../utils";
import {getTimeCalculation} from "../utils/timeCalculation";
import { getDrawingDataV1 } from '../services/drawing'

export default {
  name:"ChartUpd",
  mixins:[ChartAdding],
  components:{
    TradingVue
  },
  props:{
    titleTxt:{type:String},
    colorBack:{type:String},
    colorGrid:{type:String},
    colorText:{type:String},
    colorTitle:{type:String},
    colorWickUp:{type:String},
    colorWickDw:{type:String},
    colorVolDw:{type:String},
    colorCandleDw:{type:String},
    colorCandleUp:{type:String},
    colorCross:{type:String},
    colorTextHL:{type:String},
    colorPanel:{type:String},
    colorVolUp:{type:String},
    roundOffVolume:{type:Boolean},
    
    enableCrosshair:{type:Boolean},
    indexBased:{type:Boolean},
    ignoreNegativeIndex:{type:Boolean},
    
    
    height:{type:Number},
    width:{type:Number},
    xsett:{type:[Array,Object]},
    overlays:{type:[Array,Object]},
    extensions:{type:[Array,Object]},
    data:{type:[Array,Object],default() {
      return []
      }},
    onchart:{type:[Array,Object],default() {
        return []
      }},
    offchart:{type:[Array],default() {
        return []
      }},
  },
  methods:{
    ...mapActions(["saveChartSettings","deleteAllDrawing", "deleteDrawing"]),
    onmousedown(){
      // console.log("onmousedown",data,event.x,this.isCtrlPressed)
      if(!this.isCtrlPressed)
        return
      
      let cursorDecide = this.$refs?.tradingChart?.getCursor()
      if(cursorDecide?.t){
        let time = cursorDecide.t
        let index = cursorDecide.i
        
        const SET_CANDLE_INDEX_UPD = "SET_CANDLE_INDEX_UPD";
        const SET_CANDLE_INDEX_UPD_TRAINING = "SET_CANDLE_INDEX_UPD_TRAINING";
        const trainingModeDataLength = this.trainingModeDataRaw.length;
        const actualDataLength = this.formattedSymbolData.length;
        let checkIfEmitChartChange = this.isTrainingMode ? index >= 0 && index <= trainingModeDataLength : index >= 0 && index <= actualDataLength
        // console.log("cursorDecide",{t:time,i:index,e:checkIfEmitChartChange,tLen:trainingModeDataLength,aLen:actualDataLength})
        if(checkIfEmitChartChange)
        this.$store.commit(this.isTrainingMode ? SET_CANDLE_INDEX_UPD_TRAINING : SET_CANDLE_INDEX_UPD,{time,index,chartHit:true})
        // this.$store.commit(SET_CANDLE_INDEX_UPD_TRAINING,{time:null,index:value,chartHit:false})
        // let grid_id = cursorDecide.data
        // chartEvents.$emit('mouseHits',event)
        // debugger
      }
      
      
    },
    async addOverlaysAfterLoading(userChartlist,allChartOverlays,overlays){
      // console.log(userChartlist)
      for (const overlayType of userChartlist) {
        let config = allChartOverlays.find(n=> n.name === overlayType)
        let overlay = overlays.find(n => n.name.toUpperCase() === overlayType.toUpperCase());
        if (overlay && overlay.methods.meta_info && config) {
          let grid = {}
          
          // console.log("config.childRef",config.childRef)
          const userSettings = config.getData();
          // console.log("this.overlays",config,userSettings)
          let isArray = Array.isArray(userSettings)
          if(isArray){
            for (const settings of userSettings) {
              settings['chartId'] = config.chartId
              settings['tool_type'] = config.tool_type
            }
          }else{
            userSettings['chartId'] = config.chartId
            userSettings['tool_type'] = config.tool_type
          }

          // console.log("userSettings",userSettings)
          // chartEvents.$emit('add_overlay_new',overlay,overlayType,userSettings)
          if(config.childRef === 'VolumeOVB'){
            // grid.id =1

            setTimeout(()=> {
              let gridMap = this.$store.state?.dc?.dcgl
              const volumeKey = "offchart.VolumeOBV0";
              if(gridMap && volumeKey in gridMap){
                let gridID= gridMap[volumeKey].split("_")[0].slice(1)
                grid.id =Number(gridID)
              }else{
                return
              }
              this.addOverlay(overlay,overlayType,userSettings,false,true,grid)
            },300)
            // console.log("overlayInfo",overlayInfo)
          }else{
            this.addOverlay(overlay,overlayType,userSettings,false,true,grid)
          }
          
        }
      }
    },
    avg_grid_h(ovs) {
    if (!ovs.length) return 0.18
    let gh = 0
    for (var ov of ovs) {
      if (ov.grid && typeof ov.grid.height === 'number') {
        gh += ov.grid.height
      }
    }
    return gh / ovs.length
  },
    addOverlay(overlay, type, userSettings, scanResults = false, manuallyAddedFromTools = false,grid= {}) {
      console.log(`addOverlay ${overlay},${type},${userSettings},${scanResults},
      ${manuallyAddedFromTools},${grid},`)
      let meta = overlay.methods.meta_info()
      let side = meta.preset?.side || 'onchart'

      let isArray = Array.isArray(userSettings)
      let settings = isArray ? userSettings : [userSettings]
      // console.log("ChartUpd add_overlay_new", type, userSettings)
      const query = `${side}.${type}`;
      let overlayExist = this.chart.get(query)
      for (const key in settings) {
        const overlaySettings = settings[key]
        const settingsData = {...meta.preset.settings, ...overlaySettings, legendButtons: !scanResults};
        let overlayExistRef = scanResults ? overlayExist.find(d => d.scanResults) : overlayExist.filter(d => !d.scanResults).length > key ? overlayExist.filter(d => !d.scanResults)[key] : undefined
        // console.log("add_overlay_new MERGE", query, userSettings, scanResults, overlayExist, overlayExistRef)
        if (!overlayExistRef) {
          if (scanResults) {
            overlaySettings['scanResults'] = true
          }
          // let chartData = this.chart.get('chart.data')
          // let chartDataType = this.chart.get(type)
          // console.log("add_overlay_new chartData", type, chartData, chartDataType, manuallyAddedFromTools)
          let offchart = this.dc.data.offchart
          let h = this.avg_grid_h(offchart)
          grid.height=h;//0.17;
          const oveylay_tv = {
            type,
            name: meta.preset.name,
            settings: settingsData,
            scanResults,
            side,
            grid,
            // data: manuallyAddedFromTools ? chartData : []
            data: []
          };


          let id = this.chart.add(side, oveylay_tv)
          if (manuallyAddedFromTools) {
            // this.chart.tv.resetChart()
            // let sideData = this.chart.data[side]
            // console.log("add_overlay_new list manually", sideData.length ? sideData.map(d => d.id) : 'no data found')
            // this.chart.data[side].push(oveylay_tv)
            this.addingChartDrawings({side, type}, this)
          }
          // console.log("add_overlay_new NEWCHARTID", id, oveylay_tv)
        } else {
          const previousSettings = JSON.stringify(overlayExistRef.settings);
          const currentSettings = JSON.stringify(settingsData);
          const settingsChanged = this.isSettingsChanged(overlayExistRef.settings, settingsData);
          // console.log("add_overlay_new overlayExistRef.id", overlayExistRef.id, previousSettings, currentSettings, settingsChanged)
          if (settingsChanged) {
            overlayExistRef.settings = settingsData
            this.chart.merge(overlayExistRef.id, overlayExistRef)
            console.log("resetChart calling")
            this.chart.tv.resetChart()
          }

        }

      }

    },
    dataLengthChanges(){
      // console.log("dataLengthChanges")      
    },
    handleChartRange(timeBased,indexBased){
      // timeBased[0]
      // timeBased[1]
      // moment().unix(timeBased[0])
      // console.log("handleChartRange",timeBased,indexBased)
      this.$nextTick(debounce(() => {
        let payload = {
          startRange:FormatUnixDate(timeBased[0]),
          endRange:FormatUnixDate(timeBased[1]),
          daysCount:DaysDiffByTimeStamp(timeBased[1],timeBased[0]),
          startIndex:indexBased[0],
          endIndex:indexBased[1],
          count:indexBased[1] -  indexBased[0],
        }
        this.$store.commit('SET_ChartRangeObj',payload)

        
        this.$emit('updatChartRange',timeBased,indexBased)
      }))
    },
    getChartData(){
      return this.chart.get('chart.data')
    },
    async on_button_click(event) {
      // console.log("on_button_click event",event)
      if (event.button === 'remove') {
        let refChart = `${event.type}.${event.overlay.replace('_', '')}`
        // console.log("refChart == ",refChart)
        if('offchart.VolumeOBV0' === refChart){
          this.$store.dispatch('toggleVolumeChart')
        }else{
          //chartId
          const refChartObj = this.chart.get_one(refChart);
          // console.log("this.chart.get_one(refChart)",refChartObj)
          if(refChartObj?.settings?.chartId){
            if (await this.saveChartSettings({tool_type:refChartObj?.settings?.tool_type, name: name, selected:true, chartId:refChartObj?.settings?.chartId})) {
              let chartList = this.userChartlist;
              let ind = chartList.indexOf(refChartObj?.settings?.chartId)
              if (ind > -1) {
                let overlayData = this.chart.get(`${refChartObj.side}.${refChartObj.type}`)
                this.deleteAllByOverlayData(overlayData)
                chartList.splice(ind, 1);
                // chartEvents.$emit('remove_overlay_new', overlay, name)
                this.$store.commit('SET_USER_CHART_LIST', {data:chartList})
              }
            }          
            
          }
          
        }
      }
    },
    deleteAllByOverlayData(overlayData){
      if (overlayData && overlayData.length > 0) {
        let widgetKeys =Object.keys(this.chart.tv.ws)
        let idsToRemove = []
        for (const overlay of overlayData) {
          if(!overlay.scanResults){
            idsToRemove.push(`${overlay.id}-SettingsWin`)
            this.deleteByID(overlay.id)
          }
        }
        setTimeout(() => {
          this.tv.custom_event({
            event: 'remove-settings-popup',
            args: [widgetKeys,idsToRemove]
          })
        })
      }
    },
    deleteByID(id){
      let offChartList= this.chart.get('offchart')
      let currentId = this.findGridId(id,this)
      // console.log("removeOverlay currentId",currentId)
      let volumeChartDrawings = []
      if(offChartList.length){
        for (const offChartListElement of offChartList) {
          // console.log("removeOverlay offChartListElement",offChartListElement)
          if(offChartListElement.grid?.id && offChartListElement.grid.id === currentId){
            volumeChartDrawings.push(offChartListElement)
          }
        }
        // volumeChartDrawings = offChartList.filter(f => f.grid.id === currentId)
      }
      // console.log("removeOverlay",offChartList,currentId,volumeChartDrawings)
      if(volumeChartDrawings.length){
        for (let volumeChartDrawing of volumeChartDrawings) {
          this.chart.del(volumeChartDrawing.id)
        }
      }
      this.chart.del(id)
    },
    getRange(){
      return this.chart.tv.getRange()
    },
    async setTrading (timestamp1, timestamp2) {
      // console.log("scroller")
      // console.log('setTrading 1',timestamp1, timestamp2)
      if(this.chart && this.chart.data && this.chart.data.chart && this.chart.data.chart.data){

        let dataset = this.chart.data.chart.data
        let startingTime = timestamp1
        let firstCandleTimeObj = moment(dataset[0][0])
        let startingTimeObj = moment(timestamp1)
        let lastCandleTimeObj = moment(dataset[dataset.length - 1 ][0])
        let timestamp2Obj = moment(timestamp2)
        let diffInDaysFromFirstCandle = startingTimeObj.diff(
            firstCandleTimeObj,
            'day'
        )
        // console.log('candleData -- ',dataset.length)
        let endingTimeDiff = timestamp2Obj.diff(lastCandleTimeObj,'day')
        let startingDaysInControls = diffInDaysFromFirstCandle < 0;
        /*console.log(
            JSON.stringify({
              // startingTimeObj: startingTimeObj.toISOString(),
              // firstCandleTimeObj: firstCandleTimeObj.toISOString(),
              lastCandleTimeObj: lastCandleTimeObj.toISOString(),
              timestamp2ObjISO: timestamp2Obj.toISOString(),
              // diffInDaysFromFirstCandle,
              // startingTime,
              // startingTime2:startingTimeObj.valueOf(),
              // startingTime2Diff:startingTimeObj.valueOf() - startingTime,
              endingTimeDiff,
              // decisions:startingDaysInControls,
              decisionsEndNode:endingTimeDiff > 0
            },null,'\t')
        )*/
        const {iterations} = GetIterationData(this.$store.state.timeFrame)
        let rangeObj = { range: '', start: startingDaysInControls ? timestamp1 : startingTime, end: timestamp2 }
        let eventStr = JSON.stringify(rangeObj)
        localStorage.setItem('TimeRange', eventStr)
        this.$store.commit('CHART_VISIBLE_RANGE', '')

        this.chart.tv.setRange(startingDaysInControls
                ? 0
                : startingTime,
            endingTimeDiff > 0 ?  dataset.length +  iterations : timestamp2)
        await this.$nextTick()
      }
      return

    },
    async onDeleteButtonClick() {
      const selectedTool = this.chart.tv.data.data.selected;
      console.log("onDeleteButtonClick == ",selectedTool)
      if (!selectedTool) {
        // console.log("delete all tools")
        await this.deleteAllTools()
      } else {
        // this.selectTool('System:Remove')remove-tool
        let res = await this.deleteDrawing({t:selectedTool,e:'ChartUpd'})
        if (res) {
          this.tv.custom_event({
            event: 'remove-tool',
            args: [0, 0, selectedTool]
          })
          this.chart.del(selectedTool)
        }

      }
    },
    deleteAllChartTools() {
      this.chart.del('Tool')
      this.chart.del('Fibonacci')
      this.chart.del('Trend')
      this.chart.del('TrendLine')
      // console.log("Darvas Delete Darvas Box")
      // this.chart.del('DarvasBox')
      this.chart.tv.custom_event({
        event: 'remove-all-tools-event',
        args: []
      })
    },
    isSettingsChanged(prev,curr){
      let keysArray = Object.keys(curr)
      for (const key of keysArray) {
        if(curr[key] !== prev[key]){
          return true
        }
      }
      return false
    },
    async deleteAllTools() {
      if (await this.deleteAllDrawing()) {
        this.deleteAllChartTools()
      }
    },
    setChartRange(range,event,callback){
      const $store = this.$store;
      const state = $store.state;

      let timeFrameRange = localStorage.getItem('TimeFrameRange')
      console.log("changeTimeFrame--->", "range,event,callback",range,event,state.chartVisibleRange)
      let rangeObj = { range: range ? range : timeFrameRange , start: null, end: null }
      /**
       * isRangeChange shows whether the user clicked on 3M,6M,YTD,1Y,5Y... (buttons on the top)
       */
      let isRangeChange = rangeObj.range !== state.chartVisibleRange
      if (isRangeChange) {
       
        let eventStr = JSON.stringify(rangeObj)
        localStorage.setItem('TimeRange', eventStr)
      }
     
      let hasStoreRange = localStorage.getItem('TimeRange')
      console.log("changeTimeFrame--->", hasStoreRange, timeFrameRange  )
      if (hasStoreRange) {
        rangeObj = JSON.parse(hasStoreRange)
      }
      const candleData =this.chart.get_one('chart.data')
      console.log("changeTimeFrame--->", hasStoreRange)
      // console.log("CompareChart setChartRange",{event,range,hasStoreRange,chartVisibleRange:state.chartVisibleRange,dataLen:candleData.length})
      // console.log("CompareChart setChartRange",candleData)
      if (candleData.length) {
        $store.commit('CHART_VISIBLE_RANGE', rangeObj.range)
        if(
            (isRangeChange && state.chartPredineRangeUpdates) ||
            (rangeObj && rangeObj.range && rangeObj.range.length)
        ){

          if (state.chartPredineRangeUpdates)
            $store.commit('CHART_PREDEFINE_RANGE_UPDATES')
          const endingTime = candleData[candleData.length - 1][0]
          let startingTime = candleData[0][0]
          startingTime = getTimeCalculation(rangeObj,endingTime)
          const {iterations, type} = GetIterationData(state.timeFrame)
          let startingTimeObj = moment(startingTime)
          let firstCandleTimeObj = moment(candleData[0][0])
          let lastCandleTimeObj = moment(candleData[0][candleData.length - 1])
          let diffInDaysFromFirstCandle = startingTimeObj.diff(
              firstCandleTimeObj,
              type
          )
          let startIndex = this.chart.tv.getTimeIndex(candleData[0][0])
          if (startIndex < 0) {
            startIndex = startingTime
          } else {
            startIndex = Math.floor(startIndex)
          }
          $store.dispatch('updateChartStartingTime', startingTime)
          $store.dispatch('updateChartEndingTime', endingTime)
          /*console.log(
                JSON.stringify({
                  startingTimeObj: startingTimeObj.toISOString(),
                  firstCandleTimeObj: firstCandleTimeObj.toISOString(),
                  lastCandleTimeObj: lastCandleTimeObj.toISOString(),
                  diffInDaysFromFirstCandle,
                  calculatedStartingTimeForYears: moment(
                      calculatedStartingTimeForYears
                  ).toISOString(),
                  startingTime,
                  startingTime2:startingTimeObj.valueOf(),
                  startingTime2Diff:startingTimeObj.valueOf() - startingTime,
                  iterations,
                  decisions:diffInDaysFromFirstCandle < 0
                },null,'\t')
            )*/
          this.chart.tv.setRange(
              diffInDaysFromFirstCandle < 0
                  ? 0
                  : startingTime,
              candleData.length + iterations
          )
          // console.log("this.chart.tv.chart.auto_y_axis",this.chart?.tv?.$refs?.chart?.auto_y_axis)
          if(!this.chart?.tv?.$refs?.chart?.auto_y_axis){
            this.chart.tv.toggleSideBarYAxis()
          }
          // this.chart.tv.toggleSideBarYAxis()
        }else{
          state.chartVisibleRange = rangeObj.range
          if (rangeObj.range) {
            // console.log('[TEST 1] rangeObj.range called from store')
          } else {
            let startingTime = rangeObj.start
            let firstCandleTimeObj = moment(candleData[0][0])
            let startingTimeObj = moment(rangeObj.start)
            let lastCandleTimeObj = moment(candleData[candleData.length - 1 ][0])
            let timestamp2Obj = moment(rangeObj.end)
            let diffInDaysFromFirstCandle = startingTimeObj.diff(
                firstCandleTimeObj,
                'day'
            )
            // console.log('candleData -- ',candleData.length)
            let endingTimeDiff = timestamp2Obj.diff(lastCandleTimeObj,'day')
            let startingDaysInControls = diffInDaysFromFirstCandle < 0;
            const {iterations} = GetIterationData(state.timeFrame)
            this.chart.tv.setRange(
                startingDaysInControls
                    ? 0
                    : startingTime,
                endingTimeDiff > 0 ?  candleData.length +  iterations : rangeObj.end
            )
          }
        }
        if(callback){
          callback(this.chart)
        }
      }
    },
    // https://dimensionalsys.atlassian.net/browse/PTS-116 We Created a zoom method under this ticket
    async loadDrawingData(){
      console.log("symbol",this.symbolName)
      setTimeout(async () => {
        let symbolDrawingData = null
        let symbolDrawingDataV1 = await getDrawingDataV1(
          this.symbolName,
          this.$store.state.userId
        )


        // console.log("symbolDrawingDataV1 == ",symbolDrawingDataV1)
        if (symbolDrawingDataV1 && symbolDrawingDataV1.drawing) {
          symbolDrawingData = symbolDrawingDataV1.drawing
        }
        if (
          symbolDrawingData &&
          symbolDrawingData.symbolName &&
          symbolDrawingData.data &&
          Object.values(symbolDrawingData.data).length
        ) {
          this.$store.commit('UPDATE_USER_DRAWING_TOOLS', symbolDrawingData.data)
        } else {
          this.$store.commit('UPDATE_USER_DRAWING_TOOLS', {})
        }
      })
    }
  },
  data() {
    return {
      chart: new DataCube({
        chart: {data:this.data,type:this.$store?.state?.type || 'Candles' }, onchart: [{
          "name": "CurrentCandle",
          "type": "Splitters",
          "data": [
            [1588780800000]
          ],
          "settings": {
            "legend": false,
            "lineColor": "#79999e42",
            "lineWidth": 2
          }
        }], offchart: []
      })
    }
  },
  mounted() {
    window.chartRef = this.chart
    const addOverlayByObject = (overlayData,type) => {
      // console.log("overlayData",overlayData,JSON.stringify(overlayData))
      let isArray = Array.isArray(overlayData)

      for (const overlayDatum of overlayData) {
        overlayDatum.settings['legendButtons'] = false
        // overlayDatum['scanResults'] = true
        // overlayDatum['id'] = generateUUID()
      }
      
      
      // this.chart.data.onchart.push(overlayData)
      const query = `onchart`;
      // console.log("Darvas addOverlayByObject query",query)
      let overlayExist = this.chart.get(query)
      console.log("Darvas addOverlayByObject overlayExist",overlayExist.length)
      overlayExist = overlayExist.filter(d => d.type ===type)
      if(overlayExist && overlayExist.length > 0){
        for (const removeSetting of overlayExist) {
          // debugger
          // console.log("Darvas addOverlayByObject overlay",removeSetting)
          if(removeSetting.scanResults && removeSetting.name){
            console.log("Darvas addOverlayByObject deleting overlay",removeSetting.name)
            this.chart.del(removeSetting.name)
          }
        }
        // this.chart.data.onchart.push(overlayData[i])
        // this.chart.tv.resetChart()
        // return;
      }
      this.$nextTick(() => {
        console.log("Darvas overlayData",overlayData)
        for (const overlayDatum of overlayData) {

          // console.log("addOverlayByObject addingOverlay",overlayDatum)
          this.chart.data.onchart.push(overlayDatum)
          // this.chart.add('onchart',overlayDatum)
        }
      })
      // this.chart.tv.resetChart(true)
    }
    
    const removeOverlay = (overlay,type) => {
      // console.log("removeOverlay")
      let meta = overlay.methods.meta_info()
      let side=meta.preset?.side || 'onchart'
      let overlayData = this.chart.get(`${side}.${type}`)
      this.deleteAllByOverlayData(overlayData)
    }
    const removeOverlayScan = (type,forceDel=false) => {
      // console.log("removeOverlay",this.chart.get(`.settings.scanResults`))
      let removeSettings = [...this.chart.get(`onchart`),...this.chart.get(`offchart`)]
      // console.log("remove_scan_overlay_new 1",type,forceDel,removeSettings)
      
      for (const removeSetting of removeSettings) {
        // if('ChartPatterns' === removeSetting.type){
        //   console.log("remove_scan_overlay_new 3",JSON.stringify(removeSetting))
        //   // this.chart.del(removeSetting.id)
        // }else{
          if(removeSetting && (removeSetting.scanResults) && removeSetting.id){
            // console.log("remove_scan_overlay_new 2",removeSetting.type,type,!type === removeSetting.type,"startWith",removeSetting.id)
            if(forceDel){
              // this.chart.del('ChartPatterns')
              this.chart.del(removeSetting.id)
              // this.chart.tv.resetChart()
            }else{
              if(type !== removeSetting.type){
                // console.log("remove_scan_overlay_new 4",removeSetting.id)
                this.chart.del(removeSetting.id)
                // this.chart.tv.resetChart()
              }

            }
          }
        // }
        
      }
      // let meta = overlay.methods.meta_info()
      // let side=meta.preset?.side || 'onchart'
      // let overlayData = this.chart.get(`${side}.${type}`)
      // if (overlayData && overlayData.length > 0) {
      //   for (const overlay of overlayData) {
      //     // this.chart.del('')
      //   }
      // }
    }
    
   
    const setChartType = (type) => {
      // console.log("setChartType",type)
      // this.chart.data.chart.type = type
      this.chart.set('chart.type', type)
      // this.chart.tv.resetChart()
    }
    const deleteAllTools = () => {
      // console.log("deleteAllTools")
      this.deleteAllChartTools()
    }
    const onDeleteButtonClick = () => {
      // console.log("deleteAllTools")
      this.onDeleteButtonClick()
    }
    
    const generateCustomEvent = (event,args) => {
      const customEvent = {
        event,
        args
      };
      console.log("drawing-pro customEvent",customEvent)
      this.chart.tv.custom_event(customEvent)
    }
    const bindComponentEvent = (name,event) => {
      // console.log("bindComponentEvent",name,event)
    this.chart.tv.$on(
          name,event
      )
    }
    chartEvents.$on("add_overlay_new", this.addOverlay)
    chartEvents.$on("add_overlay_object", addOverlayByObject)
    
    chartEvents.$on("remove_overlay_new", removeOverlay)
    chartEvents.$on("reset_chart", () => {
      console.log("reset_chart")
      this.chart.tv.resetChart()
    })
    chartEvents.$on("remove_scan_overlay_new", removeOverlayScan)
    
    chartEvents.$on("deleteAllTools", deleteAllTools)
    
    chartEvents.$on("setChartType", setChartType)
    chartEvents.$on("setChartRange", this.setChartRange)
    
    chartEvents.$on("generateCustomEvent", generateCustomEvent)
    chartEvents.$on("bindComponentEvent", bindComponentEvent)
    chartEvents.$on("onDeleteButtonClick", onDeleteButtonClick)
    chartEvents.$on("loadDrawingData", this.loadDrawingData)
    chartEvents.$on("remove_comparision_overlays", (param) => {
      this.chart.del('onchart.CompareChartSub')
      if(param){
        console.log("after deletion")
        param()
      }
    })
    chartEvents.$on("addEmojiOnChart", (param) => {
      this.chart.del('onchart.EmojiTool')
      if(param){
        console.log("after deletion")
        param()
      }
    })
    // this.$store.commit('SET_TRADING_CHART_REFERENCE', {
    //   tradingChartReference: this.$refs.tradingChart
    // })

    document.addEventListener('keydown', (event) => {
      let name = event.key;
      let code = event.code;
      // Alert the key name and key code on keydown
      if(name === "Control"){
        if(!this.isCtrlPressed){
          this.isCtrlPressed = true
        }
      }else{
        this.isCtrlPressed = false
      }
      // console.log(`Key pressed ${name} \r\n Key code value: ${code}`);
    }, false);
    document.addEventListener('keyup', (event) => {
      let name = event.key;
      let code = event.code;
      // Alert the key name and key code on keydown
      if(name === "Control"){
        if(this.isCtrlPressed){
          this.isCtrlPressed = false
        }
      }
    }, false);
    this.$emit('ChartLoad',this.$refs.tradingChart,this.chart)
  },
  computed:{
    ...mapGetters(["symbolName","trainingModeDataRaw","formattedSymbolData","userChartlist","timeFrame","isTrainingMode","candleIndexJumpSelected",
      'chartLowerRange','chartUpperRange','comparisionIndex','dc']),
    isCtrlPressed:{
      get(){
        return this.$store.state.chartControlPressed
      },
      set(val){
        // console.log("isCtrlPressed",val)
        this.$store.state.chartControlPressed = val
      }
    }
  },
  watch:{
    data:{
      async handler(val){
        // console.log("Candle Watch Updated",val.length)
        // this.chart.set('chart.tf', this.timeFrame)
        const settings = {upper:this.chartUpperRange,lower:this.chartLowerRange,comparisionIndex:this.comparisionIndex};
        this.chart.merge('chart', {data:val,tf:this.timeFrame,settings: settings})
        await this.$nextTick()
        if(this.isTrainingMode){
          this.$nextTick(() => {
            let val = this.$props.data
            // console.log()
            const startingTimeFrame = val[0][0];
            const endingTimeStamp = val[val.length - 1][0];
            let totalCandles = val.length;
            let currentIndex = this.candleIndexJumpSelected?.index;
            let remainingDays = 60 - totalCandles;

            let dateAfterAddingRemainingDays = moment(endingTimeStamp).add(remainingDays,'days').valueOf();
            // console.log("startingTimeFrame == ",moment(startingTimeFrame).format("MM/DD/YYYY"))
            // console.log("endingTimeStamp == ",moment(dateAfterAddingRemainingDays).format("MM/DD/YYYY"))
            // this.chart.tv.setRange(startingTimeFrame,dateAfterAddingRemainingDays)
            let firstIndex = 0;
            let lastIndex = this.trainingModeDataRaw.length
            const t2 = currentIndex >= lastIndex - 5 ? currentIndex + 5 : lastIndex;
            // console.log("dataLengthChanges" ,JSON.stringify({totalCandles,remainingDays,currentIndex,t2}))
            this.chart.tv.setRange(0,t2)
          })
        }
        this.$emit("chartUpdate")
        // let ranges = this.chart.tv.getRange()
        // console.log("trainingModeIndex",ranges,this.chart.tv.ti_map)
      },
      deep:true      
    }
  }
}
</script>