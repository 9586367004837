<template>
<!--  <div class="check-box-container" v-for="(m,i) of map">-->
  <div>
    <input type="radio"
           :name="name"           :value="m.value"
           :id="getId + i"
           v-model="localState"
    >
    <label :for="getId + i" >{{ m.label }}</label>
  </div>
    
<!--  </div>-->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: {
      type: String,
      default: 'checkbox'
    },
    map: {
      type: Object,
      default: {}
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'checkbox'
    },
    value: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: false
    },
    fieldRef: {
      type: String
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      localState: false
    }
  },
  created() {
    // console.log(this.map)
    if(this.checked){
      this.localState = this.checked
    }
  },
  name: 'BaseCheckBox',
  watch: {
    ...mapGetters(['themeFontColor']),
    localState(newValue, oldValue) {
      // console.log(newValue, oldValue)
      this.$emit('input', newValue,this.fieldRef,this.type)
      // if (newValue !== oldValue) {
      //   this.$emit('input', newValue,this.fieldRef,this.type)
      // }
    },
    checked(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localState = newValue
      }
    }
  },
  computed:{
    getId(){
      return this.name+"~"+this.id
    }
  }
}
</script>

<style>
.check-box-container {
  display: flex;
  align-items: center;
  /*accent-color: #e72510 !important;*/
}
.check-box-container > * {
  margin-left: 0.5rem;
}
</style>