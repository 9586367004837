export const sectionConfig = (selected) => {
    // NYSE ARCA: {

    // CBOE BZX: {
    //     limit: 5,
    //     label: 'CBOE_BZX',
    //     onSelected: selected
    // }

    let ExchangeData = {
        ASX: {
            limit: 5,
            label: 'ASX',
            onSelected: selected
        },
        INDX: {
            limit: 5,
            label: 'Indices',
            onSelected: selected
        },
        NASDAQ: {
            limit: 5,
            label: 'Nasdaq',
            onSelected: selected
        },
        NYSE: {
            limit: 5,
            label: 'NYSE',
            onSelected: selected
        },

    }

    ExchangeData['NYSE ARCA'] = {
        limit: 5,
        label: 'NYSE ARCA',
        onSelected: selected
    }
    ExchangeData['CBOE BZX'] = {
        limit: 5,
        label: 'CBOE BZX',
        onSelected: selected
    }
    ExchangeData['NYSE AMERICAN'] = {
        limit: 5,
        label: 'NYSE AMERICAN',
        onSelected: selected
    }
    return ExchangeData
}