<template>
  <guru-base-modal 
    v-model="open" class="fontSize"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    header="Divergence Scan"
    :valid="isValid"
    customStyle=""
    :scanType="scanType"
    id="divergenceScan"
    :isLoading="isLoading"
  >
    <div class="fontSize">

      <input type="radio" id="obv" class="fontSize"
        name="indicator_settings"
        value="obv"
        v-model="settings.selectedScan"
      >
      <label for="obv" style=" position:relative; bottom:1px; left:3px; ">OBV</label>

      <!-- stoc -->
      <div class="input-container top-margin fontSize">
      
        <input type="radio" id="stoc"
         name="indicator_settings"
          value="stoc"
          v-model="settings.selectedScan"
      >
      <label for="stoc">STOC</label>
        <label>Period</label>
        <input
          type="number"
          min="1"
          v-model="settings.stoc.period"
          class="small-input"
        />
        <label>K%</label>
        <input
          type="number"
          min="1"
          v-model="settings.stoc.kPeriod"
          class="small-input"
        />
        <label>D%</label>
        <input
          type="number"
          min="1"
          v-model="settings.stoc.dPeriod"
          class="small-input"
        />
      </div>

      <!-- macd -->
      <div class="input-container top-margin fontSize">
        <input type="radio" id="macd"
         name="indicator_settings"
          value="macd"
          v-model="settings.selectedScan"
      >
      <label for="macd">MACD</label>
        <label>Fast</label>
        <input
          class="small-input"
          type="number"
          min="1"
          v-model="settings.macd.fast"
        />
        <label>Slow</label>
        <input
          class="small-input"
          type="number"
          min="1"
          v-model="settings.macd.slow"
        />
        <label>Signal</label>
        <input
          class="small-input"
          type="number"
          min="1"
          v-model="settings.macd.signal"
        />
      </div>

      <!-- rsi -->
      <div class="input-container top-margin fontSize">
      
<input type="radio" id="rsi"
         name="indicator_settings"
          label="RSI"
          value="rsi"
          v-model="settings.selectedScan"
      >
      <label for="rsi">RSI</label>

        <label>Period</label>
        <input
          class="small-input"
          type="number"
          min="1"
          v-model="settings.rsi"
        />
      </div>
    </div>
    <div class="input-container top-margin fontSize">
      <label>Maximum number of days in the past to be scanned</label>
      <input
        class="small-input"
        type="number"
        min="1"
        value="250"
        v-model="settings.maxRange"
      />
    </div>
  </guru-base-modal>
</template>

<script>
import GuruBaseModal from './GuruBaseModal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { GuruBaseModal },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  name: 'DivergenceScan',
  methods: {
    ...mapActions([
      'setDivergenceScanSettings',
      'sendDivergenceScanRequest',
      'cancelGuruScans'
    ]),
    async onScanClick() {
      this.isLoading = true
      await this.sendDivergenceScanRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    }
  },
  data() {
    return {
      isLoading: false,
      scanType: "divergenceScan",
      settingsData: {
        selectedScan: 'obv',
        maxRange: 20,
        stoc: {
          kPeriod: 12,
          dPeriod: 26,
          period: 9
        },
        rsi: 14,
        macd: {
          fast: 12,
          slow: 26,
          signal: 9
        }
      }
    }
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    /** @returns {Boolean} */
    isValid() {
      return (
        (this.settings.selectedScan === 'obv' ||
          (this.settings.selectedScan === 'stoc' &&
            this.settings.stoc.kPeriod > 0 &&
            this.settings.stoc.dPeriod > 0 &&
            this.settings.stoc.period > 0) ||
          (this.settings.selectedScan === 'macd' &&
            this.settings.macd.fast > 0 &&
            this.settings.macd.slow > 0 &&
            this.settings.macd.signal > 0) ||
          (this.settings.selectedScan === 'rsi' && this.settings.rsi > 0)) &&
        this.settings.maxRange > 0
      )
    },
    settings: {
      get() {
        return this.gurusScanSettings.divergenceScan || this.settingsData
      },
      set(value) {
        this.setDivergenceScanSettings(value)
      }
    }
  }
}
</script>
<style>
.fontSize{
  font-size: 13.5px;
  margin-bottom: 5px;
}
@media screen and (max-width:870px){
.fontSize{
  font-size: 11px;
  margin-bottom: 5px;
}
}
@media screen and (max-width:510px){
.fontSize{
  font-size: 10px;
  margin-bottom: 7px;
}
}
</style>