<script>
// Line drawing tool
import { Overlay, Tool } from 'trading-vue-js'
import Pin from './primitives/pin.js'
import Seg from './primitives/seg.js'
import { mapGetters } from 'vuex'
import ToolSavingMixins from 'Tools/ToolSavingMixins'
export default {
  name: 'FibonacciRetrace',
  mixins: [Overlay, Tool, ToolSavingMixins],
  methods: {
    meta_info() {
      return { author: 'C451', version: '1.0.0' }
    },
    tool() {
      let prevSettings = localStorage.getItem('FibonacciRetrace')
      if (prevSettings) {
        prevSettings = JSON.parse(prevSettings)
      }
      // console.log("tool prevSettings",prevSettings)
      //{"color":"#2346A757","lineWidth":2,"lineStyle":"dashed","lineColor":"#6BA8ECFF"}
      const defaultColor = prevSettings?.color || '#23a776'
      const defaultLineWidth = prevSettings?.lineWidth || 1
      const defaultLineStyle = prevSettings?.lineStyle || 'solid'
      return {
        // Descriptor for the tool
        group: 'Fibonacci',
        type: 'Segment',
        hint: 'This hint will be shown on hover',
        data: [], // Default data
        settings: {
          color: defaultColor,
          lineWidth: defaultLineWidth,
          lineStyle: defaultLineStyle,
          settingsList: ['color', 'lineWidth', 'lineStyle'],
        }, // Default settings
      }
    },
    // Called after overlay mounted
    init() {
      // First pin is settled at the mouse position
      this.pins.push(new Pin(this, 'p1'))
      // Second one is following mouse until it clicks
      this.pins.push(
        new Pin(this, 'p2', {
          state: 'tracking',
        })
      )
      this.pins[1].on('settled', () => {
        // Call when current tool drawing is finished
        // (Optionally) reset the mode back to 'Cursor'
        this.updateSettingsState(this)
        this.set_state('finished')
        this.$emit('drawing-mode-off')
        this.$emit('object-selected')
      })
      this.registerMouseEvent(this)
    },
    draw(ctx) {
      if (!this.p1 || !this.p2) return
      ctx.lineWidth = this.line_width * 4
      ctx.strokeStyle = this.color
      ctx.beginPath()

      //--- line style
      if (this.sett.lineStyle === 'dashed') {
        ctx.setLineDash([5, 10])
      } else if (this.sett.lineStyle === 'dotted') {
        ctx.setLineDash([3, 4])
      }

      let xFrom = this.p2[0]
      let xTo = this.p1[0]
      let yTop = this.p2[1]
      let yBottom = this.p1[1]
     
      console.log("points==>",xTo,yTop,yBottom)
      // Top
      new Seg(this, ctx, `(100.0%)`).draw([xFrom, yBottom], [xTo, yBottom])
      
      // Bottom
      new Seg(this, ctx, `${yTop.toFixed(3)} (0.0%)`).draw([xFrom, yTop], [xTo, yTop])
      ctx.stroke()
      // Inside
      ctx.beginPath()
      ctx.lineWidth = this.line_width
      let delta = yTop - yBottom
      
      for (const m of [0.236, 0.382, 0.5, 0.618, 0.786,1,1.382,1.618,2,2.382,2.618]) {
        
        new Seg(this, ctx, `${(yTop - delta * m).toFixed(3)} (${(m * 100.0).toFixed(1)}%)`).draw(
          [xFrom, yTop - delta * m],
          [xTo, yTop - delta * m]
        )
        
      }
      // Above
      //   for (const m of [1.236, 1.382, 1.5, 1.618, 1.764, 2.618, 4.236]) {
      //     new Seg(this, ctx, `${(m * 100.0).toFixed(1)}%`).draw(
      //       [xFrom, yBottom + delta * m],
      //       [xTo, yBottom + delta * m]
      //     );
      //   }
      // Below
      //   for (const m of [1.236, 1.382, 1.5, 1.618, 1.764, 2.618, 4.236]) {
      //     new Seg(this, ctx, `${(m * 100.0).toFixed(1)}%`).draw(
      //       [xFrom, yTop - delta * m],
      //       [xTo, yTop - delta * m]
      //     );
      //   }

      ctx.stroke()
      ctx.setLineDash([])
      this.render_pins(ctx)
    },
    use_for() {
      return ['FibonacciRetrace']
    },
    data_colors() {
      return [this.color, this.fontColor]
    },
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters([
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
    ]),
    sett() {
      return this.$props.settings
    },
    p1() {
      return this.$props.settings.p1
    },
    p2() {
      return this.$props.settings.p2
    },
    line_width() {
      return this.sett.lineWidth || 0.9
    },
    color() {
      return this.sett.color || '#42b28a'
    },
    fontColor() {
      return this.sett.fontColor || '#40b28a'
    },
  },
  data() {
    return {}
  },
  created() {
    this.updateSettingsState(this)
  },
}
</script>
