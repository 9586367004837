<template>
  <dialog-box
    modalHeader="Friday Broadcast"
    :open="open"
    @onClose="onClose"
    :action="false"
    :closeIcon="true"
  >
<!--    style="height: 50vh;"-->
    <div 
        
    >
<!--      <vue-core-video-player
          controls="auto"
          style="height: 55vh;"
        ref="player"
        src="https://www.protradermedia.com/scans/scan2008/Scan.mp4"
      ></vue-core-video-player>-->
<!--      <video
          style="height: 55vh;width: 38vw"
          controls
          ref="player"
          src="https://www.protradermedia.com/scans/scan2008/Scan.mp4"
      >

      </video>-->

      <!-- <video controls 
             :autoplay="false"
             :allowFullScreen="true"
             id="weeklyPlayer"
             name="media" 
             width="100%" height="100%">
        <source src="https://www.protradermedia.com/scans/scan2008/Scan.mp4" type="video/mp4"></video> -->
        <!-- <iframe width="100%" height="100%" id="weeklyPlayer" src="https://www.youtube.com/watch?v=jJ0U30g5QRY" frameborder="0" allowfullscreen /> -->
        <iframe v-if="playVideo" width="100%" height="100%" id="weeklyPlayer" :src="youtubeEmbedUrl" frameborder="0" allowfullscreen />
    </div>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
export default {
  name: 'WeeklyVideo',
  components: {
    DialogBox
  },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.playVideo = true
    // console.log("this.$refs.player",this.$refs.player?.$children[0])
    // this.$refs.player.pause()
   
    // let video = element.querySelector( 'video' );
  },
  computed: {
    youtubeEmbedUrl() {
      return `https://www.youtube.com/embed/${this.extractVideoId(this.youtubeVideoUrl)}`;
    },
  },
  methods: {
    extractVideoId(url) {
      const match = url.match(/[?&]v=([^?&]+)/);
      return match ? match[1] : null;
    },
    onClose() {
      this.$emit('change', !open)
      this.playVideo = false
      // this.$nextTick(() => {
      //   // this.$refs.player.pause()
      //   let iframe = document.getElementById( 'weeklyPlayer');
        
      //   console.log(iframe,iframe?.pause)
      //   if(iframe.pause){
      //     iframe.pause()
      //   }
      // })
    }
  },
  data() {
    return {
      playVideo: true,
      youtubeVideoUrl:"https://www.youtube.com/watch?v=MVbz9WPCz3A"
    }
  },
  watch:{
    open(n){
      if(n){
        console.log("this.$refs.player",this.$refs.player?.$children[0])
        this.playVideo = true
      }
    }
  }
}
</script>

<style></style>
