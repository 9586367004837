<template>
  <pattern-base-modal
    scanName="Candlestick Patterns"
  class="fontSize fss"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    @saveScanSettings="saveScanSettings"
    @changeScanSettings="changeScanSettings"
    @deletedScan="deletedScan"
    @ResetScanDefaultSettings = "ResetScanDefaultSettings"
    :scanType="id"
    :id="id"
    header="Candlestick Patterns"
    :valid="isValid"
    customStyle=""
    :isLoading="isLoading"
   
  >
    <multiple-checker class="fontSize fss"
      @selectAll="updateAllSelections(true)"
      @clearAll="updateAllSelections(false)"
    />
    <sui-grid divided="vertically" :columns="3" class="fontSize fss">
      <sui-grid-column>
        <CustomSvgCheckBox class="fontSize fss"
          v-for="checkBox of col1"
          :key="checkBox"
          :label="checkBox"
          :srcResolutionFunction="srcResolutionFunction"
          :updateState="updateCheckedPatterns"
          :fromProps="settings[toCamelCase(checkBox)]"
      
        />
      </sui-grid-column>

      <sui-grid-column>
        <CustomSvgCheckBox class="fontSize"
          v-for="checkBox of col2"
          :key="checkBox"
          :label="checkBox"
          :srcResolutionFunction="srcResolutionFunction"
          :updateState="updateCheckedPatterns"
          :fromProps="settings[toCamelCase(checkBox)]"
      
        />
      </sui-grid-column>

      <sui-grid-column>
        <CustomSvgCheckBox class="fontSize fss"
          v-for="checkBox of col3"
          :key="checkBox"
          :label="checkBox"
          :srcResolutionFunction="srcResolutionFunction"
          :updateState="updateCheckedPatterns"
          :fromProps="settings[toCamelCase(checkBox)]"
      
        />
      </sui-grid-column>
    </sui-grid>
  </pattern-base-modal>
</template>

<script>
  const DEFAULT_SETTINGS = {
        doji: false,
        bullishHarami: false,
        bearishHarami: false,
        bullishHaramiCross: false,
        bearishHaramiCross: false,
        hammer: false,
        invertedHammer: false,
        shootingStar: false,
        hangingMan: false,
        bullishEngulfing: false,
        bearishEngulfing: false
      }
import CustomImageCheckBox from 'BaseComponents/CustomImageCheckBox.vue'
import CustomSvgCheckBox from '../../../svg_icon/CustomSvgCheckBox.vue'
import PatternBaseModal from './PatternBaseModal.vue'
import MultipleChecker from '../../../baseComponents/MultipleChecker.vue'
import { mapActions, mapGetters } from 'vuex'
import {DEFAULT_DATA_SETTINGS,DEFAULT_FILTER_SETTINGS,DEFAULT_EVENT_SETTINGS} from "../../../settings/USER_SETTINGS_DEFAULT"
export default {
  components: { PatternBaseModal, CustomImageCheckBox, MultipleChecker,CustomSvgCheckBox },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  name: 'Candle',
  methods: {
    ...mapActions([
        'updateMarketAccordingTODataSettings'
        ,'setCandlestickScanSettings'
      , 'sendCandlestickScanRequest','setSelectedMarket', 'cancelScans', 'saveCandlestickScanRequest', 'deleteScanSettings','getUserScanList']),
   handleUserChoice() {
      if (this.userChoice == false) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
    updateAllSelections(newValue) {
      this.settings = {
        doji: newValue,
        bullishHarami: newValue,
        bearishHarami: newValue,
        bullishHaramiCross: newValue,
        bearishHaramiCross: newValue,
        hammer: newValue,
        invertedHammer: newValue,
        shootingStar: newValue,
        hangingMan: newValue,
        bullishEngulfing: newValue,
        bearishEngulfing: newValue
      }
      this.$forceUpdate();
      this.handleUserChoice();
    },
   async saveScanSettings(name){
      // console.log('saveScanSettings of candle',name)
      
      await this.saveCandlestickScanRequest(name)
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
    changeScanSettings(val){
      this.updateMarketAccordingTODataSettings({...val,id:this.id})
      this.settings = val.settings
    },
   async deletedScan(selectedScan){
      await this.deleteScanSettings(selectedScan)
     this.$store.commit('SCAN_DELETE_POPUP', false)
    },
    ResetScanDefaultSettings(){
      let val = {
        dataSettings:JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        filterSettings:JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
        events:JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS))
      }
      this.updateMarketAccordingTODataSettings({...val,id:this.id})
      this.settings = JSON.parse(JSON.stringify(DEFAULT_SETTINGS))
    },
    async onScanClick() {
      this.isLoading = true
      await this.sendCandlestickScanRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.cancelScans()
      this.isLoading = false
    },
    srcResolutionFunction(label) {
      return  label
          .replace('-', '_')
          .split(' ')
          .reverse()
          .map(w => w.toLowerCase())
          .reduce((curr, prev) => (prev && curr ? `${prev}_${curr}` : prev), '')
    },
    toCamelCase(name) {
      const pascal = name.replaceAll(' ', '')
      return pascal[0].toLowerCase() + pascal.substring(1)
    },
    updateCheckedPatterns(label, state) {
      // this.checkedPatterns = { ...this.checkedPatterns, [label]: state }
      this.settings[this.toCamelCase(label)] = state
    }
  },
  data() {
    return {
      id: "candlesticks",
      isLoading: false,
      checkedPatterns: {},
      col1: [
        'Doji',
        'Bullish Harami Cross',
        'Inverted Hammer',
        'Bullish Engulfing'
      ],
      col2: [
        'Bullish Harami',
        'Bearish Harami Cross',
        'Shooting Star',
        'Bearish Engulfing'
      ],
      col3: ['Bearish Harami', 'Hammer', 'Hanging Man'],
      settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
    }
  },
  computed: {
    /** @returns {Boolean} */
     ...mapGetters(['userChoice']),
    isValid() {
      return Object.values(this.settings).some(state => state)
    }
  },
  watch: {
    settings: {
      handler: function(newSettings) {
        this.setCandlestickScanSettings(newSettings)
      },
      deep: true
    }
  }
}
</script>

<style>
/* .fontSize{
  font-size: 15px;
} */
.fss{
  font-size: 20px;
}
</style>
