<template>
  <dialog-box
    modalHeader="Settings"
    :open="open"
    :action="false"
    :style="themeFontColor"
  >
    <div style="height: 60vh; padding: 8px">
      <div :style="{ ...themeFontColor, ...themePrimaryColor }">
        <div
          style="
            display: flex;
            gap: 5px;
            flex-direction: row;
            position: relative;
            bottom: -2px;
          "
        >
          <!-- <div > -->
          <div
            class="settingTabs"
            style=""
            @click="GeneralTab"
            :style="{
              color: themeSecondaryColor.backgroundColor,
              cursor: 'pointer',
              padding: '8px',
              fontWeight: isGeneralTabActive ? 'bold' : 'normal',
              boxShadow: isGeneralTabActive ? '0px -.2px 1px 1px #DDDDDD' : '',
              borderRadius: isGeneralTabActive ? '5px 5px 0px 0px' : '',
            }"
          >
            <div>General</div>
          </div>
          <!-- </div> -->

          <!-- <div > -->
          <div
            class="settingTabs"
            @click="IndicatorTab"
            :style="{
              color: themeSecondaryColor.backgroundColor,
              cursor: 'pointer',
              padding: '8px',
              fontWeight: isIndicatorTabActive ? 'bold' : 'normal',
              boxShadow: isIndicatorTabActive
                ? '0px -.2px 1px 1px #DDDDDD'
                : '',
              borderRadius: isIndicatorTabActive ? '5px 5px 0px 0px' : '',
            }"
          >
            <div>Indicators</div>
          </div>
          <!-- </div> -->

          <!-- <div style=""> -->
          <!-- <div
            class="settingTabs"
            @click="ThemeTab"
            :style="{
              color: themeSecondaryColor.backgroundColor,
              cursor: 'pointer',
              padding: '8px',
              fontWeight: isThemeTabActive ? 'bold' : 'normal',
              boxShadow: isThemeTabActive ? '0px -.2px 1px 1px #DDDDDD' : '',
              borderRadius: isThemeTabActive ? '5px 5px 0px 0px' : '',
            }"
          >
            <div>Themes</div>
          </div> -->
          <!-- </div> -->

           <!-- <div style=""> -->
          <div
            class="settingTabs"
            @click="UserSettings"
            :style="{
              color: themeSecondaryColor.backgroundColor,
              cursor: 'pointer',
              padding: '8px',
              fontWeight: isUserSettings ? 'bold' : 'normal',
              boxShadow: isUserSettings ? '0px -.2px 1px 1px #DDDDDD' : '',
              borderRadius: isUserSettings ? '5px 5px 0px 0px' : '',
            }"
          >
            <div>Profile</div>
          </div>
          <!-- </div> -->



          <!-- <div style=""> -->
          <div
            v-if="getUserEmail==='mat@loop2.com.au'"
            class="settingTabs"
            @click="PlusTab"
            :style="{
              color: themeSecondaryColor.backgroundColor,
              cursor: 'pointer',
              padding: '8px',
              fontWeight: isNewIndicator ? 'bold' : 'normal',
              boxShadow: isNewIndicator ? '0px -.2px 1px 1px #DDDDDD' : '',
              borderRadius: isNewIndicator ? '5px 5px 0px 0px' : '',
            }"
          >
            <div>Plus Charts</div>
          </div>
          <!-- </div> -->
        </div>
        <div
          style="border-top: 1px solid #dddddd; position: relative; top: 2px"
        />

        <div v-if="isGeneralTabActive">
          <sui-grid divided="vertically">
            <sui-grid-row :columns="2">
              <sui-grid-column padded="vertically" key="chartInfo">
                <div>
                  <p></p>
                  <h4 is="sui-header" :style="themeFontColor">Chart</h4>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row key="lineColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Line Color</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="chartLineColor"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="sideColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Unchanged Colour</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="chartSideColor"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="upColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Up Colour</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="chartUpColor"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="downColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Down Colour</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="chartDownColor"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="barChange">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Use Up/Down Colour on Bar Chart
                        </sui-table-cell>
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                              type="checkbox"
                              name="barChange"
                              v-model="chartBarChange"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="semiLogAxis">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Semi Log Price Axis</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                              type="checkbox"
                              name="semiLogAxis"
                              v-model="chartSemiLogAxis"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="volumeColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Volume Colour</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="chartVolumeColor"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="upColorVolume">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Up Volume Colour</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="chartUpColorVolume"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="downColorVolume">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Down Volume Colour</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="chartDownColorVolume"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="barChangeVolume">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Use Up/Down Colours on Volume Chart
                        </sui-table-cell>
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                              type="checkbox"
                              name="barChangeVolume"
                              v-model="chartBarChangeVolume"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row key="obvColor">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >On-Balance Volume Colour</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="chartObvColor"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </div>
              </sui-grid-column>
              <sui-grid-column padded="vertically" key="otherInfo">
                <div>
                  <p></p>
                  <h4 is="sui-header" :style="themeFontColor">CrossHair</h4>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row style="padding-bottom: 24px">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Enabled</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                              type="checkbox"
                              name="enabled"
                              v-model="crossHairEnabled"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Line Color</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="crossHairLineColor"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Box Colour</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="crossHairBoxColor"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Text Colour</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="crossHairTextColor"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </div>

                <div>
                  <p></p>
                  <h4 is="sui-header" :style="themeFontColor">Marker</h4>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row style="padding-bottom: 30px">
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Use Up/Down Colour on Bar Chart
                        </sui-table-cell>
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                              type="checkbox"
                              name="marker-enabled"
                              v-model="markerEnabled"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Line Color</sui-table-cell
                        >
                        <sui-table-cell :width="2">
                          <color-picker
                            v-model="markerLineColor"
                            :isSettingsPicker="true"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </div>

                <div>
                  <p></p>
                  <h4 is="sui-header" :style="themeFontColor">Misc</h4>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor" :width="14"
                          >Use Up/Down Colour on Bar Chart
                        </sui-table-cell>
                        <sui-table-cell :width="2">
                          <div class="check-box-container">
                            <input
                              type="checkbox"
                              name="barChange"
                              v-model="markerHidePopup"
                            />
                          </div>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </div>
              </sui-grid-column>
            </sui-grid-row>
          </sui-grid>
        </div>

        <div v-if="isIndicatorTabActive">
          <sui-grid divided="vertically" style="margin-top: 4px">
            <sui-grid-row :columns="2" :style="themePrimaryColor">
              <sui-grid-column padded="vertically">
                <p></p>
                <!-- <h4 is="sui-header">Simple Moving Average</h4> -->
                <sui-table basic="very" compact="very" :key="multiItems">
                  <sui-table-body>
                    <sui-table-row>
                      <sui-table-cell :width="14">
                        <sui-form>
                          <sui-form-field>
                            <label :style="themeFontColor"
                              >Simple Moving Average</label
                            >
                            <sui-table basic="very" compact="very">
                              <sui-table-row
                                v-for="(multi, i) of userMultiSettings.sma"
                                :key="i"
                              >
                                <sui-table-cell :width="15">
                                  <input
                                    placeholder="20"
                                    v-model.number="multi.period"
                                    type="number"
                                    v-on:input="updateProp('sma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <color-picker
                                    v-model="multi.color"
                                    :isSettingsPicker="true"
                                    type="color"
                                    v-on:input="updateProp('sma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <sui-button
                                    size="mini"
                                    icon="times"
                                    :disabled="
                                      userMultiSettings.sma.length === 1
                                    "
                                    @click.prevent="remElem('sma', i)"
                                  />
                                </sui-table-cell>
                              </sui-table-row>
                              <sui-table-row>
                                <sui-table-cell>
                                  <sui-button
                                    size="mini"
                                    icon="add"
                                    :style="themeSecondaryColor"
                                    content="Add"
                                    @click.prevent="addElem('sma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell />
                                <sui-table-cell />
                              </sui-table-row>
                            </sui-table>
                          </sui-form-field>

                          <sui-form-field>
                            <label :style="themeFontColor"
                              >Simple Moving Average of OBV</label
                            >
                            <sui-table basic="very" compact="very">
                              <sui-table-row
                                v-for="(multi, i) of userMultiSettings.smaobv"
                                :key="i"
                              >
                                <sui-table-cell :width="15">
                                  <input
                                    placeholder="20"
                                    v-model="multi.period"
                                    type="number"
                                    v-on:input="updateProp('smaobv')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <color-picker
                                    v-model="multi.color"
                                    :isSettingsPicker="true"
                                    type="color"
                                    v-on:input="updateProp('smaobv')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <sui-button
                                    size="mini"
                                    icon="times"
                                    :disabled="
                                      userMultiSettings.smaobv.length === 1
                                    "
                                    @click.prevent="remElem('smaobv', i)"
                                  />
                                </sui-table-cell>
                              </sui-table-row>
                              <sui-table-row>
                                <sui-table-cell>
                                  <sui-button
                                    size="mini"
                                    icon="add"
                                    :style="themeSecondaryColor"
                                    content="Add"
                                    @click.prevent="addElem('smaobv')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell />
                                <sui-table-cell />
                              </sui-table-row>
                            </sui-table>
                          </sui-form-field>

                          <sui-form-field>
                            <label :style="themeFontColor"
                              >Simple Moving Average of OBV of Volume</label
                            >
                            <sui-table basic="very" compact="very">
                              <sui-table-row
                                v-for="(
                                  multi, i
                                ) of userMultiSettings.smavolume"
                                :key="i"
                              >
                                <sui-table-cell :width="15">
                                  <input
                                    placeholder="20"
                                    v-model="multi.period"
                                    type="number"
                                    v-on:input="updateProp('smavolume')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <color-picker
                                    v-model="multi.color"
                                    :isSettingsPicker="true"
                                    type="color"
                                    v-on:input="updateProp('smavolume')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <sui-button
                                    size="mini"
                                    icon="times"
                                    :disabled="
                                      userMultiSettings.smavolume.length === 1
                                    "
                                    @click.prevent="remElem('smavolume', i)"
                                  />
                                </sui-table-cell>
                              </sui-table-row>
                              <sui-table-row>
                                <sui-table-cell>
                                  <sui-button
                                    size="mini"
                                    icon="add"
                                    :style="themeSecondaryColor"
                                    content="Add"
                                    @click.prevent="addElem('smavolume')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell />
                                <sui-table-cell />
                              </sui-table-row>
                            </sui-table>
                          </sui-form-field>

                          <sui-form-field>
                            <label :style="themeFontColor"
                              >Weighted Moving Average</label
                            >
                            <sui-table basic="very" compact="very">
                              <sui-table-row
                                v-for="(multi, i) of userMultiSettings.wma"
                                :key="i"
                              >
                                <sui-table-cell :width="15">
                                  <input
                                    placeholder="20"
                                    v-model="multi.period"
                                    type="number"
                                    v-on:input="updateProp('wma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <color-picker
                                    v-model="multi.color"
                                    :isSettingsPicker="true"
                                    type="color"
                                    v-on:input="updateProp('wma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <sui-button
                                    size="mini"
                                    icon="times"
                                    :disabled="
                                      userMultiSettings.wma.length === 1
                                    "
                                    @click.prevent="remElem('wma', i)"
                                  />
                                </sui-table-cell>
                              </sui-table-row>
                              <sui-table-row>
                                <sui-table-cell>
                                  <sui-button
                                    size="mini"
                                    icon="add"
                                    :style="themeSecondaryColor"
                                    content="Add"
                                    @click.prevent="addElem('wma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell />
                                <sui-table-cell />
                              </sui-table-row>
                            </sui-table>
                          </sui-form-field>

                          <sui-form-field>
                            <label :style="themeFontColor"
                              >Exponential Moving Average</label
                            >
                            <sui-table basic="very" compact="very">
                              <sui-table-row
                                v-for="(multi, i) of userMultiSettings.xma"
                                :key="i"
                              >
                                <sui-table-cell :width="15">
                                  <input
                                    placeholder="20"
                                    v-model="multi.period"
                                    type="number"
                                    v-on:input="updateProp('xma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <color-picker
                                    v-model="multi.color"
                                    :isSettingsPicker="true"
                                    type="color"
                                    v-on:input="updateProp('xma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell :width="1">
                                  <sui-button
                                    size="mini"
                                    icon="times"
                                    :disabled="
                                      userMultiSettings.xma.length === 1
                                    "
                                    @click.prevent="remElem('xma', i)"
                                  />
                                </sui-table-cell>
                              </sui-table-row>
                              <sui-table-row>
                                <sui-table-cell>
                                  <sui-button
                                    size="mini"
                                    icon="add"
                                    :style="themeSecondaryColor"
                                    content="Add"
                                    @click.prevent="addElem('xma')"
                                  />
                                </sui-table-cell>
                                <sui-table-cell />
                                <sui-table-cell />
                              </sui-table-row>
                            </sui-table>
                          </sui-form-field>
                        </sui-form>
                      </sui-table-cell>
                    </sui-table-row>
                  </sui-table-body>
                </sui-table>
              </sui-grid-column>

              <sui-grid-column padded="vertically">
                <p></p>
                <sui-form>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Trend Channels</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="trendChannelValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="trendChannelPeriod"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Bollinger Bands</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="bollingerBandPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="bollingerBandPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Deviations</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="bollingerBandDeviationValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="bollingerBandDeviationColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Average True Range</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="atrPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="atrPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Multiplier</sui-table-cell
                        >
                        <sui-table-cell>
                          <input
                            placeholder="20"
                            type="number"
                            v-model="atrMultiplierValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">MACD</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Fast Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="macdFastPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="macdFastPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Slow Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="macdSlowPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="macdSlowPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Signal Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="macdSignalPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="macdSignalPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Rate Of Change</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="rocPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="rocPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">
                            Relative Strength Index
                          </h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="rsiPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="rsiPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Stochastic Oscillator</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="stocPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="stocPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >%K Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="stocKPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="stocKPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>

                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >%D Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="stocDPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="stocDPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Williams %R</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Period</sui-table-cell
                        >
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="willRPeriodValue"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="willRPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Parabolic SAR</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Start</sui-table-cell
                        >
                        <sui-table-cell>
                          <input
                            placeholder="20"
                            type="number"
                            v-model="sarStartValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Increment</sui-table-cell
                        >
                        <sui-table-cell>
                          <input
                            placeholder="20"
                            type="number"
                            v-model="sarIncrementValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Max</sui-table-cell
                        >
                        <sui-table-cell>
                          <input
                            placeholder="20"
                            type="number"
                            v-model="sarMaxValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Colour</sui-table-cell
                        >
                        <sui-table-cell>
                          <color-picker
                            type="color"
                            :isSettingsPicker="true"
                            v-model="sarSeriesValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Value</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Colour</sui-table-cell
                        >
                        <sui-table-cell>
                          <color-picker
                            type="color"
                            :isSettingsPicker="true"
                            v-model="valueTradedSeriesValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">VWAP</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor"
                          >Colour</sui-table-cell
                        >
                        <sui-table-cell>
                          <color-picker
                            type="color"
                            :isSettingsPicker="true"
                            v-model="vwapSeriesValue"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </sui-form>
              </sui-grid-column>
            </sui-grid-row>
          </sui-grid>
        </div>

        <div v-if="isNewIndicator">
          <sui-grid divided="vertically" style="margin-top: 4px">
            <sui-grid-row :columns="2" :style="themePrimaryColor">
              <sui-grid-column padded="vertically">
                <p></p>
                <sui-form>
                  
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">CMO</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="cmoLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="cmoPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">ALMA</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="almaLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="almaPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">BBW</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="bbwLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="bbwPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">ATRP</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="atrpLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="atrpPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">COG</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="cogLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="cogPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">HMA</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="hmaLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="hmaPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">KCW</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="kcwLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="kcwPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                                      
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">MOM</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="momLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="momPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">VWMA</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="vwmaLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="vwmaPeriodColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Ribbon</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="ribbonLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="ribbonPeriodColor[0]"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">KC</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="kcLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="kcPeriodColor"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="kcBackColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">TSI</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="tsiLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                              type="color"
                                :isSettingsPicker="true"
                                v-model="tsiPeriodColor[0]"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="tsiPeriodColor[1]"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">MFI</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="mfiLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="mfiPeriodColor"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="mfiBandColor"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="mfiBackColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">CCI</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="cciLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="cciPeriodColor"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="cciBackColor"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="cciBandColor"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row>
                        <sui-table-cell>
                          <h4 :style="themeFontColor">DMI</h4>
                        </sui-table-cell>
                        <sui-table-cell />
                      </sui-table-row>
                      <sui-table-row>
                        <sui-table-cell :style="themeFontColor">Line Width</sui-table-cell>
                        <sui-table-cell>
                          <sui-table-row>
                            <sui-table-cell :width="15">
                              <input
                                placeholder="20"
                                type="number"
                                v-model="dmiLineWidth"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="dmiPeriodColor[0]"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="dmiPeriodColor[1]"
                              />
                            </sui-table-cell>
                            <sui-table-cell :width="1">
                              <color-picker
                                type="color"
                                :isSettingsPicker="true"
                                v-model="dmiPeriodColor[2]"
                              />
                            </sui-table-cell>
                          </sui-table-row>
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>

                </sui-form>
              </sui-grid-column>
            </sui-grid-row>
          </sui-grid>
        </div>

        <div v-if="isThemeTabActive">
          <sui-table called :style="themePrimaryColor">
            <sui-table-body>
              <sui-table-row>
                <sui-table-cell>
                  <h4>
                    <sui-checkbox
                      radio
                      name="themes"
                      :input-value="isThemePrimary"
                      v-on:change="disableCustomFonts"
                    />
                    <label
                      style="position: relative; bottom: 3px"
                      :style="themeFontColor"
                      >Themes</label
                    >
                  </h4>
                  <sui-dropdown
                    placeholder="Select a theme"
                    selection
                    :disabled="!isThemePrimary"
                    :options="default_themes"
                    v-model="primaryThemeId"
                  />
                </sui-table-cell>
                <sui-table-cell>
                  <sui-button
                    :icon="themeSettings.id === 'dark' ? 'sun' : 'moon outline'"
                    secondary
                    @click="checkDarkMode()"
                    :data-tooltip="
                      themeSettings.id === 'dark' ? 'Classic mode' : 'Dark mode'
                    "
                    data-position="bottom center"
                    data-inverted=""
                  />
                </sui-table-cell>
              </sui-table-row>
              <sui-table-row>
                <sui-table-cell>
                  <h4>
                    <sui-checkbox
                      :style="themeFontColor"
                      radio
                      name="themes"
                      :input-value="!isThemePrimary"
                      v-on:change="disableTheme"
                    />
                    <label
                      style="position: relative; bottom: 3px"
                      :style="themeFontColor"
                      >Custom</label
                    >
                  </h4>
                  <sui-table basic="very" compact="very">
                    <sui-table-body>
                      <sui-table-row :disabled="isThemePrimary">
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Font</h4>
                        </sui-table-cell>
                        <sui-table-cell>
                          <color-picker
                            v-model="themeSettingsSettingsFont"
                            is-settings-picker
                            fieldRef="font"
                            type="settings"
                            v-on:input="changeCustomSettings"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row :disabled="isThemePrimary">
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Primary</h4>
                        </sui-table-cell>
                        <sui-table-cell>
                          <color-picker
                            is-settings-picker
                            v-model="themeSettingsSettingsPrimary"
                            fieldRef="primary"
                            type="settings"
                            v-on:input="changeCustomSettings"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                      <sui-table-row
                        :disabled="isThemePrimary"
                        :style="themeFontColor"
                      >
                        <sui-table-cell>
                          <h4 :style="themeFontColor">Secondary</h4>
                        </sui-table-cell>
                        <sui-table-cell>
                          <color-picker
                            is-settings-picker
                            v-model="themeSettingsSettingsSecondary"
                            fieldRef="secondary"
                            type="settings"
                            v-on:input="changeCustomSettings"
                          />
                        </sui-table-cell>
                      </sui-table-row>
                    </sui-table-body>
                  </sui-table>
                </sui-table-cell>
              </sui-table-row>
            </sui-table-body>
          </sui-table>
        </div>

        <div v-if="isUserSettings" class="mt-[20px]">
          <sui-modal-content :style="{...themeFontColor}">
        <sui-message v-if="showError" error>
          <sui-message-header>Action Forbidden</sui-message-header>
          <p>
            {{ errorMsg }}
          </p>
        </sui-message>
        <sui-message  v-if="showSuccess" success>
          <sui-message-header>Success</sui-message-header>
          <p>
            {{ errorMsg }}
          </p>
        </sui-message>
        <sui-modal-description>
          <sui-header :style="themeFontColor">{{ firstName }} {{ lastName }}</sui-header>
          <sui-form>
  
  
            <sui-form-field class="profile-font">
              <label :style="themeFontColor" >First Name</label>
              <sui-form-field>
                <input type="text" placeholder="First Name" :style="themeDarkMode ? {backgroundColor:'#505050', color:'#A2A2A2'}:{}" v-model="firstName" />
              </sui-form-field>
            </sui-form-field>
          
          <sui-form-field class="profile-font">
              <label :style="themeFontColor">Last Name</label>
              <sui-form-field>
                <input type="text" placeholder="Last Name" :style="themeDarkMode ? {backgroundColor:'#505050', color:'#A2A2A2'}:{}" v-model="lastName" />
              </sui-form-field>
            </sui-form-field>
  
            <sui-form-field class="profile-font">
              <label :style="themeFontColor">Email</label>
              <sui-form-field>
                <input type="email" placeholder="Email" :style="themeDarkMode ? {backgroundColor:'#505050', color:'#A2A2A2'}:{}" v-model="email" readonly />
              </sui-form-field>
            </sui-form-field>
  
            <sui-form-field class="profile-font">
              <label :style="themeFontColor">Old Password</label>
              <sui-form-field>
                <input type="password" name="oldPassword" :style="themeDarkMode ? {backgroundColor:'#505050', color:'#A2A2A2'}:{}" v-model="currentPassword" />
                
              </sui-form-field>
            </sui-form-field>
  
            <sui-form-field class="profile-font">
              <label :style="themeFontColor">New Password</label>
              <sui-form-field>
                <input type="password" name="newPassword" :style="themeDarkMode ? {backgroundColor:'#505050', color:'#A2A2A2'}:{}"
                 v-model="newPassword" />
              </sui-form-field>
            </sui-form-field>
  
            <sui-form-field v-if="subs" class="profile-font">
              <label :style="themeFontColor"> Subscription Detail </label>
              <div v-for="(item, index) in subs" :keY="index">
                <div><b>Product Name: </b>{{ item.product?.desc?.title }}</div>
                <b>Period Start: </b> {{ formatDate(item.startPeriod) }} <b>Period End: </b
              >{{ formatDate(item.endPeriod) }}
                <div>
                  <b> {{ trialModeText }} </b>
                  <!-- <a
                      v-if="isTrialMode"
                      @click.prevent="onClickTrialActivate"
                      href="/"
                  >Activate</a
                  >
  
                  <a v-else @click.prevent="onSubscriptionCancel" href="/"
                  >Cancel</a
                  > -->
                  <a @click.prevent="onSubscriptionCancel" href="/"
                  >Cancel</a>
                </div>
              </div>
            </sui-form-field>
  
          </sui-form>
        </sui-modal-description>
      </sui-modal-content>
        </div>
      </div>
    </div>

    <template v-slot:action>
      <sui-modal-actions
        style="display: flex; justify-content: space-between"
        :style="themePrimaryColor"
      >
        <div>
          <sui-button icon="undo" secondary @click.native="onReset" />
        </div>
        <div style="justify-content: right">
          <sui-button
            :style="themeSecondaryColor"
            negative
            @click.native="onSave"
            >OK</sui-button
          >
          <sui-button
            style="
              background-color: #414343;
              filter: none;
              border: 1px solid #2e2f2f;
              opacity: 1;
              font-weight: bold;
              color: white;
            "
            @click.native="onClose"
            >Cancel</sui-button
          >
        </div>
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapActions, mapGetters } from 'vuex'
import ColorPicker from '@/baseComponents/ColorPicker.vue'
import BaseCheckBox from 'BaseComponents/BaseCheckBox'
import MultiValueSettingForm from 'Components/dialogBoxes/MultiValueSettingForm'
import SingleValueSettingForm from 'Components/dialogBoxes/SingleValueSettingForm'
import GeneralSettingsComputed from './helper/GeneralSettingsComputed'
import IndicatorSettingsComputed from './helper/IndicatorSettingsComputed'
import UpdateProfile from '../UpdateSettin.vue'
import {
  DEFAULT_THEME_SETTINGS,
  THEME_DETAILS,
} from '../../settings/THEME_DETAILS'
// 
// import DialogBox from '@/baseComponents/DialogBox.vue'
//   ../../components/dialogBoxes/helper/GeneralSettingsDefaults
  import {IndicatorSettingsDefaults} from '../../components/dialogBoxes/helper/GeneralSettingsDefaults'
  import {
    getUserData,
    subscribeToPackage,
    deleteSubscription,
    fetchAllSubscriptions, updateUserProfile,
  } from '../../services/user'
  // import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'
  import ChangeEmail from '../../components/dialogBoxes/ChangeEmail.vue'
  const notificationTimeout = 5000




export default {
  name: 'Settings',
  components: {
    MultiValueSettingForm,
    SingleValueSettingForm,
    BaseCheckBox,
    DialogBox,
    ColorPicker,
    UpdateProfile,
    
      ChangeEmail
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    checkDarkMode() {
      this.toggleDarkMode(this.themeSettings.id).then((d) => {
        console.log('toggle Dark Mode')
      })
    },
    updateProp(id) {
      if (!this.propUpdates.includes(id)) {
        this.changesNotSaved = true
        this.propUpdates.push(id)
      }
    },
    ...mapActions([
      'setIndicatorDefaultSettings',
      'saveUserSettings',
      'toggleDarkMode',
      'getUserWatches', 
      'getUserScanList', 
      'updateProfileData'
    ]),
    addElem(type) {
      if (
        this.userMultiSettings[type] &&
        this.userMultiSettings[type].length > 0
      ) {
        let n = {}
        n[type] = this.userMultiSettings[type]
        let newObj = JSON.stringify(this.userDataMap[type])
        let newObjParse = JSON.parse(newObj)
        n[type].push(newObjParse)
        this.$store.commit('SET_INDICATOR_MULTI_SETTINGS', n[type])
        this.changesNotSaved = true
        this.updateProp(type)
      }
    },
    remElem(type, id) {
      let n = {}
      n[type] = this.userMultiSettings[type]
      n[type].splice(id, 1)
      this.$store.commit('SET_INDICATOR_MULTI_SETTINGS', n[type])
      this.changesNotSaved = true
      this.updateProp(type)
    },
    GeneralTab() {
      this.isGeneralTabActive = true;
      this.isIndicatorTabActive = false;
      this.isThemeTabActive = false;
      this.isNewIndicator = false;
      this.isUserSettings = false;
    },
    UserSettings(){
      this.isGeneralTabActive = false;
      this.isIndicatorTabActive = false;
      this.isThemeTabActive = false;
      this.isNewIndicator = false;
      this.isUserSettings = true
    },
    IndicatorTab() {
      this.isGeneralTabActive = false;
      this.isIndicatorTabActive = true;
      this.isThemeTabActive = false;
      this.isNewIndicator = false;
      this.isUserSettings = false;
    },
    ThemeTab() {
      this.isGeneralTabActive = false;
      this.isIndicatorTabActive = false;
      this.isThemeTabActive = true;
      this.isNewIndicator = false;
      this.isUserSettings = false;
    },
    PlusTab() {
      this.isGeneralTabActive = false;
      this.isIndicatorTabActive = false;
      this.isThemeTabActive = false;
      this.isNewIndicator = true;
      this.isUserSettings = false;
    },
    async onSave() {
       if(this.isUserSettings){
        console.log("Update Profile----->")
        this.submit()
        return 
       }


      if (this.changesNotSaved) {
        let res = await this.saveUserSettings()
        if (res) {
          this.$store.commit('USER_SET_UPDATE_CHARTS', this.propUpdates)
          this.propUpdates = []
        }
        this.changesNotSaved = false
      }
      this.$emit('change', !open)
    },
    async onReset() {
      this.$store.commit('RESET_CHART_SETTINGS_DEFAULTS')
      let res = await this.saveUserSettings()
      if (res) {
        this.$store.commit('USER_SET_UPDATE_CHARTS', this.propUpdates)
        this.propUpdates = []
      }
      this.changesNotSaved = false
      this.propUpdates = []
      this.$emit('change', !open)
    },
    onClose() {
      this.changesNotSaved = false
      // this.$store.commit('USER_SET_UPDATE_CHARTS')
      // this.$store.commit('RESET_CHART_SETTINGS', {chartSettings: this.defaultChartSettings})
      // this.$store.commit('RESET_DEFAULT_THEME', defaultValues)
      this.propUpdates = []
      this.$emit('change', !open)
    },
    disableCustomFonts: function (d) {
      if (d) {
        this.$store.commit('UPDATE_DEFAULT_THEME', 'classic')
      }
      this.isCustomTheme = !d
      this.propChange()
    },
    disableTheme: function (d) {
      if (d) {
        this.$store.commit(
          'UPDATE_CUSTOM_THEME',
          JSON.parse(JSON.stringify(DEFAULT_THEME_SETTINGS.settings))
        )
      }
      this.propChange()
    },
    propChange() {
      // console.log('propChange')
      this.changesNotSaved = true
    },
    changeCustomSettings: function (data, prop, type) {
      let payload = this.themeSettings.settings
      payload[prop] = data
      this.propChange()
      this.$store.commit('UPDATE_CUSTOM_THEME', payload)
    },

    // extra
    setError(msg){
        this.errorMsg = msg
        this.showError = true
        this.showSuccess = false
        setTimeout(() => {
          this.showError = false
          this.showSuccess = false
          this.errorMsg = ""
        },notificationTimeout)
      },
      setSuccess(msg){
        this.currentPassword = ""
        this.newPassword = ""
        this.errorMsg = msg
        this.showError = false
        this.showSuccess = true
        setTimeout(() => {
          this.showError = false
          this.showSuccess = false
          this.errorMsg = ""
        },notificationTimeout)
      },
      submit() {
        console.log("submit updates")
        let updateProfile = {
          firstName:this.firstName,
          lastName:this.lastName,
          email:this.email,
          userId:this.userId,
          currentPassword:this.currentPassword,
          newPassword:this.newPassword,
        }
        updateUserProfile(updateProfile).then(d => {
          console.log("updateUserProfile",d)
          if(d?.status){
            this.setSuccess(d.msg)
          }else{
            this.setError(d.msg)
          }
        }).catch(e => {
          console.log("error",e)
          this.setError('Invalid Request')
        })
      },
      formatDate(val){
        return moment(val).format("DD/MM/YYYY HH:mm")
      },
      async onClickTrialActivate() {
        this.$router.push("/products")
        // const url = await subscribeToPackage(this.subscription)
        // if(!url.error){
        //   window.location = url?.data
        // }else{
        //   alert("something went wrong")
        // }
      },
      async onSubscriptionCancel() {
        if(confirm('Are you sure, you want to cancel the subscription?')){
          const payload = this.subs[0].subscriptionId
          await deleteSubscription(payload)
          this.$router.push('/products')
          // localStorage.removeItem('state')
    localStorage.removeItem('stripeSession')
    localStorage.removeItem('symbol')
    localStorage.removeItem("user-id")
    localStorage.removeItem("ScanRequests")
    localStorage.removeItem("TimeRange")
        }
  
        // location.reload()
        // const data = await fetchAllSubscriptions()
        // this.$store.commit('user/setSubscriptionList', data.subscription)
      },
  },

  data() {
    return {
      inputBackColor: 'white',
      userDataMap: {
        sma: { period: 30, color: '#165ba8' },
        smavolume: { period: 30, color: '#165ba8' },
        wma: { period: 30, color: '#165ba8' },
        xma: { period: 30, color: '#165ba8' },
        smaobv: { period: 30, color: '#165ba8' },
      },
      propUpdates: [],
      multiItems: 0,
      changesNotSaved: false,
      theme_type: 1,
      selected_theme: 1,
      default_themes: [
        {
          text: 'Classic',
          value: 'classic',
        },
        {
          text: 'Dark',
          value: 'dark',
        },
        {
          text: 'Grey',
          value: 'grey',
        },
      ],
      custom_theme: {
        themeFont: '',
        themePrim: '',
        themeSecond: '',
      },
      showVolumeBars: true,
      settings: {
        sma: 14,
        tci: 21,
      },
      isGeneralTabActive: true,
      isIndicatorTabActive: false,
      isNewIndicator: false,
      isThemeTabActive: false,
      isUserSettings: false,
      // extra
      firstName: '',
        lastName: '',
        email: '',
        userId: '',
        currentPassword: '',
        newPassword: '',
        subscription: '',
        isTrial: false,
        showError: false,
        showSuccess: false,
        showChangeEmailModel:false,
        errorMsg: "",
        subs: [],
    }
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.inputBackColor = '#505050'
        // this.inputFontColor = "#A2A2A2"
        // this.inputBorderColor = '1px solid blue'
      } else {
        this.inputBackColor = 'white'
        // this.inputFontColor = this.themeFontColor.color
        // this.inputBorderColor = '1px solid blue'
      }
    },
    showVolumeBars: function (newValue) {
      this.$store.state.dc.set('chart.settings', {
        showVolume: newValue,
      })
    },
    settings: {
      handler: function (newSettings) {
        this.setIndicatorDefaultSettings(newSettings)
      },
      deep: true,
    },
    themeSettings(n, o) {},
    // extra
    subscriptions() {
        this.subs = this.subscriptions
        console.log("this.subs", this.subs)
  
        //  console.log(payload)
      },
  },
  computed: {
    ...mapGetters([
      'getUserSettingData',
      'defaultIndicatorSettings',
      'defaultChartSettings',
      'chartSettings',
      'protraderGeneralSettings',
      'themeSettings',
      'userThemeSettings',
      'defaultThemeSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
      'isThemePrimary',
      'userMultiSettings',
      'getUserEmail',
      'themeDarkMode',
    ]),
    /** @returns {String} */
    ...GeneralSettingsComputed,
    ...IndicatorSettingsComputed,
    primaryThemeId: {
      get() {
        return this.themeSettings.id
      },
      set(value) {
        this.propChange()
        this.$store.commit('UPDATE_DEFAULT_THEME', value)
      },
    },
    themeSettingsSettingsFont: {
      get() {
        //console.log('lineColor get', lineColor)
        let themeSettings = this.themeSettings
        let settings = themeSettings.settings
        let themeDetailDefault = THEME_DETAILS[themeSettings.id]
        return settings && settings.font
          ? settings.font
          : themeDetailDefault && themeDetailDefault.font
          ? themeDetailDefault.font
          : ''
      },
    },
    themeSettingsSettingsPrimary: {
      get() {
        //console.log('lineColor get', lineColor)
        let themeSettings = this.themeSettings
        let settings = themeSettings.settings
        let themeDetailDefault = THEME_DETAILS[themeSettings.id]
        return settings && settings.primary
          ? settings.primary
          : themeDetailDefault && themeDetailDefault.primary
          ? themeDetailDefault.primary
          : ''
      },
    },
    themeSettingsSettingsSecondary: {
      get() {
        //console.log('lineColor get', lineColor)
        let themeSettings = this.themeSettings
        let settings = themeSettings.settings
        let themeDetailDefault = THEME_DETAILS[themeSettings.id]
        return settings && settings.secondary
          ? settings.secondary
          : themeDetailDefault && themeDetailDefault.secondary
          ? themeDetailDefault.secondary
          : ''
      },
    },
    // extra
    subscriptions() {
        return this.$store.state.user.subscriptionList
      },
      isTrialMode() {
        return this.$store.state.user.subscriptionStatus === 'trialing'
      },
      trialModeText() {
        return this.isTrialMode
            ? ' Trial ends in ' + this.$store.state.user.trialEndsIn + ' day(s)'
            : ''
      }
  },
  async mounted() {
    // console.log("mounted")
    // console.log('getUserSettingData -- ',JSON.stringify(this.getUserSettingData))
    const data = await getUserData();
      if(!data.userData.timeFrameRange){
        data.userData.timeFrameRange = "3M"
        localStorage.setItem("TimeFrameRange", "3M")
      }
      console.log("data one---->", data?.userData)
  
  
      if(data.status==='active'){
        // console.log("products")
        
        // this.$store.commit('SET_USER_TIME_FRAME', data?.userData?.timeFrame)
        this.$store.commit('SET_USER_DATA_TIME_RANGE', data?.userData?.timeFrameRange)
        
        // SET_USER_DATA_TIME_RANGE
        console.log("data---->",data?.userData?.timeFrameRange )
      }else if(data.status==='inactive'){
        console.log("products")
        // this.$router.push('/products')
        console.log("data---->", )
      }
      else{
        this.$router.push('/login')
      }
      this.firstName = data.firstName
      this.userId = data.id
      this.lastName = data.lastName
      this.email = data.email
      this.subscription = data.subscription || 'yearly'
      this.currentPassword = ''
      // console.log("data.userData === ",data.userData)
      if (data && data.userData) {
        // console.log('SET_USER_DATA from profile')
        let newDataSet = JSON.parse(JSON.stringify(data.userData))
        if(!("additional" in newDataSet?.indicatorSettings)){
          newDataSet.indicatorSettings['additional'] = IndicatorSettingsDefaults?.indicatorSettings?.additional 
        }
        await this.updateProfileData(newDataSet)
        this.$store.commit('SET_USER_EMAIL', this.email)
      }
      if (data && data.id) {
        this.$store.commit('SET_USER_ID', data.id)
        this.getUserWatches()
        this.getUserScanList()
      }
      
      if (data && data.userSettings && data.userSettings.chartSettings) {
        this.$store.commit('SET_CHART_SETTINGS', data.userSettings.chartSettings)
        
  
        this.$store.commit(
            'SET_CHART_DEFAULT_SETTINGS',
            data.userSettings.chartSettings
        )
        this.$store.commit('UPDATE_CHART_STATE', new Date().getTime())
      }
  },
}
</script>

<style scoped>
/* .menu,.transition option{
  background-color: v-bind(inputBackColor) !important;

} */

div[role='listbox'] {
  background-color: v-bind(inputBackColor) !important;
}

/* .menu,.transition option{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
.ui.form select{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
option{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
select{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
}
input[type="number"]{
  background-color: v-bind(inputBackColor) !important;
  color:v-bind(inputFontColor) !important;
} */
</style>
