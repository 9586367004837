export default {
    cmoPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.CMO.color
        },
        set(value) {
            if(!this.propUpdates.includes('CMO'))
            this.propUpdates.push('CMO')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {CMO:{...this.$store.state.userData.indicatorSettings.additional.CMO,color:value}})
        },
    },
    cmoLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.CMO.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('CMO'))
            this.propUpdates.push('CMO')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {CMO:{...this.$store.state.userData.indicatorSettings.additional.CMO,lineWidth:Number(value)}})
        },
    },
    almaPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.ALMA.color
        },
        set(value) {
            if(!this.propUpdates.includes('ALMA'))
            this.propUpdates.push('ALMA')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {ALMA:{...this.$store.state.userData.indicatorSettings.additional.ALMA,color:value}})
        },
    },
    almaLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.ALMA.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('ALMA'))
            this.propUpdates.push('ALMA')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {ALMA:{...this.$store.state.userData.indicatorSettings.additional.ALMA,lineWidth:Number(value)}})
        },
    },
    atrpPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.ATRp.color
        },
        set(value) {
            if(!this.propUpdates.includes('ATRp'))
            this.propUpdates.push('ATRp')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {ATRp:{...this.$store.state.userData.indicatorSettings.additional.ATRp,color:value}})
        },
    },
    atrpLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.ATRp.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('ATRp'))
            this.propUpdates.push('ATRp')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {ATRp:{...this.$store.state.userData.indicatorSettings.additional.ATRp,lineWidth:Number(value)}})
        },
    },
    bbwPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.BBW.color
        },
        set(value) {
            if(!this.propUpdates.includes('BBW'))
            this.propUpdates.push('BBW')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {BBW:{...this.$store.state.userData.indicatorSettings.additional.BBW,color:value}})
        },
    },
    bbwLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.BBW.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('BBW'))
            this.propUpdates.push('BBW')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {BBW:{...this.$store.state.userData.indicatorSettings.additional.BBW,lineWidth:Number(value)}})
        },
    },
    cciPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.CCI.color
        },
        set(value) {
            if(!this.propUpdates.includes('CCI'))
            this.propUpdates.push('CCI')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {CCI:{...this.$store.state.userData.indicatorSettings.additional.CCI,color:value}})
        },
    },
    cciBackColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.CCI.backColor
        },
        set(value) {
            if(!this.propUpdates.includes('CCI'))
            this.propUpdates.push('CCI')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {CCI:{...this.$store.state.userData.indicatorSettings.additional.CCI,backColor:value}})
        },
    },
    cciBandColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.CCI.bandColor
        },
        set(value) {
            if(!this.propUpdates.includes('CCI'))
            this.propUpdates.push('CCI')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {CCI:{...this.$store.state.userData.indicatorSettings.additional.CCI,bandColor:value}})
        },
    },
    cciLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.CCI.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('CCI'))
            this.propUpdates.push('CCI')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {CCI:{...this.$store.state.userData.indicatorSettings.additional.CCI,lineWidth:Number(value)}})
        },
    },
    cogPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.COG.color
        },
        set(value) {
            if(!this.propUpdates.includes('COG'))
            this.propUpdates.push('COG')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {COG:{...this.$store.state.userData.indicatorSettings.additional.COG,color:value}})
        },
    },
    cogLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.COG.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('COG'))
            this.propUpdates.push('COG')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {COG:{...this.$store.state.userData.indicatorSettings.additional.COG,lineWidth:Number(value)}})
        },
    },    
    dmiPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.DMI.colors
        },
        set(value) {
            if(!this.propUpdates.includes('DMI'))
            this.propUpdates.push('DMI')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {DMI:{...this.$store.state.userData.indicatorSettings.additional.DMI,colors:[...value]}})
        },
    },
    dmiLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.DMI.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('DMI'))
            this.propUpdates.push('DMI')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {DMI:{...this.$store.state.userData.indicatorSettings.additional.DMI,lineWidth:Number(value)}})
        },
    },
    hmaPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.HMA.color
        },
        set(value) {
            if(!this.propUpdates.includes('HMA'))
            this.propUpdates.push('HMA')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {HMA:{...this.$store.state.userData.indicatorSettings.additional.HMA,color:value}})
        },
    },
    hmaLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.HMA.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('HMA'))
            this.propUpdates.push('HMA')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {HMA:{...this.$store.state.userData.indicatorSettings.additional.HMA,lineWidth:Number(value)}})
        },
    },
    kcwPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.KCW.color
        },
        set(value) {
            if(!this.propUpdates.includes('KCW'))
            this.propUpdates.push('KCW')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {KCW:{...this.$store.state.userData.indicatorSettings.additional.KCW,color:value}})
        },
    },
    kcwLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.KCW.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('KCW'))
            this.propUpdates.push('KCW')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {KCW:{...this.$store.state.userData.indicatorSettings.additional.KCW,lineWidth:Number(value)}})
        },
    },
    kcPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.KC.color
        },
        set(value) {
            if(!this.propUpdates.includes('KC'))
            this.propUpdates.push('KC')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {KC:{...this.$store.state.userData.indicatorSettings.additional.KC,color:value}})
        },
    },
    kcBackColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.KC.backColor
        },
        set(value) {
            if(!this.propUpdates.includes('KC'))
            this.propUpdates.push('KC')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {KC:{...this.$store.state.userData.indicatorSettings.additional.KC,backColor:value}})
        },
    },
    kcLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.KC.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('KC'))
            this.propUpdates.push('KC')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {KC:{...this.$store.state.userData.indicatorSettings.additional.KC,lineWidth:Number(value)}})
        },
    },
    momPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.MOM.color
        },
        set(value) {
            if(!this.propUpdates.includes('MOM'))
            this.propUpdates.push('MOM')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {MOM:{...this.$store.state.userData.indicatorSettings.additional.MOM,color:value}})
        },
    },
    momLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.MOM.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('MOM'))
            this.propUpdates.push('MOM')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {MOM:{...this.$store.state.userData.indicatorSettings.additional.MOM,lineWidth:Number(value)}})
        },
    },
    mfiPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.MFI.color
        },
        set(value) {
            if(!this.propUpdates.includes('MFI'))
            this.propUpdates.push('MFI')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {MFI:{...this.$store.state.userData.indicatorSettings.additional.MFI,color:value}})
        },
    },
    mfiBandColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.MFI.bandColor
        },
        set(value) {
            if(!this.propUpdates.includes('MFI'))
            this.propUpdates.push('MFI')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {MFI:{...this.$store.state.userData.indicatorSettings.additional.MFI,bandColor:value}})
        },
    },
    mfiBackColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.MFI.backColor
        },
        set(value) {
            if(!this.propUpdates.includes('MFI'))
            this.propUpdates.push('MFI')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {MFI:{...this.$store.state.userData.indicatorSettings.additional.MFI,backColor:value}})
        },
    },
    mfiLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.MFI.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('MFI'))
            this.propUpdates.push('MFI')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {MFI:{...this.$store.state.userData.indicatorSettings.additional.MFI,lineWidth:Number(value)}})
        },
    },
    ribbonPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.Ribbon.colors
        },
        set(value) {
            if(!this.propUpdates.includes('Ribbon'))
            this.propUpdates.push('Ribbon')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {Ribbon:{...this.$store.state.userData.indicatorSettings.additional.Ribbon,colors:[...value]}})
        },
    },
    ribbonLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.Ribbon.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('Ribbon'))
            this.propUpdates.push('Ribbon')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {Ribbon:{...this.$store.state.userData.indicatorSettings.additional.Ribbon,lineWidth:Number(value)}})
        },
    },
    vwmaPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.VWMA.color
        },
        set(value) {
            if(!this.propUpdates.includes('VWMA'))
            this.propUpdates.push('VWMA')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {VWMA:{...this.$store.state.userData.indicatorSettings.additional.VWMA,color:value}})
        },
    },
    vwmaLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.VWMA.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('VWMA'))
            this.propUpdates.push('VWMA')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {VWMA:{...this.$store.state.userData.indicatorSettings.additional.VWMA,lineWidth:Number(value)}})
        },
    },
    tsiPeriodColor: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.TSI.colors
        },
        set(value) {
            if(!this.propUpdates.includes('TSI'))
            this.propUpdates.push('TSI')
            this.changesNotSaved = true;            
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {TSI:{...this.$store.state.userData.indicatorSettings.additional.TSI,colors:[...value]}})
        },
    },
    tsiLineWidth: {
        get() {
            return this.$store.state.userData.indicatorSettings.additional.TSI.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('TSI'))
            this.propUpdates.push('TSI')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_ADDITIONAL', {TSI:{...this.$store.state.userData.indicatorSettings.additional.TSI,lineWidth:Number(value)}})
        },
    },
    trendChannelPeriod: {
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.trendChannels.color
        },
        set(value) {
            this.changesNotSaved = true;
            
            if(!this.propUpdates.includes('trendChannels'))
                this.propUpdates.push('trendChannels')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {trendChannels:{...this.$store.state.userData.indicatorSettings.single.trendChannels,color:value}})
        },
    },
    trendChannelValue: {
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.trendChannels.period
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('trendChannels'))
                this.propUpdates.push('trendChannels')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {trendChannels:{...this.$store.state.userData.indicatorSettings.single.trendChannels,period:Number(value)}})
        },
    },
    bollingerBandPeriodColor:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.bollingerBands.color
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('bollingerBands'))
                this.propUpdates.push('bollingerBands')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {bollingerBands:{...this.$store.state.userData.indicatorSettings.single.bollingerBands,color:value}})
        },
    },
    bollingerBandPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.bollingerBands.length
        },
        set(value) {
            // console.log("bollingerBands ",this.propUpdates.includes('bollingerBands'),this.propUpdates)
            if(!this.propUpdates.includes('bollingerBands'))
                this.propUpdates.push('bollingerBands')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {bollingerBands:{...this.$store.state.userData.indicatorSettings.single.bollingerBands,length:value}})
        },
    },
    bollingerBandDeviationColor:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.bollingerBands.backColor
        },
        set(value) {
            if(!this.propUpdates.includes('bollingerBands'))
                this.propUpdates.push('bollingerBands')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {bollingerBands:{...this.$store.state.userData.indicatorSettings.single.bollingerBands,backColor:value}})
        },
    },
    bollingerBandDeviationValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.bollingerBands.stddev
        },
        set(value) {
            if(!this.propUpdates.includes('bollingerBands'))
                this.propUpdates.push('bollingerBands')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {bollingerBands:{...this.$store.state.userData.indicatorSettings.single.bollingerBands,stddev:value}})
        },
    },
    atrPeriodColor:{
        

        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.averageTrueRange.color
        },
        set(value) {
            if(!this.propUpdates.includes('averageTrueRange'))
                this.propUpdates.push('averageTrueRange')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {averageTrueRange:{...this.$store.state.userData.indicatorSettings.single.averageTrueRange,color:value}})
        },
    },
    atrPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.averageTrueRange.length
        },
        set(value) {
            if(!this.propUpdates.includes('averageTrueRange'))
                this.propUpdates.push('averageTrueRange')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {averageTrueRange:{...this.$store.state.userData.indicatorSettings.single.averageTrueRange,length:value}})
        },
    },
    atrMultiplierValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.averageTrueRange.lineWidth
        },
        set(value) {
            if(!this.propUpdates.includes('averageTrueRange'))
                this.propUpdates.push('averageTrueRange')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {averageTrueRange:{...this.$store.state.userData.indicatorSettings.single.averageTrueRange,lineWidth:value}})
        },
    },
    macdFastPeriodColor:{


        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.macd.macdColor
        },
        set(value) {
            if(!this.propUpdates.includes('macd'))
                this.propUpdates.push('macd')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {macd:{...this.$store.state.userData.indicatorSettings.single.macd,macdColor:value}})
        },
    },
    macdFastPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.macd.fast
        },
        set(value) {
            if(!this.propUpdates.includes('macd'))
                this.propUpdates.push('macd')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {macd:{...this.$store.state.userData.indicatorSettings.single.macd,fast:Number(value)}})
        },
    },
    macdSlowPeriodColor:{


        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.macd.signalColor
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('macd'))
                this.propUpdates.push('macd')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {macd:{...this.$store.state.userData.indicatorSettings.single.macd,signalColor:value}})
        },
    },
    macdSlowPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.macd.slow
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('macd'))
                this.propUpdates.push('macd')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {macd:{...this.$store.state.userData.indicatorSettings.single.macd,slow:Number(value)}})
        },
    },
    macdSignalPeriodColor:{


        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.macd.histColor
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('macd'))
                this.propUpdates.push('macd')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {macd:{...this.$store.state.userData.indicatorSettings.single.macd,histColor:value}})
        },
    },
    macdSignalPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.macd.smooth
        },
        set(value) {
            if(!this.propUpdates.includes('macd'))
                this.propUpdates.push('macd')
            this.changesNotSaved = true;
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {macd:{...this.$store.state.userData.indicatorSettings.single.macd,smooth:Number(value)}})
        },
    },
    rocPeriodColor:{


        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.rateOfChange.color
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('rateOfChange'))
                this.propUpdates.push('rateOfChange')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {rateOfChange:{...this.$store.state.userData.indicatorSettings.single.rateOfChange,color:value}})
        },
    },
    rocPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.rateOfChange.length
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('rateOfChange'))
                this.propUpdates.push('rateOfChange')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {rateOfChange:{...this.$store.state.userData.indicatorSettings.single.rateOfChange,length:value}})
        },
    },
    stocPeriodColor:{


        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.stochasticOscillator.color
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('stochasticOscillator'))
                this.propUpdates.push('stochasticOscillator')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {stochasticOscillator:{...this.$store.state.userData.indicatorSettings.single.stochasticOscillator,color:value}})
        },
    },
    stocPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.stochasticOscillator.smooth
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('stochasticOscillator'))
                this.propUpdates.push('stochasticOscillator')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {stochasticOscillator:{...this.$store.state.userData.indicatorSettings.single.stochasticOscillator,smooth:value}})
        },
    },
    stocKPeriodColor:{


        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.stochasticOscillator.kColor
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('stochasticOscillator'))
                this.propUpdates.push('stochasticOscillator')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {stochasticOscillator:{...this.$store.state.userData.indicatorSettings.single.stochasticOscillator,kColor:value}})
        },
    },
    stocKPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.stochasticOscillator.param_k
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('stochasticOscillator'))
                this.propUpdates.push('stochasticOscillator')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {stochasticOscillator:{...this.$store.state.userData.indicatorSettings.single.stochasticOscillator,param_k:value}})
        },
    },
    stocDPeriodColor:{


        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.stochasticOscillator.dColor
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('stochasticOscillator'))
                this.propUpdates.push('stochasticOscillator')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {stochasticOscillator:{...this.$store.state.userData.indicatorSettings.single.stochasticOscillator,dColor:value}})
        },
    },
    stocDPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.stochasticOscillator.param_d
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('stochasticOscillator'))
                this.propUpdates.push('stochasticOscillator')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {stochasticOscillator:{...this.$store.state.userData.indicatorSettings.single.stochasticOscillator,param_d:value}})
        },
    },
    rsiPeriodColor:{


        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.relativeStrengthIndex.color
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('relativeStrengthIndex'))
                this.propUpdates.push('relativeStrengthIndex')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {relativeStrengthIndex:{...this.$store.state.userData.indicatorSettings.single.relativeStrengthIndex,color:value}})
        },
    },
    rsiPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.relativeStrengthIndex.length
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('relativeStrengthIndex'))
                this.propUpdates.push('relativeStrengthIndex')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {relativeStrengthIndex:{...this.$store.state.userData.indicatorSettings.single.relativeStrengthIndex,length:value}})
        },
    },
    willRPeriodColor:{


        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.williamsR.color
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('williamsR'))
                this.propUpdates.push('williamsR')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {williamsR:{...this.$store.state.userData.indicatorSettings.single.williamsR,color:value}})
        },
    },
    willRPeriodValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.williamsR.length
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('williamsR'))
                this.propUpdates.push('williamsR')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {williamsR:{...this.$store.state.userData.indicatorSettings.single.williamsR,length:value}})
        },
    },
    sarStartValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.parabolicSar.start
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('parabolicSar'))
                this.propUpdates.push('parabolicSar')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {parabolicSar:{...this.$store.state.userData.indicatorSettings.single.parabolicSar,start:value}})
        },
    },
    sarIncrementValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.parabolicSar.inc
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('parabolicSar'))
                this.propUpdates.push('parabolicSar')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {parabolicSar:{...this.$store.state.userData.indicatorSettings.single.parabolicSar,inc:value}})
        },
    },
    sarMaxValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.parabolicSar.max
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('parabolicSar'))
                this.propUpdates.push('parabolicSar')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {parabolicSar:{...this.$store.state.userData.indicatorSettings.single.parabolicSar,max:value}})
        },
    },
    sarSeriesValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.parabolicSar.color
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('parabolicSar'))
                this.propUpdates.push('parabolicSar')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {parabolicSar:{...this.$store.state.userData.indicatorSettings.single.parabolicSar,color:value}})
        },
    },
    valueTradedSeriesValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.valueTraded.color
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('valueTraded'))
                this.propUpdates.push('valueTraded')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {valueTraded:{...this.$store.state.userData.indicatorSettings.single.valueTraded,color:value}})
        },
    },
    vwapSeriesValue:{
        get() {
            //console.log('lineColor get', lineColor)
            return this.$store.state.userData.indicatorSettings.single.vwap.color
        },
        set(value) {
            this.changesNotSaved = true;
            if(!this.propUpdates.includes('vwap'))
                this.propUpdates.push('vwap')
            this.$store.commit('SET_INDICATOR_SETTINGS_SINGLE', {vwap:{...this.$store.state.userData.indicatorSettings.single.vwap,color:value}})
        },
    },
}