<template>
  <guru-base-modal
    class="fontSize"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    header="Capitulation"
    :valid="isValid"
    :scanType="scanType"
    id="capitulation"
    :isLoading="isLoading"
  >
    <div class="input-container fontSize">
      <span>
        Any volume of the last two days is at least
      </span>
      <input
        style="width: 10ch"
        type="number"
        min="0.1"
        v-model="volumeMultiplier"
        step="0.01"
      />
      <span>
        times greater than the average volume of the
      </span>
      <input
        style="width: 10ch"
        type="number"
        min="1"
        v-model="lastVolumeAvgPeriod"
        step="1"
      />
      <span> days before them </span>
    </div>
  </guru-base-modal>
</template>

<script>
import GuruBaseModal from './GuruBaseModal.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: { GuruBaseModal },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  name: 'Capitulation',
  data() {
    return {
      scanType:"capitulation",
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['sendCapitulationRequest', 'setCapitulationSettings', 'cancelGuruScans']),
    async onScanClick() {
      this.isLoading = true
      await this.sendCapitulationRequest()
      this.isLoading = false
      // result?.data?.results?.length
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    }
  },
  computed: {
    ...mapGetters(['capitulationSettings']),
    /** @returns {Boolean} */
    isValid() {
      return this.volumeMultiplier > 0 && this.lastVolumeAvgPeriod > 0
    },
    /** @returns {Number} */
    volumeMultiplier: {
      get() {
        return this.capitulationSettings.volumeMultiplier
      },
      set(value) {
        this.setCapitulationSettings({ volumeMultiplier: value })
      }
    },
    /** @returns {Number} */
    lastVolumeAvgPeriod: {
      get() {
        return this.capitulationSettings.lastVolumeAvgPeriod
      },
      set(value) {
        this.setCapitulationSettings({ lastVolumeAvgPeriod: Math.round(value) })
      }
    }
  }
}
</script>

<style>
.input-container {
  display: flex;
  align-items: center;
  height: max-content;
  width: 100%;
  margin-top: 0.1rem;
  font-size: 15px;
}
.input-container > * {
  margin-right: 0.3rem;
}
</style>
