import {
  deleteAllDrawingDataV1,
  getDrawingDataV1,
  updateDrawingDataV1,
  updateDrawingDataV2,
} from '@/services/drawing'
import { updateChartSettings, updateUserSettings } from '@/services/user'
import {
  deleteWatchList,
  getWatchListData,
  saveWatches,
} from '@/services/watches'
import { marketsData } from 'Data/markets'
import moment from 'moment'
import Vue from 'vue'
import Vuex from 'vuex'
import {
  ChartSettingsDefaults,
  IndicatorSettingsDefaults,
  MultipleIndicatorSettings,
} from '../components/dialogBoxes/helper/GeneralSettingsDefaults'
import gurus from '../services/gurus'
import scanner from '../services/scans'
import symbol, { getLastSymbol, setLastSymbol } from '../services/symbol'
import { getMarketTime, parseToChartCandleData } from '../utils'
import multipleSymbolStore from './multipleSymbol'
import userModule from './user'
import {
  DEFAULT_DATA_SETTINGS,
  DEFAULT_EVENT_SETTINGS,
  DEFAULT_FILTER_SETTINGS,
  defaultChartSettings,
  protraderCharts,
} from '../settings/USER_SETTINGS_DEFAULT'

import {
  DEFAULT_THEME_SETTINGS,
  isThemePrimary,
  THEME_DETAILS,
} from '../settings/THEME_DETAILS'
import { currentSymbolDefaults } from '../settings'
import { GetIterationData } from '../settings/TimeRangeConfig'
import { chartEvents } from '../chartEvents'
import Overlays from '../overlays'
import { DEFAULT_OVERLAYS, OVERLAYS_CHARTS } from '../settings/DEFAULT_OVERLAYS'
import { TimeRangesConfig } from '../utils/TimeRangesConfig'
import { deleteSingleDrawingDataV1 } from "../services/drawing";
import { copy as copyObject } from "../utils";
import { getSymbolData } from '../services/watches'
import { Utils } from 'trading-vue-js'
Vue.use(Vuex)

const themeModeSave = localStorage.getItem('themeModeSave')
const defaultThemeSettings = JSON.parse(JSON.stringify(DEFAULT_THEME_SETTINGS))
let themeDarkMode = false
if (themeModeSave) {
  themeDarkMode = themeModeSave === 'true'
  if (themeDarkMode) {
    defaultThemeSettings.id = 'dark'
    defaultThemeSettings.settings = THEME_DETAILS['dark']
    defaultThemeSettings.themeDarkMode = themeDarkMode
  }
}
let hasStoreRange = localStorage.getItem('TimeRange')
let ShowVolume = localStorage.getItem('ShowVolume')
let GetType = localStorage.getItem('SET_CHART_TYPE')
if (!GetType) {
  GetType = 'Candles'
}
// GetType = 'Candles'
localStorage.removeItem('showLegendProps')
let debugActiveStore = localStorage.getItem('debugActive')
let rangeObj = { range: '1Y' }
if (hasStoreRange) {
  rangeObj = JSON.parse(hasStoreRange)
}
localStorage.setItem('HideVolumeBar', JSON.stringify(['VolumeOVB_0']))

let showVolume = true
// if(ShowVolume && ShowVolume === "false"){
//   showVolume = false
// }
let debugActive = false
if (debugActiveStore && debugActiveStore === 'true') {
  debugActive = true
}
export default new Vuex.Store({
  modules: {
    multipleSymbol: multipleSymbolStore,
    user: userModule,
  },
  state: {
    scanFavListTypes: ['candlesticks', 'patterns', 'activity', 'fundementals', 'indicators', 'darvasScan'],
    guruFavListTypes: ["samsPattern"],
    minusIndex: 0,
    deleteFavScanLoader: false,
    candleIndexSelected: null,
    candleIndexJumpSelected: null,
    trainingOffset: 10,
    compareChartSymbols: [],
    trainingModeDataRaw: [],
    trainingModeData: [],
    debugActive: debugActive,
    chartRangeObj: {
      startRange: '2022/10/22',
      endRange: '2022/11/22',
      startIndex: 0,
      endIndex: 0,
      count: 0,
      daysCount: 0,
    },
    scanOverlay: null,
    showOverlay: false,
    mainChartProps: false,
    chartControlPressed: false,
    searchBoxUpdates: false,
    userChoice: false,
    selectedDrawingID: '',
    userSavedScans: [],
    proTrader: {
      themeSettings: defaultThemeSettings,
    },
    themeDarkMode: themeDarkMode,
    favSelectedScanId: undefined,
    scanDeletePopup: false,
    scanPopup: false,
    watchlistPopup: false,
    topListSymbolName: '',
    topListSymbol: '',
    scrollRight: true,
    SelectedScanId: '',
    SelectedScanSymbol: '',
    DEFAULT_THEME_SETTINGS: JSON.stringify(DEFAULT_THEME_SETTINGS),
    scanCopyBtn: false,
    type: GetType,
    // timeFrame:"D",
    timeFrameRange: "3M",
    protraderCharts: protraderCharts,
    trainingMode: false,
    userChartlist: [],
    chartIDList: [],
    drawingDataList: [],
    symbolIsMissing: false,
    showWatchesModal: false,
    showWatchRenameModal: false,
    showWatchesDeleteModal: false,
    showWatchesImportModal: false,
    watchLists: [],
    watchListsData: [],
    selectedWatch: '',
    selectedWatchCode: -1,
    selectedWatchSymbolCode: -1,
    selectedMarket: 'market',
    intradayMode: true,
    trainingModeStartingIndex: 0,
    trainingModeStartingIndexSubset: 0,
    tradingChartReference: null,
    brushChartReference: null,
    dc: null,
    drawingData: {},
    drawingDataSaveSettings: {},
    data: [],
    ohlcv: [],
    ohlcvTimeMap: [],
    comparisionHigherHigh: [],
    comparisionLowestLow: [],
    chartUpperRange: 0,
    chartLowerRange: 0,
    comparisionIndex: 0,
    currentSymbol: currentSymbolDefaults,
    currentSymbolData: "",
    symbolName: 'ZNO.au',
    marketId: '',
    marketDesc: '',
    symbolIndex: 0,
    symbolsList: [],
    industryGroup: {},
    subIndustryGroup: {},
    sectorGroup: {},
    timeFrame: localStorage.getItem("CHART_TIME_FRAME") || 'D',
    chartVisibleRange: localStorage.getItem("TimeFrameRange"),
    // chartVisibleRange: 'uuu',
    chartPredineRangeUpdates: false,
    chartToggleVolume: showVolume,
    chartSettingUpdatedDate: null,
    userData: {
      ...ChartSettingsDefaults,
      ...IndicatorSettingsDefaults,
      ...MultipleIndicatorSettings,
    },
    ChartSettingsDefaults: JSON.stringify(ChartSettingsDefaults),
    IndicatorSettingsDefaults: JSON.stringify(IndicatorSettingsDefaults),
    MultipleIndicatorSettings: JSON.stringify(MultipleIndicatorSettings),
    userId: null,
    emailId: null,
    settingUpdatesForCharts: [],
    chartOverlays: DEFAULT_OVERLAYS,
    chartSettings: defaultChartSettings,
    defaultChartSettings: defaultChartSettings,
    savedTradePlans: [],
    tadePlanner: {
      accountBalanceData: {
        accountBalance: null,
        stockCode: null,
        currentPrice: null,
        purchasePrice: null,
      },
      positionSize: {
        entryPrice: null,
        stopLoss: null,
        portFolioRisk: null,
        positionSize: null,
        costOfPosition: null,
      },
      entrySignalData: {
        darvas: false,
        ascendingTriangle: false,
        breakResistance: false,
        newsletter: false,
      },
      confirmationSignalData: {
        obv: false,
        marketDepth: false,
      },
      riskManagementSignalData: {
        atr: false,
        pctValue: false,
        dataPoint: false,
        supportLevel: false,
      },
      exitSignalData: {
        gapFilling: false,
        patternTarget: false,
      },
    },
    trailingStop: {
      isOnChart: false,
      isDialogBoxOpened: false,
      entryPrice: 0,
      stopPrice: 0,
      trailingType: '',
      stepPrice: 0.01,
      positionSize: 1000,
      riskValueInCash: 500,
      riskValueInPct: 10,
      atrPeriod: 14,
      atrMultiplier: 5,
    },

    isChartLoading: false,
    scans: {
      results: [],
      gurus: {
        capitulation: {
          selectedMarket: 'market',
          volumeMultiplier: 2,
          lastVolumeAvgPeriod: 5,
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
        bollingerSqueeze: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          minScanningPeriod: 200,
          maxScanningPeriod: 250,
          dayFromToday: 5,
          minSqueezeLength: 200,
          maxSqueezeLength: 250,
          ratioOfSpikeHeight: 10,
        },
        divergenceScan: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          selectedScan: 'obv',
          maxRange: 20,
          stoc: {
            kPeriod: 12,
            dPeriod: 26,
            period: 9,
          },
          rsi: 14,
          macd: {
            fast: 12,
            slow: 26,
            signal: 9,
          },
        },
        bigCandle: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          candleSizeMultiplier: 2,
          previousCandlePeriod: 5,
        },
        pocketPivot: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          period: 5,
        },
        heikenAshi: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          period: 10,
        },
        franksPattern: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
        hhhl: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
      },
      scanner: {
        samsPattern: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          settings: {
            scanType: 'long',
            scanPeriod: 55,
            topConfirmationPeriod: 3,
            bottomConfirmationPeriod: 3,
            percentageRiskBuffer: 2.0,
            limitTotalRisk: {
              isActive: false,
              valuePct: 20.0,
            },
            volumeIncreasedOver: {
              isActive: false,
              percent: 300,
              previousBars: 1,
            },
            inBox: {
              isActive: false,
              type: 'anyBox', // 'anyBox' | '1stBox'
            },
            within: {
              isActive: false,
              type: 'close', // 'close' | 'high'
              pricePoints: 0,
            },
            restrictBoxToMaxBox: {
              isActive: false,
              maxBoxes: 0,
            },
            restrictCurrentBoxWidth: {
              isActive: false,
              start: 0,
              end: 10,
            },
            onlyShowBoxOfTopBottomOnFirstBar: false,
          },
        },
        darvasScan: {
          selectedMarket: 'market',
          settings: {
            scanType: 'long',
            scanPeriod: 55,
            topConfirmationPeriod: 3,
            bottomConfirmationPeriod: 3,
            percentageRiskBuffer: 2.0,
            limitTotalRisk: {
              isActive: false,
              valuePct: 20.0,
            },
            volumeIncreasedOver: {
              isActive: false,
              percent: 300,
              previousBars: 1,
            },
            inBox: {
              isActive: false,
              type: 'anyBox', // 'anyBox' | '1stBox'
            },
            within: {
              isActive: false,
              type: 'close', // 'close' | 'high'
              pricePoints: 0,
            },
            restrictBoxToMaxBox: {
              isActive: false,
              maxBoxes: 0,
            },
            restrictCurrentBoxWidth: {
              isActive: false,
              start: 0,
              end: 10,
            },
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          scanType: 'long',
          scanPeriod: 55,
          topConfirmationPeriod: 3,
          bottomConfirmationPeriod: 3,
          percentageRiskBuffer: 2.0,
          limitTotalRisk: {
            isActive: false,
            valuePct: 20.0,
          },
          volumeIncreasedOver: {
            isActive: false,
            percent: 300,
            previousBars: 1,
          },
          inBox: {
            isActive: false,
            type: 'anyBox', // 'anyBox' | '1stBox'
          },
          within: {
            isActive: false,
            type: 'close', // 'close' | 'high'
            pricePoints: 0,
          },
          restrictBoxToMaxBox: {
            isActive: false,
            maxBoxes: 0,
          },
          restrictCurrentBoxWidth: {
            isActive: false,
            start: 0,
            end: 10,
          },
        },
        fundementals: {
          selectedMarket: 'market',
          settings: {
            earningYield: {
              isActive: false,
              type: 'more',
              value: 5,
            },
            peRatio: {
              isActive: false,
              type: 'more',
              value: 20,
            },
            dividendYield: {
              isActive: false,
              type: 'more',
              value: 5,
            },
            frankedPct: {
              isActive: false,
              type: '100',
            },
            capitalisation: {
              topSymbols: {
                isActive: false,
                value: 20,
              },
              sharesIssued: {
                isActive: false,
                type: 'more',
                value: 5,
              },
              marketCap: {
                isActive: false,
                type: 'more',
                value: 5,
              },
            },
            returns: {
              isActive: false,
              type: 'more',
              value: 100,
              month: 'return12m',
            },
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          earningYield: {
            isActive: false,
            type: 'more',
            value: 5,
          },
          peRatio: {
            isActive: false,
            type: 'more',
            value: 20,
          },
          dividendYield: {
            isActive: false,
            type: 'more',
            value: 5,
          },
          frankedPct: {
            isActive: false,
            type: '100',
          },
          capitalisation: {
            topSymbols: {
              isActive: false,
              value: 20,
            },
            sharesIssued: {
              isActive: false,
              type: 'more',
              value: 5,
            },
            marketCap: {
              isActive: false,
              type: 'more',
              value: 5,
            },
          },
          returns: {
            isActive: false,
            type: 'more',
            value: 100,
            month: 'return12m',
          },
        },
        activity: {
          selectedMarket: 'market',
          settings: {
            mode: 'top',
            topCount: 10,
            topMethod: 'volume',
            withinPct: 1.0,
            priceRangeStart: 1,
            priceRangeEnd: 5,
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          mode: 'top',
          topCount: 10,
          topMethod: 'volume',
          withinPct: 1.0,
          priceRangeStart: 1,
          priceRangeEnd: 5,
        },
        candlesticks: {
          selectedMarket: 'market',
          settings: {
            doji: false,
            bullishHarami: false,
            bearishHarami: false,
            bullishHaramiCross: false,
            bearishHaramiCross: false,
            hammer: false,
            invertedHammer: false,
            shootingStar: false,
            hangingMan: false,
            bullishEngulfing: false,
            bearishEngulfing: false,
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          doji: false,
          bullishHarami: false,
          bearishHarami: false,
          bullishHaramiCross: false,
          bearishHaramiCross: false,
          hammer: false,
          invertedHammer: false,
          shootingStar: false,
          hangingMan: false,
          bullishEngulfing: false,
          bearishEngulfing: false,
        },
        patterns: {
          selectedMarket: 'market',
          settings: {
            saucerBottom: false,
            doubleBottom: false,
            bearishKeyReversal: false,
            bullishPennant: false,
            bearishFlag: false,
            ascendingTriangle: false,
            doubleTop: false,
            triPointResistance: false,
            bearishPennant: false,
            descendingTriangle: false,
            bullishKeyReversal: false,
            triPointSupport: false,
            bullishFlag: false,
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
          saucerBottom: false,
          doubleBottom: false,
          bearishKeyReversal: false,
          bullishPennant: false,
          bearishFlag: false,
          ascendingTriangle: false,
          doubleTop: false,
          triPointResistance: false,
          bearishPennant: false,
          descendingTriangle: false,
          bullishKeyReversal: false,
          triPointSupport: false,
          bullishFlag: false,
        },
        indicators: {
          selectedMarket: 'market',
          settings: {
            crossesAndAboveBelow: {
              isActive: false,
              left: 'close',
              leftPeriod: 14,
              operatorType: 'above',
              right: 'ma',
              rightPeriod: 14,
            },
            ROC: {
              isActive: false,
              period: 10,
              mode: 'overbought',
            },
            RSI: {
              isActive: false,
              period: 14,
              mode: 'overbought',
            },
            STOC: {
              isActive: false,
              period: 15,
              kPercent: 3,
              dPercent: 3,
              mode: 'overbought',
            },
            BANDS: {
              isActive: false,
              period: 21,
              breakMode: 'top',
            },
          },
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
          crossesAndAboveBelow: {
            isActive: false,
            left: 'close',
            leftPeriod: 14,
            operatorType: 'above',
            right: 'ma',
            rightPeriod: 14,
          },
          ROC: {
            isActive: false,
            period: 10,
            mode: 'overbought',
          },
          RSI: {
            isActive: false,
            period: 14,
            mode: 'overbought',
          },
          STOC: {
            isActive: false,
            period: 15,
            kPercent: 3,
            dPercent: 3,
            mode: 'overbought',
          },
          BANDS: {
            isActive: false,
            period: 21,
            breakMode: 'top',
          },
        },
        proReversalSignal: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
          eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),

        }

      },
      // New scanner added the in the store staring point

      dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
      filterSettings: JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
      eventsSettings: JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS)),
      eventUpdates: {
        status: false,
        dialogId: '',
      },
    },
    indicatorDefaults: {
      sma: 14,
      tci: 21,
    },
  },
  mutations: {
    SET_CANDLE_DATA_TIME_MAP(state, payload) {
      state.ohlcvTimeMap = payload
    },
    SET_MINUS_INDEX(state, payload) {
      state.minusIndex = payload
    },
    COMPARE_CHART_SYMBOLS(state, payload) {
      state.compareChartSymbols = payload
    },
    MAIN_CHART_PROPS(state, payload) {
      state.mainChartProps = payload
    },
    UPDATE_TRAINING_MODE_DATA(state, payload) {
      state.trainingModeData = payload.data
      state.trainingModeDataRaw = payload.raw
    },
    SET_CANDLE_INDEX_UPD(state, payload) {

      state.candleIndexSelected = payload
    },
    SET_CANDLE_INDEX_UPD_TRAINING(state, payload) {

      state.candleIndexJumpSelected = payload
    },
    SET_ChartRangeObj(state, payload) {
      state.chartRangeObj = payload
    },
    SET_CHART_YRANGE_UPPER(state, payload) {
      state.chartUpperRange = payload
    },
    SET_CHART_YRANGE_LOWER(state, payload) {
      state.chartLowerRange = payload
    },
    SET_COMPARISION_HIGHEST_HIGH(state, payload) {
      state.comparisionHigherHigh = payload
    },
    SET_COMPARISION_LOWEST_LOW(state, payload) {
      state.comparisionLowestLow = payload
    },
    SET_COMPARISION_INDEX(state, payload) {
      state.comparisionIndex = payload
    },
    SET_OHLCV(state, payload) {
      state.ohlcv = payload
    },
    SHOW_OVERLAYS(state, payload) {
      state.showOverlay = payload
    },
    SCAN_OVERLAYS(state, payload) {
      state.scanOverlay = payload
    },
    SET_SEARCH_BOX_UPDATE(state, payload) {
      state.searchBoxUpdates = payload
    },
    SET_DEL_FAV_SCAN_LOADER(state, payload) {
      state.deleteFavScanLoader = payload
    },
    THEME_MODE(state, payload) {
      state.proTrader.themeSettings = {
        ...state.proTrader.themeSettings,
        themeDarkMode: payload,
      }
    },
    SET_USER_CHOICE(state, payload) {
      state.userChoice = payload
    },
    SET_DRAWING_ID(state, payload) {
      state.selectedDrawingID = payload
    },
    FAV_SELECTED_SCAN_ID(state, payload) {
      state.favSelectedScanId = payload
    },
    RESET_SCAN_SETTINGS_DEFAULT(state) {

      state.dataSettings = JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS))
      state.filterSettings = JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS))
      state.eventsSettings = JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS))
    },
    UPDATE_USER_SCAN_LIST(state, payload) {
      state.userSavedScans = payload
    },

    SCAN_DELETE_POPUP(state, payload) {
      state.scanDeletePopup = payload
    },
    SCAN_POPUP(state, payload) {
      state.scanPopup = payload
    },
    WATCHLIST_POPUP(state, payload) {

      state.watchlistPopup = payload
    },
    TOP_LIST_SYMBOL_NAME(state, payload) {
      state.topListSymbolName = payload
    },
    TOP_LIST_SYMBOL(state, payload) {
      state.topListSymbol = payload
    },
    SCROLL_RIGHT(state, payload) {
      state.scrollRight = payload
    },
    SCAN_COPY_BUTTON(state, payload) {
      state.scanCopyBtn = payload
    },
    SELECTED_SCAN_ID(state, payload) {
      state.SelectedScanId = payload

    },
    RESET_DEFAULT_THEME(state, payload) {

      state.proTrader.themeSettings = payload
    },
    UPDATE_CUSTOM_THEME(state, payload) {

      // delete state.proTrader.themeSettings.id
      state.proTrader.themeSettings = { settings: payload }
    },
    UPDATE_DEFAULT_THEME(state, payload) {

      // delete state.proTrader.themeSettings.settings
      state.proTrader.themeSettings = { id: payload }
    },
    UPDATE_THEME_SETTINGS(state, payload) {
      // console.log('UPDATE_THEME_SETTINGS',payload)
      state.proTrader.themeSettings = payload
    },
    TOGGLE_VOLUME_CHART(state) {
      const chartToggleVolume = !state.chartToggleVolume
      localStorage.setItem('ShowVolume', chartToggleVolume)
      state.chartToggleVolume = chartToggleVolume
    },
    SET_CHART_TYPE(state, payload) {
      //state.dc.data.chart.type = payload
      chartEvents.$emit('setChartType', payload)
      localStorage.setItem('SET_CHART_TYPE', payload)
      state.type = payload
    },
    SET_TIME_FRAME(state, payload) {
      localStorage.setItem('SET_TIME_FRAME', payload)
      state.currentSymbol.timeFrame = payload
      state.timeFrame = payload
    },
    SET_TRADING_CHART_REFERENCE(state, payload) {

      state.tradingChartReference = payload.tradingChartReference
    },
    SET_TRADING_BRUSH_REFERENCE(state, payload) {
      state.brushChartReference = payload.brushChartReference
    },
    SET_DATA_CUBE(state, payload) {
      state.dc = payload.dc
    },
    SET_CURRENT_SYMBOL(state, symbol) {
      //////////////////////////////////////////////
      state.currentSymbol = symbol
    },
    SET_CURRENT_SYMBOL_DATA(state, payload) {
      state.currentSymbolData = payload

    },
    SET_MARKET_ID(state, marketId) {
      ////////////////////////////////////////////////////////////

      let data = marketsData.find((m) => m.code === marketId)
      if (data) {
        state.marketDesc = data.desc
      }
      state.marketId = marketId

    },
    SET_BAR_DATA(state, payload) {
      state.dc.data.chart.data = payload.barData
    },
    ADD_INDICATOR(state, payload) {
      state.dc.data.onchart.push(payload.indicator)
    },
    // SET_SYMBOL_TIME_FRAME(state, timeFrame) {
    //   state.timeFrame = timeFrame
    // },
    SET_CHART_SETTINGS(state, payload) {
      state.chartSettings = {
        ...state.chartSettings,
        ...payload,
      }
    },
    SET_CHART_GENERAL_SETTINGS(state, payload) {
      state.userData.generalSettings.chart = {
        ...state.userData.generalSettings.chart,
        ...payload,
      }
    },
    SET_INDICATOR_SETTINGS_SINGLE(state, payload) {
      state.userData.indicatorSettings.single = {
        ...state.userData.indicatorSettings.single,
        ...payload,
      }
    },
    SET_INDICATOR_SETTINGS_ADDITIONAL(state, payload) {
      state.userData.indicatorSettings.additional = {
        ...state.userData.indicatorSettings.additional,
        ...payload,
      }
    },
    SET_MARKER_GENERAL_SETTINGS(state, payload) {
      state.userData.generalSettings.marker = {
        ...state.userData.generalSettings.marker,
        ...payload,
      }
    },
    SET_CROSSHAIR_GENERAL_SETTINGS(state, payload) {
      state.userData.generalSettings.crossHair = {
        ...state.userData.generalSettings.crossHair,
        ...payload,
      }
    },
    SET_MISC_GENERAL_SETTINGS(state, payload) {
      state.userData.generalSettings.misc = {
        ...state.userData.generalSettings.misc,
        ...payload,
      }
    },
    SET_INDICATOR_MULTI_SETTINGS(state, payload) {
      state.userData.multi = {
        ...state.userData.multi,
        ...payload,
      }
    },

    RESET_CHART_SETTINGS_DEFAULTS(state) {

      let ChartSettingsDefaults = JSON.parse(state.ChartSettingsDefaults)
      let IndicatorSettingsDefaults = JSON.parse(
        state.IndicatorSettingsDefaults
      )
      let MultipleIndicatorSettings = JSON.parse(
        state.MultipleIndicatorSettings
      )
      state.userData = {
        ...ChartSettingsDefaults,
        ...IndicatorSettingsDefaults,
        ...MultipleIndicatorSettings,
      }
      state.proTrader.themeSettings = JSON.parse(state.DEFAULT_THEME_SETTINGS)
    },
    SET_USER_ID(state, payload) {
      state.userId = payload
    },
    SET_USER_EMAIL(state, payload) {
      state.emailId = payload
    },
    SET_USER_CHART_LIST(state, payload) {
      const defaultCharts = [
        'trendCharts',
        'momentumCharts',
        'volatilityCharts',
        'plusCharts'
      ]
      let userChartData = false
      let userChartlist = []
      let chartIDList = []
      for (let defaultChart of defaultCharts) {
        // debugger
        let findElement = false
        if (
          state.chartOverlays[defaultChart] &&
          state.chartOverlays[defaultChart].data
        ) {
          for (const datum of state.chartOverlays[defaultChart].data) {

            if (payload.data.includes(datum.chartId)) {
              userChartlist.push(datum.chartId)
              chartIDList.push(datum.name)
              datum.selected = true
              findElement = true
            } else {
              datum.selected = false
            }
          }
        }
        if (!userChartData) {
          userChartData = findElement
        }
        state.chartOverlays[defaultChart].selected = findElement
      }
      state.userChartlist = userChartlist
      state.chartIDList = chartIDList
    },
    SET_USER_DATA(state, payload) {
      console.log('stf', payload)
      // Object.keys(state.userData.indicatorSettings.additional).map((key) => {
      //   if(!payload.indicatorSettings.additional[key]) {
      //     payload.indicatorSettings.additional[key] = state.userData.indicatorSettings.additional[key]
      //   }
      // })
      state.userData = {
        ...state.userData,
        ...payload,
      }
    },
    SET_USER_TIME_FRAME(state, payload) {
      state.timeFrame = payload

    },
    SET_USER_DATA_TIME_RANGE(state, payload) {
      console.log('paylMounted Count:oad', typeof (payload), typeof (state.timeFrameRange))
      localStorage.setItem("TimeFrameRange", payload)


      state.timeFrameRange = payload
      // console.log('paylMounted Count:oad', payload, state.timeFrameRange)

    },
    USER_SET_UPDATE_CHARTS(state, payload) {
      state.settingUpdatesForCharts = payload
    },
    SET_CHART_DEFAULT_SETTINGS(state, payload) {
      state.defaultChartSettings = {
        ...state.defaultChartSettings,
        ...payload,
      }
      state.chartSettingUpdatedDate = new Date().getTime()
    },
    RESET_CHART_SETTINGS(state, payload) {
      if (payload && payload.chartSettings) {
        state.chartSettings = payload.chartSettings
      } else {
        state.chartSettings = defaultChartSettings
      }

      state.chartSettingUpdatedDate = new Date().getTime()
      // reset the indicator defaults as well
      state.indicatorDefaults = {
        sma: 14,
        tci: 21,
      }
    },
    RESET_THEME_SETTINGS(state, payload) {
      if (payload && payload.chartSettings) {
        state.chartSettings = payload.chartSettings
      } else {
        state.chartSettings = defaultChartSettings
      }

      state.chartSettingUpdatedDate = new Date().getTime()
      // reset the indicator defaults as well
      state.indicatorDefaults = {
        sma: 14,
        tci: 21,
      }
    },
    UPDATE_CHART_STATE(state, payload) {
      state.chartSettingUpdatedDate = payload
    },
    SET_INDICATOR_DEFAULTS(state, payload) {
      state.indicatorDefaults = {
        ...state.indicatorDefaults,
        ...payload,
      }
    },
    UPDATE_CHART_SELECTION(state, payload) {
      state.chartSettings[payload.chartName] = payload.chartType
      // state.chartSettings =
    },
    RESET_TRADE_PLANNER_STATE(state) {
      state.tadePlanner = {
        accountBalanceData: {
          accountBalance: null,
          stockCode: null,
          currentPrice: null,
          purchasePrice: null,
        },
        positionSize: {
          entryPrice: null,
          stopLoss: null,
          portFolioRisk: null,
          positionSize: null,
          costOfPosition: null,
        },
        entrySignalData: {
          darvas: false,
          ascendingTriangle: false,
          breakResistance: false,
          newsletter: false,
        },
        confirmationSignalData: {
          obv: false,
          marketDepth: false,
        },
        riskManagementSignalData: {
          atr: false,
          pctValue: false,
          dataPoint: false,
          supportLevel: false,
        },
        exitSignalData: {
          gapFilling: false,
          patternTarget: false,
        },
      }
    },
    RESET_GURUS(state) {
      state.scans.gurus = {
        capitulation: {
          selectedMarket: 'market',
          volumeMultiplier: 2,
          lastVolumeAvgPeriod: 5,
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
        bollingerSqueeze: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          minScanningPeriod: 200,
          maxScanningPeriod: 250,
          dayFromToday: 5,
          minSqueezeLength: 200,
          maxSqueezeLength: 250,
          ratioOfSpikeHeight: 10,
        },
        divergenceScan: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          selectedScan: 'obv',
          maxRange: 20,
          stoc: {
            kPeriod: 12,
            dPeriod: 26,
            period: 9,
          },
          rsi: 14,
          macd: {
            fast: 12,
            slow: 26,
            signal: 9,
          },
        },
        bigCandle: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          candleSizeMultiplier: 2,
          previousCandlePeriod: 5,
        },
        pocketPivot: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          period: 5,
        },
        heikenAshi: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
          period: 10,
        },
        franksPattern: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
        hhhl: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },
        proReversal: {
          selectedMarket: 'market',
          dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        },

      }
      state.scans.filterSettings = {
        priceRangeDollar: {
          isActive: false,
          start: 0,
          end: 0,
        },
        ignoreResults: {
          isActive: false,
          minDays: 280,
        },
        isIgnoreResultsWithNoRangeForLastDay: false,
        onlyShowResultsThatHaveTraded: {
          isActive: false,
          pastDays: 10,
        },
        ignoreResultsWithValueLess: {
          isActive: false,
          value: 2500,
        },
        ignoreResultsWithVolumeLess: {
          isActive: false,
          volume: 2500,
        },
        isExcludeIntraDayData: false,
      }
    },
    UPDATE_LATEST_GURU_SETTINGS(state, payload) {
      let data = {
        dataSettings: payload.settings.dataSettings,
        ...payload.settings?.settings,
      }
      state.scans.gurus[payload.id] = data
    },
    UPDATE_TRADE_PLANNER_STATE(state, payload) {
      state.tadePlanner = {
        ...state.tadePlanner,
        [payload.key]: payload.data,
      }
    },
    SAVE_TRADE_PLAN(state) {
      state.savedTradePlans.push({
        name: `${state.tadePlanner.accountBalanceData.stockCode}@${state.tadePlanner.positionSize.entryPrice}-${state.tadePlanner.positionSize.stopLoss}`,
        date: moment().format('HH:MM Do MMM YYYY'),
        plan: state.tadePlanner,
      })
    },
    DELETE_PLAN(state, payload) {
      state.savedTradePlans.splice(
        state.savedTradePlans.findIndex((plan) => plan.name === payload),
        1
      )
    },
    CHART_VISIBLE_RANGE(state, range) {
      console.log("Chart Visible Range", range)
      state.chartVisibleRange = range
    },
    CHART_PREDEFINE_RANGE_UPDATES(state) {
      state.chartPredineRangeUpdates = !state.chartPredineRangeUpdates
    },
    UPDATE_INDUSTRY_MAP(state, industryGroup) {
      ///////////////////////////////////////
      state.industryGroup = industryGroup
    },
    UPDATE_SUB_INDUSTRY_MAP(state, subIndustryGroup) {
      ///////////////////////////////////////
      state.subIndustryGroup = subIndustryGroup
    },
    UPDATE_SECTOR_MAP(state, sectorGroup) {
      ///////////////////////////////////////
      state.sectorGroup = sectorGroup
    },
    UPDATE_SYMBOLS_LIST(state, symbolsList) {
      ///////////////////////////////////////
      state.symbolsList = symbolsList
    },
    UPDATE_SYMBOL_INDEX(state, newSymbolIndex) {
      /////////////////////////////////////
      state.symbolIndex = newSymbolIndex
    },
    SET_CHART_LOADING_STATUS(state, status) {
      state.isChartLoading = status
    },
    ADD_NEW_SCAN_RESULT(state, newScanResult) {
      let date = moment().format('HH:mm:ss')
      if (!newScanResult.events) {
        newScanResult.events = {}
      }
      newScanResult.settings = JSON.parse(
        JSON.stringify({ ...newScanResult.settings, ...newScanResult.events })
      )
      let scanId =
        newScanResult.scanType + ' - ' + newScanResult.timeFrame + ' - ' + date
      let scanName =
        newScanResult.scanType.substr(0, 3).toUpperCase() +
        ' - ' +
        newScanResult.timeFrame +
        ' - ' +
        date

      // console.log("newScanResult",newScanResult)
      state.scans.results.push({
        ...newScanResult,

        date: date,
        scanId,
        scanName,
        createdDate: moment(),
        count: 0,
      })

    },

    // var a = moment([2007, 0, 29]);
    // var b = moment([2007, 0, 28]);
    // a.diff(b, 'days')

    UPDATE_SCAN_RESULTS(state, payload) {

      state.scans.results.map((m) => {
        let currentDate = moment()
        let seconds = currentDate.diff(m.createdDate, 'seconds')
        let minutes = currentDate.diff(m.createdDate, 'minutes')
        let hours = currentDate.diff(m.createdDate, 'hours')
        if (seconds < 60) {
          m.count = seconds + ' seconds ago'
        }
        if (seconds >= 60) {
          m.count = minutes + ' minutes ago'
        }
        if (minutes >= 60) {
          m.count = hours + ' hours ago'
        }
      })
    },
    SET_SCAN_DATA_SETTINGS(state, settings) {
      state.scans.dataSettings = { ...state.scans.dataSettings, ...settings }
    },
    SET_SCAN_DATA_SETTINGS2(state, payload) {
      console.log("SET_SCAN_DATA_SETTINGS2", payload)
      if (payload.type === 'scanner') {
        let spreadElements = {
          ...state.scans.scanner[payload.id].dataSettings,
          ...payload.settings,
        }
        state.scans.scanner[payload.id].dataSettings = spreadElements
      } else if (payload.type === 'guru') {
        let spreadElements = state.scans.gurus[payload.id].dataSettings
        spreadElements = { ...spreadElements, ...payload.settings }
        state.scans.gurus[payload.id].dataSettings = spreadElements
      }
    },
    SET_SCAN_FILTER_SETTINGS(state, settings) {
      state.scans.filterSettings = {
        ...state.scans.filterSettings,
        ...settings,
      }
    },
    SET_SCAN_FILTER_SETTINGS2(state, payload) {
      let spreadElements = {
        ...state.scans.scanner[payload.id].filterSettings,
        ...payload.settings,
      }
      state.scans.scanner[payload.id].filterSettings = spreadElements
    },
    SET_EVENTS_SETTINGS(state, payload) {
      let spreadElements = {
        ...state.scans.scanner[payload.id].eventsSettings,
        ...payload.settings,
      }
      state.scans.scanner[payload.id].eventsSettings = spreadElements
    },
    SET_GURUS_CAPITULATION_SETTINGS(state, settings) {
      state.scans.gurus.capitulation = {
        ...state.scans.gurus.capitulation,
        ...settings,
      }
    },
    SET_GURUS_SAMS_PATTERN_SETTINGS(state, settings) {
      state.scans.scanner.samsPattern.settings = {
        ...state.scans.scanner.samsPattern.settings,
        ...settings,
      }
    },
    SET_GURUS_BOLLINGER_SQUEEZE_PATTERN_SETTINGS(state, settings) {
      state.scans.gurus.bollingerSqueeze = {
        ...state.scans.gurus.bollingerSqueeze,
        ...settings,
      }
    },
    SET_GURUS_DIVERGENCE_SCAN_SETTINGS(state, settings) {
      state.scans.gurus.divergenceScan = {
        ...state.scans.gurus.divergenceScan,
        ...settings,
      }
    },
    SET_GURUS_BIG_CANDLE_SCAN_SETTINGS(state, settings) {
      state.scans.gurus.bigCandle = {
        ...state.scans.gurus.bigCandle,
        ...settings,
      }
    },
    SET_GURUS_POCKET_PIVOT_SCAN_SETTINGS(state, pocketPivotPeriod) {
      state.scans.gurus.pocketPivot = pocketPivotPeriod
    },
    SET_GURUS_HEIKEN_ASHI_SCAN_SETTINGS(state, heikenAshiPeriod) {
      state.scans.gurus.heikenAshi = heikenAshiPeriod
    },
    SET_DARVAS_SCAN_SETTINGS(state, settings) {
      state.scans.scanner.darvasScan.settings = {
        ...state.scans.scanner.darvasScan.settings,
        ...settings,
      }
    },
    SET_FUNDEMENTAL_SCAN_SETTINGS(state, settings) {
      state.scans.scanner.fundementals.settings = {
        ...state.scans.scanner.fundementals.settings,
        ...settings,
      }
    },
    SET_ACTIVITY_SCAN_SETTINGS(state, settings) {
      state.scans.scanner.activity.settings = {
        ...state.scans.scanner.activity.settings,
        ...settings,
      }
    },
    SET_CANDLESTICK_SCAN_SETTINGS(state, settings) {
      state.scans.scanner.candlesticks.settings = {
        ...state.scans.scanner.candlesticks.settings,
        ...settings,
      }
    },
    SET_PATTERNS_SCAN_SETTINGS(state, settings) {
      state.scans.scanner.patterns.settings = {
        ...state.scans.scanner.patterns.settings,
        ...settings,
      }
    },
    SET_INDICATORS_SCAN_SETTINGS(state, settings) {
      state.scans.scanner.indicators.settings = {
        ...state.scans.scanner.indicators.settings,
        ...settings,
      }
    },
    SET_TRAINING_MODE(state, payload) {
      state.trainingMode = payload.state
      state.trainingModeStartingIndex =
        payload.startingIndex ?? state.trainingModeStartingIndex
      state.trainingModeStartingIndexSubset =
        payload.trainingDataIndex ?? state.trainingModeStartingIndex
    },
    SET_INTRADAY_MODE(state, payload) {
      state.intradayMode = payload
    },
    UPDATE_DATA_SET(state, payload) {
      state.currentSymbol.data = payload
    },
    UPDATE_USER_DRAWING_TOOLS(state, payload) {
      state.drawingData = payload
    },
    UPDATE_USER_DRAWING_Settings(state, payload) {
      state.drawingDataSaveSettings[payload.id] = payload.value
    },
    UPDATE_USER_DRAWING_LIST(state, payload) {
      state.drawingDataList = payload
    },
    UPDATE_FILTER_EVENT_SETTINGS(state, payload) {
      state.scans.eventUpdates = payload
    },
  },
  actions: {
    async deleteScanSettings(context, id) {
      let deletescan = await scanner.deleteScanId(context.state.userId, id)
      context.commit('SET_DEL_FAV_SCAN_LOADER', false)
      if (deletescan && deletescan.data && deletescan.data.deletedCount) {
        context.dispatch('getUserScanList')
        context.commit('SCAN_DELETE_POPUP', false)
      }
    },
    runAndUpdateScanSettings(context, id) {
      const scanTypeMap = {
        darvasScan: 'darvasScan',
        fundementalScan: 'fundementals',
        activityScan: 'activity',
        candlestickScan: 'candlesticks',
        patternsScan: 'patterns',
        indicatorsScan: 'indicators',
        samsPattern: "samsPattern"
      }
      let currentScan = context.state.userSavedScans[id]
      let scanType = currentScan.scanType
      if (scanTypeMap[scanType]) {
        scanType = scanTypeMap[scanType]
      }
      context.commit('SET_SCAN_DATA_SETTINGS2', {
        id: scanType,
        settings: currentScan.data.dataSettings,
      })
      context.commit('SET_SCAN_FILTER_SETTINGS2', {
        id: scanType,
        settings: currentScan.data.filterSettings,
      })

      if (scanType === 'fundementals') {
        context.commit(
          'SET_FUNDEMENTAL_SCAN_SETTINGS',
          currentScan.data.settings
        )
        context.dispatch('sendFundementalScanRequest')
      } else if (scanType === 'activity') {
        context.commit('SET_ACTIVITY_SCAN_SETTINGS', currentScan.data.settings)
        context.dispatch('sendActivityScanRequest')
      } else if (scanType === 'candlesticks') {
        context.commit(
          'SET_CANDLESTICK_SCAN_SETTINGS',
          currentScan.data.settings
        )
        context.dispatch('sendCandlestickScanRequest')
      } else if (scanType === 'darvasScan') {
        context.commit('SET_DARVAS_SCAN_SETTINGS', currentScan.data.settings)
        context.dispatch('sendDarvasScanRequest')
      } else if (scanType === 'indicators') {
        context.commit(
          'SET_INDICATORS_SCAN_SETTINGS',
          currentScan.data.settings
        )
        context.commit('SET_EVENTS_SETTINGS', {
          id: scanType,
          settings: currentScan.data.events,
        })
        context.dispatch('sendIndicatorsScanRequest')
      } else if (scanType === 'patterns') {
        context.commit('SET_PATTERNS_SCAN_SETTINGS', currentScan.data.settings)
        context.commit('SET_EVENTS_SETTINGS', {
          id: scanType,
          settings: currentScan.data.events,
        })
        context.dispatch('sendPatternsScanRequest')
      } else if (scanType === 'samsPattern') {
        context.commit('SET_GURUS_SAMS_PATTERN_SETTINGS', currentScan.data.settings)
        context.dispatch('sendSamsPatternRequest')
      }
    },
    enterThemehoverColor(context, obj) {
      obj.style.backgroundColor =
        context.getters.themeSecondaryColor.backgroundColor
      obj.style.color = 'white'
    },
    exitElementColor(context, obj) {
      obj.style.backgroundColor =
        context.getters.themePrimaryColor.backgroundColor
      obj.style.color = context.getters.themeFontColor.color
    },
    updateTrainingModeState(context, payload) {
      // const { state: trainingModeActivated } = payload
      const trainingModeActivated = payload.state

      // when the training mode is deactivated, reset the chart
      if (!trainingModeActivated) {
        context.state.dc.set('chart.data', context.getters.getSymbolData)
        chartEvents.$emit(
          'setChartRange',
          context.state.chartVisibleRange,
          'TrainingModeRangeReset'
        )
      } else {
        // context.getters.dc.tv.resetChart(true)
        const trainingModeDataRaw = payload.trainingModeDataRaw
        const trainingModeData = payload.trainingModeData
        context.commit('UPDATE_TRAINING_MODE_DATA', {
          data: trainingModeData,
          raw: trainingModeDataRaw,
        })
      }
      context.commit('SET_TRAINING_MODE', payload)
    },
    async updateIntraDayModeState(context, isIntradayMode) {
      context.commit('SET_INTRADAY_MODE', isIntradayMode)

      window.dc = this.getters.dc
      const {
        currentZoneTime,
        marketStartTime,
        marketEndTime,
        isMarketOpened,
      } = getMarketTime(context.state.marketId)

      let dataForCurrentSymbol = context.state.currentSymbol.data
      let timeFrame = context.state.currentSymbol.timeFrame
      let lastCandleApi = context.state.currentSymbol?.lastCandle
      let latestCandle = context.state.currentSymbol?.latestCandle
      const lastDataIndex = dataForCurrentSymbol.length - 1
      const lastCandle = lastCandleApi
        ? lastCandleApi
        : dataForCurrentSymbol[lastDataIndex]
      const lastCandleTime = moment(lastCandle.date).valueOf()

      if (isMarketOpened) {
        context
          .dispatch('setSymbolData', {
            symbolName: context.state.currentSymbol.symbol,
            timeFrame: context.state.timeFrame,
            reForce: true,
          })
          .then((d) => {
            chartEvents.$emit('setChartRange', undefined, 'MarketIntraDayChange')
            chartEvents.$emit('symbolChangeVerifyScanDetails')
            chartEvents.$emit('loadDrawingData')
          })
        // getD the
        /*if (!isIntradayMode) {
          // remove last candle

          if (moment(lastCandle.date).isSame(currentZoneTime, 'day')) {
            context.commit(
              'UPDATE_DATA_SET',
              context.state.currentSymbol.data.slice(0, lastDataIndex)
            )
            this.getters.dc.set(
              'chart.data',
              parseToChartCandleData(context.state.currentSymbol.data)
            )
          }
        } else {
          // re-add last candle
          let data = await symbol.getLatestCandle(
            context.state.currentSymbol.symbol,
            context.state.currentSymbol.timeFrame,
            context.state.marketId
          )

          if (data) {
            let dataset = context.state.currentSymbol.data
            if (dataset && dataset.length) {
              let candleDate = dataset[dataset.length - 1].date
              let dateNow = data.date
              if (!moment(candleDate).isSame(moment(dateNow))) {
                dataset.push(data)
                context.commit('UPDATE_DATA_SET', dataset)
              }
            }
          }

          this.getters.dc.set(
            'chart.data',
            parseToChartCandleData(dataForCurrentSymbol)
          )
        }*/
      }
    },
    async retrieveSymbolsList(context) {

      const symbolsList = await symbol.getListOfSymbols()

      context.commit('UPDATE_SECTOR_MAP', symbolsList.sectorGroup)
      context.commit('UPDATE_INDUSTRY_MAP', symbolsList.industryGroup)
      context.commit('UPDATE_SUB_INDUSTRY_MAP', symbolsList.subIndustryGroup)
      context.commit('UPDATE_SYMBOLS_LIST', symbolsList.symbolList)
    },
    async setTimeFrame(context, newTimeFrame) {
      context.state.dc.set('chart.tf', '1' + newTimeFrame)
    },
    async setSymbolData(context, payload) {
      console.log('first', payload)
      //--- check if we are already on the same symbol
      let isComparision = payload?.isComparison || context.state.type === "CompareChartMain"
      if (!payload.reForce) {
        if (
          payload.symbolName.length > 0 &&
          payload.symbolName === context.state.currentSymbol.symbol &&
          payload.timeFrame === context.state.currentSymbol.timeFrame
        ) {
          // console.log('failing to fetch')
          return
        }
      }

      //TODO:CHANGE_EFFECTS
      // remove chart drawings
      // context.getters.dc.del('Tool')
      // context.getters.dc.del('Fibonacci')
      // context.getters.dc.del('Trend')
      // context.getters.dc.tv.custom_event({
      //   event: 'remove-all-tools-event',
      //   args: [],
      // })
      //--- set loading status
      // console.log("OVERLAYS_CHARTS payload",payload)
      context.dispatch('setNewChartTimeFrame', { timeFrame: payload?.timeFrame, symbol: payload?.symbolName?.length ? payload.symbolName : "" })
      if (!payload.scanResultData) {
        chartEvents.$emit('remove_scan_overlay_new', null, true)
      }
      chartEvents.$emit('deleteAllTools')
      await context.dispatch('setChartLoadingStatus', true)

      //--- delete the existing objects
      if (payload.symbolName !== context.state.currentSymbol.symbol) {
        // await context.dispatch('removeAllObjects')
      }
      const searchElement = 'BHP.au~ASX'
      let containBHP = context.state.symbolsList?.includes(searchElement)
      let symbolName = payload.symbolName || getLastSymbol()
      // console.log('symbolName',symbolName)
      if (!symbolName) {
        if (containBHP) {
          symbolName = searchElement
        } else {
          symbolName = context.state.symbolsList?.[0]
        }
      }

      let marketId = 'ASX'
      // console.log('symbolName ==',symbolName)
      let symbolArray = symbolName.split('~')
      if (symbolArray && symbolArray.length) {
        symbolName = symbolArray[0]
        marketId = symbolArray[1]
      }

      // let comparisionChart =
      //   context.getters.dc.data.chart.type === 'CompareChartMain'
      let symbolData = {}
      let multipleSymbol = {}
      let rangeMap
      // console.log("comparisionChart",isComparision)
      if (isComparision) {
        const timeFrame =
          payload.timeFrame || context.state.currentSymbol.timeFrame
        const symbolList = Object.values(
          context.getters.getSelectedMultipleSymbols
        ).filter((str) => str)
        // const symbolName = context.rootGetters.
        let config = TimeRangesConfig[timeFrame]
        rangeMap = config.map((d) => d.range)
        multipleSymbol = await symbol.getMultipleSymbolData(
          symbolList,
          timeFrame,
          symbolName,
          rangeMap
        )
        symbolData = multipleSymbol.main
      } else {
        console.log('symbolName', symbolName, 'payload', payload.timeFrame)
        let marketOpened = getMarketTime().isMarketOpened
        let intradayMode = context.state.intradayMode
        const isIntraday = intradayMode && marketOpened
        symbolData = await symbol.getData(
          symbolName,
          payload.timeFrame,
          isIntraday,
          marketId,
          context.state.minusIndex
        )
      }
      //  console.log(symbolData)

      if ('Error' === symbolData.symbol) {
        //--- set loading status
        context.dispatch('setChartLoadingStatus', false)
        context.dispatch('toggleSymbolMissing')
        const ohlcvData = parseToChartCandleData([currentSymbolDefaults])[0]
        //   console.log(ohlcvData)
        context.state.dc.set('chart.data', ohlcvData)

        context.commit('SET_CURRENT_SYMBOL', {
          ...currentSymbolDefaults,
          symbol: symbolName,
        })
        return false
        // console.log(setChartLoadingStatus)
      } else {
        let symIdentifier =
          symbolData.symbol + '~' + symbolData.metaData.exchange

        let res = getSymbolData(symbolData)
        context.commit('SET_CURRENT_SYMBOL_DATA', res)
        context.commit('SET_CURRENT_SYMBOL', symbolData)
        context.commit('SET_MARKET_ID', symbolData.metaData.exchange)
        setLastSymbol(symIdentifier)
        let marketOpened = getMarketTime().isMarketOpened
        let intradayMode = context.state.intradayMode
        const isIntraday = intradayMode && marketOpened
        // console.log('isIntradayFilterOff', {
        //   intradayMode,
        //   isIntraday,
        //   marketOpened,
        // })
        const len = symbolData.data.length - 1
        if (isIntraday && symbolData?.latestCandle && symbolData?.lastCandle) {
          // symbolData.data.pop()
          let latestDateApi = symbolData?.latestCandle?.date
          let lastCandleDate = symbolData?.lastCandle.date
          if (latestDateApi && lastCandleDate) {
            if (!moment(latestDateApi).isSame(moment(lastCandleDate))) {
              //symbolData.data.push(symbolData.latestCandle)
            }
          }
        }
        let [parsedData, ohlcvMap] = parseToChartCandleData(symbolData.data)
        context.commit('SET_OHLCV', parsedData)
        context.commit('SET_CANDLE_DATA_TIME_MAP', ohlcvMap)

        symbolData.health = Math.random() * 100
        context.commit('SET_CURRENT_SYMBOL', symbolData)
        const selectedSymbolIndex = context.state.symbolsList.findIndex(
          (sym) => sym === symIdentifier
        )

        if (selectedSymbolIndex !== -1) {
          context.commit('UPDATE_SYMBOL_INDEX', selectedSymbolIndex)
        }

        if (payload.timeFrame !== context.state.timeFrame) {
          context.commit('SET_SYMBOL_TIME_FRAME', payload.timeFrame || 'D')
        }

        //--- reset the chart
        // context.state.tradingChartReference.resetChart(true)

        //--- if the chart is in compare symbol mode

        if (isComparision) {
          // await context.dispatch('retrieveMultipleSymbolData')
          //--- set loading status
          context.commit('setMultiSymbolData', multipleSymbol)
          context.dispatch('updateMultipleSymbolData2', {
            data: multipleSymbol,
            rangeMap,
          })

          context.dispatch('setChartLoadingStatus', false)

          //--- setting the chart range
          // console.log('context.state.chartVisibleRange',context.state.chartVisibleRange)
          //TODO:CHANGE_EFFECTS
          // context.dispatch('setChartRange', context.state.chartVisibleRange)
        } else {
          //--- set loading status
          context.dispatch('setChartLoadingStatus', false)
        }
        // if(!context.getters?.tv?.$refs?.chart?.auto_y_axis){
        //   context?.getters?.tv?.toggleSideBarYAxis();
        // }
        // await getDrawingData(symbolData.symbol)
        return true

      }
    },

    async setNewChartTimeFrame(context, payload) {
      try {
        let userData = {
          ...context.state.userData,
        }
        userData['timeFrame'] = payload.timeFrame
        if (payload?.symbol)
          userData['symbol'] = payload?.symbol
        // console.log('updateUserSettings',userData)
        context.commit('SET_TIME_FRAME', payload.timeFrame)
        const result = await updateUserSettings({
          userData: userData,
        }, "setNewChartTimeFrame")
        return result.updated
      } catch (error) {
        return null
      }
    },

    async setNewTimeFrameRange(context, payload) {
      console.log("time---->")
      try {
        let userData = {
          ...context.state.userData,
        }
        userData[payload.key] = payload.value
        const result = await updateUserSettings({
          userData: userData,
        }, "setNewTimeFrameRange")
        context.state.userData[payload.key] = payload.value
        return result.updated
      } catch (error) {
        return null
      }
    },


    async setNewTimeFrameRanges(context, payload) {
      console.log("time---->", payload)
      try {
        let userData = {
          ...context.state.userData,
        }
        userData.timeFrameRange = payload
        const result = await updateUserSettings({
          userData: userData,
        }, "setNewTimeFrameRanges")
        context.state.userData[payload] = payload
        return result.updated
      } catch (error) {
        return null
      }
    },

    async setNewChartType(context, payload) {
      try {
        let userData = {
          ...context.state.userData,
        }
        console.log("setNewChartType", payload)
        userData['chartType'] = payload?.type
        userData['symbolMap'] = payload?.symbolMap
        // console.log('updateUserSettings',userData)
        const result = await updateUserSettings({
          userData: userData,
        }, "setNewChartType")
        context.state.userData = userData
        context.commit('SET_CHART_TYPE', payload?.type)
        return result.updated
      } catch (error) {
        return null
      }

      if (payload?.type === 'PnF' || payload?.type === 'Kagi') return

      /*
      TODO:CHANGE_EFFECTS
      setTimeout(() => {
        context.getters.tv.goto(
          context.getters.tv.data.data.chart.data.length + 20
        )
      }, 0)*/
    },
    async saveTradePlan(context) {
      context.commit('SAVE_TRADE_PLAN')
      context.commit('RESET_TRADE_PLANNER_STATE')
    },
    async toggleVolumeChart(context) {
      context.commit('TOGGLE_VOLUME_CHART')
    },
    async deletePlan(context, payload) {
      context.commit('DELETE_PLAN', payload)
    },
    /**
     * Change chart range
     * @param {Object} context store context
     * @param {String} range current selected range
     */
    async setChartRange(context, range) {
      console.log("range==>", range)
      //   console.log('[TEST 1] setChartRange called from store')

      //     console.log(
      //       'chartPredineRangeUpdates',
      //      context.state.chartPredineRangeUpdates
      //   )
      let rangeObj = { range: range, start: null, end: null }
      let isRangeChange = rangeObj.range !== context.state.chartVisibleRange

      if (isRangeChange) {
        // console.log('[TEST 1] isRangeChange called from store')

        let eventStr = JSON.stringify(rangeObj)
        localStorage.setItem('TimeRange', eventStr)
      }

      let hasStoreRange = localStorage.getItem('TimeRange')

      if (hasStoreRange) {
        // console.log('[TEST 1] hasStoreRange called from store')
        rangeObj = JSON.parse(hasStoreRange)
      }
      const candleData = context.getters.tv.data.data.chart.data //context.state.currentSymbol.data
      // const candleData = context.getters.dc.get('chart.data') //context.state.currentSymbol.data
      // console.log("setChartRange",rangeObj.range,context.state.chartVisibleRange,isRangeChange,rangeObj)
      if (candleData.length) {
        let calculatedStartingTimeForYears
        context.commit('CHART_VISIBLE_RANGE', rangeObj.range)
        if (
          (isRangeChange && context.state.chartPredineRangeUpdates) ||
          (rangeObj && rangeObj.range && rangeObj.range.length)
        ) {
          if (context.state.chartPredineRangeUpdates)
            context.commit('CHART_PREDEFINE_RANGE_UPDATES')

          const endingTime = candleData[candleData.length - 1][0]
          let startingTime = candleData[0][0]

          if (rangeObj.range === '3M') {
            // let calculateThreeM = moment(endingTime).subtract(10, 'days');
            let calculateThreeM = moment(endingTime).subtract(3, 'months')
            startingTime = calculateThreeM.valueOf()
            // console.log("calculateThreeM",calculateThreeM.toISOString())
          } else if (rangeObj.range === '6M') {
            let calculateSixM = moment(endingTime).subtract(6, 'months')
            startingTime = calculateSixM.valueOf()
            // console.log("calculateSixM",calculateSixM.toISOString())
            // console.log("hello",startingTime)
          } else if (rangeObj.range === 'YTD') {
            // console.log('ytd')
            startingTime = moment().startOf('year').valueOf()
            // console.log("hello",startingTime)
          } else if (rangeObj.range.includes('Y')) {
            const numOfYears = parseInt(
              rangeObj.range.substring(0, rangeObj.range.length - 1)
            )
            calculatedStartingTimeForYears = moment(endingTime)
              .subtract(numOfYears, 'year')
              .valueOf()

            // console.log(
            //   'valid date:',
            //   moment(calculatedStartingTimeForYears).format('LLLL')
            // )
            let checkValidDate = moment(calculatedStartingTimeForYears)
            if (checkValidDate.isValid()) {
              startingTime = calculatedStartingTimeForYears
            }
          }
          const { iterations, type } = GetIterationData(context.state.timeFrame)
          let startingTimeObj = moment(startingTime)
          let firstCandleTimeObj = moment(candleData[0][0])
          let lastCandleTimeObj = moment(candleData[0][candleData.length - 1])
          let diffInDaysFromFirstCandle = startingTimeObj.diff(
            firstCandleTimeObj,
            type
          )
          let startIndex = context.state.tradingChartReference.getTimeIndex(
            candleData[0][0]
          )

          if (startIndex < 0) {
            startIndex = startingTime
          } else {
            startIndex = Math.floor(startIndex)
          }
          let endIndex = context.state.tradingChartReference.getTimeIndex(
            candleData[0][candleData.length - 1]
          )
          // console.log('startIndex -- ', {candleData:candleData.length,startIndex,diffInDaysFromFirstCandle})
          // console.log('startIndex',startIndex)
          // console.log('endIndex',endIndex)
          /*console.log(
              JSON.stringify({
                startingTimeObj: startingTimeObj.toISOString(),
                firstCandleTimeObj: firstCandleTimeObj.toISOString(),
                lastCandleTimeObj: lastCandleTimeObj.toISOString(),
                diffInDaysFromFirstCandle,
                calculatedStartingTimeForYears: moment(
                    calculatedStartingTimeForYears
                ).toISOString(),
                startingTime,
                startingTime2:startingTimeObj.valueOf(),
                startingTime2Diff:startingTimeObj.valueOf() - startingTime,

                decisions:diffInDaysFromFirstCandle < 0
              },null,'\t')
          )*/
          // console.log('[TEST 1] before dispatch called from store')
          context.dispatch('updateChartStartingTime', startingTime)
          context.dispatch('updateChartEndingTime', endingTime)

          // console.log('getTimeIndex',{iterations,startIndex,endIndex})
          context.state.tradingChartReference.setRange(
            diffInDaysFromFirstCandle < 0 ? 0 : startingTime,
            candleData.length + iterations
          )
        } else {
          //   console.log('local storage ranges', JSON.stringify(rangeObj))
          // console.log(
          //   '[TEST 1] isRangeChange && context.state.chartPredineRangeUpdates else called from store'
          // )

          context.state.chartVisibleRange = rangeObj.range

          if (rangeObj.range) {
            // console.log('[TEST 1] rangeObj.range called from store')
          } else {
            // console.log('[TEST 1] rangeObj.range else called from store')

            let startingTime = rangeObj.start
            let firstCandleTimeObj = moment(candleData[0][0])
            let startingTimeObj = moment(rangeObj.start)
            let lastCandleTimeObj = moment(candleData[candleData.length - 1][0])
            let timestamp2Obj = moment(rangeObj.end)
            let diffInDaysFromFirstCandle = startingTimeObj.diff(
              firstCandleTimeObj,
              'day'
            )
            // console.log('candleData -- ',candleData.length)
            let endingTimeDiff = timestamp2Obj.diff(lastCandleTimeObj, 'day')
            let startingDaysInControls = diffInDaysFromFirstCandle < 0
            /*            console.log(
            JSON.stringify({
              // startingTimeObj: startingTimeObj.toISOString(),
              // firstCandleTimeObj: firstCandleTimeObj.toISOString(),
              lastCandleTimeObj: lastCandleTimeObj.toISOString(),
              timestamp2ObjISO: timestamp2Obj.toISOString(),
              // diffInDaysFromFirstCandle,
              // startingTime,
              // startingTime2:startingTimeObj.valueOf(),
              // startingTime2Diff:startingTimeObj.valueOf() - startingTime,
              endingTimeDiff,
              // decisions:startingDaysInControls,
              decisionsEndNode:endingTimeDiff > 0
            },null,'\t')
        )*/
            const { iterations } = GetIterationData(context.state.timeFrame)
            let index =
              context.state.tradingChartReference.getTimeIndex(startingTime)
            // console.log('getTimeIndex',index,iterations)
            context.state.tradingChartReference.setRange(
              startingDaysInControls ? 0 : startingTime,
              endingTimeDiff > 0 ? candleData.length + iterations : rangeObj.end
            )
          }

          // context.state.tradingChartReference.setRange(
          //     rangeObj[0],
          //     candleData.length + 20
          // )
        }

        // context.state.chartVisibleRange
        /* let rangeFinal = context.state.tradingChartReference.getRange()
              if (isRangeChange) {
                rangeObj.start = rangeFinal[0]
                rangeObj.end = rangeFinal[1]
                rangeObj.range = range
                let rangeFinalStr = JSON.stringify(rangeObj)

                console.log('range change from store', rangeFinalStr)
                localStorage.setItem('TimeRange', rangeFinalStr)
              } */
      } else {
        alert('Symbol Data Not Available')
        await context.dispatch('incrementSymbolIndex', context)
      }
    },
    async incrementSymbolIndex(context) {
      const max = context.state.symbolsList.length - 1
      // console.log("context.state.symbolIndex --- ", context.state.symbolIndex)
      // console.log("current   ==  ", context.state.symbolsList[context.state.symbolIndex])
      // console.log("next  ==  ", context.state.symbolsList[context.state.symbolIndex + 1])
      let submitObj = {
        symbolName: context.state.symbolsList[context.state.symbolIndex + 1],
        timeFrame: context.state.timeFrame,
      }
      // console.log("submitObj  ==  ", JSON.stringify(submitObj))
      if (context.state.symbolIndex < max) {
        context.dispatch('setSymbolData', submitObj).then((d) => {
          chartEvents.$emit('setChartRange', undefined, 'RightSymbolSelect')
          chartEvents.$emit('symbolChangeVerifyScanDetails')
          chartEvents.$emit('loadDrawingData')
        })
      }
    },
    async decrementSymbolIndex(context) {
      console.log('first 12', context.state.timeFrame)
      if (context.state.symbolIndex > 0) {

        context
          .dispatch('setSymbolData', {
            symbolName:
              context.state.symbolsList[context.state.symbolIndex - 1],
            timeFrame: context.state.timeFrame,
          })
          .then((d) => {
            chartEvents.$emit('setChartRange', undefined, 'LeftSymbolSelect')
            chartEvents.$emit('symbolChangeVerifyScanDetails')
            chartEvents.$emit('loadDrawingData')
          })
      }
    },
    async toggleSymbolMissing(context) {
      context.state.symbolIsMissing = !context.state.symbolIsMissing
    },
    async setChartLoadingStatus(context, status) {
      // console.log('SET_CHART_LOADING_STATUS',status)
      context.commit('SET_CHART_LOADING_STATUS', status)
    },
    async addNewScanResult(context, newResult) {
      console.log("newResult.scanType  ==", newResult.settings)
      context.commit('ADD_NEW_SCAN_RESULT', newResult)
    },
    async setScanDataSettings(context, payload) {
      console.log("setScanDataSettings", payload)
      context.commit('SET_SCAN_DATA_SETTINGS2', payload)
    },
    async setScanDataSettingsOld(context, payload) {
      context.commit('SET_SCAN_DATA_SETTINGS', payload)
    },
    async setScanFilterSettingsOld(context, settings) {
      context.commit('SET_SCAN_FILTER_SETTINGS', settings)
    },
    async setScanFilterSettings(context, settings) {
      context.commit('SET_SCAN_FILTER_SETTINGS2', settings)
      return true
    },
    async setEventsFilterSettings(context, settings) {
      // console.log("SET EVENTS Settings",settings)
      context.commit('SET_EVENTS_SETTINGS', settings)
    },
    async setCapitulationSettings(context, settings) {
      context.commit('SET_GURUS_CAPITULATION_SETTINGS', settings)
    },
    async setSamsPatternSettings(context, settings) {
      console.log("SET SAMS Settings", settings)
      context.commit('SET_GURUS_SAMS_PATTERN_SETTINGS', settings)
    },
    async setBollingerSqueezeSettings(context, settings) {
      context.commit('SET_GURUS_BOLLINGER_SQUEEZE_PATTERN_SETTINGS', settings)
    },
    async setDivergenceScanSettings(context, settings) {
      context.commit('SET_GURUS_DIVERGENCE_SCAN_SETTINGS', settings)
    },
    async setBigCandleScanSettings(context, settings) {
      context.commit('SET_GURUS_BIG_CANDLE_SCAN_SETTINGS', settings)
    },
    async setPocketPivotScanSettings(context, period) {
      context.commit('SET_GURUS_POCKET_PIVOT_SCAN_SETTINGS', period)
    },
    async setHeikenAshiScanSettings(context, period) {
      context.commit('SET_GURUS_HEIKEN_ASHI_SCAN_SETTINGS', period)
    },
    async setDarvasScanSettings(context, settings) {
      context.commit('SET_DARVAS_SCAN_SETTINGS', settings)
    },
    async setFundementalsScanSettings(context, settings) {
      console.log("SET FUNDEMENTAL Settings", settings)
      context.commit('SET_FUNDEMENTAL_SCAN_SETTINGS', settings)
    },
    async setActivityScanSettings(context, settings) {
      context.commit('SET_ACTIVITY_SCAN_SETTINGS', settings)
    },
    async setCandlestickScanSettings(context, settings) {
      context.commit('SET_CANDLESTICK_SCAN_SETTINGS', settings)
    },
    async setPatternsScanSettings(context, settings) {
      context.commit('SET_PATTERNS_SCAN_SETTINGS', settings)
    },
    async setIndicatorsScanSettings(context, settings) {
      context.commit('SET_INDICATORS_SCAN_SETTINGS', settings)
    },

    // gurus requests
    resetGurusSettings(context) {
      context.commit('RESET_GURUS')
    },
    async sendCapitulationRequest(context) {
      try {
        const settings = {
          ...context.state.scans.gurus.capitulation,
          tci: context.state.indicatorDefaults.tci,
        }
        const result = await gurus.capitulation(
          settings,
          context.state.scans.gurus.capitulation.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendFranksPatternRequest(context) {
      try {
        chartEvents.$emit('removeDarvas')
        const result = await gurus.franksPattern(
          context.state.scans.gurus.franksPattern.dataSettings,
          context.state.scans.gurus.franksPattern.selectedMarket,
          context.state.userId
        )
        context.dispatch('addNewScanResult', {
          ...result.data.data,
          settings: {},
        })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendSamsPatternRequest(context) {
      try {
        chartEvents.$emit('removeDarvas')
        const settings = context.state.scans.scanner.samsPattern.settings
        const filterSettings =
          context.state.scans.scanner.samsPattern.filterSettings
        const result = await gurus.samsPattern(
          settings,
          filterSettings,
          context.state.scans.scanner.samsPattern.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendHigherHighHigherLowPatternRequest(context) {
      try {
        const settings = context.state.scans.gurus.hhhl.dataSettings
        const result = await gurus.higherHighHigherLow(
          settings,
          context.state.scans.gurus.hhhl.selectedMarket,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendBollingerSqueezePatternRequest(context) {
      try {
        const settings = context.state.scans.gurus.bollingerSqueeze
        const result = await gurus.bollingerSqueeze(
          settings,
          context.state.scans.gurus.bollingerSqueeze.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendDivergenceScanRequest(context) {
      try {
        const settings = context.state.scans.gurus.divergenceScan
        const result = await gurus.divergenceScan(
          settings,
          context.state.scans.gurus.divergenceScan.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendBigCandleScanRequest(context) {
      try {
        const settings = context.state.scans.gurus.bigCandle
        const result = await gurus.bigCandleScan(
          settings,
          context.state.scans.gurus.bigCandle.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendPocketPivotScanRequest(context) {
      try {
        const settings = context.state.scans.gurus.pocketPivot
        const result = await gurus.pocketPivotScan(
          settings,
          context.state.scans.gurus.pocketPivot.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendHeikenAshiScanRequest(context) {
      try {
        const settings = context.state.scans.gurus.heikenAshi
        const result = await gurus.heikenAshiScan(
          settings,
          context.state.scans.gurus.heikenAshi.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async updateGuruSettingForLatestScans(context, payload) {
      try {
        let userData = { ...context.state.userData }
        const savedGuruLatest = localStorage.getItem('GuruRequests')
        userData['GuruRequests'] = savedGuruLatest
        const result = await updateUserSettings({ userData: userData }, "saveLatestScansForGuru")
        context.state.userData['GuruRequests'] = savedGuruLatest
        return result.updated
      } catch (error) {
        return null
      }
    },
    cancelGuruScans() {
      gurus.cancelGuruScan()
    },

    // scanners
    async sendDarvasScanRequest(context) {
      try {
        chartEvents.$emit('removeDarvas')
        const settings = context.state.scans.scanner.darvasScan.settings
        const filterSettings =
          context.state.scans.scanner.darvasScan.filterSettings
        const result = await scanner.darvasScan(
          settings,
          filterSettings,
          context.state.scans.scanner.darvasScan.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async saveDarvasScanRequest(context, name) {
      try {
        const result = await scanner.saveDarvasScan(
          context.state.scans.scanner.darvasScan.settings,
          context.state.scans.scanner.darvasScan.filterSettings,
          context.state.scans.scanner.darvasScan.dataSettings,
          context.state.userId,
          name
        )
        // context.dispatch('addNewScanResult', result.data.data)
        return result.data
      } catch (error) {
        return null
      }
    },
    async saveSamsPatternScanRequest(context, name) {
      try {
        const result = await scanner.saveSamsPatternScan(
          context.state.scans.scanner.samsPattern.settings,
          context.state.scans.scanner.samsPattern.filterSettings,
          context.state.scans.scanner.samsPattern.dataSettings,
          context.state.userId,
          name
        )
        // context.dispatch('addNewScanResult', result.data.data)
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendFundementalScanRequest(context) {
      try {
        const settings = context.state.scans.scanner.fundementals.settings
        const filterSettings =
          context.state.scans.scanner.fundementals.filterSettings
        const result = await scanner.fundementalScan(
          settings,
          filterSettings,
          context.state.scans.scanner.fundementals.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },

    async saveFundementalScanRequest(context, name) {
      try {
        const result = await scanner.saveFundementalScan(
          context.state.scans.scanner.fundementals.settings,
          context.state.scans.scanner.fundementals.filterSettings,
          context.state.scans.scanner.fundementals.dataSettings,
          context.state.userId,
          name
        )
        // context.dispatch('addNewScanResult', result.data.data)
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendActivityScanRequest(context) {
      try {
        const settings = context.state.scans.scanner.activity.settings
        const filterSettings =
          context.state.scans.scanner.activity.filterSettings
        const result = await scanner.activityScan(
          settings,
          filterSettings,
          context.state.scans.scanner.activity.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async saveActivityScanRequest(context, name) {
      try {
        const result = await scanner.saveActivityScan(
          context.state.scans.scanner.activity.settings,
          context.state.scans.scanner.activity.filterSettings,
          context.state.scans.scanner.activity.dataSettings,
          context.state.userId,
          name
        )

        return result.data
      } catch (error) {
        return null
      }
    },
    async sendCandlestickScanRequest(context) {
      try {
        const settings = context.state.scans.scanner.candlesticks.settings
        const result = await scanner.candlestickScan(
          settings,
          context.state.scans.scanner.candlesticks.filterSettings,
          context.state.scans.scanner.candlesticks.dataSettings,
          context.state.userId
        )
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async saveCandlestickScanRequest(context, name) {
      try {
        const result = await scanner.saveCandlestickScan(
          context.state.scans.scanner.candlesticks.settings,
          context.state.scans.scanner.candlesticks.filterSettings,
          context.state.scans.scanner.candlesticks.dataSettings,
          context.state.userId,
          name
        )
        // context.dispatch('addNewScanResult', result.data.data)
        return result.data
      } catch (error) {
        return null
      }
    },

    async sendPatternsScanRequest(context) {
      console.log("sendProReversalScanRequest", context.state.userId,)
      try {
        // console.log("context.state.scans.scanner.patterns.eventsSettings",JSON.stringify(context.state.scans.scanner.patterns.eventsSettings))
        const settings = context.state.scans.scanner.patterns.settings
        let events = context.state.scans.scanner.patterns.eventsSettings
        if ("macd" in events) {
          let macd = context.getters.getSingleIndicator.macd;
          console.log("macd", macd)
          events = {
            ...events,
            macd: {
              ...events.macd,
              fastPeriod: macd.fast,
              slowPeriod: macd.slow,
              signalPeriod: macd.smooth
            }
          }
        }
        const result = await scanner.patternsScan(
          settings,
          context.state.scans.scanner.patterns.filterSettings,
          context.state.scans.scanner.patterns.dataSettings,
          events,
          context.state.userId
        )
        context.dispatch('addNewScanResult', {
          ...result.data.data,
          settings,
          events,
        })
        return result.data
      } catch (error) {
        console.log(error)
        return null
      }
    },
    async savePatternsScanRequest(context, name) {

      try {
        const result = await scanner.savePatternsScan(
          context.state.scans.scanner.patterns.settings,
          context.state.scans.scanner.patterns.filterSettings,
          context.state.scans.scanner.patterns.dataSettings,
          context.state.scans.scanner.patterns.eventsSettings,
          context.state.userId,
          name
        )
        return result.data
      } catch (error) {
        return null
      }
    },
    async sendIndicatorsScanRequest(context) {

      try {
        const settings = context.state.scans.scanner.indicators.settings
        let events = context.state.scans.scanner.indicators.eventsSettings
        if ("macd" in events) {
          let macd = context.getters.getSingleIndicator.macd;
          events = {
            ...events,
            macd: {
              ...events.macd,
              fastPeriod: macd.fast,
              slowPeriod: macd.slow,
              signalPeriod: macd.smooth
            }
          }
        }
        const result = await scanner.indicatorsScan(
          settings,
          context.state.scans.scanner.indicators.filterSettings,
          context.state.scans.scanner.indicators.dataSettings,
          events,
          context.state.userId
        )
        context.dispatch('addNewScanResult', {
          ...result.data.data,
          settings,
          events,
        })
        return result.data
      } catch (error) {
        return null
      }
    },
    async saveIndicatorsScanRequest(context, name) {
      try {
        const result = await scanner.saveIndicatorsScan(
          context.state.scans.scanner.indicators.settings,
          context.state.scans.scanner.indicators.filterSettings,
          context.state.scans.scanner.indicators.dataSettings,
          context.state.scans.scanner.indicators.eventsSettings,
          context.state.userId,
          name
        )
        // context.dispatch('addNewScanResult', result.data.data)
        return result.data
      } catch (error) {
        return null
      }
    },
    // add pro scaner 

    async sendProReversalScanRequest(context) {
      console.log("sendProReversalScanRequest", context.state.userId,)
      try {
        const settings = context.state.scans.scanner.proReversalSignal.dataSettings
        const result = await scanner.proReversalScan(
          context.state.scans.scanner.proReversalSignal.dataSettings,
          context.state.scans.scanner.proReversalSignal.filterSettings,
          context.state.userId,
        )
        console.log("result", result)
        context.dispatch('addNewScanResult', { ...result.data.data, settings })
        return result.data
      } catch (error) {
        return null
      }
    },
    async updateScansSettingForLatestScans(context, payload) {
      try {
        let userData = { ...context.state.userData }
        const savedScansLatest = localStorage.getItem('ScanRequests')
        userData['ScanRequests'] = savedScansLatest
        const result = await updateUserSettings({ userData: userData }, "saveLatestScansForScans")
        context.state.userData['ScanRequests'] = savedScansLatest
        return result.updated
      } catch (error) {
        return null
      }
    },
    cancelScans() {
      scanner.cancelScan()
    },

    // settings
    async setIndicatorDefaultSettings(context, newSettings) {
      context.commit('SET_INDICATOR_DEFAULTS', newSettings)
    },
    async saveUserSettings(context, userSettings) {
      // context.commit('SET_INDICATOR_DEFAULTS', newSettings)
      try {
        let userData = {
          ...context.state.userData,
        }
        userData['proTrader.themeSettings'] =
          context.state.proTrader.themeSettings

        const result = await updateUserSettings({
          userData: userData,
        }, "saveUserSettings")
        //
        // context.dispatch('addNewScanResult', result.data.data)
        return result.updated
      } catch (error) {
        return null
      }
    },
    async saveChartSettings(context, chartSettings) {
      // context.commit('SET_INDICATOR_DEFAULTS', newSettings)

      try {
        let toolType = chartSettings.tool_type
        let chartId = chartSettings.chartId
        let changeState = chartSettings.selected
        let chartSettingObj = context.state.chartOverlays
        let chartType = chartSettingObj[toolType]

        let settings = {
          isAdd: !changeState,
          isRemove: changeState,
          chartName: chartId,
        }

        if (chartType && chartType.data) {
          let hasChartSelected = false
          for (const key in chartType.data) {
            if (chartType.data[key].name === chartSettings.tool_name) {
              chartType.data[key].selected = !chartType.data[key].selected

              if (!changeState) {

                hasChartSelected = true

                // settings.isAdd = false
                // settings.isRemove = true
              }
            } else {
              if (chartType.data[key].selected) {
                hasChartSelected = true
              }
            }
          }

          chartType.selected = hasChartSelected
          // let filtered = chartType.data.filter(a => a.name === name)
          // let chartSettingsData = {}
          chartSettingObj[toolType] = chartType
          const result = await updateChartSettings(settings)
          // context.commit('UPDATE_CHART_SELECTION', {
          //   chartType,
          //   chartName: chartSettings.tool_name,
          // })
          return result.updated
        }

        return false
      } catch (error) {
        console.log(error)
        return null
      }
    },

    // scan results
    async removeAllObjects(context) {
      context.state.dc.del('Tool')
      context.state.dc.del('Fibonacci')
      context.state.dc.del('Darvas')
      context.state.dc.del('ChartPatterns')
    },
    async addDrawingForScannedSymbol(
      context,
      { symbolData, scanType, settings }
    ) {
      let scanOverlay
      let patternName = symbolData.patternName
      let symbolName = symbolData.symbol
      console.log("pattername==>", patternName)
      let name = OVERLAYS_CHARTS[patternName]
      let allChartOverlays = context.getters.allChartOverlays

      let isHeikenAshi = scanType === 'heikenAshi'
      let isBollingerSqueeze = scanType === 'bollingerSqueeze'
      let isDivergenceScan = scanType === 'divergenceScan'
      let isFranksPattern = scanType === 'franksPattern'
      let overlay_tv = Object.values(Overlays)
      let ChartPatternsOverlay = overlay_tv.find(
        (n) => n.name.toUpperCase() === 'ChartPatterns'.toUpperCase()
      )
      if (isHeikenAshi) {
        await context.dispatch('setNewChartType', { type: 'HeikenAshi', location: "From HeikenAshi" })
      } else if (isDivergenceScan) {
        if ('data' in symbolData) {
          const presetSettings =
            ChartPatternsOverlay.methods.meta_info().preset.settings
          const chartPattern = {
            name: `ChartPatterns-${symbolName}`,
            type: 'ChartPatterns',
            data: [
              {
                patternName: symbolData.patternName,
                drawData: symbolData?.data?.elements,
              },
            ],
            scanResults: true,
            settings: { ...presetSettings },
          }
          // context.state.dc.data.onchart.push(chartPattern)
          chartEvents.$emit(
            'add_overlay_object',
            [chartPattern],
            'ChartPatterns'
          )
        }
      } else if (isBollingerSqueeze) {
        setTimeout(() => {
          context.getters.dc.add(
            'onchart',
            {
              name: 'BollingerBands',
              type: 'BB',
              data: [],
              settings: {},
            },
            0
          )
        })
        context.state.dc.del('BollingerBands')
      }
      let isChartPattern = scanType === 'chartPatterns'
      let isHHL = scanType === 'hhhl'
      let isDarvasScans =
        scanType === 'samsPattern' || scanType === 'darvasScan'

      if (isChartPattern) {
        if ('drawingData' in symbolData && symbolData?.drawingData) {
          if (symbolData.drawingData && symbolData.drawingData.elements) {
            const presetSettings =
              ChartPatternsOverlay.methods.meta_info().preset.settings
            const chartPattern = {
              name: `ChartPatterns-${symbolName}`,
              type: 'ChartPatterns',
              data: [
                {
                  patternName: symbolData.patternName,
                  drawData: symbolData?.drawingData?.elements,
                },
              ],
              scanResults: true,
              settings: { ...presetSettings },
            }
            // context.state.dc.data.onchart.push(chartPattern)
            chartEvents.$emit(
              'add_overlay_object',
              [chartPattern],
              'ChartPatterns'
            )
          }
        } else if ('data' in symbolData && symbolData?.data) {
          if (symbolData.data && symbolData.data.elements) {
            const presetSettings =
              ChartPatternsOverlay.methods.meta_info().preset.settings
            const chartPattern = {
              name: `ChartPatterns-${symbolName}`,
              type: 'ChartPatterns',
              data: [
                {
                  patternName: symbolData.patternName,
                  drawData: symbolData?.data?.elements,
                },
              ],
              scanResults: true,
              settings: { ...presetSettings },
            }
            // context.state.dc.data.onchart.push(chartPattern)
            chartEvents.$emit(
              'add_overlay_object',
              [chartPattern],
              'ChartPatterns'
            )
          }
        }
      } else if (isHHL) {
        if ('data' in symbolData) {
          const presetSettings =
            ChartPatternsOverlay.methods.meta_info().preset.settings
          const chartPattern = {
            name: `ChartPatterns-${symbolName}`,
            type: 'ChartPatterns',
            data: [
              {
                patternName: symbolData.patternName,
                drawData: symbolData?.data?.elements,
              },
            ],
            scanResults: true,
            settings: { ...presetSettings },
          }
          // context.state.dc.data.onchart.push(chartPattern)
          chartEvents.$emit(
            'add_overlay_object',
            [chartPattern],
            'ChartPatterns'
          )
        }
      } else if (isDarvasScans || isFranksPattern) {
        // drawing darvas
        // chartEvents.$emit('removeDarvas')
        function getDarvasDirection(mainPoints, riskPoints) {
          if (!riskPoints || !mainPoints) return true
          return (
            Math.min(mainPoints[0].value, mainPoints[1].value) >
            Math.min(riskPoints[0].value, riskPoints[1].value)
          )
        }
        const darvasDrawingList = []
        for (const element of symbolData?.data?.elements) {
          const points = element.points
          let mainPoints = null,
            riskPoints = null
          if (element.metatype === 'Main') {
            mainPoints = points
            riskPoints = symbolData.data.elements.find(
              (el) =>
                el.metatype === 'Risk' &&
                el.points[0].timeStamp === mainPoints[0].timeStamp &&
                el.points[1].timeStamp === mainPoints[1].timeStamp
            )?.points
          } else {
            riskPoints = points
            mainPoints = symbolData.data.elements.find(
              (el) =>
                el.metatype === 'Main' &&
                el.points[0].timeStamp === riskPoints[0].timeStamp &&
                el.points[1].timeStamp === riskPoints[1].timeStamp
            )?.points
          }
          const isBullish = getDarvasDirection(mainPoints, riskPoints)
          const darvasDrawing = {
            name: `Darvas-${symbolName}-${element.metatype}-${points[0].value}`,
            type: 'DarvasBox',
            data: [
              [
                element.metatype,
                points[0].timeStamp,
                points[1].timeStamp,
                points[0].value,
                points[1].value,
                element.metatype === 'Main'
                  ? 'rgba(39, 128, 227, 0.3)'
                  : 'rgba(245, 188, 195, 0.3)',
                isBullish,
              ],
            ],
            scanResults: true,
            settings: {
              legend: false,
            },
          }
          // context.state.dc.data.onchart.push(darvasDrawing)
          darvasDrawingList.push(darvasDrawing)
        }
        chartEvents.$emit('add_overlay_object', darvasDrawingList, 'DarvasBox')
      }

      if (isDivergenceScan) {
        name = OVERLAYS_CHARTS[settings.selectedScan]
      }

      chartEvents.$emit('remove_scan_overlay_new', name)
      let patternDataSet = ["CLOSE", "MA", "XMA", "WMA"]

      let isCrossAboveSet = scanType === "indicatorsPatterns" && (settings?.crossesAndAboveBelow?.isActive)
        && patternDataSet.includes(patternName);
      if (isCrossAboveSet) {
        let _overlay1, _overlay2;
        let _leftName = OVERLAYS_CHARTS[settings?.crossesAndAboveBelow?.left?.toUpperCase()]
        let _rightName = OVERLAYS_CHARTS[settings?.crossesAndAboveBelow?.right?.toUpperCase()]


        if (_leftName === _rightName) {
          chartEvents.$emit('remove_scan_overlay_new', _leftName.toUpperCase(), true)
          setTimeout(() => {
            let config = allChartOverlays.find((n) => n.name === _leftName)
            const userSettings = config.getOverlayData(settings, scanType)
            _overlay1 = overlay_tv.find(
              (n) => n.name.toUpperCase() === _leftName.toUpperCase()
            )
            chartEvents.$emit(
              'add_overlay_new',
              _overlay1,
              _leftName,
              [{ ...userSettings, period: settings?.crossesAndAboveBelow?.leftPeriod }, { ...userSettings, period: settings?.crossesAndAboveBelow?.rightPeriod }],
              true
            )
          })
        }
        else {
          chartEvents.$emit('remove_scan_overlay_new', "", true)
          setTimeout(() => {
            if (_leftName) {
              let config = allChartOverlays.find((n) => n.name === _leftName)
              const userSettings = config.getOverlayData(settings, scanType)
              _overlay1 = overlay_tv.find(
                (n) => n.name.toUpperCase() === _leftName.toUpperCase()
              )
              chartEvents.$emit(
                'add_overlay_new',
                _overlay1,
                _leftName,
                { ...userSettings, period: settings?.crossesAndAboveBelow?.leftPeriod },
                true
              )
            }

            if (_rightName) {
              let config = allChartOverlays.find((n) => n.name === _rightName)
              const userSettings = config.getOverlayData(settings, scanType)
              _overlay2 = overlay_tv.find(
                (n) => n.name.toUpperCase() === _rightName.toUpperCase()
              )
              chartEvents.$emit(
                'add_overlay_new',
                _overlay2,
                _rightName,
                { ...userSettings, period: settings?.crossesAndAboveBelow?.rightPeriod },
                true
              )
            }
          })
        }
      } else {
        if (name) {
          let config = allChartOverlays.find((n) => n.name === name)
          let overlay = overlay_tv.find(
            (n) => n.name.toUpperCase() === name.toUpperCase()
          )

          if (config.getOverlayData) {


            // else{
            const userSettings = config.getOverlayData(settings, scanType)
            chartEvents.$emit(
              'add_overlay_new',
              overlay,
              name,
              { ...userSettings },
              true
            )
            // }

          }
        }
      }

      // await context.dispatch('setChartRange', context.state.chartVisibleRange)
      if (scanOverlay) {
        return scanOverlay
        // context.commit('SCAN_OVERLAYS',scanOverlay)
        // context.commit('SHOW_OVERLAYS',true)
      } else {
        return null
      }
    },
    async changeSymbolToScannedSymbol(
      context,
      { symbolData, timeFrame, scanType, settings }
    ) {
      context.state.SelectedScanSymbol = symbolData.symbol
      // Check ScanType

      const symbolName = symbolData.symbol ? symbolData.symbol : symbolData
      const check = timeFrame ? timeFrame : 'D'
      console.log('tatatatat', check)
      await context.dispatch('setSymbolData', {
        symbolName,
        check,
        scanResultData: true,
      })
      chartEvents.$emit('setChartRange', undefined, 'ScannedSymbolSelect')
      let val = await context.dispatch('addDrawingForScannedSymbol', {
        symbolData,
        scanType,
        settings,
      })
      chartEvents.$emit('loadDrawingData')
      // if we are not drawing anything
      if (val) {
        return val
      }
      return false
    },
    async updateDrawing(context, payload) {

      if (context.state.drawingData.hasOwnProperty(payload.ref)) {
        context.state.drawingData[payload.ref] = payload.data
      } else {
        context.state.drawingData[payload.ref] = payload.data
      }
      let newMapData = {}
      for (let key in context.state.drawingData) {
        let data = JSON.parse(context.state.drawingData[key])

        if (data?.settings?.$uuid === key) {
          newMapData[key] = context.state.drawingData[key]
        }

      }
      let data = {
        symbolName: context.state.currentSymbol.symbol,
        data: {
          modeId: payload.ref,
          data: payload.data,
          type: payload?.type,
          newTool: payload?.newTool,
          pointsData: payload?.pointsData
        }

      }

      // await updateDrawingData(data)
      console.log('heehaa', context.state.userId, 'h00', localStorage.getItem('user-id'))

      await updateDrawingDataV2({ ...data, userId: localStorage.getItem('user-id') })
      context.state.drawingData = newMapData
      return true
    },
    async deleteDrawing(context, payload) {

      const parts = payload?.t?.split('.');
      let toolType = parts?.[1]?.split('-')
      let chartType = parts?.[0]
      const drawingList = [
        "FibonacciRetrace",
        "FibonacciTime",
        "HLineTool",
        "TrendLine",
        "ArcTool",
        "ChannelTool",
        "TradeVisualizerTool",
        "RectangleTool",
        "TrailingStopTool",
        "TextTool",
        "RangeTool",
        "EmojiTool",
      ]

      toolType = drawingList.find(d => {
        const startsWith = toolType?.[0]?.startsWith(d);
        // console.log("startsWith decision",toolType?.[0],d,startsWith)
        return startsWith
      })
      // console.log("deleteDrawing",payload,context.getters.tv,toolType)
      let elementDeleted = false
      let localDrawData = copyObject(context.state.drawingData)
      // let grid_id=JSON.parse(copyObject(context.state.drawingData)[payload])?.grid?.id;
      // if (localDrawData.hasOwnProperty(payload)) {
      if (Object.keys(localDrawData).includes(payload.t)) {
        delete localDrawData[payload.t]
        elementDeleted = true
      }
      let newMapData = {}
      let deletedTypes = []
      let chartTypeList = context.getters.tv.data.get(`${chartType}.${toolType}`)
      for (let chartTypeListElement of chartTypeList) {
        // console.log("chartTypeListElement",chartTypeListElement)
        context.getters.tv.data.del(chartTypeListElement.settings.$uuid)
      }
      console.log("deleteDrawing payload", payload)
      for (let key in localDrawData) {
        let data = JSON.parse(localDrawData[key])
        // console.log("Data",data)
        if (toolType === data.type) {
          deletedTypes.push(data)
        } else {
          if (data?.settings?.$uuid === key) {
            // console.log("Data keys==>",key)
            newMapData[key] = localDrawData[key]
          }
        }
      }
      let deletedIterator = 0
      if (deletedTypes.length) {
        for (let deletedType of deletedTypes) {
          let _chartType = deletedType.id.split('.')[0]
          let timeStamp = Utils.now()
          // let _grid_id=deletedType?.grid?.id;
          if (_chartType === chartType) {//&& _grid_id===grid_id ){
            deletedType.name = `${deletedType.type} ${deletedIterator}`
            deletedType.id = `${_chartType}.${deletedType.type}${deletedIterator}`
            deletedType.settings.$uuid = `${_chartType}.${deletedType.type}${deletedIterator}-${timeStamp}`
            deletedIterator++;
            // context.getters.tv.data.add(chartType,toolType)
            newMapData[deletedType.settings.$uuid] = JSON.stringify(deletedType)
          } else {
            newMapData[deletedType.settings.$uuid] = JSON.stringify(deletedType)
          }
        }
      }
      if (elementDeleted) {
        let data = {
          symbolName: context.state.currentSymbol.symbol,
          data: localDrawData,
        }
        // setTimeout(() => {
        //
        // })
        updateDrawingDataV1({
          data: newMapData,
          symbolName: context.state.currentSymbol.symbol,
          userId: context.state.userId
        }).then(() => {
          context.state.drawingData = newMapData
        })
        // await deleteSingleDrawingDataV1(
        //     context.state.currentSymbol.symbol,
        //     context.state.userId,payload)

        return true
      } else {
        return false
      }
    },
    async deleteAllDrawing(context, payload) {
      console.log("deleteAllDrawing==", context, payload)
      let elementDeleted = false
      // let localDrawData = context.state.drawingData
      elementDeleted = await deleteAllDrawingDataV1(
        context.state.currentSymbol.symbol,
        context.state.userId
      )
      // console.log(elementDeleted)
      context.state.drawingData = {}
      return elementDeleted
    },
    async toggleWatchesModal(context) {
      context.state.showWatchesModal = !context.state.showWatchesModal
    },
    async toggleWatchesRenameModal(context) {
      context.state.showWatchRenameModal = !context.state.showWatchRenameModal
    },
    async toggleWatchesDeleteModal(context) {
      context.state.showWatchesDeleteModal =
        !context.state.showWatchesDeleteModal
    },
    async toggleWatchesImportModal(context) {
      context.state.showWatchesImportModal =
        !context.state.showWatchesImportModal
    },

    async renameWatchListItem(context, payload) {
      // console.log(
      //   'selectedWatchData ==',
      //   context.getters.selectedWatchData,
      //   payload
      // )
      let newWatch = context.getters.selectedWatchData
      if (newWatch) {
        let arrayData = context.state.watchLists
        newWatch.newName = payload
        let newWatchRes = await saveWatches(newWatch)
        // console.log(newWatchRes)
        if (newWatchRes) {
          // arrayData[context.state.selectedWatchCode] = newWatchRes
          // for (let k in arrayData) {
          //   // console.log(
          //   //   'arrayData[k] ==',
          //   //   typeof context.state.selectedWatchCode,
          //   //   typeof k,
          //   //   JSON.stringify(arrayData[k])
          //   // )
          //   if (Number(k) === context.state.selectedWatchCode) {
          //     // console.log('matching content')
          //     arrayData[k] = newWatchRes
          //   }
          // }
          context.state.watchLists = newWatchRes?.data
          const index = newWatchRes?.data.findIndex(item => item.id === newWatch.id);
          // console.log("rename index==>",index)
          context.dispatch("setSelectedWatch", index)
          // this.setSelectedWatch(index)
        }
      }
      context.state.showWatchRenameModal = !context.state.showWatchRenameModal
    },
    async createNewWatchList(context, payload, callback) {
      let array = []
      if (payload.ScanResults) {
        console.log('payload.ScanResults ', payload, callback)
        for (const iterator in payload.ScanResults) {
          if (
            payload.ScanResults[iterator].scanId == context.state.SelectedScanId
          ) {
            for (const i of payload.ScanResults[iterator].results) {
              array.push({ name: i.symbol, id: context.state.marketId })
            }
            break
          }
        }
      }
      let watches = {
        symbolList: array,
        name: payload.name,
        userId: context.state.userId,
      }
      //  console.log(watches)
      let newWatch = await saveWatches(watches)
      // let w =context.state.watchLists
      // w[payload] = []
      console.log('newWatch', newWatch)
      if (newWatch) {
        context.state.watchLists = newWatch?.data
        // context.state.selectedWatch = payload
      }
      if (payload.updateModal) {
        context.state.showWatchesModal = !context.state.showWatchesModal
      }
      return newWatch
    },
    async updateWatchList(context, payload) {
      let newWatch = await saveWatches(payload.value)
      console.log('newWatch updateWatchList', newWatch)
      if (newWatch) {
        // console.log('watch response updated')
        context.state.watchLists = newWatch?.data
        return true
      } else {
        return false
      }
    },
    async deleteWatchList(context, payload) {
      let deleteWatchListRes = await deleteWatchList(payload)
      // context.state.selectedWatch = ''
      // console.log(deleteWatchListRes.isError)
      context.dispatch('toggleWatchesDeleteModal')
      if (!deleteWatchListRes.isError) {
        let newWatchList = context.state.watchLists.filter(
          (s) => s.id !== payload
        )
        context.state.watchLists = newWatchList

        context.state.selectedWatch = "";
        context.state.selectedWatchCode = "";
        context.state.watchListsData = "";
      }
    },
    async setSelectedWatch(context, payload) {
      let watchListDataObj = context.state.watchLists[payload]
      console.log('watchListDataObj', watchListDataObj)
      if (watchListDataObj && watchListDataObj.symbolList) {
        // console.log('list updates')
        context.state.selectedWatch = watchListDataObj.id
        context.state.selectedWatchCode = payload
        context.state.watchListsData = watchListDataObj.symbolList
      }
    },
    async addNewSymbolToList(context, payload) {
      // context.state.selectedWatch = payload
      let currentList = context.getters.selectedWatchData
      console.log('addNewSymbolToList 1', currentList)
      let saveIt = false
      let alreadyInList = currentList.symbolList.find(
        (s) => s.id === payload.id && s.name === payload.name
      )
      if (!alreadyInList) {
        console.log('addNewSymbolToList 2', payload)
        currentList.symbolList.push(payload)
        let response = await saveWatches(currentList)
        console.log('addNewSymbolToList 3', response)
        let updatedList = response.find((d) => d.id === currentList.id)
        console.log('addNewSymbolToList 4', updatedList)
        let index = updatedList.symbolList?.findIndex(
          (d) => d.name === payload.name
        )
        context.state.watchLists = response?.data
        context.state.watchListsData = updatedList?.symbolList
        return index > -1 ? index : currentList.symbolList.length - 1
      }
      return -1
      // if(false)
      // if(context.state.watchListsData[context.state.selectedWatch]){
      //     currentList =  context.state.watchListsData[context.state.selectedWatch]
      //     if(currentList && currentList.length){
      //         if(!currentList.includes(payload)){
      //             currentList.push(payload)
      //         }
      //     }else{
      //         currentList.push(payload)
      //     }
      //     let watches = {symbolList:currentList,name:context.state.selectedWatch,userId:context.state.userId}
      //
      //     context.state.watchListsData[context.state.selectedWatch] = currentList
      // }
    },
    async removeCurrentSymbolFromList(context, payload) {
      // context.state.selectedWatch = payload
      let currentList = context.getters.selectedWatchData
      // let saveIt = false
      let alreadyInList = currentList.symbolList.find(
        (s) => s.id === payload.id && s.name === payload.name
      )
      if (alreadyInList) {
        const _index = currentList.symbolList.findIndex(
          (s) => s.id === payload.id && s.name === payload.name
        )
        currentList.symbolList.splice(payload.index, 1)
        await saveWatches(currentList)
        context.watchLists = currentList
        if (_index == currentList.symbolList.length) return currentList.symbolList.length - 1
        return payload.index //-1
      }
      return payload.index //-1
    },
    async setSelectedWatchCode(context, payload) {
      // console.log("setSelectedWatchCode",payload)
      if (
        payload.name &&
        payload.name.length > 0 &&
        payload.name !== context.state.currentSymbol.symbol
      ) {
        await context.dispatch('setSymbolData', { symbolName: payload.name, timeFrame: localStorage.getItem("SET_TIME_FRAME") || "D" })
        chartEvents.$emit('setChartRange', undefined, 'WatchSymbolSelect')
        chartEvents.$emit('symbolChangeVerifyScanDetails')
        chartEvents.$emit('loadDrawingData')
      }
      context.state.selectedWatchSymbolCode = payload.id
    },
    async getUserWatches(context, payload) {
      let watches = await getWatchListData(context.state.userId)
      // console.log("watches ",watches)
      if (watches && watches.data) {
        context.state.watchLists = watches.data
        // let watchListsData = context.state.watchListsData
        // for (const watch of watches.data) {
        //     console.log(watch)
        //     context.state.watchLists.push(watch.name)
        //     if(watch.symbolList && watch.symbolList.length){
        //         watchListsData[watch.name] =watch.symbolList
        //     }else{
        //         watchListsData[watch.name] = []
        //     }
        // }
        // context.state.watchListsData = watchListsData
      }
    },
    async getUserScanList(context) {
      let scannerList = await scanner.getScanList(context.state.userId)
      let scannerListForStore = []

      // console.log('scannerList ', scannerList)
      if (scannerList && scannerList.data) {
        for (const scan of scannerList.data) {
          let newObj = scan
          newObj.data = JSON.parse(scan.data)

          scannerListForStore.push(newObj)
        }
        context.commit('UPDATE_USER_SCAN_LIST', scannerListForStore)
        // context.state.userSavedScans = scannerListForStore
      }
    },
    async toggleDarkMode(context, id) {
      if (id !== 'dark') {
        context.commit('UPDATE_DEFAULT_THEME', 'dark')
        context.commit('THEME_MODE', true)
        localStorage.setItem('themeDarkModeSave', 'dark')
      } else {
        context.commit('UPDATE_DEFAULT_THEME', 'classic')
        context.commit('THEME_MODE', false)
        localStorage.setItem('themeDarkModeSave', 'classic')
      }
      await context.dispatch('saveUserSettings')
      return true
    },
    async updateProfileData(context, payload) {
      context.commit('SET_USER_DATA', payload)
      if ('GuruRequests' in payload) {
        localStorage.setItem('GuruRequests', JSON.stringify(JSON.parse(payload.GuruRequests)))
      } else {
        localStorage.setItem('GuruRequests', "{}")
      }

      if ('ScanRequests' in payload) {
        localStorage.setItem('ScanRequests', JSON.stringify(JSON.parse(payload.ScanRequests)))
      } else {
        localStorage.setItem('ScanRequests', "{}")
      }

      if ('chartType' in payload) {
        context.commit('SET_CHART_TYPE', payload['chartType'])
        if (payload['chartType'] === "CompareChartMain") {
          localStorage.setItem("isComparisonChart", "true")
        }
      }

      if ('symbol' in payload) {
        localStorage.setItem('symbol', payload.symbol)
      }
      if ('symbolMap' in payload) {
        context.commit('changeSymbol', { propName: "symbol1", propValue: payload.symbolMap?.symbol1 || "" })
        context.commit('changeSymbol', { propName: "symbol2", propValue: payload.symbolMap?.symbol2 || "" })
        context.commit('changeSymbol', { propName: "symbol3", propValue: payload.symbolMap?.symbol3 || "" })
        context.commit('changeSymbol', { propName: "symbol4", propValue: payload.symbolMap?.symbol4 || "" })
      }

      if ('timeFrame' in payload) {
        context.commit('SET_TIME_FRAME', payload['timeFrame'])
      }
      if ('proTrader.chart.0.indicators' in payload) {
        context.commit('SET_USER_CHART_LIST', {
          data: payload['proTrader.chart.0.indicators'],
        })
      }

      if ('proTrader.themeSettings' in payload) {
        let themeSettings = payload['proTrader.themeSettings']
        if (!isThemePrimary(themeSettings)) {
          context.commit('UPDATE_CUSTOM_THEME', themeSettings.settings)
          // context.commit('UPDATE_DEFAULT_THEME', '')
        } else {
          const id = payload['proTrader.themeSettings'].id
          // console.log("proTrader.themeSettings",id)
          context.commit('UPDATE_DEFAULT_THEME', id)
          if ('dark' === id) {
            context.commit('THEME_MODE', true)
          }
          // context.dispatch('toggleDarkMode',id)
        }
      }
    },
    async updateGuruMarketAccordingTODataSettings(context, payload) {
      let value = ''
      // console.log('updateMarketAccordingTODataSettings isWatch Active', payload.id,payload)
      context.commit('SET_SCAN_DATA_SETTINGS2', {
        id: payload.id,
        settings: payload.dataSettings,
        type: 'gurus',
      })
      if (payload.dataSettings.isWatch) {
        value = 'watch-list'
      } else if (payload.dataSettings.isScan) {
        value = 'scan-result'
      } else {
        value = 'market'
      }

      await context.dispatch('setSelectedMarketNew', {
        id: payload.id,
        value: value,
        type: 'gurus',
      })
    },
    async updateMarketAccordingTODataSettings(context, payload) {
      let value = ''
      // console.log('updateMarketAccordingTODataSettings isWatch Active', payload.id,payload)
      context.commit('SET_SCAN_FILTER_SETTINGS2', {
        id: payload.id,
        settings: payload.filterSettings,
      })
      context.commit('SET_EVENTS_SETTINGS', {
        id: payload.id,
        settings: payload.events,
      })
      context.commit('SET_SCAN_DATA_SETTINGS2', {
        id: payload.id,
        settings: payload.dataSettings,
        type: 'scanner',
      })
      if (payload.dataSettings.isWatch) {
        value = 'watch-list'
      } else if (payload.dataSettings.isScan) {
        value = 'scan-result'
      } else {
        value = 'market'
      }

      await context.dispatch('setSelectedMarketNew', {
        id: payload.id,
        value: value,
        type: 'scanner',
      })
    },
    async setSelectedMarketNew(context, payload) {
      if (payload.type === 'scanner') {
        context.state.scans.scanner[payload.id].selectedMarket = payload.value
        if (payload.value === 'watch-list') {
          context.state.scans.scanner[payload.id].dataSettings.isScan = false
          context.state.scans.scanner[payload.id].dataSettings.isWatch = true
        } else if (payload.value === 'scan-result') {
          context.state.scans.scanner[payload.id].dataSettings.isScan = true
          context.state.scans.scanner[payload.id].dataSettings.isWatch = false
        } else {
          context.state.scans.scanner[payload.id].dataSettings.isScan = false
          context.state.scans.scanner[payload.id].dataSettings.isWatch = false
        }
      } else if (payload.type === 'guru') {
        context.state.scans.gurus[payload.id].selectedMarket = payload.value
        if (payload.value === 'watch-list') {
          context.state.scans.gurus[payload.id].dataSettings.isScan = false
          context.state.scans.gurus[payload.id].dataSettings.isWatch = true
        } else if (payload.value === 'scan-result') {
          context.state.scans.gurus[payload.id].dataSettings.isScan = true
          context.state.scans.gurus[payload.id].dataSettings.isWatch = false
        } else {
          context.state.scans.gurus[payload.id].dataSettings.isScan = false
          context.state.scans.gurus[payload.id].dataSettings.isWatch = false
        }
      }
    },

    async setSelectedMarket(context, payload) {
      context.state.selectedMarket = payload
      if (payload === 'watch-list') {
        context.state.scans.dataSettings.isScan = false
        context.state.scans.dataSettings.isWatch = true
      } else if (payload === 'scan-result') {
        context.state.scans.dataSettings.isScan = true
        context.state.scans.dataSettings.isWatch = false
      } else {
        context.state.scans.dataSettings.isScan = false
        context.state.scans.dataSettings.isWatch = false
      }
    },
    async showBrushData(context) {
      if (
        context.state.brushChartReference &&
        context.state.tradingChartReference
      )
        context.state.brushChartReference.setBrushed(
          context.state.tradingChartReference.getRange(),
          '{store function}'
        )
    },
  },
  getters: {
    getChartType: (state) => {
      return state.type
    },
    getScanFavListTypes: (state) => { return state.scanFavListTypes },
    getGuruFavListTypes: (state) => { return state.guruFavListTypes },
    mainChartProps: (state) => {
      return state.mainChartProps
    },
    comparisionHigherHigh: (state) => {
      return state.comparisionHigherHigh
    },
    comparisionLowestLow: (state) => {
      return state.comparisionLowestLow
    },
    compareChartSymbols: (state) => {
      return state.compareChartSymbols
    },
    chartUpperRange: (state) => {
      return state.chartUpperRange
    },
    comparisionIndex: (state) => {
      return state.comparisionIndex
    },
    chartLowerRange: (state) => {
      return state.chartLowerRange
    },
    trainingModeDataRaw: (state) => {
      return state.trainingModeDataRaw
    },
    trainingModeData: (state) => {
      return state.trainingModeData
    },
    ohlcv: (state) => {
      // console.log("data", data)
      return state.ohlcv
    },
    ohlcvTimeMap: state => state.ohlcvTimeMap,
    chartRangeObj: (state) => state.chartRangeObj,
    SelectedScanSymbol: (state) => state.SelectedScanSymbol,
    candleIndexSelected: (state) => state.candleIndexSelected,
    candleIndexJumpSelected: (state) => state.candleIndexJumpSelected,
    trainingOffset: (state) => state.trainingOffset - 1,
    chartOverlays: (state) => state.chartOverlays,
    scanOverlay: (state) => state.scanOverlay,
    showOverlay: (state) => state.showOverlay,
    searchBoxUpdates: (state) => state.searchBoxUpdates,
    themeDarkMode: (state) => state.proTrader.themeSettings.themeDarkMode,
    selectedDrawingID: (state) => state.selectedDrawingID,
    userChoice: (state) => state.userChoice,
    favSelectedScanId: (state) => state.favSelectedScanId,
    chartPatternObject: (state) => state.scans.scanner.patterns,
    getUserId: (state) => state.userId,
    getUserEmail: (state) => state.emailId,
    eventUpdates: (state) => state.scans.eventUpdates,
    userSavedScans: (state) => state.userSavedScans,
    scans: (state) => state.scans,
    scanDeletePopup: (state) => state.scanDeletePopup,
    scanPopup: (state) => state.scanPopup,
    watchlistPopupGetter: (state) => state.watchlistPopup,
    topListSymbolName: (state) => state.topListSymbolName,
    topListSymbol: (state) => state.topListSymbol,
    scrollRight: (state) => state.scrollRight,
    scanCopyBtn: (state) => state.scanCopyBtn,
    userMultiSettings: (state) => state.userData.multi,
    userGeneralSettings: (state) => state.userData.generalSettings,
    userData: (state) => state.userData,
    drawingDataList: (state) => state.drawingDataList,
    drawingData: (state) => state.drawingData,
    drawingDataSaveSettings: (state) => state.drawingDataSaveSettings,
    getUserSettingData: (state) => state.userData,
    themeSettings: (state) => state.proTrader.themeSettings,
    userThemeSettings: (state) => state.userData['proTrader.themeSettings'],
    defaultThemeSettings: (state) => state.DEFAULT_THEME_SETTINGS,
    settingUpdatesForCharts: (state) => state.settingUpdatesForCharts,
    getSingleIndicator: (state) => {
      return state.userData.indicatorSettings.single
    },
    getAdditionalIndicator: (state) => {
      return state.userData.indicatorSettings.additional
    },
    getMultiIndicator: (state) => {
      return state.userData.multi
    },
    getGeneralUserSettings: (state) => {
      // console.log("indicatorSettings from store")
      return state.userData.generalSettings
    },
    protraderGeneralSettings: (state) =>
      state.protraderCharts.protraderGeneralSettings.config,
    chartSettingUpdatedDate: (state) => state.chartSettingUpdatedDate,
    chartSettings: (state) => state.chartSettings,
    allChartOverlays: (state) => [
      ...state.chartOverlays.trendCharts.data,
      ...state.chartOverlays.momentumCharts.data,
      ...state.chartOverlays.volatilityCharts.data,
      ...state.chartOverlays.plusCharts.data,
    ],
    trendCharts: (state) => state.chartOverlays.trendCharts,
    momentumCharts: (state) => state.chartOverlays.momentumCharts,
    volatilityCharts: (state) => state.chartOverlays.volatilityCharts,
    plusCharts: (state) => state.chartOverlays.plusCharts,
    defaultChartSettings: (state) => state.defaultChartSettings,
    scanningIndicators: (state) => state.scans.scanner.indicators,
    chartToggleVolume: (state) => state.chartToggleVolume,
    getSavedTradePlans: (state) => state.savedTradePlans,
    symbolName: (state) => state.currentSymbol.symbol,
    companyName: (state) => state.currentSymbol.companyName,
    timeFrame: (state) => state.currentSymbol.timeFrame,
    getTimeFrameRange: (state) => state.timeFrameRange,
    getCurrentSymbolData: (state) => state.currentSymbolData,
    getChartVisibleRange: (state) => state.chartVisibleRange,
    getSymbolsList: (state) => state.symbolsList,
    getIndustryGroup: (state) => state.industryGroup,
    getSubIndustryGroup: (state) => state.subIndustryGroup,
    getSectorGroup: (state) => state.sectorGroup,
    getMarketId: (state) => state.marketId,
    getMarketDesc: (state) => state.marketDesc,
    getSymbolData: (state) => state.ohlcv,
    formattedSymbolData: (state) => state.currentSymbol.data,
    getSymbolByIndex: (state) => state.symbolsList[state.symbolIndex],
    isChartLoading: (state) => state.isChartLoading,
    dc: (state) => state.tradingChartReference?.data,
    tv: (state) => state.tradingChartReference,
    capitulationSettings: (state) => state.scans.gurus.capitulation,
    samsPatternSettings: (state) => state.scans.scanner.samsPattern.settings,
    gurusScanSettings: (state) => state.scans.gurus,
    dataSettings: (state) => state.scans.dataSettings,
    selectedMarket: (state) => state.selectedMarket,
    eventsSettings(state) {
      return (scanId) => {
        return state.scans.scanner[scanId].eventsSettings
      }
    },
    selectedMarketForScan(state) {
      return (scanId, scanType) => {
        if (scanType === 'scanner') {
          return state.scans.scanner[scanId].selectedMarket
        } else if (scanType === 'guru') {
          return state.scans.gurus[scanId].selectedMarket
        }
      }
    },
    dataSettingsForScan(state) {
      return (scanId, scanType) => {
        if (scanId && scanType === 'scanner') {
          console.log("scanner store", scanId, scanType)
          return state.scans.scanner[scanId].dataSettings
        } else if (scanId && scanType === 'guru') {
          // console.log("guru block store",scanId,state.scans.gurus[scanId].dataSettings  )

          return state.scans.gurus[scanId].dataSettings
        } else if (scanId && scanType === 'proScan') {
          // console.log("guru block store",scanId,state.scans.gurus[scanId].dataSettings  )

          return state.scans.proScan[scanId].dataSettings
        }
        else return state.scans.dataSettings
      }
    },
    filterSettingsForScan(state) {
      return (scanId) => {
        // console.log("scanId",scanId)
        if (scanId) return state.scans.scanner[scanId].filterSettings
        else return state.scans.filterSettings
      }
    },
    filterSettings: (state) => state.scans.filterSettings,
    scanResults: (state) => state.scans.results,
    symbolMetaData: (state) => state.currentSymbol.metaData,
    symbolClassificationInfo: (state) => {
      return state.currentSymbol.classificationInfo
    },
    defaultIndicatorSettings: (state) => state.indicatorDefaults,
    getSymbolHealth: (state) => state.currentSymbol.health,
    isTrainingMode: (state) => state.trainingMode,
    symbolIsMissing: (state) => state.symbolIsMissing,
    isIntradayMode: (state) => state.intradayMode, // && getMarketTime().isMarketOpened,
    trainingModeStartingIndex: (state) => state.trainingModeStartingIndex,
    trainingModeStartingIndexSubset: (state) =>
      state.trainingModeStartingIndexSubset,
    userChartlist: (state) => JSON.parse(JSON.stringify(state.userChartlist)),
    chartIDList: (state) => JSON.parse(JSON.stringify(state.chartIDList)),
    themePrimaryColor: (state) => {
      if (isThemePrimary(state.proTrader.themeSettings)) {
        return {
          backgroundColor:
            THEME_DETAILS[state.proTrader.themeSettings.id].primary,
        }
      } else {
        return {
          backgroundColor: state.proTrader.themeSettings.settings.primary,
        }
      }
    },
    themeSecondaryColor: (state) => {
      if (isThemePrimary(state.proTrader.themeSettings)) {
        return {
          backgroundColor:
            THEME_DETAILS[state.proTrader.themeSettings.id].secondary,
        }
      } else {
        return {
          backgroundColor: state.proTrader.themeSettings.settings.secondary,
        }
      }
    },
    themeFontColor: (state) => {
      if (isThemePrimary(state.proTrader.themeSettings)) {
        return { color: THEME_DETAILS[state.proTrader.themeSettings.id].font }
      } else {
        return { color: state.proTrader.themeSettings.settings.font }
      }
    },
    isThemePrimary: (state) => isThemePrimary(state.proTrader.themeSettings),
    showWatchesModal: (state) => state.showWatchesModal,
    showWatchRenameModal: (state) => state.showWatchRenameModal,
    showWatchesDeleteModal: (state) => state.showWatchesDeleteModal,
    showWatchesImportModal: (state) => state.showWatchesImportModal,
    watchLists: (state) => state.watchLists,
    selectedWatch: (state) => state.selectedWatch,
    selectedWatchCode: (state) => state.selectedWatchCode,
    selectedWatchSymbolCode: (state) => state.selectedWatchSymbolCode,
    watchListsData: (state) => state.watchListsData,
    selectedWatchData: (state) =>
      state.watchLists && state.watchLists[state.selectedWatchCode]
        ? state.watchLists[state.selectedWatchCode]
        : null,
    watchListsData1: (state) => {
      if (state.selectedWatch.length > 0) {
        // console.log(
        //   'getter for list',
        //   state.watchListsData[state.selectedWatch]
        // )
        if (state.watchListsData[state.selectedWatch]) {
          return state.watchListsData[state.selectedWatch]
        } else {
          return []
        }
      } else {
        return []
      }
    },
    deleteFavScanLoader: (state) => state.deleteFavScanLoader,
  },
})
