<template>
  
      <sui-table-row>        
        <sui-table-cell :width="15">
          <input placeholder="20" v-model="propertyObj.value" type="number" @change.p.prevent="valueChange"/>
        </sui-table-cell>
        <sui-table-cell :width="1">          
            <color-picker v-model="propertyObj.color" :field-ref="propName" type="color" :isSettingsPicker="true" v-on:input="valueChange"/>          
        </sui-table-cell>        
      </sui-table-row>
  
</template>
<script>
import ColorPicker from '@/baseComponents/ColorPicker.vue'
export default {
  components:{ColorPicker},
  name: 'MultiValueSettingForm',
  props:["value","color","itemid","parentId","propName","type"],
  data(){
      return {
        propertyObj:{
          value:0,
          color:"",
          propName:"",
          }        
      }
    
  },
  methods:{
    valueChange(){
      
      this.$emit('valueChange',this.propertyObj,this.propertyObj.propName,this.type)
    }
  },
  created() {
    this.propertyObj = {
      value:this.value,
      color:this.color,
      itemid:this.itemid,
      parentId:this.parentId,
      propName:this.propName,  
    }
    // console.log('propertyObj -- ',JSON.stringify(this.propertyObj))
  },
  watch:{
    propertyObj(newval,oldval){
      // console.log(this.propName,newval,oldval)
    }   
  }
}
</script>