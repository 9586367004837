<template>
  <guru-base-modal
  class="fontSize"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    header="Bollinger Squeeze"
    :valid="isValid"
    customStyle=""
    :isLoading="isLoading"
    :scanType="scanType"
    id="bollingerSqueeze"
  >
    <div class="input-container fontSize">
      <label>Min scanning period</label>
      <input
        style="width: 10ch"
        type="number"
        value="200"
        min="0"
        v-model="settings.minScanningPeriod"
      />
    </div>

    <div class="input-container fontSize">
      <label>Max scanning period</label>
      <input
        style="width: 10ch"
        type="number"
        value="250"
        min="0"
        v-model="settings.maxScanningPeriod"
      />
    </div>

    <div class="input-container fontSize">
      <label>Days from today when squeeze started breaking out</label>
      <input
        style="width: 10ch"
        type="number"
        value="5"
        min="0"
        v-model="settings.dayFromToday"
      />
    </div>

    <div class="input-container fontSize">
      <label>Min squeeze length</label>
      <input
        style="width: 10ch"
        type="number"
        value="20"
        min="0"
        v-model="settings.minSqueezeLength"
      />
    </div>

    <div class="input-container fontSize">
      <label>Max squeeze length</label>
      <input
        style="width: 10ch"
        type="number"
        value="40"
        min="0"
        v-model="settings.maxSqueezeLength"
      />
    </div>

    <div class="input-container fontSize">
      <label>Ratio of spike height to a squeeze height</label>
      <input
        style="width: 10ch"
        type="number"
        value="10"
        min="0"
        v-model="settings.ratioOfSpikeHeight"
      />
    </div>
  </guru-base-modal>
</template>

<script>
import GuruBaseModal from './GuruBaseModal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { GuruBaseModal },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  name: 'BollingerSqueeze',
  data() {
    return {
      scanType: "bollingerSqueeze",
      settingsData: {
        minScanningPeriod: 200,
        maxScanningPeriod: 250,
        dayFromToday: 5,
        minSqueezeLength: 200,
        maxSqueezeLength: 250,
        ratioOfSpikeHeight: 10
      },
      isLoading: false
    }
  },
  methods: {
    ...mapActions([
      'sendBollingerSqueezePatternRequest',
      'setBollingerSqueezeSettings',
      'cancelGuruScans'
    ]),
    async onScanClick() {
      this.isLoading = true
      await this.sendBollingerSqueezePatternRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    }
  },
  computed: {
    ...mapGetters(['gurusScanSettings']),
    /** @returns {Boolean} */
    isValid() {
      return (
        this.settings.minScanningPeriod > 0 &&
        this.settings.maxScanningPeriod > 0 &&
        this.settings.minScanningPeriod > 0 &&
        this.settings.maxScanningPeriod > 0 &&
        this.settings.dayFromToday > 0 &&
        this.settings.ratioOfSpikeHeight > 0 &&
        this.settings.minScanningPeriod < this.settings.maxScanningPeriod &&
        this.settings.minSqueezeLength < this.settings.maxSqueezeLength
      )
    },
    settings: {
      get() {
        return this.gurusScanSettings.bollingerSqueeze || this.settingsData
      },
      set(value) {
        this.setBollingerSqueezeSettings(value)
      }
    }
  }
}
</script>
<style scoped>
.input-container {
  display: flex;
  align-items: baseline;
  height: max-content;
  width: 100%;
  margin-top: 0.1rem;
  font-size: 13.5px;
}
.input-container > * {
  margin-right: 1rem;
  margin-top: 1rem;
}
.fontSize{
  font-size: 13.5px;
  margin-bottom: 5px;
}
@media screen and (max-width:870px){
.fontSize{
  font-size: 11px;
  margin-bottom: 5px;
}
}
@media screen and (max-width:510px){
.fontSize{
  font-size: 10px;
  margin-bottom: 7px;
}
}
</style>
