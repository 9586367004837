<template>
  <div style="height: max-content; padding-top: 12px" class="fontSize">
    <sui-header dividing :style="themeFontColor" class="fontSize"
      >Scan Type</sui-header
    >
    <sui-form-fields inline class="fontSize">
      <sui-form-field v-for="(v, i) of scanTypes" :key="i" class="fontSize">
        <input
          type="radio"
          class="radioBtn"
          style="background-color: blue !important"
          :id="'timeFrame~' + i + dataId"
          name="timeFrame"
          :value="v.value"
          :style="{ cursor: 'pointer' }"
          v-model="selectedTimeFrame"
          @input="handleUserChoice"
        />
        <label
          :for="'timeFrame~' + i + dataId"
          :style="{ ...themeFontColor, cursor: 'pointer' }"
        >
          {{ v.label }}</label
        >
      </sui-form-field>
    </sui-form-fields>
    <div class="scan-type">
      <!-- <sui-form-fields inline class="fontSize">
        <sui-form-field>
          <sui-dropdown
            placeholder="Market"
            selection
            :options="marketTypes"
            v-model="selectedMarketCode"
            @input="handleUserChoice"
          />
        </sui-form-field>
        <sui-form-field>
          <sui-dropdown
            placeholder="Indices"
            selection
            :options="marketType"
            v-model="selectedMarketTye"
            v-if="selectedMarketCode === 'market'"
            @input="handleUserChoice"
          />
        </sui-form-field>
        <sui-form-field>
          <sui-dropdown
            placeholder="Type"
            selection
            :options="filterTypes"
            v-model="selectedFilterBy"
            v-if="selectedMarketCode === 'market'"
            @input="handleUserChoice"
          />
        </sui-form-field>
        <sui-form-field>
          <sui-dropdown
            placeholder="Sector"
            selection
            v-model="selectedSector"
            :options="getMarketCategory"
            v-if="selectedFilterBy === 'sector'"
            @input="handleUserChoice"
          />
        </sui-form-field>
        <sui-form-field>
          <sui-dropdown
            placeholder="Industry"
            selection
            v-model="selectedIndustry"
            :options="getIndustryGroupDD"
            v-if="selectedFilterBy === 'industry'"
            @input="handleUserChoice"
          />
        </sui-form-field>
        <sui-form-field>
          <sui-dropdown
            placeholder="SubIndustry"
            selection
            v-model="selectedSubIndustry"
            :options="getSubIndustryGroupDD"
            v-if="selectedFilterBy === 'subIndustry'"
            @input="handleUserChoice"
          />
        </sui-form-field>
        <sui-form-field :key="watcherField">
          <sui-dropdown
            placeholder="Type"
            selection
            :options="getWatchListFromStore"
            v-model="selectedWatchCode"
            v-if="selectedMarketCode === 'watch-list'"
            @input="handleUserChoice"
          />
        </sui-form-field>
        <sui-form-field :key="getScannerId">
          <sui-dropdown
            placeholder="Type"
            selection
            :options="getScanListFromStore"
            v-model="selectedScanCode"
            @input="handleUserChoice"
            v-if="selectedMarketCode === 'scan-result'"
          />
        </sui-form-field>
      </sui-form-fields> -->
        <sui-form-fields inline class="fontSize">
        <sui-form-field>
          <select style="max-width: 180px; min-width:130px" v-model="selectedMarketCode" @input="handleUserChoice">
            <option v-for="option in marketTypes" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </sui-form-field>
        <sui-form-field>
          <select style="max-width: 180px; min-width:130px" v-model="selectedMarketTye" @input="handleUserChoice" v-if="selectedMarketCode === 'market'">
            <option v-for="option in marketType" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </sui-form-field>
        <sui-form-field>
          <select style="max-width: 180px; min-width:130px" v-model="selectedFilterBy" @input="handleUserChoice" v-if="selectedMarketCode === 'market'">
            <option v-for="option in filterTypes" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </sui-form-field>
        <sui-form-field>
          <select style="max-width: 180px; min-width:130px" v-model="selectedSector" @input="handleUserChoice" v-if="selectedFilterBy === 'sector'">
            <option v-for="option in getMarketCategory" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </sui-form-field>
        <sui-form-field>
          <select style="max-width: 180px; min-width:130px" v-model="selectedIndustry" @input="handleUserChoice" v-if="selectedFilterBy === 'industry'">
            <option v-for="option in getIndustryGroupDD" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </sui-form-field>
        <sui-form-field>
          <select style="max-width: 180px; min-width:130px" v-model="selectedSubIndustry" @input="handleUserChoice" v-if="selectedFilterBy === 'subIndustry'">
            <option v-for="option in getSubIndustryGroupDD" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </sui-form-field>
        <sui-form-field :key="watcherField">
          <select style="max-width: 180px; min-width:130px" v-model="selectedWatchCode" @input="handleUserChoice" v-if="selectedMarketCode === 'watch-list'">
            <option v-for="option in getWatchListFromStore" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </sui-form-field>
        <sui-form-field :key="getScannerId">
          <select style="max-width: 180px; min-width:130px" v-model="selectedScanCode" @input="handleUserChoice" v-if="selectedMarketCode === 'scan-result'">
            <option v-for="option in getScanListFromStore" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </sui-form-field>
      </sui-form-fields>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { generateUUID } from 'Utils'
import { marketsData } from '../../data/markets'

export default {
  name: 'DataSettings',
  props: {
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'scanner',
    },
  },
  methods: {
    ...mapActions([
      'setScanDataSettings',
      'setScanDataSettingsOld',
      'setSelectedMarket',
      'setSelectedMarketNew',
    ]),
/*************  ✨ Codeium Command ⭐  *************/
    /**
     * Handles the user's selection or input change.
     * If no user choice has been made, it commits to the Vuex store
     * to set the 'userChoice' state to true.
     */

/******  ca6f316c-a8a4-483a-9b2b-36551636b72e  *******/
    handleUserChoice() {
      console.log('handleUserChoice')
      if (!this.userChoice) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
  },
  data() {
    return {
      inputBackColor: 'white',
      SelectedItem: 'black',
      dataId: '',
      watcherField: 0,
      scannerField: 0,
      selectedIndex: 'asx',
      indicesTypes: [
        {
          text: 'Indices',
          value: 'indices',
        },
        {
          text: 'ASX',
          value: 'asx',
        },
      ],
      filterTypes: [
        {
          text: 'All',
          value: 'all',
        },
        {
          text: 'Sector',
          value: 'sector',
        },
        {
          text: 'Industry',
          value: 'industry',
        },
        {
          text: 'SubIndustry',
          value: 'subIndustry',
        },
      ],
      scanTypes: [
        { value: 'D', label: 'Daily' },
        { value: 'W', label: 'Weekly' },
        { value: 'M', label: 'Monthly' },
      ],
    }
  },
  mounted() {
    console.log('id from guru', this.id)
  },
  created() {
    const random = generateUUID()
    // console.log("random id == ",random)
    this.dataId = random
  },
  computed: {
    ...mapGetters([
      'dataSettings',
      'dataSettingsForScan',
      'selectedMarketForScan',
      'themePrimaryColor',
      'themeSecondaryColor',
      'showWatchRenameModal',
      'themeFontColor',
      'scanResults',
      'watchLists',
      'selectedMarket',
      'getIndustryGroup',
      'getSubIndustryGroup',
      'getSectorGroup',
      'userChoice',
      'themeDarkMode',
    ]),
    getMarketCategory() {
      let data = []
      if (this.getSectorGroup[this.selectedMarketTye]) {
        return this.getSectorGroup[this.selectedMarketTye].map((d) => {
          return { value: d, text: d }
        })
      }
      return data
    },
    getIndustryGroupDD() {
      let data = []
      if (this.getIndustryGroup[this.selectedMarketTye]) {
        return this.getIndustryGroup[this.selectedMarketTye].map((d) => {
          return { value: d, text: d }
        })
      }
      return data
    },
    getSubIndustryGroupDD() {
      let data = []
      if (this.getSubIndustryGroup[this.selectedMarketTye]) {
        return this.getSubIndustryGroup[this.selectedMarketTye].map((d) => {
          return { value: d, text: d }
        })
      }
      return data
    },
    modalID() {
      console.log('this.$props.id', this.$props.id)
      return this.$props.id
    },
    modalType() {
      return this.$props.type
    },
    marketTypes() {
      return this.scanResults.length > 0
        ? [
            {
              text: 'Market',
              value: 'market',
            },
            {
              text: 'Watch list',
              value: 'watch-list',
            },
            {
              text: 'Scan Result',
              value: 'scan-result',
            },
          ]
        : [
            {
              text: 'Market',
              value: 'market',
            },
            {
              text: 'Watch list',
              value: 'watch-list',
            },
          ]
    },

    /** @returns {String} */
    selectedTimeFrame: {
      get() {
        if (this.modalID && this.modalType === 'scanner') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .timeFrame
        } else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .timeFrame
        }else if (this.modalID && this.modalType ==="proScan"){
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .timeFrame
        }else return this.dataSettings.timeFrame
      },
      set(value) {
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            type: 'scanner',
            id: this.modalID,
            settings: { timeFrame: value },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: { timeFrame: value },
          })
        } else if (this.modalID && this.modalType === 'proScan') {
          return this.setScanDataSettings({
            type: 'proScan',
            id: this.modalID,
            settings: { timeFrame: value },
          })
        }
        
        else {
          this.setScanDataSettingsOld({ timeFrame: value })
        }
      },
    },
    selectedMarketTye: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType).type
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType).type
        }else if (this.modalID && this.modalType === 'proScan') {
          return this.dataSettingsForScan(this.modalID, this.modalType).type
        }else return this.dataSettings.type
      },
      set(value) {
        console.log("selectedMarketTye value",value , this.modalID, this.modalType, this.id)
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            id: this.modalID,
            type: this.modalType,
            settings: { type: value },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: this.modalType,
            id: this.modalID,
            settings: { type: value },
          })
        }else if (this.modalID && this.modalType === 'proScan') {
          return this.setScanDataSettings({
            type: this.modalType,
            id: this.modalID,
            settings: { type: value },
          })
        } else {
          this.setScanDataSettingsOld({ type: value })
        }
      },
    },
    selectedFilterBy: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType).filterBy
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType).filterBy
        }else if (this.modalID && this.modalType === 'proScan') {
          return this.dataSettingsForScan(this.modalID, this.modalType).filterBy
        } else return this.dataSettings.filterBy
      },
      set(value) {
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            id: this.modalID,
            type: this.modalType,
            settings: { filterBy: value, sector: '' },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: { filterBy: value, sector: '' },
          })
        }else if (this.modalID && this.modalType === 'ProScan') {
          return this.setScanDataSettings({
            type: 'ProScan',
            id: this.modalID,
            settings: { filterBy: value, sector: '' },
          })
        } else {
          this.setScanDataSettingsOld({ filterBy: value })
        }
      },
    },
    selectedSector: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType).sector
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType).sector
        } else if (this.modalID && this.modalType === 'proScan') {
          return this.dataSettingsForScan(this.modalID, this.modalType).sector
        } 
        else return this.dataSettings.sector
      },
      set(value) {
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            id: this.modalID,
            type: this.modalType,
            settings: { sector: value },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: { sector: value },
          })
        } else if (this.modalID && this.modalType === 'proScan') {
          return this.setScanDataSettings({
            type: 'proScan',
            id: this.modalID,
            settings: { sector: value },
          })
        }
        
        else {
          this.setScanDataSettingsOld({ sector: value })
        }
      },
    },
    selectedIndustry: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType).industry
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType).industry
        } else if (this.modalID && this.modalType === 'proScan') {
          return this.dataSettingsForScan(this.modalID, this.modalType).industry
        }
         else return this.dataSettings.industry
      },
      set(value) {
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            id: this.modalID,
            type: this.modalType,
            settings: { industry: value },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: { industry: value },
          })
        }else if (this.modalID && this.modalType === 'proScan') {
          return this.setScanDataSettings({
            type: 'proScan',
            id: this.modalID,
            settings: { industry: value },
          })
        }
        
        
        else {
          this.setScanDataSettingsOld({ industry: value })
        }
      },
    },
    selectedSubIndustry: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .subIndustry
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .subIndustry
        } else if (this.modalID && this.modalType === 'proScan') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .subIndustry
        }
        
        else return this.dataSettings.subIndustry
      },
      set(value) {
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            id: this.modalID,
            type: this.modalType,
            settings: { subIndustry: value },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: { subIndustry: value },
          })
        }else if (this.modalID && this.modalType === 'proScan') {
          return this.setScanDataSettings({
            type: 'proScan',
            id: this.modalID,
            settings: { subIndustry: value },
          })
        }  
        
        else {
          this.setScanDataSettingsOld({ subIndustry: value })
        }
      },
    },
    marketType: {
      get() {
        return marketsData.map((m) => {
          return { value: m.code, text: m.desc }
        })
      },
    },
    selectedWatchCode: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .watchCode
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .watchCode
        }else if (this.modalID && this.modalType === 'proScan') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .watchCode
        }  
        else return this.dataSettings.watchCode
      },
      set(value) {
        // console.log(value, this.watchLists[value])
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            id: this.modalID,
            type: this.modalType,
            settings: {
              watchCode: value,
              watchCodeId: value,
              scanCode: '',
            },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: {
              watchCode: value,
              watchCodeId: value,
              scanCode: '',
            },
          })
        }else if (this.modalID && this.modalType === 'proScan') {
          return this.setScanDataSettings({
            type: 'proScan',
            id: this.modalID,
            settings: {
              watchCode: value,
              watchCodeId: value,
              scanCode: '',
            },
          })
        }
        
        else {
          this.setScanDataSettingsOld({
            watchCode: value,
            watchCodeId: value,
            scanCode: '',
          })
        }
      },
    },
    selectedScanCode: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType).scanCode
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType).scanCode
        }else if (this.modalID && this.modalType === 'proscan') {
          return this.dataSettingsForScan(this.modalID, this.modalType).scanCode
        }
        
        else return this.dataSettings.scanCode
      },
      set(value) {
        // console.log(value,this.watchLists[value])
        let filterList = this.scanResults.find((f) => f.scanId === value)
        // console.log(filterList.results,value)
        if (filterList && filterList.results && filterList.results.length) {
          let symbolList = filterList.results.map((s) => s.symbol)
          if (this.modalID && this.modalType === 'scanner') {
            this.setScanDataSettings({
              id: this.modalID,
              type: this.modalType,
              settings: { scanCode: value, scanList: symbolList },
            })
          } else if (this.modalID && this.modalType === 'guru') {
            this.setScanDataSettings({
              id: this.modalID,
              settings: { scanCode: value, scanList: symbolList },
            })
          } else if (this.modalID && this.modalType === 'proScan') {
            this.setScanDataSettings({
              id: this.modalID,
              settings: { scanCode: value, scanList: symbolList },
            })
          } 
          else {
            this.setScanDataSettingsOld({
              scanCode: value,
              scanList: symbolList,
            })
          }
        } else {
          if (this.modalID && this.modalType === 'scanner') {
            this.setScanDataSettings({
              id: this.modalID,
              type: this.modalType,
              settings: { scanCode: value, scanList: [] },
            })
          } else if (this.modalID && this.modalType === 'guru') {
            this.setScanDataSettings({
              id: this.modalID,
              settings: { scanCode: value, scanList: [] },
            })
          }else if (this.modalID && this.modalType === 'proScan') {
            this.setScanDataSettings({
              id: this.modalID,
              settings: { scanCode: value, scanList: [] },
            })
          }
          
          else {
            this.setScanDataSettingsOld({ scanCode: value, scanList: [] })
          }
        }
      },
    },
    selectedMarketCode: {
      get() {
        if (this.modalID) {
          return this.selectedMarketForScan(this.modalID, this.modalType)
        } 
        return this.selectedMarket
      },
      set(value) {
        if (this.modalID) {
          this.setSelectedMarketNew({ id: this.modalID, value,type:this.modalType })
        } else {
          this.setSelectedMarket(value)
        }
      },
    },

    getWatchListFromStore() {
      return this.watchLists.map((v, k) => {
        // console.log(JSON.stringify(v))
        return { value: v.id, text: v.name }
      })
    },
    getScanListFromStore() {
      return this.scanResults.map((v, k) => {
        // console.log(JSON.stringify(v))
        return { value: v.scanId, text: v.scanName }
      })
    },
    getScannerId() {
      return 'scan-' + this.scannerField
    },
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.inputBackColor = '#505050'
        this.SelectedItem = 'white'
      } else {
        this.inputBackColor = 'white'
        this.SelectedItem = 'black'
      }
    },
    showWatchRenameModal(n, o) {
      if (!n) {
        this.watcherField = this.watcherField + 1
      }
    },
  },
}
</script>
<style>
.text {
  color: v-bind(SelectedItem) !important;
}
.ui.selection.dropdown .menu > .item {
  border-top: 1px solid #959494 !important;
}
.scan-type .ui.selection.dropdown {
  min-width: 12rem;
}
</style>
<style lang="scss" scoped>
div[role='listbox'] {
  background-color: v-bind(inputBackColor) !important;
}

// .ui.selection.dropdown .menu>.item{
//   background-color:  !important;
// }

.fontSize {
  font-size: 13.5px;
  margin-bottom: 5px;
}

/*.radioBtn{
  accent-color : #e72510  !important;
}*/
@media screen and (max-width: 870px) {
  .fontSize {
    font-size: 11px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 510px) {
  .fontSize {
    font-size: 10px;
    margin-bottom: 7px;
  }
}
</style>
