<template>
  <dialog-box
    modalHeader="Training Mode"
    :open="open"
    @onClose="onClose"
    @ok="onPressOk"
  >
    <div style="font-size: 13.5px">
      <p>
        Training mode is designed to simulate your trading procedures. Once
        training mode is on, the data displayed reverts to a date of your choice
        (we suggest going back approximately 200 days). You may then arrow
        forward day by day, thereby testing your trading skills. Using the
        cursor controls (left and right arrows) in the data panel (located to
        the right of the stock date, above the open/high/low/close data), the
        cursor may be moved to the right revealing each new days' data. Using
        the training mode you may safely 'practice trading', resulting in
        testing and improving your trading knowledge and trading plan without
        risking your capital. Select your desired starting date, and click OK to
        get started.
      </p>
      <br />
      <div class="ui form">
        <div class="inline field">
          <label :style="themeFontColor">Starting Date</label>
          <input
            type="date"
            v-model="startingDate"
            :style="{ ...themeFontColor, ...themePrimaryColor }"
          />
        </div>
      </div>
      <br />
      <b> Note:</b> The application will remain in training mode until you click
      the training mode icon a second time.
    </div>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment/moment'
import { FormatUnixDate } from '../../utils/candle'
export default {
  name: 'TrainingMode',
  components: {
    DialogBox,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startingDate: '2022-10-01',
      inputcolor: 'light',
    }
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.inputcolor = 'dark'
      } else {
        this.inputcolor = 'light'
      }
    },
  },
  methods: {
    ...mapActions(['updateTrainingModeState']),
    onClose() {
      this.$emit('change', !open)
    },
    async onPressOk() {
      const startingTime = new Date(this.startingDate).getTime()
      const symbolDataLastIndex = this.getSymbolData.length - 1
      const symbolDatumElement = this.getSymbolData[symbolDataLastIndex][0]
      if (startingTime > symbolDatumElement) return

      let index = 0
      for (const dt of this.getSymbolData) {
        if (dt[0] > startingTime) break
        index++
      }

      const end = index + 1
      const trainingOffset = this.trainingOffset
      const trainingModeData = this.formattedSymbolData.slice(
        index - trainingOffset,
        end
      )
      const trainingModeDataRemaining = this.getSymbolData.slice(
        index + 1,
        this.getSymbolData.length
      )

      // this.dc.set('chart.tf', '1' + this.timeFrame)
      // this.dc.set('chart.data', dataSlice)
      // this.dc.tv.setRange(0,end)
      // this.dc.set('chart.range', [dataSlice[0][0],symbolDatumElement])
      // await this.$nextTick()
      // debugger
      const trainingModeDataRaw = trainingModeData.map((candle) => [
        moment(candle.date).valueOf(),
        candle.open,
        candle.high,
        candle.low,
        candle.close,
        candle.volume,
        null,
        candle.value,
      ])
      const trainingModeDataRaw2 = trainingModeDataRemaining.map((candle) => [
        candle[0],
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
        NaN,
      ])
      const finalRawDataSet = [...trainingModeDataRaw, ...trainingModeDataRaw2]
      console.log(
        'last dataSlice',
        FormatUnixDate(finalRawDataSet[finalRawDataSet.length - 1][0]),
        finalRawDataSet.length,
        FormatUnixDate(this.getSymbolData[symbolDataLastIndex][0]),
        this.getSymbolData.length
      )
      // console.log("setting range")

      this.updateTrainingModeState({
        state: !this.isTrainingMode,
        startingIndex: index,
        trainingDataIndex: trainingModeData.length - 1,
        trainingModeData,
        trainingModeDataRaw: finalRawDataSet,
      })
      await this.$nextTick()
      this.onClose()
    },
  },
  computed: {
    ...mapGetters([
      'themeDarkMode',
      'isTrainingMode',
      'dc',
      'getSymbolData',
      'formattedSymbolData',
      'trainingOffset',
      'themeFontColor',
      'themePrimaryColor',
      'themeSecondaryColor',
      'timeFrame',
    ]),
    today() {
      return Date.now()
    },
  },
}
</script>

<style>
input {
  color-scheme: v-bind(inputcolor);
}
</style>
