<template>
  <div class="flex flex-col -bg-red-100 w-full h-full">
    <div class="flex h-full w-full p-2">
      <TableComp :tableData="getSymbol" />
    </div>

  </div>
</template>


<script>
import axios from 'axios'
import TableComp from './TableComp.vue'
export default {
  name:'FiftyTwoWeeksLow',
  components:{
    TableComp
  },
  methods:{
    async apiCall(){
      // this.isLoading = true;
      console.log("topGainers");
      try{
        const res = await axios.get("https://core.protradingscans.com/api/v1/marketInsight/52WeeksLow")
        if(res.data.data.results.length){
          console.log('res',res.data.data.results)
          const keys = Object.keys(res.data.data.results[0]);
          let result = [];
          res.data.data.results.forEach((entry)=>{
            let obj = {};

            keys.forEach((key)=>{
              if (key !== "data" && key !== "patternName") {
                if (key === "candleData") {
                  // Handle candleData key specifically
                  obj["close"] = entry[key]?.lastCandle?.close || "-";
                  obj["value"] = entry[key]?.lastCandle?.value || "-";
                } else {
                  // Map key names to desired format
                  let mappedKey = key;
                  if (key === "marketCap") mappedKey = "market cap";
                  else if (key === "changePercent") mappedKey = "change percent";
                  else if (key === "companyName") mappedKey = "company name";

                  obj[mappedKey] = entry[key] || "-";
                }
              }
            })
            result.push(obj);
          })
          // const res = await axios.get("https://core.protradingscans.com/api/v1/marketInsight/topLosers")
          console.log("new result", result)
          this.getSymbol = result
        }
      }catch(e){
        // this.isErorr = true
        console.log(e)
      }
      // this.isLoading = false
      // console.log("topGainers",this.getSymbol);
    },
  },
  data(){
    return{
      getSymbol: [],

    }
  },
  mounted() {
    this.apiCall()
  }
}
</script>