import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import SuiVue from 'semantic-ui-vue'
import 'semantic-ui-css/semantic.min.css'
import './assets/index.css';
import './app.css'
import VueCoreVideoPlayer from 'vue-core-video-player'
// register component globally
Vue.config.productionTip = false

Vue.use(SuiVue)
Vue.use(VueCoreVideoPlayer)
Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
console.log("Testing Workflow")
// added code in base charting library
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
