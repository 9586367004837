<template>
  <div id="zoom-brush-chart">
    <svg ></svg>
  </div>
</template>
<script>
import * as d3 from 'd3'
import {debounce} from '../utils/helper'
import moment from 'moment'
import {GetIterationData} from "../settings/TimeRangeConfig";
import { mapGetters } from 'vuex';
import {chartEvents} from "../chartEvents";
import chart from "./Chart";

export default {
  props: ['data', 'width', 'containerH'],
  data() {
    return {
      // backColorIs:'rgb(184 205 255)',
      // borderColoris:'#eee',
      update: false,
      outside: true,
      sid: 0,
      context: null,
      brush: null,
      xScale2: null,
      handleSetTrading: null,
    }
  },
  computed:{
    ...mapGetters(['themeDarkMode','getChartVisibleRange'])
  },
  mounted() {
    chartEvents.$on("brush_range_change",this.rangeChanges)
    this.handleSetTrading = debounce((extent) => {
     
      this.setTrading(extent)
    }, []) //300
    this.render('mounted')
  },
  methods: {
    rangeChanges(){
      this.outside = true
    },
    render(event) {
      var self = this
      var data = this.data
      // console.log("brush event",event,data.length)
      let refChart = this.$store.state.tradingChartReference
      let timeFrame = this.$store.state.timeFrame
      // console.log('timeFrame in brush',timeFrame)
      if (refChart && refChart.getRange() && refChart.getRange().length === 2) {
        let refChartRange = refChart.getRange()
        let differenceInRange = moment(data[0][0]).diff(
          moment(refChartRange[0]),
          'days'
        )

       

        if (data.length > 0) {
          let lastCandle = data[data.length - 1][0]
          // let differenceInRange = moment(refChartRange[1]).diff(
          //   moment(lastCandle),
          //   'days'
          // )

          let {iterations, type} = GetIterationData(timeFrame);
          for (let i = 0; i < iterations; i++) {
            lastCandle = moment(lastCandle).add(1, type).valueOf()
            data.push([lastCandle,0.000000001])
          }
        }
      }

      var margin = { top: 0, right: 0, bottom: 0, left: 0 }
      var width = this.width
      var containerH = this.containerH ? this.containerH : 80
      var height2 = this.containerH
      var xScale = d3
        .scaleLinear()
        .domain(d3.extent(data.map((d) => d[0])))
        .range([0, width - margin.right - margin.left])

      if (this.update) {
        this.update = false
        d3.select('#zoom-brush-chart svg').remove()
      }
      const svg = d3.select('#zoom-brush-chart svg').node()
        ? d3.select('#zoom-brush-chart svg')
        : d3.select('#zoom-brush-chart').append('svg')

      // Placing the <defs> element
      svg.attr('width', '100%').attr('height', `${containerH}px`)

      var xScale2 = d3
        .scaleLinear()
        // re-use the domain from the other xScale
        .domain(xScale.domain())
        .range([0, width - margin.right - margin.left])
      this.xScale2 = xScale2

      var yScale2 = d3
        .scaleLinear()
        // re-use the y domain as well
        .domain([
          d3.min(data.map((d) => d[1])),
          d3.max(data.map((d) => d[1])),
        ])
        .range([height2 - margin.bottom, 0])

      var area2 = d3
        .area()
        .curve(d3.curveMonotoneX)
        .x((d) => xScale2(d[0]))
        .y0(height2 - margin.bottom)
        .y1((d) => yScale2(d[1]))

      var line = d3
        .line()
        .x((d) => xScale2(d[0]))
        .y((d) => yScale2(d[1]))

      // The context chart
      const context = svg
        .append('g')
        .attr('class', 'context')
        .attr('transform', `translate(0,${margin.top})`)

      this.context = context
      // rgb(184 205 255) owais
      context
        .append('path')
        .datum(data)
        .attr('class', 'area')
        .style('fill', `none`)
        .attr('d', area2)

      context
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', 'rgb(115 155 249)')
        .attr('stroke-width', 1)
        .attr('d', line)

      var contextBrush = context.append('g').attr('class', 'x-brush')

      function brushed({ selection }) {
        //   console.log("setTrading called with extent",JSON.stringify({
        //   selection,
        //   outside:self.outside
        // })) 
        // This point is convert ids to timestamp
        let extent = selection.map((d) => xScale2.invert(d))
        // Here we verify that call is from outside handler or inside
        if (!self.outside) {
          // self.$emit("setTrading", extent[0], extent[1]);
          // console.log(extent)
          const lastBrushId = Math.floor(selection[0]);
          self.sid = lastBrushId
          self.handleSetTrading(extent)
        } else {
          self.outside = false
        }
        xScale.domain(extent)
        shadow
          .attr('width', (d, i) => {
            // console.log(xScale2.range(),xScale2(extent[1]),xScale2(extent[0]),xScale2.range()[1] - xScale2(extent[1]))
            if (d.type === 'w') {
              let wid = xScale2.range()[1] - xScale2(extent[1])

              return wid > 0 ? wid + 'px' : '0px'
            } else {
              return xScale2(extent[0]) > 0 ? xScale2(extent[0]) + 'px' : '0px'
            }
          })
          .attr('x', (d, i) => {
            return d.type === 'w' ? xScale2(extent[1]) : 0
          })
        brushHandle
          .attr('display', null)
          .attr(
            'transform',
            (d, i) =>
              'translate(' +
              [selection[i] + (d.type == 'w' ? -4 : -10), 15] +
              ')'
          )
      }

      const brush = d3
        .brushX(xScale2)
        .extent([
          [0, 0],
          [width - margin.right - margin.left, height2 - margin.bottom],
        ])
        .on('brush', brushed)

      this.brush = brush

      var brushHandle = contextBrush
        .selectAll('.handle--custom')
        .data([{ type: 'w' }, { type: 'e' }])
        .enter()
        .append('image')
        .attr('display', 'none')
        .attr('class', 'handle--custom')
        .attr('stroke', '#000')
        .attr('cursor', 'ew-resize')
        .attr('xlink:href', require('@/assets/handler.svg'))

      var shadow = contextBrush
        .selectAll('.shadow--rect')
        .data([{ type: 'w' }, { type: 'e' }])
        .enter()
        .append('rect')
        .attr('class', 'shadow--rect')
        .attr('fill', '#777')
        .attr('fill-opacity', '0.3')
        .attr('height', `${containerH}px`)
        .attr('rx', 5)
        .attr('ry', 5)

      // context.select('g.x-brush').call(brush)
    },
    setBrushed(extent, from) {

      // console.log("outside move 1 == ",this.xScale2.range(),extent)
      // console.log("outside move 0 == ",this.xScale2.range()[0] - this.xScale2(extent[0]))
      // let goMove  = this.xScale2.range()[1] - this.xScale2(extent[1]) > 0 && this.xScale2(extent[0]) >0

      // this.outside = true
      this.context
        .select('g.x-brush')
        .call(this.brush)
        .call(this.brush.move, [
          this.xScale2(extent[0]),
          this.xScale2(extent[1]),
        ])
    },
    setTrading(extent) {
      // console.log("setTrading called with extent =",{extent});
      if(this.getChartVisibleRange && this.getChartVisibleRange.length)
        localStorage.setItem("LastChartVisibleRange",this.getChartVisibleRange)
      const callback = () => {
        // this.dc
        // console.log("this from brush",this.sid)
        
        chartEvents.$emit("update_compare_chart",this.sid,this.getChartVisibleRange)
      }
      this.$emit('setTrading', extent[0], extent[1],callback)
    },
    setRangeInWatch() {
      let refChart = this.$store.state.tradingChartReference
      if (refChart && refChart.getRange() && refChart.getRange().length === 2) {
        let extent = refChart.getRange()
        // console.log(`extent[0] ${moment(extent[0]).toISOString()}`);
        // console.log(`extent[1] ${moment(extent[1]).toISOString()}`);
        this.context
          .select('g.x-brush')
          .call(this.brush)
          .call(this.brush.move, [
            this.xScale2(extent[0]),
            this.xScale2(extent[1]),
          ])
      }
    },
  },
  watch: {
    // themeDarkMode(val){
    //   if(val){
    //     this.backColorIs = '#505050'
    //     this.borderColoris = '#2C2929'
    //   }
    //   else{
    //     this.backColorIs = "rgb(184 205 255)"
    //     this.borderColoris = '#eee'
    //   }
    // },
    width() {
      this.update = true
      this.outside = true
      // console.log("tradingChartReference ==",this.$store.state.tradingChartReference.getRange());
      this.render('width')
      this.setRangeInWatch()
    },
    data() {

      this.update = true
      this.outside = true
      this.render('data')
      this.setRangeInWatch()
    },
  },
}
</script>
<style>
.handle {
  width: 20px;
}
.selection {
  fill-opacity: 0;
  stroke: none;
}
.area{
  /* stroke: #151515 !important; */
  /* fill: v-bind(backColorIs) !important; */
  /* stroke: v-bind(borderColoris) !important */
  fill: rgb(184 205 255) !important;
}
</style>
