//import showloaderjs from '../services/watches'
// new


export const TimeRangesConfig = {
    D: [
        {range: '3M', toolTip: '3 Months of Visible Data'},
        {range: '6M', toolTip: '6 Months of Visible Data'},
        {range: 'YTD', toolTip: 'Year to Date Visible Data'},
        {range: '1Y', toolTip: '1 Year of Visible Data'},
        {range: '50Y', toolTip: '5 Year of Visible Data'},
    ],
    W: [
        {range: '3M', toolTip: '3 Months of Visible Data'},
        {range: '6M', toolTip: '6 Months of Visible Data'},
        {range: 'YTD', toolTip: 'Year to Date Visible Data'},
        {range: '1Y', toolTip: '1 Year of Visible Data'},
        {range: '50Y', toolTip: '5 Year of Visible Data'},
    ],
    M: [
        {range: '3M', toolTip: '3 Months of Visible Data'},
        {range: '6M', toolTip: '6 Months of Visible Data'},
        {range: 'YTD', toolTip: 'Year to Date Visible Data'},
        {range: '1Y', toolTip: '1 Year of Visible Data'},
        {range: '50Y', toolTip: '5 Year of Visible Data'},
    ],
};





// export const TimeRangesConfig = {
//     D: [
//         {range: '3M', toolTip: '3 Months of Visible Data'},
//         {range: '6M', toolTip: '6 Months of Visible Data'},
//         {range: 'YTD', toolTip: 'Year to Date Visible Data'},
//         {range: '1Y', toolTip: '1 Year of Visible Data'},
//         {range: '5Y', toolTip: '5 Year of Visible Data'},
//     ],
//     W: [
//         {range: '1Y', toolTip: '1 Year of Visible Data'},
//         {range: '2Y', toolTip: '2 Year of Visible Data'},
//         {range: '5Y', toolTip: '5 Year of Visible Data'},
//         {range: '10Y', toolTip: '10 Year of Visible Data'},
//         {range: '20Y', toolTip: '20 Year of Visible Data'},
//     ],
//     M: [
//         {range: '5Y', toolTip: '5 Year of Visible Data'},
//         {range: '10Y', toolTip: '10 Year of Visible Data'},
//         {range: '25Y', toolTip: '25 Year of Visible Data'},
//         {range: '50Y', toolTip: '50 Year of Visible Data'},
//     ],
// };