import store from "../store";

const singleIndicator = () => {
    return store.getters.getSingleIndicator
};
const indicatorSettings = () => {
    return store.getters.getSingleIndicator
};
const indicatorSettingsAdditional = () => {
    return store.getters.getAdditionalIndicator
};
const multiIndicator = () => {
    return store.getters.getMultiIndicator
};
export const OVERLAYS_CHARTS = {
    "MacdSetting": "MACD",
    "macd": "MACD",
    "Stoc": "Stoch",
    "stoc": "Stoch",
    "Roc": "ROC",
    "Bands": "BB",
    "MA": "SMA",
    "WMA": "WMA",
    "XMA": "XMA",
    "RSI": "RSI",
    "rsi": "RSI",
    "TCITrend": "TCI",
    "TCIBreak": "TCI",


}
export const DEFAULT_OVERLAYS = {
    trendCharts: {
        selected: false,
        data: [
            {
                id: 1,
                name: 'SMA',
                desc: 'SMA(Price)',
                tool_type:'trendCharts',
                selected: false,
                multi_value: true,
                props: [
                    {
                        length: 30,
                        color: '#0980e8',
                    },
                ],
                datatype: 'object',
                storeRef: 'sma',
                mapping: {
                    length: {prop: ['parameters', 'period', 'value']},
                    color: {prop: 'period', color: true},
                },
                chartId: 'simpleMovingAverage',
                getData: function () {

                    const data = multiIndicator();
                    console.log("this.mapping getSingleIndicator", this.mapping, data?.sma)
                    return data?.sma
                },
                getOverlayData: function (settings) {
                    // console.log('settings in overlays', settings)
                    let settingsBase = this.getData()
                    return {
                        ...settingsBase,
                        length: settings.crossesAndAboveBelow.rightPeriod,
                        color: '#0980e8',
                        chartId: 'simpleMovingAverage',
                    }
                }
            },
            {
                id: 2,
                name: 'SMAOBV',
                desc: 'SMA(OBV)',
                tool_type:'trendCharts',
                gridid: 1,
                selected: false,
                multi_value: true,
                props: [
                    {
                        period: 30,
                        color: '#0980e8',
                    },
                ],
                childRef:'VolumeOVB',
                datatype: 'object',
                storeRef: 'smaobv',
                mapping: {
                    period: {prop: ['parameters', 'period', 'value']},
                    color: {prop: 'period', color: true},
                },
                chartId: 'simpleMovingAverageObv',
                getData: function () {

                    const data = multiIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.smaobv)
                    return data?.smaobv
                }
            },
            {
                id: 3,
                name: 'SMAVolume',
                desc: 'SMA(Volume)',
                tool_type:'trendCharts',
                gridid: 1,
                selected: false,
                multi_value: true,
                props: [
                    {
                        period: 30,
                        color: '#0980e8',
                    },
                ],
                childRef:'VolumeOVB',
                datatype: 'object',
                storeRef: 'smavolume',
                mapping: {
                    period: {prop: ['parameters', 'period', 'value']},
                    color: {prop: 'period', color: true},
                },
                chartId: 'simpleMovingAverageVolume',
                getData: function () {

                    const data = multiIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.smavolume)
                    return data?.smavolume
                }
            },
            {
                id: 4,
                name: 'WMA',
                desc: 'WMA',
                tool_type:'trendCharts',
                selected: false,
                multi_value: true,
                props: [
                    {
                        period: 9,
                        color: '#0980e8',
                    },
                    {
                        period: 27,
                        color: '#FF7518',
                    },
                ],
                datatype: 'object',
                storeRef: 'wma',
                mapping: {
                    period: {prop: ['parameters', 'period', 'value']},
                    color: {prop: 'period', color: true},
                },
                chartId: 'weightedMovingAverage',
                getData: function () {

                    const data = multiIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.wma)
                    return data?.wma
                },
                getOverlayData: function (settings) {
                    // console.log('settings in overlays', settings)
                    let settingsBase = this.getData()
                    return {
                        ...settingsBase,
                        period: settings.crossesAndAboveBelow.rightPeriod,
                        color: '#0980e8',
                    }
                }
            },
            {
                id: 5,
                name: 'XMA',
                desc: 'XMA',
                tool_type:'trendCharts',
                selected: false,
                multi_value: true,
                props: [
                    {
                        period: 15,
                        color: '#0980e8',
                    },
                    {
                        period: 45,
                        color: '#FF7518',
                    },
                    {
                        period: 60,
                        color: '#9954BB',
                    },
                ],
                datatype: 'object',
                storeRef: 'xma',
                mapping: {
                    period: {prop: ['parameters', 'period', 'value']},
                    color: {prop: 'period', color: true},
                },
                chartId: 'exponentialMovingAverage',
                getData: function () {

                    const data = multiIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.xma)
                    if(data?.xma){
                        for (const datum of data?.xma) {
                            if(datum?.period){
                                // console.log("datum?.period",datum.period)
                                datum.period = Number(datum.period)
                            }
                        }                        
                    }
                    return data?.xma
                },
                getOverlayData: function (settings) {
                    // console.log('settings in overlays', settings)
                    let settingsBase = this.getData()
                    return {
                        ...settingsBase,
                        period: settings.crossesAndAboveBelow.rightPeriod,
                        color: '#0980e8',
                    }
                }
            },
            {
                id: 6,
                name: 'SAR',
                desc: 'SAR',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    start: 0.2,
                    inc: 0.1,
                    max: 0.3,
                    color: '#35a9c6',
                },
                datatype: 'object',
                mapping: {
                    start: {prop: ['parameters', 'start', 'value']},
                    inc: {prop: ['parameters', 'increment', 'value']},
                    max: {prop: ['parameters', 'max', 'value']},
                    color: {prop: 'series', color: true},
                },
                storeRef: 'parabolicSar',
                chartId: 'parabolicSar',
                getData: function () {

                    const data = singleIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.parabolicSar)
                    return data?.parabolicSar
                }
            },
            {
                id: 7,
                name: 'MACD',
                desc: 'MACD',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'macd',
                chartId: 'macd',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = singleIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    return data?.macd
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
        ],
    },
    plusCharts: {
        selected: false,
        data: [
            {
                id: 1,
                name: 'ALMA',
                desc: 'ALMA',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'ALMA',
                chartId: 'ALMA',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return  {
                    //     lineWidth: 0.75,
                    //     color: '#559de0'
                    // }
                    return data?.ALMA
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 2,
                name: 'ATRp',
                desc: 'ATRp',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'ATRp',
                chartId: 'ATRp',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return  {
                    //     lineWidth: 0.75,
                    //     color: '#f44336'
                    // }
                    return data?.ATRp
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 3,
                name: 'BBW',
                desc: 'BBW',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'BBW',
                chartId: 'BBW',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return  {
                    //     lineWidth: 0.75,
                    //     color: '#2cc6c9ab'
                    // }
                    return data?.BBW
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 4,
                name: 'CCI',
                desc: 'CCI',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'CCI',
                chartId: 'CCI',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return   {
                    //     lineWidth: 0.75,
                    //     color: '#e28a3dee',
                    //     backColor: '#e28a3d11',
                    //     bandColor: '#aaaaaa',
                    //     upper: 100,
                    //     lower: -100
                    // }
                    return data?.CCI
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 5,
                name: 'CMO',
                desc: 'CMO',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'CMO',
                chartId: 'CMO',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // return   {
                    //     lineWidth: 0.75,
                    //     color: '#559de0'
                    // }
                    return data?.CMO
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },


            {
                id: 6,
                name: 'COG',
                desc: 'COG',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'COG',
                chartId: 'COG',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return  {
                    //     lineWidth: 0.75,
                    //     color: '#559de0'
                    // }
                    return data?.COG
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 7,
                name: 'DMI',
                desc: 'DMI',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'DMI',
                chartId: 'DMI',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return  {
                    //     lineWidth: 0.75,
                    //     colors: ["#ef1360", "#3782f2", "#f48709"]
                    // }
                    return data?.DMI
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 8,
                name: 'HMA',
                desc: 'HMA',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'HMA',
                chartId: 'HMA',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return {
                    //     lineWidth: 0.75,
                    //     color: '#3af475'
                    // }
                    return data?.HMA
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 9,
                name: 'KCW',
                desc: 'KCW',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'KCW',
                chartId: 'KCW',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return {
                    //     lineWidth: 0.75,
                    //     color: '#4c8dffab'
                    // }
                    return data?.KCW
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 10,
                name: 'KC',
                desc: 'KC',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'KC',
                chartId: 'KC',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return  {
                    //     lineWidth: 0.75,
                    //     color: '#4c8dffab',
                    //     showMid: true,
                    //     backColor: '#4c8dff0a'
                    // }
                    return data?.KC
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            
            
            {
                id: 11,
                name: 'MOM',
                desc: 'MOM',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'MOM',
                chartId: 'MOM',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return   {
                    //     lineWidth: 0.75,
                    //     color: '#bcc427ee'
                    // }
                   return data?.MOM
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 12,
                name: 'MFI',
                desc: 'MFI',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'MFI',
                chartId: 'MFI',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return  {
                    //     lineWidth: 0.75,
                    //     color: '#85c427ee',
                    //     bandColor: '#aaaaaa',
                    //     backColor: '#85c42711',
                    //     upper: 80,
                    //     lower: 20
                    // }
                    return data?.MFI
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 13,
                name: 'Ribbon',
                desc: 'Ribbon',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'Ribbon',
                chartId: 'Ribbon',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data = indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return   {
                    //     lineWidth: 0.75,
                    //     colors: ["#3aaaf4ee"]
                    // }
                    return data?.Ribbon
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 14,
                name: 'VWMA',
                desc: 'VWMA',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'VWMA',
                chartId: 'VWMA',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data =indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return  {
                    //     lineWidth: 0.75,
                    //     color: '#db0670'
                    // }
                   return data?.VWMA
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
            {
                id: 15,
                name: 'TSI',
                desc: 'TSI',
                tool_type:'trendCharts',
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: '#2772e3',
                    signalColor: '#441b1b',
                    histColor: '#54a0bb',
                },
                datatype: 'object',
                storeRef: 'TSI',
                chartId: 'TSI',
                mapping: {
                    fast: {prop: ['parameters', 'fastPeriod', 'value']},
                    slow: {prop: ['parameters', 'slowPeriod', 'value']},
                    smooth: {prop: ['parameters', 'signalPeriod', 'value']},
                    macdColor: {prop: 'fastPeriod', color: true},
                    signalColor: {prop: 'slowPeriod', color: true},
                    histColor: {prop: 'signalPeriod', color: true},
                },
                getData: function () {

                    const data =indicatorSettingsAdditional();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.macd)
                    // return {
                    //     lineWidth: 0.75,
                    //     colors: ["#3bb3e4", "#f7046d"]
                    // }
                    return data?.TSI
                },
                getOverlayData: function (settings,scanType) {
                    //macd
                    let settingsBase = this.getData()
                    let indicatorSettingsData = indicatorSettings()
                    //chartPatterns
                    console.log('settings in overlays',scanType,indicatorSettingsData, settings,settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            fast: Number(settings["macd"].fast),
                            slow: Number(settings["macd"].slow),
                            smooth: Number(settings["macd"].signal),
                        }
                    }else{
                        if (settings.macd.isActive) {
                            return {
                                ...settingsBase,
                                fast: Number(indicatorSettingsData["macd"].fast),
                                slow: Number(indicatorSettingsData["macd"].slow),
                                smooth: Number(indicatorSettingsData["macd"].smooth),
                                // macdColor: '#2772e3',
                                // signalColor: '#441b1b',
                                // histColor: '#54a0bb',
                            }
                        }
                    }
                }
            },
        ],
    },
    momentumCharts: {
        selected: false,
        data: [
            {
                id: 1,
                name: 'RSI',
                desc: 'RSI',
                tool_type:'momentumCharts',
                selected: false,
                props: {
                    length: 15,
                    color: '#ecca20',
                },
                datatype: 'object',
                storeRef: 'relativeStrengthIndex',
                mapping: {
                    length: {prop: ['parameters', 'period', 'value']},
                    color: {prop: 'period', color: true},
                },
                chartId: 'relativeStrengthIndex',
                getData: function () {

                    const data = singleIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.relativeStrengthIndex)
                    return data?.relativeStrengthIndex
                },
                getOverlayData: function (settings,scanType) {
                    let settingsBase = this.getData()
                    // console.log("OVERLAYS_CHARTS", settingsBase)
                    if(scanType === 'divergenceScan'){
                        return {...settingsBase,length:settings['rsi']}
                    }else{
                        if ("RSI" in settings && settings["RSI"].period) {
                            return {...settingsBase, length: settings["RSI"].period}
                        } else {
                            return settingsBase
                        }    
                    }
                    
                }
            },
            {
                id: 2,
                name: 'Stoch',
                desc: 'STOC',
                tool_type:'momentumCharts',
                selected: false,
                props: {
                    param_k: 14,
                    param_d: 3,
                    smooth: 3,
                    kColor: '#3782f2',
                    dColor: '#f48709',
                },
                datatype: 'object',
                storeRef: 'stochasticOscillator',
                mapping: {
                    param_k: {prop: ['parameters', 'period', 'value']},
                    param_d: {prop: ['parameters', 'kPeriod', 'value']},
                    smooth: {prop: ['parameters', 'dPeriod', 'value']},
                    kColor: {prop: 'kPeriod', color: true},
                    dColor: {prop: 'dPeriod', color: true},
                },
                chartId: 'stochasticOscillator',
                getData: function () {

                    const data = singleIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.stochasticOscillator)
                    return data?.stochasticOscillator
                },
                getOverlayData: function (settings,scanType) {
                    let settingsBase = this.getData()
                    // console.log("OVERLAYS_CHARTS", settingsBase, settings)
                    if(scanType === 'divergenceScan'){
                        return {
                            ...settingsBase,
                            param_d: settings["stoc"].dPercent,
                            param_k: settings["stoc"].kPercent,
                            smooth: settings["stoc"].period,
                        }
                    }else{
                        if ("STOC" in settings && settings["STOC"].period && settings["STOC"].kPercent && settings["STOC"].dPercent) {
                            return {
                                ...settingsBase,
                                param_d: settings["STOC"].dPercent,
                                param_k: settings["STOC"].kPercent,
                                smooth: settings["STOC"].period,
                            }
                        } else {
                            return settingsBase
                        }
                    }
                    
                }
            },
            {
                id: 3,
                name: 'WilliamsR',
                desc: 'W%R',
                tool_type:'momentumCharts',
                selected: false,
                props: {
                    length: 14,
                    color: '#0980e8',
                },
                datatype: 'object',
                storeRef: 'williamsR',
                mapping: {
                    length: {prop: ['parameters', 'period', 'value']},
                    color: {prop: 'period', color: true},
                },
                chartId: 'williamsR',
                getData: function () {

                    const data = singleIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.williamsR)
                    return data?.williamsR
                }
            },
            {
                id: 4,
                name: 'TCI',
                desc: 'TCI',
                tool_type:'momentumCharts',
                selected: false,
                props: {
                    length: 14,
                    color: '#0980e8',
                },
                datatype: 'object',
                storeRef: 'trendChannels',
                mapping: {
                    length: {prop: ['parameters', 'period', 'value']},
                    color: {prop: 'period', color: true},
                },
                chartId: 'trendChannels',
                getData: function () {

                    const data = singleIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.trendChannels)
                    return data?.trendChannels
                },
                getOverlayData: function (settings) {
                    // console.log('settings in overlays', settings)
                    let settingsBase = this.getData()
                    if (settings && settings.tciTrend && settings.tciTrend.isActive) {
                        return {
                            ...settingsBase,
                            period: settings.tciTrend.period,
                            color: '#0980e8',
                        }
                    }
                    if (settings && settings.tciBreak && settings.tciBreak.isActive) {
                        return {
                            ...settingsBase,
                            period: 14,
                            color: '#0980e8',
                        }
                    }
                    // return {period:settings.BANDS.period}
                }
            },
        ],
    },
    volatilityCharts: {
        selected: false,
        data: [
            {
                id: 1,
                name: 'BB',
                desc: 'BOL',
                tool_type:'volatilityCharts',
                selected: false,
                props: {
                    length: 21,
                    stddev: 2,
                    color: '#0980e8',
                    backColor: '#2b86a6',
                },
                datatype: 'object',
                storeRef: 'bollingerBands',
                mapping: {
                    length: {prop: ['parameters', 'period', 'value']},
                    stddev: {prop: ['parameters', 'deviation', 'value']},
                    color: {prop: 'period', color: true},
                    backColor: {prop: 'deviation', color: true},
                },
                chartId: 'bollingerBands',
                getData: function () {

                    const data = singleIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.bollingerBands)
                    return data?.bollingerBands
                },
                getOverlayData: function (settings) {
                    let settingsBase = this.getData()
                    // console.log('settings in overlays', settings)
                    return {...settingsBase,length: settings.BANDS.period}
                }
            },
            {
                id: 2,
                name: 'ATR',
                desc: 'ATR',
                tool_type:'volatilityCharts',
                selected: false,
                props: {
                    length: 12,
                    lineWidth: 2,
                    color: '#0980e8',
                },
                datatype: 'object',
                storeRef: 'averageTrueRange',
                mapping: {
                    length: {prop: ['parameters', 'period', 'value']},
                    lineWidth: {prop: ['parameters', 'multiplier', 'value']},
                    color: {prop: 'period', color: true},
                },
                chartId: 'averageTrueRange',
                getData: function () {

                    const data = singleIndicator();
                    // console.log("this.mapping getSingleIndicator", this.mapping, data?.averageTrueRange)
                    return data?.averageTrueRange
                }
            },
            {
                id: 3,
                name: 'ROC',
                desc: 'ROC',
                tool_type:'volatilityCharts',
                selected: false,
                props: {
                    length: 14,
                    color: '#0980e8',
                },
                datatype: 'object',
                storeRef: 'rateOfChange',
                mapping: {
                    length: {prop: ['parameters', 'period', 'value']},
                    color: {prop: 'period', color: true},
                },
                chartId: 'rateOfChange',
                getData: function () {

                    const data = singleIndicator();
                    //console.log("this.mapping getSingleIndicator", this.mapping, data?.rateOfChange)
                    return data?.rateOfChange
                },
                getOverlayData: function (settings) {
                    let settingsBase = this.getData()
                    //console.log("OVERLAYS_CHARTS", settingsBase)
                    if ("ROC" in settings && settings["ROC"].period) {
                        return {...settingsBase, length: settings["ROC"].period}
                    } else {
                        return settingsBase
                    }
                }
            },
            {
                id: 4,
                name: 'Value',
                desc: 'Value',
                tool_type:'volatilityCharts',
                selected: false,
                props: {
                    color: '#0980e8',
                },
                datatype: 'object',
                storeRef: 'valueTraded',
                mapping: {
                    color: {prop: 'series', color: true},
                },
                chartId: 'valueTraded',
                getData: function () {

                    const data = singleIndicator();
                    //console.log("this.mapping getSingleIndicator", this.mapping, data?.valueTraded)
                    return data?.valueTraded
                }
            },
            {
                id: 5,
                name: 'VWAP',
                desc: 'VWAP',
                tool_type:'volatilityCharts',
                selected: false,
                props: {
                    color: '#0980e8',
                },
                datatype: 'object',
                storeRef: 'vwap',
                mapping: {
                    color: {prop: 'series', color: true},
                },
                chartId: 'vwap',
                getData: function () {

                    const data = singleIndicator();
                    //console.log("this.mapping getSingleIndicator", this.mapping, data?.vwap)
                    return data?.vwap
                }
            },
        ],
    },
}