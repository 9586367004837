<template>
  <pattern-base-modal
    class="fontSize"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    @saveScanSettings="saveScanSettings"
    @deletedScan="deletedScan"
    @changeScanSettings="changeScanSettings"
    @ResetScanDefaultSettings="ResetScanDefaultSettings"
    header="Parameters"
    scanName="Darvas Boxes"
    :scanType="id"
    :id="id"
    :valid="isValid"
    customStyle=""
    :isLoading="isLoading"
  >
    <div class="input-container space-between fontSize">
      <span> Scan Type:</span>
      <select
        type="select"
        v-model="settings.scanType"
        class="small-input"
        @change="handleUserChoice"
      >
        <option
          v-for="scanType in scanTypes"
          :key="scanType.value"
          :value="scanType.value"
        >
          {{ scanType.text }}
        </option>
      </select>
    </div>

    <div class="input-container space-between fontSize">
      <span> Scan period </span>
      <input
        class="small-input"
        type="number"
        v-model="settings.scanPeriod"
        @input="handleUserChoice"
      />
    </div>

    <div class="input-container space-between fontSize">
      <span> Top confirmation period </span>
      <input
        class="small-input"
        type="number"
        v-model="settings.topConfirmationPeriod"
        @input="handleUserChoice"
      />
    </div>

    <div class="input-container space-between fontSize">
      <span> Bottom confirmation period </span>
      <input
        class="small-input"
        type="number"
        @input="handleUserChoice"
        v-model="settings.bottomConfirmationPeriod"
      />
    </div>

    <div class="input-container space-between fontSize">
      <span> Percentage risk buffer </span>
      <input
        class="small-input"
        type="number"
        @input="handleUserChoice"
        v-model="settings.percentageRiskBuffer"
      />
    </div>

    <div class="input-container space-between fontSize">
      <base-check-box
        @click="handleUserChoice"
        label="Limit total risk to"
        v-model="settings.limitTotalRisk.isActive"
      />
      <input
        class="small-input"
        type="number"
        @input="handleUserChoice"
        v-model="settings.limitTotalRisk.valuePct"
      />
    </div>

    <div class="input-container fontSize">
      <base-check-box
        @click="handleUserChoice"
        label="Volume increased over"
        v-model="settings.volumeIncreasedOver.isActive"
      />
      <input
        class="small-input"
        type="number"
        step="0.01"
        min="0.01"
        max="100.0"
        v-model="settings.volumeIncreasedOver.percent"
        @input="handleUserChoice"
      />
      <span>% on previous</span>
      <input
        @input="handleUserChoice"
        class="small-input"
        type="number"
        step="1"
        min="1"
        v-model="settings.volumeIncreasedOver.previousBars"
      />
      <span>bar(s)</span>
    </div>

    <div class="input-container fontSize">
      <base-check-box
        label="In a box"
        v-model="settings.inBox.isActive"
        @click="handleUserChoice"
      />
      <input
        @input="handleUserChoice"
        type="radio"
        id="box_anyBox"
        name="box"
        value="anyBox"
        :style="{ cursor: 'pointer' }"
        v-model="settings.inBox.type"
      />
      <label for="box_anyBox" :style="{ ...themeFontColor, cursor: 'pointer' }"
        >Any Box</label
      >
      <input
        type="radio"
        id="box_1stBox"
        name="box"
        value="1stBox"
        :style="{ cursor: 'pointer' }"
        v-model="settings.inBox.type"
        @input="handleUserChoice"
      />
      <label for="box_1stBox" :style="{ ...themeFontColor, cursor: 'pointer' }"
        >1st Box</label
      >
    </div>

    <div class="input-container fontSize">
      <base-check-box
        label="With the"
        v-model="settings.within.isActive"
        @click="handleUserChoice"
      />

      <input
        type="radio"
        id="close-high-close-dar"
        name="close-high"
        value="close"
        :style="{ cursor: 'pointer' }"
        v-model="settings.within.type"
        @input="handleUserChoice"
      />
      <label
        for="close-high-close-dar"
        :style="{ ...themeFontColor, cursor: 'pointer' }"
        >Close</label
      >
      <input
        type="radio"
        id="close-high-high-dar"
        name="close-high"
        value="high"
        :style="{ cursor: 'pointer' }"
        v-model="settings.within.type"
        @input="handleUserChoice"
      />
      <label
        for="close-high-high-dar"
        :style="{ ...themeFontColor, cursor: 'pointer' }"
        >High</label
      >

      <span>within</span>
      <input
        class="small-input"
        type="number"
        step="0.01"
        min="0.01"
        @input="handleUserChoice"
        v-model="settings.within.pricePoints"
      />
      <span>price points below the top</span>
    </div>

    <div class="input-container fontSize">
      <base-check-box
        label="Restrict box sequences to a maximum of"
        v-model="settings.restrictBoxToMaxBox.isActive"
        @click="handleUserChoice"
      />
      <input
        class="small-input"
        type="number"
        min="0"
        step="1"
        @input="handleUserChoice"
        v-model="settings.restrictBoxToMaxBox.maxBoxes"
      />
      <span> boxes </span>
    </div>

    <div class="input-container fontSize">
      <base-check-box
        label="Restrict current box width to between"
        v-model="settings.restrictCurrentBoxWidth.isActive"
        @click="handleUserChoice"
      />
      <input
        class="small-input"
        type="number"
        min="0"
        step="1"
        v-model="settings.restrictCurrentBoxWidth.start"
        @input="handleUserChoice"
      />
      <span> and </span>
      <input
        class="small-input"
        type="number"
        min="0"
        step="1"
        @input="handleUserChoice"
        v-model="settings.restrictCurrentBoxWidth.end"
      />
      <span> bars </span>
    </div>
  </pattern-base-modal>
</template>

<script>
const DEFAULT_SETTINGS = {
  scanType: 'long',
  scanPeriod: 55,
  topConfirmationPeriod: 3,
  bottomConfirmationPeriod: 3,
  percentageRiskBuffer: 2.0,
  limitTotalRisk: {
    isActive: false,
    valuePct: 20.0,
  },
  volumeIncreasedOver: {
    isActive: false,
    percent: 300,
    previousBars: 1,
  },
  inBox: {
    isActive: false,
    type: 'anyBox', // 'anyBox' | '1stBox'
  },
  within: {
    isActive: false,
    type: 'close', // 'close' | 'high'
    pricePoints: 0,
  },
  restrictBoxToMaxBox: {
    isActive: false,
    maxBoxes: 0,
  },
  restrictCurrentBoxWidth: {
    isActive: false,
    start: 0,
    end: 10,
  },
}
import CustomImageCheckBox from 'BaseComponents/CustomImageCheckBox.vue'
import PatternBaseModal from './PatternBaseModal.vue'
import { mapActions, mapGetters } from 'vuex'
import BaseCheckBox from '@/baseComponents/BaseCheckBox.vue'
import {DEFAULT_DATA_SETTINGS,DEFAULT_FILTER_SETTINGS,DEFAULT_EVENT_SETTINGS} from "../../../settings/USER_SETTINGS_DEFAULT"
export default {
  components: { PatternBaseModal, CustomImageCheckBox, BaseCheckBox },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  name: 'DarvasBoxes',
  methods: {
    ...mapActions([
      'updateMarketAccordingTODataSettings',
      'sendDarvasScanRequest',
      'setDarvasScanSettings',
      'cancelScans',
      'saveDarvasScanRequest',
      'deleteScanSettings',
      'getUserScanList',
      'setSelectedMarket',
    ]),
    handleUserChoice() {
      if (this.userChoice == false) {
        this.$store.commit('SET_USER_CHOICE', true)
      }
    },
    async onScanClick() {
      this.isLoading = true
      await this.sendDarvasScanRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.cancelScans()
      this.isLoading = false
    },
    async deletedScan(selectedScan) {
      await this.deleteScanSettings(selectedScan)
      this.$store.commit('SCAN_DELETE_POPUP', false)
    },
    ResetScanDefaultSettings() {
      let val = {
        dataSettings:JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        filterSettings:JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
        events:JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS))
      }
      this.updateMarketAccordingTODataSettings({...val,id:this.id})
      this.settings = JSON.parse(JSON.stringify(DEFAULT_SETTINGS))
    },
    changeScanSettings(val) {
      this.updateMarketAccordingTODataSettings({...val,id:this.id})
      this.settings = val.settings
    },
    async saveScanSettings(name) {
      await this.saveDarvasScanRequest(name)
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
  },

  data() {
    return {
      inputFontColor:'black',
      inputBackColor: "white",
      id: "darvasScan",
      isLoading: false,
      selectedScanType: 'long',
      scanTypes: [
        {
          text: 'Long',
          value: 'long',
        },
        {
          text: 'Short',
          value: 'short',
        },
      ],
      settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)),
    }
  },
  computed: {
    ...mapGetters(['themeFontColor', 'userChoice', 'themeDarkMode']),
    /** @returns {Boolean} */
    isValid() {
      return true
    },
  },
  watch: {
    themeDarkMode(val){
      if(val){
        this.inputBackColor = "#505050"
        // this.inputFontColor = "#A2A2A2"
      }
      else{
        this.inputBackColor = "white"
        // this.inputFontColor = this.themeFontColor.color
      }
    },
    settings: {
      handler: function (newValue) {
        this.setDarvasScanSettings(newValue)
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.input-container {
  font-size: 13.5px;
}
.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url]{
  background-color: v-bind(inputBackColor) !important;
  /* color: v-bind(inputFontColor) !important; */
}
.ui.form select{
  background-color: v-bind(inputBackColor) !important;
  /* color: v-bind(inputFontColor) !important; */
}
</style>
