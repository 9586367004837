<template>
  <pattern-base-modal
    scanName="Sams Pattern"
    v-model="open"
    @onClose="$emit('change', !open)"
    @stop="onScanStop"
    @ok="onScanClick"
    header="Sam's Darvas Box"
    :valid="isValid"
    :scanType="id"
    :id="id"
    @changeScanSettings="changeScanSettings"
    @ResetScanDefaultSettings="ResetScanDefaultSettings"
    :hideFav="false"
    customStyle=""
    :isLoading="isLoading"
    :showResetter="false"
    @saveScanSettings="saveScanSettings"
    @deletedScan="deletedScan"
    >
    <sui-header dividing>Parameters</sui-header>
    <div class="input-container space-between fontSize">
      <span> Scan Type:</span>
      <select type="select" v-model="samSettings.scanType" class="small-input">
        <option
          v-for="scanType in scanTypes"
          :key="scanType.value"
          :value="scanType.value"
          >{{ scanType.text }}</option
        >
      </select>
    </div>

    <div class="input-container  space-between fontSize">
      <span>
        Scan period
      </span>
      <input class="small-input" 
             step="1"
             min="0"
             max="1000"
             type="number"
             v-model.number="samSettings.scanPeriod" />
    </div>

    <div class="input-container  space-between fontSize">
      <span>
        Top confirmation period
      </span>
      <input
        class="small-input"
        step="1"
        min="0"
        max="1000"
        type="number"
        v-model.number="samSettings.topConfirmationPeriod"
      />
    </div>

    <div class="input-container  space-between fontSize">
      <span>
        Bottom confirmation period
      </span>
      <input
        class="small-input"
        step="1"
        min="0"
        max="1000"
        type="number"
        v-model.number="samSettings.bottomConfirmationPeriod"
      />
    </div>

    <div class="input-container  space-between fontSize">
      <span>
        Percentage risk buffer
      </span>
      <input
        class="small-input"
        step="1"
        min="1"
        type="number"
        v-model.number="samSettings.percentageRiskBuffer"
      />
    </div>

    <div class="input-container  space-between fontSize">
      <base-check-box
        label="Limit total risk to"
        v-model="samSettings.limitTotalRisk.isActive"
      />
      <input
        class="small-input"
        step="1"
        min="1"
        type="number"
        v-model.number="samSettings.limitTotalRisk.valuePct"
      />
    </div>

    <div class="input-container fontSize">
      <base-check-box
        label="Volume increased over"
        v-model="samSettings.volumeIncreasedOver.isActive"
      />
      <input
        class="small-input"
        type="number"
        step="1"
        min="1"
        v-model.number="samSettings.volumeIncreasedOver.percent"
      />
      <span>% on previous</span>
      <input
        class="small-input"
        type="number"
        step="1"
        min="1"
        v-model.number="samSettings.volumeIncreasedOver.previousBars"
      />
      <span>bar(s)</span>
    </div>

    <div class="input-container fontSize">
      <base-check-box label="In a box" v-model="samSettings.inBox.isActive" />      
      <input type="radio"
             id="box_anyBox_sam"
             name="box"
             value="anyBox"
             :style="{cursor:'pointer'}"
             v-model="samSettings.inBox.type"
      >
      <label for="box_anyBox_sam" :style="{...themeFontColor,cursor:'pointer'}">Any Box</label>
      <input type="radio"
             id="box_1stBox_sam"
             name="box"
             value="1stBox"
             :style="{cursor:'pointer'}"
             v-model="samSettings.inBox.type"
      >
      <label for="box_1stBox_sam" :style="{...themeFontColor,cursor:'pointer'}">1st Box</label>
    </div>

    <div class="input-container fontSize">
      <base-check-box label="With the" v-model="samSettings.within.isActive" />
      <input type="radio"
             id="close-high-close-sam"
             name="close-high"
             value="close"
             :style="{cursor:'pointer'}"
             v-model.number="samSettings.within.type"
      >
      <label for="close-high-close-sam" :style="{...themeFontColor,cursor:'pointer'}">Close</label>
      <input type="radio"
             id="close-high-high-sam"
             name="close-high"
             value="high"
             :style="{cursor:'pointer'}"
             v-model="samSettings.within.type"
      >
      <label for="close-high-high-sam" :style="{...themeFontColor,cursor:'pointer'}">High</label>      
      <span>within</span>
      <input
        class="small-input"
        type="number"
        step="1"
        min="1"
        v-model.number="samSettings.within.pricePoints"
      />
      <span>price points below the top</span>
    </div>

    <div class="input-container fontSize">
      <base-check-box
        label="Restrict box sequences to a maximum of"
        v-model="samSettings.restrictBoxToMaxBox.isActive"
      />
      <input
        class="small-input"
        type="number"
        step="1"
        min="1"
        v-model.number="samSettings.restrictBoxToMaxBox.maxBoxes"
      />
      <span>
        boxes
      </span>
    </div>

    <div class="input-container fontSize">
      <base-check-box
        label="Restrict current box width to between"
        v-model="samSettings.restrictCurrentBoxWidth.isActive"
      />
      <input
        class="small-input"
        type="number"
        step="1"
        min="1"
        v-model.number="samSettings.restrictCurrentBoxWidth.start"
      />
      <span>
        and
      </span>
      <input
        class="small-input"
        type="number"
        step="1"
        min="1"
        v-model.number="samSettings.restrictCurrentBoxWidth.end"
      />
      <span>
        bars
      </span>
    </div>

    <div class="input-container fontSize">
      <base-check-box
        label="Only show boxes where the top and bottom are formed by the first bar"
        v-model="samSettings.onlyShowBoxOfTopBottomOnFirstBar"
      />
    </div>
  </pattern-base-modal>
</template>

<script>
import BaseCheckBox from '@/baseComponents/BaseCheckBox.vue'
import { mapActions, mapGetters } from 'vuex'
import PatternBaseModal from '../patterns/PatternBaseModal.vue'
import {
  DEFAULT_DATA_SETTINGS,
  DEFAULT_EVENT_SETTINGS,
  DEFAULT_FILTER_SETTINGS
} from "../../../settings/USER_SETTINGS_DEFAULT";
const DEFAULT_SETTINGS =  {
  scanType: 'long',
  scanPeriod: 55,
  topConfirmationPeriod: 3,
  bottomConfirmationPeriod: 3,
  percentageRiskBuffer: 2.0,
  limitTotalRisk: {
    isActive: false,
    valuePct: 20.0
  },
  volumeIncreasedOver: {
    isActive: false,
    percent: 300,
    previousBars: 1
  },
  inBox: {
    isActive: false,
    type: 'anyBox' // 'anyBox' | '1stBox'
  },
  within: {
    isActive: false,
    type: 'close', // 'close' | 'high'
    pricePoints: 0
  },
  restrictBoxToMaxBox: {
    isActive: false,
    maxBoxes: 0
  },
  restrictCurrentBoxWidth: {
    isActive: false,
    start: 0,
    end: 10
  },
  onlyShowBoxOfTopBottomOnFirstBar: false
}
export default {
  components: { PatternBaseModal, BaseCheckBox },
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  name: 'SamsPattern',
  data() {
    return {
      id: "samsPattern",
      isLoading: false,
      selectedScanType: 'long',
      scanTypes: [
        {
          text: 'Long',
          value: 'long'
        },
        {
          text: 'Short',
          value: 'short'
        }
      ],
      settingsData:JSON.parse(JSON.stringify(DEFAULT_SETTINGS))
    }
  },
  methods: {
    ...mapActions([
      'sendSamsPatternRequest',
      'saveSamsPatternScanRequest',
      'updateMarketAccordingTODataSettings',
      'getUserScanList',
      'setSamsPatternSettings',
      'deleteScanSettings',
      'cancelGuruScans'
    ]),
   
    ResetScanDefaultSettings() {
      console.log("ResetScanDefaultSettings")
      let val = {
        dataSettings:JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
        filterSettings:JSON.parse(JSON.stringify(DEFAULT_FILTER_SETTINGS)),
        events:JSON.parse(JSON.stringify(DEFAULT_EVENT_SETTINGS))
      }
      this.updateMarketAccordingTODataSettings({...val,id:this.id})
      this.samSettings = JSON.parse(JSON.stringify(DEFAULT_SETTINGS))
    },
    changeScanSettings(val) {
      this.updateMarketAccordingTODataSettings({...val,id:this.id})
      this.samSettings = val.settings
    },
    isValueWithinRange(value, min, max) {
      return value >= min && value <= max;
    },
    async onScanClick() {
      console.log("onScanClick")
      const {topConfirmationPeriod, bottomConfirmationPeriod, percentageRiskBuffer,scanPeriod }=this.samSettings
      if (
        !this.isValueWithinRange(topConfirmationPeriod, 0, 1000) ||
        !this.isValueWithinRange(bottomConfirmationPeriod, 0, 1000) ||
        // !this.isValueWithinRange(percentageRiskBuffer, 0, 1000) ||
        !this.isValueWithinRange(scanPeriod, 0, 1000)
      ) {
        return;
      }
      this.samSettings={
        ...this.samSettings,
        scanPeriod: parseInt(scanPeriod),
        topConfirmationPeriod: parseInt(topConfirmationPeriod),
        bottomConfirmationPeriod: parseInt(bottomConfirmationPeriod),
        // percentageRiskBuffer: parseInt(percentageRiskBuffer)
      }
      this.isLoading = true
      await this.sendSamsPatternRequest()
      this.isLoading = false
      this.$emit('change', !open)
    },
    onScanStop() {
      this.isLoading = false
      this.cancelGuruScans()
    },
    async saveScanSettings(name) {
      await this.saveSamsPatternScanRequest(name)
      this.$store.commit('SCAN_POPUP', false)
      await this.getUserScanList()
    },
    async deletedScan(selectedScan) {
      await this.deleteScanSettings(selectedScan)
      this.$store.commit('SCAN_DELETE_POPUP', false)
    },
  },
  computed: {
    ...mapGetters(['samsPatternSettings','themeFontColor']),
    /** @returns {Boolean} */
    isValid() {
      return true
    },
    samSettings: {
      get() {
        return this.samsPatternSettings || this.settingsData
      },
      set(value) {
        this.setSamsPatternSettings(value)
      }
    }
  }
}
</script>

<style>
.space-between {
  max-width: 60%;
  justify-content: space-between;
}
</style>
