<template>
  <dialog-box
    size="small"
    modalHeader="WatchList Import"
    :open="open"
    :action="false"
    style="z-index: 100"
  >
    <!--      <h3 class="">-->
    <WatchlistPopup
      v-model="watchlistPopupGetter"
      :showWatchesModal="watchlistPopupGetter"
      :showLoader="showLoader"
      :duplicationError="duplicationError"
      @createWatches="createWatches"
      @toggleWatchesModalInner="toggleWatchesModalInner"
    />
    <div class="ui center aligned" style="font-size: 14px">
      <br />
      <p class="ui" :style="themeDarkMode ? { color: '#A2A2A2' } : {}">
        The imported file should be in a csv or text format. The first column
        should contain the symbol while the second should contain the exchange.
        These can be separated by a comma, @, fullstop or space. There should
        only be one stock per line/row.
      </p>
      <br />
    </div>
    <label :style="themeDarkMode ? { color: '#A2A2A2' } : {}"
      >Select import target</label
    >
    <div
      style="
        flex-direction: row;
        display: flex;
        cursor: pointer;
        align-items: center;
      "
    >
      <sui-form-field style="width: 100%">
        <sui-dropdown
          placeholder="- NONE -"
          selection
          :options="getWatchListFromStore"
          v-model="responseObj.watchCode"
          :style="
            themeDarkMode
              ? {
                  background: '#505050 !important',
                  borderColor: '#151515',
                  color: '#A2A2A2',
                }
              : {}
          "
          style="width: 100%"
        />
      </sui-form-field>

      <sui-button icon="file" secondary @click="ShowPopup" />
    </div>
    <sui-form>
      <sui-form-field>
        <label :style="themeDarkMode ? { color: '#A2A2A2' } : {}"
          >Select import source file</label
        >
         
        <input
          type="file"
          ref="importFileRef"
          @change="fileChange"
        />
 
        <!-- <sui-input
          placeholder="Select Watchlist"
          type="file"
          ref="importFileRef"
          @change.native="fileChange"
        /> -->
      </sui-form-field>
      <sui-form-field v-if="responseObj.hasFile">
        <label :style="themeDarkMode ? { color: '#A2A2A2' } : {}"
          >Response:</label
        >
        <sui-input
          placeholder=""
          type="text"
          disabled
          :value="responseObj.status"
        />
      </sui-form-field>
    </sui-form>
    <!--      </h3> -->
    <template v-slot:action>
      <sui-modal-actions
        :style="
          themeDarkMode
            ? {
                background: '#151515 !important',
                borderColor: '#151515',
                color: '#A2A2A2',
              }
            : {}
        "
      >
        <div>
          <sui-button
            negative
            :disabled="!hasAllParams"
            @click.native="createWatchList"
            :style="themeSecondaryColor"
            >Yes
          </sui-button>
          <sui-button @click.native="toggleWatchesImportModalInner"
            >No
          </sui-button>
        </div>
      </sui-modal-actions>
    </template>
  </dialog-box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DialogBox from '../../../baseComponents/DialogBox'
import WatchlistPopup from '../../../components/dialogBoxes/patterns/WatchlistPopup.vue'
export default {
  name: 'ImportWatch',
  computed: {
    ...mapGetters([
      'watchlistPopupGetter',
      'showWatchesImportModal',
      'getSymbolsList',
      'watchLists',
      'showWatchesModal',
      'themeDarkMode',
      'themeSecondaryColor',
    ]),
    getWatchListFromStore() {
      return this.watchLists.map((v, k) => {
        // console.log(JSON.stringify(v))
        return { value: v.id, text: v.name }
      })
    },
    hasAllParams() {
      let decision =this.responseObj.hasFile &&
        this.responseObj.addAbleWatches.length &&
        this.responseObj.watchCode
      // console.log("hasAllParams",decision);  
      return decision
    },
  },
  components: {
    DialogBox,
    WatchlistPopup,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    duplicationError: {
      type: Boolean,
      default: false,
    },
    createWatches: {
      type: [Function, Object],
    },
    toggleWatchesModalInner: {
      type: [Function, Object],
    },
  },
  data() {
    return {
      reader: null,
      selectedFileName:'',
      responseObj: {
        content: '',
        watchCode: null,
        status: '',
        hasFile: false,
        addAbleWatches: [],
      },
    }
  },
  mounted() {
    const reader = new FileReader()
    reader.onload = this.processFile
    this.reader = reader
  },
  methods: {
    ...mapActions(['toggleWatchesImportModal', 'updateWatchList']),
    updateWatchCode(code) {
      this.responseObj = {...JSON.parse(JSON.stringify(this.responseObj)),watchCode:code}
    },
    toggleWatchesImportModalInner() {
      this.responseObj = {
        content: '',
        status: '',
        hasFile: false,
        addAbleWatches: [],
      }
      this.$refs.importFileRef.value = null;
      this.$emit('change', !open)
      // this.toggleWatchesImportModal()
    },
    showCreateWatchList() {
      this.$store.dispatch('toggleWatchesModal')
    },
    ShowPopup() {
      this.$store.commit('WATCHLIST_POPUP', true)
    },

    async createWatchList() {
      let watchList = this.watchLists
      for (let i in watchList) {
        let watchListElement = watchList[i]
        if (watchListElement.id === this.responseObj.watchCode) {
          let newSymbolList = watchListElement.symbolList
          if (newSymbolList.length > 0) {
            for (let addAbleWatch of this.responseObj.addAbleWatches) {
              let hasAlready = newSymbolList.find(
                (s) => s.id === addAbleWatch.id && s.name === addAbleWatch.name
              )
              if (!hasAlready) {
                newSymbolList.push(addAbleWatch)
              }
            }
          } else {
            for (let addAbleWatch of this.responseObj.addAbleWatches) {
              newSymbolList.push(addAbleWatch)
            }
          }
          watchListElement.symbolList = newSymbolList
          await this.updateWatchList({ index: i, value: watchListElement })
        }
      }
      this.responseObj = {
        content: '',
        status: '',
        hasFile: false,
        addAbleWatches: [],
      }
      this.$refs.importFileRef.value = null;
      this.$emit('change', !open)
    },
    fileChange(event) {
      console.log('event', event)
      const fileList = event.target.files
      console.log('fileList',fileList)
      if (fileList.length > 0) {
        if (this.reader) this.reader.readAsText(fileList[0])
      }
    },
    processFile() {
      let sepratorArray = ['@', ',', ' ']

      if (this.reader) {
        let text = this.reader.result
        let records = text.split('\n')
        // console.log("processFile",records)
        let addTheseToWatch = []
        for (let record of records) {
          let findRec
          // console.log("processFile",record)
          if (record && record.length) {
            for (let sep of sepratorArray) {
              if (record.includes(sep)) {
                findRec = record.split(sep)
                break
              }
            }
            // console.log("findRec",findRec)
            if (findRec && findRec.length === 2) {
              let id = findRec[1].trim();
              let name = findRec[0].trim();
              if(id === "ASX"){
                let nameSplit = name.split(".");
                if(nameSplit.length !== 2){
                  name = name + ".au";
                }
              }
              let symbolRef = `${name}~${id}`

              // console.log("processFile",symbolRef)
              let symbolList = this.$store.state.symbolsList
              if (symbolList && symbolList.includes(symbolRef)) {
                addTheseToWatch.push({ id: id, name: name })
              }
            }
          }
        }
        // console.log("addTheseToWatch",addTheseToWatch)
        if (addTheseToWatch && addTheseToWatch.length > 0) {
          this.responseObj.addAbleWatches = addTheseToWatch
          this.responseObj.status = `SuccessFully Process have ${addTheseToWatch.length} Valid Symbols`
          this.responseObj.hasFile = true
        }
      }
    },
  },
}
</script>
