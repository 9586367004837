<template>
  <div
    ref="chartContainer"
    id="chartContainer"
    class="f-wh inner-chart relative"
    style="overflow: hidden"
    
  >
  <div>
    
  </div>
  <!-- <div class="h-full w-full absolute flex items-center justify-center bg-slate-100"> -->
    <span class="absolute top-[35%] left-[35%] watermark xxs:!text-[30px] sm:!text-[48px] md:!text-[54px] ">
      protradingscans.com  
    </span>
  <!-- </div> -->
    <ChartUpd
      v-on:legend-button-click="on_button_click"
      @ChartLoad="ChartLoad"
      @chartUpdate="chartUpdate"
      @updatChartRange="updatChartRange"
      :data="getOhlcv"
      ref="chartWrapper"
      :titleTxt="getSymbolName"
      :colorBack="colors.back"
      :colorGrid="colors.grid"
      :colorText="themeFontColor.color"
      :colorTitle="themeFontColor.color"
      :colorWickUp="settings.colorWickUp"
      :colorWickDw="settings.colorWickDw"
      :colorVolDw="settings.colorVolDw"
      :roundOffVolume="roundOffVolume"
      :colorCandleDw="generalSettingSet('chart', 'downColor', 'colorCandleDw')"
      :colorCandleUp="generalSettingSet('chart', 'upColor', 'colorCandleUp')"
      :colorCross="
        generalCrossHairSettingSet(
          'crossHair',
          'lineColor',
          'colorCrossHairLine'
        )
      "
      :colorTextHL="
        generalCrossHairSettingSet(
          'crossHair',
          'textColor',
          'colorCrossHairText'
        )
      "
      :colorPanel="
        generalCrossHairSettingSet('crossHair', 'boxColor', 'colorCrossHairBox')
      "
      colorVolUp="#FF0039"
      :enableCrosshair="true"
      :indexBased="true"
      :ignoreNegativeIndex="true"
      :extensions="extensions"
      :overlays="overlays"
      :height="chartHeight"
      :width="chartWidth"
      :xsett="xsett"
    />
<!--    <BrushChart
      ref="brushChart"
      :width="chartWidth"
      :container-h="toolHeight"
      :data="getBrushData"
      @setTrading="setTrading"
      v-if="!showLoader"
    />-->
  </div>
</template>

<script>
import TradingVue, { DataCube } from 'trading-vue-js'
import dataJson from 'Data/data.json'
import Loader from 'BaseComponents/Loader'
import Overlays from 'Overlays'
import Extensions from '../extensions'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import ChartAdding from 'Components/ChartAdding'
import BrushChart from './BrushChart'
import { debounce } from '../utils/helper'
import { GetIterationData } from '../settings/TimeRangeConfig'
import ChartUpd from './ChartUpd'
import { chartEvents } from '../chartEvents'
import { TimeRangesConfig } from '../utils/TimeRangesConfig'

export default {
  name: 'Chart',
  components: {
    ChartUpd,
    TradingVue,
    Loader,
    BrushChart,
  },

  data() {
    return {
      candleData: [],
      chartData: new DataCube(dataJson),
      chartWidth: 1,
      chartHeight: 0,
      roundOffVolume:true,
      toolHeight: 0,
      overlays: Object.values(Overlays),
      names: Object.keys(Overlays),
      extensions: Extensions,
      xsett: {},
      colors: {
        back: '',
        border: '#d6d5d5',
        cmAttribute: '#5a5a5a',
        cmBack: '#fff',
        cmCode: '#8e8e8e',
        cmComment: '#b3b3b3',
        cmKeyword: '#30ad77',
        cmLineNumber: '#42465642',
        cmNumber: '#c08a57',
        cmProperty: '#5a5a5a',
        file: 'transparent',
        fileName: 'black',
        fileSelected: '#e8a63e',
        grid: '#eee',
        logo: '#888888',
        selection: '#efefef',
        splitter: '#35a776c4',
        tabBack: '#f1f1f1',
        tabBackHL: '#dadada',
        text: '#333',
        textHL: '#111',
        textHLBack: '#eeeeee88',
        title: '#333', //"#c5c5c5",
        tvTitle: '#333',

        colorCross: '#2780E3',
        colorTextHL: '#23a77688',
        colorPanel: '#242425',
      },
    }
  },
  async mounted() {
    window.addEventListener('resize', this.resize)
    this.resize()
    //--- adding the dc to the store
    this.$nextTick(() => {
      if (this.themeDarkMode) {
        this.colors.grid = '#2C2929'
      } else {
        this.colors.grid = '#eee'
      }
    })
    this.$store.commit('SET_TRADING_BRUSH_REFERENCE', {
      brushChartReference: this.$refs.brushChart,
    })
    // this.$store.commit('SET_DATA_CUBE', { dc: this.chartData })
    const tf = this.userData.timeFrame || 'D'
    this.setSymbolData({ symbolName: '', timeFrame: tf || 'D' }).then((d) => {
      
      const rangeExists= Object.keys(this.userData).includes(`${tf}_range`)
      let config = TimeRangesConfig[tf]
      let rangeMap = config.map((d) => d.range)
      let _index=0;
      if(rangeExists){
          const _range= this.userData[`${tf}_range`]
          _index= rangeMap.findIndex((x)=>{ return x == _range})
      }
      if(_index < 0) _index=0;
      console.log("kkk--->",rangeMap[_index], localStorage.getItem("TimeFrameRange"))
      let range = localStorage.getItem("TimeFrameRange")
      chartEvents.$emit('setChartRange', range, 'Chart Mounted')
      chartEvents.$emit('loadDrawingData')
    })

    // let dataForRef = {"name":"TrendLine 0","type":"TrendLine","settings":{"color":"#42b28a","lineWidth":2,"lineStyle":"solid","lineHeadStyle":"normal","settingsList":["color","lineWidth","lineStyle","lineHeadStyle"],"legend":false,"z-index":100,"$selected":false,"$state":"finished","$uuid":"onchart.TrendLine0-1649240769417","p1":[102,0.1353822033898305],"p2":[110,0.14450423728813558],"firstRun":false},"data":[],"grid":{"id":0},"id":"onchart.TrendLine0"}
    // this.dc.data.onchart.push(dataForRef)
    // console.log("Chart Loaded",this.dc.data.onchart)

    /*
    TODO:CHANGE_EFFECTS
    this.handleChartRange = debounce((event,indexBased) => {
      // console.log('debounce')
      // GetIterationData()
      const {iterations} = GetIterationData(this.$store.state.timeFrame)
      let dataset = []
      if (this.dc && this.dc.data && this.dc.data.chart && this.dc.data.chart.data) {

        dataset = this.dc.data.chart.data
      }
      let endRangeOutside = dataset.length > 0 && indexBased[1] > (dataset.length + iterations);
      let startRangeOutLimit = indexBased[0] < 0;
      // console.log('handle range change',dataset.length,indexBased,startRangeOutLimit,endRangeOutside)
      if(startRangeOutLimit || endRangeOutside){
        if(startRangeOutLimit && endRangeOutside){
          this.$refs.tradingChart.setRange(0,dataset.length + iterations)
        }else if(startRangeOutLimit){
          this.$refs.tradingChart.setRange(0,indexBased[1])
        }else if(endRangeOutside){
          this.$refs.tradingChart.setRange(indexBased[0],dataset.length + iterations)
        }

      }else{
        this.rangeFunction(event)
      }

    }, 300)*/

    //TODO:CHANGE_EFFECTS
    // this.$nextTick(() => {
    //   // console.log("Next Tick Chart Calling")
    //   if(this.chartToggleVolume){
    //     this.add_overlay('VolumeOBV', 0.3, true)
    //   }
    //   if(this.userChartlist.length){
    //     this.$store.commit('USER_CHART_DATA_FOUND', true)
    //   }
    //   this.$refs.brushChart.setBrushed(this.$refs.tradingChart.getRange(),'{chart next tick}')
    // })
    // this.$refs.brushChart.setBrushed(this.$refs.tradingChart.getRange(),'chart mounted')
    // this.$store.commit('USER_CHART_DATA_FOUND', userChartlist)
    // this.add_drawings_to_offChart('VolumeOBV')
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updatChartRange(timeBased, indexBased) {
      // console.log('updatChartRange',timeBased,indexBased)
      // console.log("chart range function found",this.$refs?.brushChart?.outside)
      if (
        this.$refs.brushChart &&
        this.$refs.brushChart.setBrushed &&
        this.$refs?.brushChart?.outside
      ) {
        this.$refs.brushChart.setBrushed(timeBased, 'chart mounted')
      }
    },
    async updateAllOverlay() {
      const allChartOverlays = this.allChartOverlays
      const userChartlist = this.chartIDList
      const overlays = this.overlays
      setTimeout(async () => {
        if (this.chartWrapper && this.chartWrapper.addOverlaysAfterLoading) {
          await this.chartWrapper.addOverlaysAfterLoading(
            userChartlist,
            allChartOverlays,
            overlays
          )
        }
      }, 300)
    },
    chartUpdate() {
      this.$nextTick(async () => {
        await this.updateAllOverlay()
      })
    },
    ChartLoad(compRef, dataCube) {
      // console.log("compRef",compRef)
      // console.log("dataCube",dataCube)
      this.$store.commit('SET_DATA_CUBE', { dc: dataCube })
      this.$store.commit('SET_TRADING_CHART_REFERENCE', {
        tradingChartReference: compRef,
      })
      this.addVolume()
      if (this.chartToggleVolume) {
      }
    },
    addVolume() {
      const volumeOBV = 'VolumeOBV'
      let overlay = this.overlays.find((n) => n.name === volumeOBV)
      chartEvents.$emit(
        'add_overlay_new',
        overlay,
        volumeOBV,
        {},
        false,
        true,
        { hideValues: false }
      )
    },
    ...mapActions(['setSymbolData']),
    generalSettingSet(base, prop, backProp) {
      return this.getGeneralUserSettingsWithProp({ base, prop, backProp })
    },
    generalCrossHairSettingSet(base, prop, backProp) {
      return this.getGeneralUserSettingsForCrossHair({ base, prop, backProp })
    },
    rangeFunction(event) {
      let rangeObj = {
        start: event[0],
        end: event[1],
        range: this.$store.state.chartVisibleRange,
      }
      // console.log("range Function ", JSON.stringify({
      //   startRange: moment(event[0]).format("YYYY MMM"),
      //   endRange: moment(event[1]).format("YYYY MMM")
      // }))
      // console.log(event)

      if (event[0] != null && event[1] != null && event[0] !== event[1]) {
        let prevTimeRange = localStorage.getItem('TimeRange')
        let prevTimeRangeObj = null
        let updateBrush = true
        if (prevTimeRange) {
          prevTimeRangeObj = JSON.parse(prevTimeRange)
        }
        // console.log('this.$store.state.chartPredineRangeUpdates = ',this.$store.state.chartPredineRangeUpdates)
        if (this.$store.state.chartPredineRangeUpdates) {
        }

        // console.log("updateBrush from range", updateBrush)
        if (updateBrush) {
          if (!this.inside) {
            this.$refs.brushChart.setBrushed(event, '{chart range function}')
          } else {
            this.inside = false
          }
        }
      }

      /*console.log("rangeFunction == ",event[0])
      console.log(moment.unix(event[0]/1000).format("MM/DD/YYYY"),
      ' TO ',
       moment.unix(event[1]/1000).format("MM/DD/YYYY"))*/
    },
    on_button_click(event) {
      if (event.button === 'remove') {
        let refChart = `${event.type}.${event.overlay.replace('_', '')}`
        // console.log("refChart == ",refChart)
        if ('offchart.VolumeOBV0' === refChart) {
          this.$store.dispatch('toggleVolumeChart')
        } else {
          this.dc.del(refChart)
        }
      }
    },

    setScreenWidth(width, height) {
    if(width>=1400){
      return 0.95
    }
    else if(width>=700 && width<1400 ){
      return 0.93
    }
   else if(width<700 ){
      return 0.88
    }
    else{
      return 0.88
    }},

    resize() {
      this.chartWidth = 0
      this.chartHeight = 0
      this.$nextTick(() => {
        // this.chartHeight =
        // this.chartContainerRef?.clientHeight.classList.add('chart-height')
        // console.log('clientHeight', this.chartContainerRef?.clientHeight * 0.87)
        const width= window?.screen?.width
        const height= window?.screen?.height
        this.chartHeight = this.chartContainerRef?.clientHeight * this.setScreenWidth(width,height)
        this.toolHeight = this.chartContainerRef?.clientHeight * 0.05
        this.chartWidth = this.chartContainerRef?.clientWidth
      })
    },
    add_overlay(name, height, noDuplicate = false) {
      const e = { type: name, side: 'auto' }
      let preset = this.get_preset(e.type) || {}
      if (preset.side) e.side = preset.side
      // let onchart = this.chart.data.onchart
      let offchart = this.dc.data.offchart
      // console.log(preset.name)
      if (e.side === 'onchart') {
        this.dc.add('onchart', {
          name: preset.name,
          type: e.type,
          data: [],
          settings: preset.settings || {},
        })
      } else {
        // if the overlay is already added, do not add again(no duplicates)
        if (noDuplicate && offchart.find((indi) => indi.type === e.type)) return

        const h = height || this.avg_grid_h(offchart)
        this.dc.add('offchart', {
          name: preset.name,
          type: e.type,
          data: [],
          settings: preset.settings || {},
          grid: { height: h },
        })

        this.addingChartDrawings(e, this)
      }
    },
    get_preset(type) {
      let proto = this.tradingChart.overlays.find((x) => x.name === type)
      if (proto && proto.methods.meta_info) {
        let meta = proto.methods.meta_info()
        return meta.preset
      }
    },
    avg_grid_h(ovs) {
      if (!ovs.length) return 0.25
      let gh = 0
      for (var ov of ovs) {
        if (ov.grid && typeof ov.grid.height === 'number') {
          gh += ov.grid.height
        }
      }
      return gh / ovs.length
    },
    setTrading(timestamp1, timestamp2, callback) {
      this.inside = true
      // console.log("scroller",this.chartWrapper)
      if (this.chartWrapper && this.chartWrapper.setTrading) {
        if (typeof this.chartWrapper.setTrading === 'function') {
          // console.log('set trading',timestamp1, timestamp2)
          this.chartWrapper.setTrading(timestamp1, timestamp2).then((d) => {
            callback()
          })
        }
      }
    },
  },
  updated() {
    // console.log("showLoader charts",this.showLoader)
  },

  computed: {
    ...mapGetters([
      'symbolName',
      'dc',
      'chartToggleVolume',
      'getUserSettingData',
      'protraderGeneralSettings',
      'chartSettings',
      'themeSettings',
      'themePrimaryColor',
      'themeSecondaryColor',
      'chartIDList',
      'userChartlist',
      'allChartOverlays',
      'userGeneralSettings',
      'themeFontColor',
      'drawingDataList',
      'ohlcv',
      'isTrainingMode',
      'trainingModeData',
      'trainingModeDataRaw',
      'settingUpdatesForCharts',
      'themeDarkMode',
      'userData'
    ]),
    getOhlcv() {
      return this.isTrainingMode ? this.trainingModeDataRaw : this.ohlcv
    },
    getSymbolName() {
      return this.symbolName
    },
    getGeneralUserSettingsWithProp() {
      return (payload) => {
        //upColor
        const { base, prop, backProp } = payload

        let protraderGeneralSettings = this.userGeneralSettings
        if (
          protraderGeneralSettings[base] &&
          protraderGeneralSettings[base][prop]
        ) {
          // console.log(base,prop, backProp,protraderGeneralSettings[base][prop].value)
          return protraderGeneralSettings[base][prop].value
        }
        return this.chartSettings[backProp]
      }
    },
    getGeneralUserSettingsForCrossHair() {
      return (payload) => {
        //upColor
        const { base, prop, backProp } = payload

        let protraderGeneralSettings = this.userGeneralSettings
        // console.log(base,prop, backProp,protraderGeneralSettings[base][prop])
        if (
          protraderGeneralSettings[base] &&
          protraderGeneralSettings[base]['enabled'] &&
          protraderGeneralSettings[base]['enabled'].value &&
          protraderGeneralSettings[base][prop]
        ) {
          // console.log(base,prop, backProp,protraderGeneralSettings[base][prop].value)
          return protraderGeneralSettings[base][prop].value
        }
        return this.chartSettings[backProp]
      }
    },
    settings() {
      return this.$store.state.chartSettings
    },

    /** @returns {Object} */
    chartContainerRef() {
      return this.$refs.chartContainer
    },
    /** @returns {Object} */
    tradingChart() {
      return this.$refs.tradingChart
    },
    /** @returns {Object} */
    chart() {
      return this.dc || this.chartData
    },
    /** @returns {Boolean} */
    showLoader() {
      return false
    },
    chartWrapper() {
      return this.$refs.chartWrapper
    },
    getBrushData() {
      if (this.isTrainingMode) {
        // console.log("this.dc.get('chart.data')",this.dc.get('chart.data'))
      }
      return this.isTrainingMode
        ? this.trainingModeDataRaw.map((d) => [
            d[0],
            Number.isNaN(d[4]) ? 0 : d[4],
          ])
        : this.ohlcv.map((d) => [d[0], d[4]])
    },
  },
  watch: {
    async settingUpdatesForCharts(n, o) {
      if (n.length) {
        await this.updateAllOverlay()
        this.$store.commit('USER_SET_UPDATE_CHARTS', [])
      }
    },
    userChartlist(n, o) {
      // console.log("userChartlist",n,o)
    },
    themeDarkMode(val) {
      if (val) {
        this.colors.grid = '#2C2929'
      } else {
        this.colors.grid = '#eee'
      }
    },
    symbolName(newSymbolName, oldSymbolName) {
      if (newSymbolName !== oldSymbolName) {
        this.chartData = new DataCube(dataJson)
      }
    },
    chartToggleVolume(chartToggleVolumn_new, chartToggleVolumn_old) {
      // console.log("chartToggleVolumn_new",chartToggleVolumn_new)
      if (chartToggleVolumn_new) {
        this.addVolume()
      } else {
        // console.log("chartToggleVolumn_new",this.chartWrapper.deleteByID)
        if (this.chartWrapper && this.chartWrapper.deleteByID) {
          this.chartWrapper.deleteByID('offchart.VolumeOBV0')
        }
      }
    },
  },
}
</script>

<style>
canvas {
  /* all four are needed to support the most browsers */
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

.t-vue-lspan {
  font-variant-numeric: tabular-nums;
  font-size: 0.95em;
  color: #7c7c7c !important; /* TODO: move => params */
  margin-left: 0.1em;
  margin-right: 0.2em;
}

/* .chart-height {
  height: 88% !important;
} */
</style>
