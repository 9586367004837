<script>
// Price/Time measurment tool
import { Overlay, Tool } from 'trading-vue-js'
import Pin from './primitives/pin.js'
import Math2 from './primitives/math.js'
import { mapGetters } from 'vuex'
import ToolSavingMixins from 'Tools/ToolSavingMixins'
export default {
  name: 'RectangleTool',
  mixins: [Overlay, Tool, ToolSavingMixins],
  mounted() {
    console.log('user props color', this.$props.settings?.color)
    console.log('user props lineColor', this.$props.settings?.lineColor)    
  },
  methods: {
    meta_info() {
      return { author: 'LakshanPerera', version: '1.0.0' }
    },
    tool() {
      let prevSettings = localStorage.getItem('RectangleTool')
      if (prevSettings) {
        prevSettings = JSON.parse(prevSettings)
      }
      // console.log("tool prevSettings",prevSettings)
      //{"color":"#2346A757","lineWidth":2,"lineStyle":"dashed","lineColor":"#6BA8ECFF"}
      const defaultColor = prevSettings?.color || '#E1E1E2B3' //  '#23a776FF'
      const defaultLineColor = prevSettings?.lineColor || "#2780E3" //'#23a776'
      const defaultLineWidth = prevSettings?.lineWidth || 2
      const defaultLineStyle = prevSettings?.lineStyle || 'solid'
      // console.log("defaultColor",defaultColor)
      // console.log("defaultLineColor",defaultLineColor)
      return {
        // Descriptor for the tool
        group: 'Shape',
        type: 'Shape',
        hint: 'Rectangle Tool',
        data: [], // Default data
        settings: {
          color:  defaultColor , //'#23a776'  
          lineColor:  defaultLineColor, //'#23a776'
          lineWidth: defaultLineWidth,
          lineStyle: defaultLineStyle,
          settingsList: ['color', 'lineWidth', 'lineStyle', 'lineColor'],
        }, // Default settings
      }
    },
    // Called after overlay mounted
    init() {
      // First pin is settled at the mouse position
      this.pins.push(
        new Pin(this, 'p1', {
          hidden: this.shift,
        })
      )
      // Second one is following mouse until it clicks
      this.pins.push(
        new Pin(this, 'p2', {
          state: 'tracking',
          hidden: this.shift,
        })
      )
      console.log("computed lineStyle this.sett.color",this.sett.color)
      this.pins[1].on('settled', () => {
        this.set_state('finished')
        this.updateSettingsState(this)
        this.$emit('drawing-mode-off')
        // Deselect the tool in shiftMode
        if (this.shift) {
          this._$emit('custom-event', {
            event: 'object-selected',
            args: [],
          })
        } else {
          this.$emit('object-selected')
        }
      })
      this.registerMouseEvent(this)
    },
    draw(ctx) {
      if (!this.p1 || !this.p2) return
      const layout = this.$props.layout
      // Background
      // ctx.globalAlpha = 0.3
      let x1 = layout.t2screen(this.p1[0])
      let y1 = layout.$2screen(this.p1[1])
      let x2 = layout.t2screen(this.p2[0])
      let y2 = layout.$2screen(this.p2[1])

      ctx.fillStyle = this.color

      ctx.lineWidth = this.line_width
      ctx.strokeStyle = this.line_color
      ctx.beginPath()
      //--- line style
      const lineStyle = this.line_style
      // console.log("lineStyle",lineStyle)
      if (lineStyle === 'dashed') {
        ctx.setLineDash([10, 10])
      } else if (lineStyle === 'dotted') {
        ctx.setLineDash([1, 1])
      } else {
        ctx.setLineDash([])
      }

      ctx.moveTo(x1, y1)
      ctx.lineTo(x2, y1)
      ctx.lineTo(x2, y2)
      ctx.lineTo(x1, y2)
      ctx.closePath()

      ctx.fill()

      ctx.stroke()
      

      // for collisions and moving the object
      this.collisions.push(this.make([x1, y1], [x2, y2]))

      ctx.globalAlpha = 1
      this.render_pins(ctx)
    },
    make(p1, p2) {
      return (x, y) => {
        return Math2.is_within_rectangle([x, y], p1, p2)
      }
    },
    use_for() {
      return ['RectangleTool']
    },
    data_colors() {
      return [this.color]
    },
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters([
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
    ]),
    sett() {
      return this.$props.settings
    },
    preferedColor() {
        return this.colorSetting?.color || '#23a776'
    },
    preferedLineColor() {
      return this.colorSetting?.lineColor || '#23a776'
    },
    preferedLineStyle() {
      return this.colorSetting?.lineStyle || ''
    },
    p1() {
      return this.$props.settings.p1
    },
    p2() {
      return this.$props.settings.p2
    },
    line_width() {
      return this.sett.lineWidth || 1
    },
    line_color() {
      const computedLineColor = this.sett.lineColor || this.preferedLineColor;
      console.log("computedLineColor",computedLineColor)
      console.log("computed color",{
        "this.sett.color":this.sett.color,
        "this.preferedColor":this.preferedColor,
      })
      return   computedLineColor
    },
    color() {
      const computedColor = this.sett.color || this.preferedColor;
      console.log("computed color",{
        "this.sett.color":this.sett.color,
        "this.preferedColor":this.preferedColor,
      })
      return   computedColor
      // return   this.sett.color || this.preferedColor
    },
    line_style() {
      const computedColor = this.sett.lineStyle || this.preferedLineStyle;
      console.log("computed lineStyle",{
        "this.sett.lineStyle":this.sett.lineStyle,
        "this.preferedLineStyle":this.preferedLineStyle,
      })
      return   computedColor
      // return   this.sett.color || this.preferedColor
    },
    borderColor() {
      return this.sett.borderColor || this.$props.colors.cross
    },
    back_color() {
      return this.sett.backColor || '#9b9ba316'
    },
    value_back() {
      return this.sett.valueBack || '#9b9ba316'
    },
    value_color() {
      return this.sett.valueColor || this.$props.colors.text
    },
    prec() {
      return this.sett.precision || 2
    },
    new_font() {
      return '12px ' + this.$props.font.split('px').pop()
    },
    price() {
      return 'price' in this.sett ? this.sett.price : true
    },
    time() {
      return 'time' in this.sett ? this.sett.time : false
    },
    shift() {
      return this.sett.shiftMode
    },
  },
  data() {
    return {
      colorSetting:"",
      userLineColor:"",
      userColor:"",
    }
  },
  created() {
    console.log("RectangleTool Created")
    let prevSettings = localStorage.getItem('RectangleTool');
    if(prevSettings) {
      prevSettings = JSON.parse(prevSettings)
      this.colorSetting=prevSettings
    }
    this.updateSettingsState(this)
  },
}
</script>
