<template>
  <dialog-box
    modalHeader=""
    :open="open"
    :action="false"
    :scrolling="false"
    :size="`large`"

  >
    <div class="flex flex-col gap-0 h-full w-full">
      <!--    New Modal Hearder start -->
      <div
        class="w-full h-full flex items-center justify-between py-2 pl-2 border-b dark:border-[#2D2D2D]!important border-[#E2E8F0]!important"
      >
        <div>
          <span class="md:text-[18px] font-sans font-[600]">Market Insight - {{scannerName}}</span>
        </div>

        <div
          @click="closeModal"
          class="cursor-pointer p-[8px] rounded-md hover:!bg-appBtnHover dark:!bg-[#FFFFFF1A] dark:hover:!bg-[#2D2D2D]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            class="h-[18px] w-[18px]"
          >
            <path
              stroke="currentColor"
              stroke-width="1.2"
              d="m1.5 1.5 15 15m0-15-15 15"
            ></path>
          </svg>

        </div>
      </div>
      <!--    New Modal Hearder end -->

      <!--  scanner area start  -->
      <div class="flex h-[70vh] w-full border overflow-hidden mt-10">
        <div class="flex flex-col h-full w-[20rem] border-r">
          <span class="text-[16px] font-medium">Scan Type</span>
          <div class="flex flex-col h-[69vh] w-full py-2 overflow-y-auto gap-3 mt-3 scrollbar">
            <div @click="scannerCall(scanner)" v-for="(scanner, i) in insightData" :key="scanner.id" class="h-[10vh] w-full items-center flex cursor-pointer p-3 gap-4"
                 :class="scannerName === scanner.name ? `bg-black text-white`:`hover:bg-gray-50 text-black`"
            >
              <img class="h-5 w-5" src="../../assets/short_logo.png"/>
              <span  class=" text-[15px]">{{scanner.name}}</span>
            </div>
          </div>
        </div>
        <div class="flex h-[20vh] w-full">
          <component :is="scannerState" />
        </div>
      </div>
      <!--  scanner area end  -->


    </div>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import TodaysTopGainers from '../MarketInsightScanners/TodaysTopGainers.vue'
import TodaysTopLosers from '../MarketInsightScanners/TodaysTopLosers.vue'
import FiftyTwoWeeksHigh from '../MarketInsightScanners/FiftyTwoWeeksHigh.vue'
import FiftyTwoWeeksLow from '../MarketInsightScanners/FiftyTwoWeeksLow.vue'
import PennyStocks from '../MarketInsightScanners/PennyStocks.vue'
import LowBudgetStocks from '../MarketInsightScanners/LowBudgetStocks.vue'
import GapUpSotcks from '../MarketInsightScanners/GapUpSotcks.vue'
import GapDownSotcks from '../MarketInsightScanners/GapDownSotcks.vue'
import SteadyGrowthSotcks from '../MarketInsightScanners/SteadyGrowthSotcks.vue'
import HighVolumeSotcks from '../MarketInsightScanners/HighVolumeSotcks.vue'

export default {
  name: 'SearchSymbol',
  components: {
    DialogBox,
    TodaysTopGainers,
    TodaysTopLosers,
    FiftyTwoWeeksHigh,
    FiftyTwoWeeksLow,
    PennyStocks,
    LowBudgetStocks,
    GapUpSotcks,
    GapDownSotcks,
    SteadyGrowthSotcks,
    HighVolumeSotcks,
  },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('change', !open)
    },
    closeModal() {
      this.onClose()
    },
    scannerCall(item) {
      this.scannerName = item.name
      const selectedScanner = this.insightData.find(scanner => scanner.id === item.id);
      if (selectedScanner) {
        this.scannerState = selectedScanner.component;
      }
    }
  },
  data() {
    return {
      insightData : [
        {
          id: 'topGainers',
          name:`Today's Top Gainers`,
          logo:'https://app.protradingscans.com/img/logo.8225e2f2.svg',
          component: TodaysTopGainers
        },{
          id: 'topLosers',
          name:`Today's Top Losers`,
          logo:'https://app.protradingscans.com/img/logo.8225e2f2.svg',
          component: TodaysTopLosers
        },{
          id: '52WeeksHigh',
          name:`52-Weeks High`,
          logo:'https://app.protradingscans.com/img/logo.8225e2f2.svg',
          component: FiftyTwoWeeksHigh
        },{
          id: '52WeeksLow',
          name:`52-Weeks Low`,
          logo:'https://app.protradingscans.com/img/logo.8225e2f2.svg',
          component: FiftyTwoWeeksLow
        },
        {
          id: 'pennyStocks',
          name:`Penny Stocks`,
          logo:'https://app.protradingscans.com/img/logo.8225e2f2.svg',
          component: PennyStocks
        },{
          id: 'lowBudgetStocks',
          name:`Low Budget Stocks\n(Under $20)`,
          logo:'https://app.protradingscans.com/img/logo.8225e2f2.svg',
          component: LowBudgetStocks
        },{
          id: 'gapUpStocks',
          name:`Gap-Up Stocks`,
          logo:'https://app.protradingscans.com/img/logo.8225e2f2.svg',
          component: GapUpSotcks
        },{
          id: 'gapDownStocks',
          name:`Gap-Down Stocks`,
          logo:'https://app.protradingscans.com/img/logo.8225e2f2.svg',
          component: GapDownSotcks
        },{
          id: 'steadyGrowthStocks',
          name:`Steady Growth Stocks`,
          logo:'https://app.protradingscans.com/img/logo.8225e2f2.svg',
          component: SteadyGrowthSotcks
        },{
          id: 'highVolumeStocks',
          name:`High Volume Stocks`,
          logo:'https://app.protradingscans.com/img/logo.8225e2f2.svg',
          component: HighVolumeSotcks
        }
      ],
      scannerState: 'TodaysTopGainers',
      scannerName:`Today's Top Gainers`
    }
  },
  watch: {
    themeDarkMode(val) {
      if (val) {
        this.darkBackColor = '#505050'
        this.darkFontColor = '#A2A2A2'
        this.darkBorderColor = '#505050'
      } else {
        this.darkBackColor = 'white'
        this.darkFontColor = 'black'
        this.darkBorderColor = '#eee'
      }
    },
    open(n, o) {
      // console.log("watching modal open close",n,o)
      if (n) {
        this.selectMarketRef(this.selectedMarket)
      }
    },
  },
}
</script>

<style>
.symbolList {
  display: flex;
  justify-content: space-between;
}

.symbolList button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 100;
  width: 100%;
  font-size: 12px;
}

.symbolList button:hover {
  background-color: #000000;
}

.selected-chart {
  /* background-color: red; */
  color: #ffffff !important;
  border-bottom: 0px !important;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.header {
  font-size: 12.3px;
}

.ui.fluid.input > input {
  background-color: v-bind(darkBackColor);
  color: v-bind(darkFontColor);
  border: 1px solid v-bind(darkBorderColor);
}
</style>
