<template>
    <dialog-box
     :scanName="scanName"
     :modalHeader="header"
     :open="open"
     :action="false"

    >
    <div >
      <sui-segment :loading="isLoading" :inverted="themeDarkMode"  v-bind:style="customStyle" :style="themeDarkMode ? {'background-color':'#151515', border:'1px solid #2C2929'}:{}">
        <sui-form >
          <slot />
          <DataSettings :id="id" type="scanner" />
        </sui-form>  
      </sui-segment>
      
    </div>
    
        <!-- <slot></slot>  
        <data-settings :id="ProRevresal" type="proScan" />  -->

        <!-- <template v-slot:action>



      <sui-modal-actions :style="{ ...themePrimaryColor, ...themeFontColor }">
        <div class="bottom_layout bottom_layout_Scan">
          <div>
            <ScanBottomForm
              :valid="valid"
              :scanType="scanType"
              
            />
            
          </div>
          <div :style="hideFav? 'display:flex;  justify-content: space-between; width:100%':''" class="">
            <div>
              <sui-button
                icon="undo"
                key="onResetGurus"
                secondary
                @click="ResetScanDefaultSettings"
                v-if="showResetter"
              />
            </div>
            <div>
              <sui-button
                :disabled="!valid"
                :style="themeSecondaryColor"
                @click.native="stopScan"
                v-if="isLoading"
              >
                Stop
              </sui-button>
              <sui-button
                style="color: white"
                :disabled="!valid"
                :style="themeSecondaryColor"
                @click.native="startScan"
                v-if="!isLoading"
              >
                Scan
              </sui-button>
              <sui-button @click.native="onClose" secondary>
                Cancel
              </sui-button>
            </div>
          </div>
        </div>
      </sui-modal-actions>
    </template> -->

    <template v-slot:action>
      <sui-modal-actions class="gurus-action-bar" :style="{...themePrimaryColor,...themeFontColor}">
        <div>
          <sui-button icon="undo" secondary  />
        </div>
        <div>
          <sui-button
            :disabled="!valid"
            :style="themeSecondaryColor"
            
            v-if="isLoading"
          >
            Stop
          </sui-button>
          <sui-button
          style="color:white;"
            :disabled="!valid"
            :style="themeSecondaryColor"
            @click.native="startScan"
            v-if="!isLoading"
          >
            Scan
          </sui-button>
          <sui-button @click.native="onClose" secondary>Cancel</sui-button>
        </div>
      </sui-modal-actions>
    </template>
    </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import DataSettings from './DataSettings.vue'
import { mapGetters } from 'vuex';


export default {
  name: 'ProScansBaseModal',
  components: {
    DialogBox,
    DataSettings
  },
  model:{
    prop: 'open',
    event: 'change'
  }, 
  props: {
    open: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: "",
    },
    scanType:{
      type: String,
      default: ""
    },
    header:{
       type: String,
       default: "" 
    },
    valid:{
      type: Boolean,
      default: false
    },
    isLoading:{
      type: Boolean,
      default: false
    },
    
    
  },


  methods: {
    onClose() {
        console.log("onClose", this.id)
      this.$emit('onClose')
    },
    startScan(){
        this.$emit('ok')
    }
    
  },
  computed:{
        ...mapGetters(['themeFontColor','themePrimaryColor','themeSecondaryColor', 'themeDarkMode'])
    }
}
</script>
<style >
.gurus-action-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
