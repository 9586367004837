<template>
  <div class="w-full h-full relative flex flex-col items-center" :style="themePrimaryColor">
    <!-- Left Bar Toolbar -->
    <div class="left-tool-bar">
      <!-- Drawing Tools -->
      <div class="drawing-tools">
        <sui-button-group vertical>
          <!-- Tool Buttons with Tooltips -->
          <div
            v-for="tool of toolButtons"
            :key="tool.img"
            class="toolTipCon w-[4rem] h-[3.3rem] md:w-[43.2px] md:h-[43.2px] dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer items-center justify-center"
            :class="
              tool.key == getCurrentDrawing
                ? 'bg-btnActiveLite'
                : 'dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover'
            "
            v-tooltip.right="{ content: tool.toolTip, class: 'tooltip-custom' }"
            @click="tool.onClick"
          >
            <div>
              <div v-html="getSvg(tool?.img, tool.key == getCurrentDrawing)"></div>
            </div>
          </div>

          <!-- Emoji Button with Tooltip -->
          <div
            v-for="tool in emojiButton"
            :key="tool.img"
            class="toolTipCon w-[4rem] h-[3.3rem] md:w-[43.2px] md:h-[43.2px] dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer items-center justify-center"
            :class="
              tool.key == getCurrentDrawing
                ? 'bg-btnActiveLite'
                : 'dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover'
            "
            v-tooltip.right="{ content: tool.toolTip, class: 'tooltip-custom' }"
            @click="tool.onClick"
          >
            <div>
              <div v-html="getSvg(tool?.img, tool.key == getCurrentDrawing)"></div>
            </div>
          </div>

          <!-- Delete Button with Tooltip -->
          <div
            class="toolTipCon w-[4rem] h-[3.3rem] dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer items-center dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover justify-center"
            v-tooltip.right="{ content: 'Delete All Drawings', class: 'tooltip-custom' }"
            @click="onDeleteButtonClick"
          >
            <div>
              <div v-html="getSvg('delete')"></div>
            </div>
          </div>
        </sui-button-group>
      </div>
    </div>

    <!-- Emoji Picker -->
    <div
      v-if="openEmoji"
      class="top-[30rem] fixed -absolute xxs:left-20 sm:left-0 sm:mb-0 md:left-20 xxs:!mt-[-2.5rem] sm:!mt-[0rem] md:!mt-[-20rem]"
      style="z-index: 9999 !important"
    >
      <VuemojiPicker @emojiClick="handleEmojiClick" :isDark="false" />
    </div>

    <!-- Trailing Stop Dialog -->
    <trailing-stop
      v-if="showTrailingStop"
      :trialingStopData="trialingStopData"
      @hideTrailingStop="trailingStopDialogOnClose"
    />
  </div>
</template>

<script>
import CustomImageIconButton from '@/baseComponents/CustomImageIconButton.vue';
import TrailingStop from 'Components/dialogBoxes/TrailingStop';
import { mapActions, mapGetters } from 'vuex';
import { chartEvents } from '../chartEvents';
import { VuemojiPicker } from 'vuemoji-picker';
import { getSvgByName } from './SvgUtils';
import store from '@/store';

export default {
  components: { CustomImageIconButton, TrailingStop, VuemojiPicker },
  name: 'LeftBar',
  data() {
    return {
      trialingStopData: {},
      showTrailingStop: false,
      openEmoji: false,
      selectedEmoji: null,
      toolButtons: [
        {
          img: 'ruler',
          onClick: () => this.selectTool('RangeTool:PriceTime'),
          toolTip: 'Price Measurement Tool',
          key: 'RangeTool:PriceTime',
        },
        {
          img: 'trendLine',
          onClick: () => this.selectTool('TrendLine:Trend'),
          toolTip: 'Line Tool',
          key: 'TrendLine:Trend',
        },
        {
          img: 'horizontalLine',
          onClick: () => this.selectTool('HLineTool:Segment'),
          toolTip: 'Horizontal Line Tool',
          key: 'HLineTool:Segment',
        },
        {
          img: 'arc',
          onClick: () => this.selectTool('ArcTool:Arc'),
          toolTip: 'Arc Tool',
          key: 'ArcTool:Arc',
        },
        {
          img: 'T',
          onClick: () => this.selectTool('TextTool:Label'),
          toolTip: 'Text Tool',
          key: 'TextTool:Label',
        },
        {
          img: 'emoji',
          onClick: () => this.handleOpenEmoji(),
          toolTip: 'Emoji Tool',
          key: 'EmojiTool:Label',
        },
        {
          img: 'rectangle',
          onClick: () => this.selectTool('RectangleTool:Shape'),
          toolTip: 'Box Tool',
          key: 'RectangleTool:Shape',
        },
        {
          img: 'parallelLine',
          onClick: () => this.selectTool('ChannelTool:Segment'),
          toolTip: 'Parellel Lines Tool',
          key: 'ChannelTool:Segment',
        },
        {
          img: 'fibRetracement',
          onClick: () => this.selectTool('FibonacciRetrace:Segment'),
          toolTip: 'Fibonacci Retracement Tool',
          key: 'FibonacciRetrace:Segment',
        },
        {
          img: 'tradePlanner',
          onClick: () => this.selectTool('TradeVisualizerTool:PL'),
          toolTip: 'Trade Visualizer',
          key: 'TradeVisualizerTool:PL',
        },
        {
          img: 'tradeStop',
          onClick: () => this.selectTool('TrailingStopTool:PL'),
          toolTip: 'Trailing Stop',
          key: 'TrailingStopTool:PL',
        },
      ],
    };
  },
  methods: {
    ...mapActions(['enterThemehoverColor', 'exitElementColor', 'deleteAllDrawing']),
    trailingStopDialogOnClose() {
      this.showTrailingStop = false;
    },
    onDeleteButtonClick() {
      chartEvents.$emit('onDeleteButtonClick');
    },
    handleOpenEmoji() {
      this.openEmoji = !this.openEmoji;
    },
    handleEmojiClick(detail) {
      this.openEmoji = false;
      localStorage.setItem('emoji', detail?.emoji?.unicode);
      this.selectedEmoji = { text: detail?.emoji?.unicode };
      this.selectTool('EmojiTool:Label');
    },
    selectTool(toolID) {
      store.commit('SET_CURRENT_DRAWING', toolID);
      chartEvents.$emit('generateCustomEvent', 'tool-selected', [toolID]);
    },
    getSvg(name, isTrue = false) {
      return getSvgByName(name, isTrue);
    },
  },
  computed: {
    ...mapGetters([
      'getCurrentDrawing',
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
    ]),
  },
  mounted() {
    chartEvents.$on('updateTrailingStopModal', (params) => {
      this.trialingStopData = params;
      this.showTrailingStop = true;
    });
  },
  beforeDestroy() {
    chartEvents.$off('updateTrailingStopModal');
  },
};
</script>

<style scoped>
.left-bar-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #1a1a1a; /* Example background color */
  width: 60px; /* Adjust width as needed */
  padding: 10px;
  box-sizing: border-box;
}

.left-tool-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.drawing-tools {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.toolTipCon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-custom {
  background-color: #fff; /* Tooltip background color */
  color: #000; /* Tooltip text color */
  animation-duration: 1.5s;
  animation-delay: 1s;
  font-size: 0.875rem; /* Adjust font size */
  padding: 8px 12px; /* Inner padding */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Drop shadow */
  border: 1px solid #666; /* Border color */
  right: -100px;
  position: absolute;
  max-width: 200px; /* Limit width */
  z-index: 1000000 !important; /* Ensure it’s above other elements */
}

.emoji-picker-container {
  position: absolute;
  left: 70px; /* Adjust position as needed */
  bottom: 10px;
  z-index: 9999;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

/* Custom styles for dropdowns and buttons */
.sui-dropdown {
  width: 100%;
}

.sui-button-group.vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sui-button-group.vertical .sui-button {
  margin: 5px 0;
}
</style>