import {mapGetters} from "vuex";
import {copy as copyObject} from "../utils";
import {Utils} from "trading-vue-js"


export default {
    methods: {
        findGridId(ref, self) {
            let gid = 0
            let findVal = false
            // console.log("findGridId",self.chart)
            for (const gldcKey in self.chart.gldc) {
                // console.log(ref,gldcKey,gldcKey.startsWith("g0"),self.chart.gldc[gldcKey])
                if (gldcKey.startsWith("g0")) {
                    continue;
                }
                let cid = self.chart.gldc[gldcKey]
                if (ref === cid) {
                    gid++
                    findVal = true
                    break;
                } else {
                    gid++
                }
            }
            return findVal ? gid : -1
        },
        addingChartDrawings(e, self) {
            let ref = `${e.side}.${e.type}0`
            let currentId = this.findGridId(ref, self)
            //console.log("addingChartDrawings ",ref,currentId,this.drawingDataList)
            if (currentId > -1 && this.drawingDataList.length) {
                let drawingData = this.drawingDataList.filter(f => f.gridRef === e.type)
                // console.log("drawingData ",JSON.stringify(drawingData))
                if (drawingData.length) {
                    for (const drawingDatum of drawingData) {
                        if (drawingDatum.settings && drawingDatum.settings.$selected) {
                            drawingDatum.settings.$selected = false
                        }
                        // set state finised
                        drawingDatum.grid.id = currentId
                        drawingDatum.settings.gridRef = drawingDatum.gridRef
                        self.chart.data.offchart.push(drawingDatum)
                    }

                }
            }
        }
    },
    computed: {
        ...mapGetters(["ohlcvTimeMap", "drawingData", "drawingDataList", "timeFrame", "tv"])
    },
    watch: {
        drawingData: {
            handler(symbolDrawingData) {
                // console.log("drawing-pro  drawingDataList 1",symbolDrawingData)
                if (this.chart && this.chart.data) {
                    let drawingDataList = []
                    for (let dataRef in symbolDrawingData) {

                        let settings = JSON.parse(symbolDrawingData[dataRef])
//                        console.log("drawing-pro dataRef",symbolDrawingData[dataRef])
                        const toolId = dataRef.split('-')[0]
                        // let type = settings.type
                        let type = dataRef.split('.', 1)?.[0]

                        const tool = this.chart.data[type].find(
                            (tool) => tool.id === toolId
                        )
                        // console.log("DrawSettings ",this.chart.data,type,tool)
                        drawingDataList.push(settings)
                        let drawIt = true;
                        let gridData
                        let gridCurrentId
                        if (type === 'offchart') {
                            let offChartGridId = `${type}.${settings.gridRef}`;
                            gridData = this.chart.get(offChartGridId);
                            gridCurrentId = this.findGridId(`${offChartGridId + '0'}`, this)
                            drawIt = gridData.some(d => !d.scanResults)
                        }
                        /*console.log('drawing-pro drawingDataList 3 ', {
                            id:tool?.id,
                            name:tool?.name,
                            uuid:tool?.settings?.$uuid,
                            drawIt,
                            gridData, 
                            gridRef:settings.gridRef
                        })*/
                        // drawingDataList.push(settings)

                        if (!tool && drawIt) {

                            if (
                                settings &&
                                settings.settings &&
                                settings.settings.$selected
                            ) {
                                settings.settings.$selected = false
                                settings.settings['gridRef'] = settings.gridRef
                            }
                            settings['gridRef'] = settings.gridRef
                            // if(this.timeFrame !== settings['timeFrame']){
                            const p1 = settings?.settings?.p1;
                            const p2 = settings?.settings?.p2;
                            const p3 = settings?.settings?.p3;
                            const tiMap = this.tv?.$refs?.chart?.ti_map;
                            if (tiMap && p1 && p1?.length >= 2) {
                                let calculateDataIndexWithoutTiMap = Utils.calculate_data_index_without_ti_map(this.ohlcvTimeMap, p1?.[2])
                                // const p1MapVal = tiMap.t2i(p1?.[2]);
                                // console.log("DrawSettings p1 ==  ",p1,calculateDataIndexWithoutTiMap)
                                settings.settings.p1 = [calculateDataIndexWithoutTiMap?.index, p1[1], p1[2]]
                            }
                            if (tiMap && p2 && p2?.length >= 2) {
                                let calculateDataIndexWithoutTiMap2 = Utils.calculate_data_index_without_ti_map(this.ohlcvTimeMap, p2?.[2])
                                // console.log("DrawSettings p2 == ",p2,calculateDataIndexWithoutTiMap2)
                                // const p2MapVal = tiMap.t2i(p2?.[2]);
                                // console.log("DrawSettings == ",[calculateDataIndexWithoutTiMap2?.index, p2[1], p2[2]])
                                settings.settings.p2 = [calculateDataIndexWithoutTiMap2?.index, p2[1], p2[2]]
                            }
                            if (tiMap && p3 && p3?.length >= 2) {
                                // console.log("DrawSettings == ",p3,tiMap.t2i(p3?.[2]))
                                // const p3MapVal = tiMap.t2i(p3?.[2]);
                                let calculateDataIndexWithoutTiMap3 = Utils.calculate_data_index_without_ti_map(this.ohlcvTimeMap, p3?.[2])
                                // console.log("DrawSettings == ",p3,p3MapVal)
                                settings.settings.p3 = [calculateDataIndexWithoutTiMap3?.index, p3[1], p3[2]]
                            }
                            // }
                            //     settings['timeFrame'] = 'D'

                            // console.log("TrailingStopTool Adding ",JSON.stringify(settings.settings))
                            settings['timeFrame'] = settings.timeFrame
                            settings['saveTimeFrame'] = settings.timeFrame
                            // if(settings?.id)
                            // settings['id'] = settings.id
                            if (settings?.modeId)
                                settings['modeId'] = settings.modeId
                       

                            let copy = copyObject(settings)


                            if (type === 'offchart') {
                                copy.grid.id = gridCurrentId
                            }


                            copy.settings.$selected = false
                            copy.settings.$state = 'finished'
                            
                            this.tv.data.add(type, copy)
                        }

                        // context.state.dc[type]
                    }

                    this.$store.commit('UPDATE_USER_DRAWING_LIST', drawingDataList)

                }
            },
            deep: true
        }
    }
}   
