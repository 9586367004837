<template>
  <dialog-box
    :modalHeader="modelHeader"
    :open="open"
    class="fontSize"
    v-model="open"
    @onClose="onCancel"
    @ok="onSave"
  >
    <div class="input-container fontSize" v-if="isPnf">
      <span> Box size </span>
      <input
        style="width: 10ch"
        type="number"
        min="0.1"
        step="0.01"
        v-model="boxSize"
      />
    </div>
    <div class="input-container fontSize" v-if="isPnf">
      <span> Reversal </span>
      <input
        style="width: 10ch"
        type="number"
        min="1"
        step="1"
        v-model="reversal"
      />
    </div>
    <sui-form-fields>
       <sui-form-field style="margin-bottom:10px">
        <sui-input
          v-model="maxPrice"
          type="number"
          tabindex="1"
      
        />
        Max price scale
      </sui-form-field>
      <sui-form-field style="margin-bottom:10px" >
        <sui-input
          v-model="minPrice"
          type="number"
          tabindex="1"
  
        />
        Min price scale
      </sui-form-field>
    </sui-form-fields>
    <sui-button  style="margin-bottom:10px" @click.prevent="handleResetScale()" size="mini">
      Set Default Scale
    </sui-button>
  </dialog-box>
</template>

<script>
import DialogBox from '@/baseComponents/DialogBox.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { chartEvents } from '../../chartEvents'
export default {
  components: { DialogBox },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  name: 'MainChartProps',
  data() {
    return {
      isLoading: false,
      maxPrice: 0,
      minPrice: 0,
      boxSize: 0,
      reversal: 2,
    }
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['MAIN_CHART_PROPS']),
    onCancel() {
      this.MAIN_CHART_PROPS(!open)
    },
    handleResetScale() {
      this.tv.toggleSideBarYAxis()
      this.minPrice=0;
      this.maxPrice=0;
      this.MAIN_CHART_PROPS(!open)
    },
    onSave() {
      if (this.isPnf) {
        chartEvents.$emit('PnfValueUpdate', {
          boxSize: this.boxSize,
          reversal: this.reversal,
        })
      }
      console.log("[this.max,this.min]",[this.maxPrice+2,this.minPrice+2])
      this.tv?.toggleSidebarCustomRange([this.maxPrice,this.minPrice])
      // setTimeout(()=>{
      //   this.dc.tv.updateChart()
      // },5000)
      this.MAIN_CHART_PROPS(!open)
    },
    PnfValueSetterToModal(val) {
      this.boxSize = val.boxSize
      this.reversal = val.reversal
    },
  },
  computed: {
    ...mapGetters(['getChartType', 'tv','dc']),
    modelHeader() {
      return `Chart Settings ${this.getChartType}`
    },
    isPnf() {
      return this.getChartType === 'PnF'
    },
    isCandles() {
      return this.getChartType === 'Candles'
    },
  },
  mounted() {
    chartEvents.$on('PnfValueSetterToModal', this.PnfValueSetterToModal)
  },
  beforeDestroy() {
    console.log('PnfValueSetterToModal destroy')
    chartEvents.$off('PnfValueSetterToModal')
  },
}
</script>

<style>
.input-container {
  display: flex;
  align-items: center;
  height: max-content;
  width: 100%;
  margin-top: 0.1rem;
  font-size: 15px;
}
.input-container > * {
  margin-right: 0.3rem;
}
</style>
