<template>
  
      <sui-table-row>        
        <sui-table-cell :width="15">
          <input placeholder="20" v-model="propertyObj.period" type="number" @change.prevent="changeEffects"/>
        </sui-table-cell>
        <sui-table-cell :width="1">          
            <color-picker v-model="propertyObj.color" :isSettingsPicker="true" v-on:input="changeEffects" :field-ref="getRef" type="color"/>          
        </sui-table-cell>
        <sui-table-cell :width="1">
          <sui-button size="mini" icon="times"   :disabled="itemid < min" @click.p.prevent="removeItem" />
        </sui-table-cell>
      </sui-table-row>
  
</template>
<script>
import ColorPicker from '@/baseComponents/ColorPicker.vue'
export default {
  components:{ColorPicker},
  name: 'MultiValueSettingForm',
  props:["period","color","itemid","parentId","propName","min"],
  data(){
      return {
        propertyObj:{
            period:0,
            color:"",
          }        
      }
    
  },
  methods:{
    removeItem(){
      this.$emit('removeItem',this.parentId,this.itemid,this.propName)
    },
    changeEffects(){
      this.$emit('changeEffects',this.propertyObj)
    }
  },
  created() {
    
    this.propertyObj = {
      period:this.period,
      color:this.color,
      itemid:this.itemid,
      parentId:this.parentId,
      propName:this.propName,  
    }
    // console.log('propertyObj -- ',JSON.stringify(this.propertyObj))
  },
  computed:{
     getRef(){
       return this.propName+"."+this.itemid
     }
  }
}
</script>