<template>
  <div style="padding: 16px">
    <sui-form>
      <h3>Select one or more of the risk management options</h3>
      <sui-form-field>
        <sui-checkbox label="ATR" v-model="formData.atr" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="% Value" v-model="formData.pctValue" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Data Point" v-model="formData.dataPoint" />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox label="Support Level" v-model="formData.supportLevel" />
      </sui-form-field>
    </sui-form>
  </div>
</template>

<script>
export default {
  computed: {
    /** @returns {Object} */
    formData: {
      get() {
        return this.$store.state.tadePlanner.riskManagementSignalData;
      },
      set(value) {
        this.$store.commit("UPDATE_TRADE_PLANNER_STATE", {
          key: "riskManagementSignalData",
          data: value,
        });
      },
    },
  },
  name: "RiskManagement",
};
</script>

<style></style>
